import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Button, Col, Row } from "reactstrap";
import CommonInput from "../sys-common/CommonInput";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import FreeStockProductService from "../../services/srv-freeStock/FreeStockProductService";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import FreeStockPutoLogDetail from "./FreeStockPutoLogDetail";

const FreeStockPutoLog = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataItem, setDataItem] = useState([]);
  const [teklifSiparis, setTeklifSiparis] = useState(null);
  const [teklifSiparisId, setTeklifSiparisId] = useState(null);
  const [visibleLogDetail, setVisibleLogDetail] = useState(false);

  const logDetailToggle = (data) => {
    setDataItem(data);
    setVisibleLogDetail(!visibleLogDetail);
    visibleLogDetail && getProductsData();
  };

  const getProductsData = () => {
    setIsLoading(true);
    FreeStockProductService.getByTeklifSiparisId(
      teklifSiparis,
      teklifSiparisId,
      onCallbackGetProducts
    );
  };
  const onCallbackGetProducts = (data) => {
    setData(data.data);
    setIsLoading(false);
  };

  return (
    <div style={{ padding: "20px" }}>
      {visibleLogDetail ? (
        <FreeStockPutoLogDetail
          modalToggle={logDetailToggle}
          data={dataItem}
        ></FreeStockPutoLogDetail>
      ) : (
        ""
      )}
      {isLoading && LoadingPanel}
      <Card>
        <CardBody>
          <Row>
            <Col>
              <CommonDropDownList
                id="teklif_siparis"
                label="Teklif/Sipariş"
                data={[
                  { id: "TEKLIF", text: "Teklif" },
                  { id: "SIPARIS", text: "Sipariş" },
                ]}
                onChange={(e) => setTeklifSiparis(e.target.value.id)}
                value={teklifSiparis}
              />
            </Col>
            <Col>
              <CommonInput
                id="teklif_siparis_id"
                name="teklif_siparis_id"
                label="Teklif/Sipariş Id"
                value={teklifSiparisId}
                onChange={(e) => setTeklifSiparisId(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                style={{ marginTop: "10px" }}
                color="primary"
                onClick={getProductsData}
              >
                Ara
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card style={{ marginTop: "20px" }}>
        <CardHeader>
          <CardTitle>
            <i class="fa fa-list"></i> Teklif/Sipariş Ürün Listesi
          </CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Grid data={data} sortable={false} style={{ marginTop: "15px" }}>
              <GridColumn field="uruN_KODU" title="Ürün Kodu"></GridColumn>
              <GridColumn field="adet" title="Adet"></GridColumn>
              <GridColumn field="puto" title="Puto"></GridColumn>
              <GridColumn
                cell={(props) => (
                  <td className="k-command-cell">
                    {props.dataItem.puto === "MST" ? (
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => logDetailToggle(props.dataItem)}
                      >
                        <span class="fas fa-info-circle"> </span> DETAY
                      </Button>
                    ) : undefined}
                  </td>
                )}
              />
            </Grid>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default FreeStockPutoLog;
