import React, { useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import {
  DATASEVKEDILMEMENEDENI,
  DATASEVKEDILMEMEDETAYI,
  DATASEVKSEKLI,
  DATASEVKARACTIPI,
  DATASEVKZAMANI,
} from "../../config/globalVariables";
import { Row, Col, Button } from "reactstrap";
import CommonTextArea from "../sys-common/CommonTextArea";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import OrderService from "../../services/srv-order/OrderService";

const MyOrderUpdateBry = (props) => {
  const [data, setData] = useState(props.data);

  return (
    <Window
      title={props.title}
      onClose={props.toggleDialog}
      initialHeight={"40vh"}
      initialWidth={"30%"}
      style={{
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <CommonDropDownList
        id="sevK_EDILMEME_NEDENI"
        label="Sevk Edilmeme Nedeni"
        data={DATASEVKEDILMEMENEDENI}
        onChange={(e) =>
          setData({ ...data, sevK_EDILMEME_NEDENI: e.target.value.id })
        }
        value={data?.sevK_EDILMEME_NEDENI}
      />

      <CommonDropDownList
        id="sevK_EDILMEME_DETAYI"
        label="Sevk Edilmeme Detayı"
        data={DATASEVKEDILMEMEDETAYI.filter(
          (child) => child?.parent === data?.sevK_EDILMEME_NEDENI
        )}
        onChange={(e) =>
          setData({ ...data, sevK_EDILMEME_DETAYI: e.target.value.id })
        }
        value={data?.sevK_EDILMEME_DETAYI}
      />

      <CommonTextArea
        id="sevK_EDILMEME_ACIKLAMA"
        label="Sevk Edilmeme Açıklama"
        onChange={(e) => setData({ ...data, sevK_EDILMEME_ACIKLAMA: e.value })}
        value={data?.sevK_EDILMEME_ACIKLAMA}
      ></CommonTextArea>

      <CommonDropDownList
        id="sevK_SEKLI"
        label="Sevk Şekli"
        data={DATASEVKSEKLI}
        onChange={(e) =>
          setData({
            ...data,
            sevK_SEKLI: e.target.value.id,
            sevK_PARTI_ACIKLAMA: null,
          })
        }
        value={data?.sevK_SEKLI}
      />

      {data.sevK_SEKLI === "PARTI_PARTI" && (
        <CommonTextArea
          id="sevK_PARTI_ACIKLAMA"
          label="Parti Açıklama"
          onChange={(e) => setData({ ...data, sevK_PARTI_ACIKLAMA: e.value })}
          value={data?.sevK_PARTI_ACIKLAMA}
        ></CommonTextArea>
      )}

      <CommonDropDownList
        id="sevK_ARAC_TIPI"
        label="Sevk Aracı Tipi"
        data={DATASEVKARACTIPI}
        onChange={(e) =>
          setData({ ...data, sevK_ARAC_TIPI: e.target.value.id })
        }
        value={data?.sevK_ARAC_TIPI}
      />

      <CommonDropDownList
        id="sevK_ZAMANI"
        label="Sevk Zamanı"
        data={DATASEVKZAMANI}
        onChange={(e) => setData({ ...data, sevK_ZAMANI: e.target.value.id })}
        value={data?.sevK_ZAMANI}
      />

      <Row>
        <Col lg="12" md="12" xs="12" style={{ marginTop: "40px" }}>
          <Button
            color="secondary"
            type="button"
            size="sm"
            onClick={props.toggleDialog}
          >
            Vazgeç
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            style={{ width: "150px", float: "right" }}
            onClick={() => OrderService.updateMyOrder(data, props.callBack)}
          >
            {" "}
            Kaydet
          </Button>
        </Col>
      </Row>
    </Window>
  );
};

export default MyOrderUpdateBry;
