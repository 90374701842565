import { authorizationToken } from "../../../helpers/authorizationToken";
import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case actionTypes.LOGIN_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.LOGIN_FULFILLED:
      return {
        ...state,
        isMfa: true,
        fetching: false,
      };
    case actionTypes.LOGIN_REJECTED:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        isMfa: false,
        fetching: false,
      };
    case actionTypes.LOGIN_MFA_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.LOGIN_MFA_FULFILLED:
      if (action.payload.token) {
        const { token } = action.payload;
        localStorage.setItem("jwtToken", token);
        authorizationToken(localStorage.getItem("jwtToken"));
      }
      return {
        ...state,
        isAuthenticated: true,
        fetching: false,
      };
    case actionTypes.LOGIN_MFA_REJECTED:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        isMfa: true,
        fetching: false,
      };

    case actionTypes.LOGIN_AAD_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.LOGIN_AAD_FULFILLED:
      var azureToken = JSON.parse(action.payload.config.data).token;
      if (action.payload.data && azureToken) {
        localStorage.setItem("aadToken", azureToken);
        localStorage.setItem("jwtToken", action.payload.data.token);
        authorizationToken(localStorage.getItem("jwtToken"));
        window.location.href = "/Homepage";
      }
      return {
        ...state,
        isMfa: false,
        isAuthenticated: true,
        fetching: false,
      };
    case actionTypes.LOGIN_AAD_REJECTED:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        fetching: false,
      };
    case actionTypes.REFRESH_JWT_BY_AAD_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.REFRESH_JWT_BY_AAD_FULFILLED:
      if (action.payload.data) {
        localStorage.setItem("jwtToken", action.payload.data.token);
        authorizationToken(localStorage.getItem("jwtToken"));
        window.location.href = "/Homepage";
      }
      return {
        ...state,
        isMfa: false,
        isAuthenticated: true,
        fetching: false,
      };
    case actionTypes.REFRESH_JWT_BY_AAD_REJECTED:
      return {
        ...state,
        error: action.payload,
        isAuthenticated: false,
        fetching: false,
      };
    case actionTypes.RESET_PASSWORD_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.RESET_PASSWORD_REJECTED:
      return {
        ...state,
        fetching: false,
      };

    case actionTypes.CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.CHANGE_PASSWORD_FULFILLED:
      if (action.payload) {
        localStorage.removeItem("jwtToken");
        const { token } = action.payload;
        localStorage.setItem("jwtToken", token);
        authorizationToken(localStorage.getItem("jwtToken"));
        window.location.href = "/";
      }
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.CHANGE_PASSWORD_REJECTED:
      return {
        ...state,
        fetching: false,
      };

    case actionTypes.UPDATE_FORGOT_PASSWORD_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.UPDATE_FORGOT_PASSWORD_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.UPDATE_FORGOT_PASSWORD_REJECTED:
      return {
        ...state,
        fetching: false,
      };

    case actionTypes.CHANGE_USER:
      let unix_timestamp = action.payload.exp;
      var date = new Date(unix_timestamp * 1000);
      var st = action.payload.lastPasswordChange;
      var dt = new Date(st);
      var checkLastPasswordChange = new Date(dt.setDate(dt.getDate() + 90));
      return {
        ...state,
        userName: action.payload.given_name,
        mail: action.payload.email,
        departmant: action.payload.departmant,
        exp: action.payload.exp,
        id: action.payload.nameid,
        isAuthenticated: date > new Date(),
        lastPasswordChange: checkLastPasswordChange < new Date() || st === "",
        roles: action.payload.roles,
        isDaikin: action.payload.isDaikin,
        isSmarty: action.payload.isSmarty,
        bayiKodu: action.payload.bayiKodu,
        menus: action.payload.menus,
      };
    case actionTypes.CHANGE_MFA:
      return {
        ...state,
        isAuthenticated: false,
        isMfa: false,
      };
    case actionTypes.SET_SHOW_FORM:
      return {
        ...state,
        showForm: true,
      };
    default:
      return state;
  }
}
