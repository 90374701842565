import React from "react";
import FormatNumber from "../sys-toolbox/NumberFormat";

const GridNumberFormatCell = (props, decimalScale) => {
  const value = props.dataItem[props.field];
  if (value === null || value === undefined || value === "") {
    return <td></td>;
  } else {
    return <td>{FormatNumber(value, decimalScale)}</td>;
  }
};

export default GridNumberFormatCell;
