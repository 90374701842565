export const DAGITIM_TIPI = [
  { id: "GENEL", text: "Tüm Kullanıcılara" },
  { id: "TUM_DAIKIN_KULLANICILARI", text: "Tüm Daikin Personeline" },
  { id: "TUM_BAYI_KULLANICILARI", text: "Tüm Bayilere" },
  { id: "KULLANICI", text: "Belirli Kullanıcılara" },
  { id: "ROLE", text: "Belirli Rollere" },
  { id: "BAYI", text: "Belirli Bayilere" },
];
export const DUYURU_TIPI = [
  { id: "POPUP", text: "POPUP" },
  { id: "KALICI", text: "KALICI" },
  { id: "HAVALI", text: "HAVALI" },
  { id: "ANAEKRAN", text: "ANAEKRAN" },
  { id: "ANAEKRAN DUYURU", text: "ANAEKRAN DUYURU" },
];

export const ANNOUNCEMENT_FILE_TYPE_ICON = "DUYURU_ICON";
export const ANNOUNCEMENT_FILE_TYPE = "DUYURU";
export const ANNOUNCEMENT_TABLE_NAME = "DKN_DUYURU_TANIM";
