import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UserService from "../../services/srv-user/UserService";

const BryDealerUserDropDownList = ({
    id,
    label,
    required,
    onChange,
    defaultValue,
    value
  }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      setDataLoading(true);
      UserService.getDaikinUsers(
        {
          filter: { logic: "or", filters: [] },
        },
        defaultDataHandler
      );
    }, []);
  
    const defaultDataHandler = ({data}) => {
      data.sort((a, b) => (a.adsoyad > b.adsoyad ? 1 : -1))
      setData(data);
      setFilteredData(data);
      setDataLoading(false);
  
    };
  
    const filterData = (e) => {
      setFilteredData([
        ...data.filter((x) =>
          x.adsoyad
            .toLowerCase()
            .includes(e.filter.value.toLowerCase())
        ),
      ]);
    };
  
    const itemRender = (li, itemProps) => {
      var itemChildren = (
        <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.email})
          </span>
        </div>
      </div>
      );
  
      return React.cloneElement(li, li.props, itemChildren);
    };
  
    const onChangeTest=(e)=>{
      onChange(e)
    }
  
    return (
      <DropDownList
        name={id}
        id={id}
        label={label ? label : undefined}
        data={filteredData}
        textField="adsoyad"
        dataItemKey="kullanicI_ID"
        style={{ width: "100%" }}
        onChange={onChangeTest}
        defaultValue={defaultValue}
        required={required}
        itemRender={itemRender}
        filterable={true}
        onFilterChange={filterData}
        loading={dataLoading}
        value={value}
  
      ></DropDownList>
    );
  };

export default BryDealerUserDropDownList;
