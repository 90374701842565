import { AZURE_ACTIVE_DOMAIN } from "../config/globalVariables";

export const msalConfig = {
  auth: {
    clientId: AZURE_ACTIVE_DOMAIN.appId,
    authority:
      "https://login.microsoftonline.com/" + AZURE_ACTIVE_DOMAIN.tenant,
    redirectUri: AZURE_ACTIVE_DOMAIN.redirectUri,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const apiConfig = {
  resourceScopes: AZURE_ACTIVE_DOMAIN.scopes,
};

export const loginRequest = {
  scopes: ["openid", "profile", "offline_access", ...apiConfig.resourceScopes],
  prompt: "select_account",
};

export const silentRequest = {
  scopes: [...apiConfig.resourceScopes],
};
export const tokenRequest = {
  scopes: [...apiConfig.resourceScopes],
};
