import React, {
  Component,
  Fragment
} from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  Grid,
  GridColumn,
  GridToolbar
} from '@progress/kendo-react-grid';
import {
  IntlProvider,
  LocalizationProvider
} from '@progress/kendo-react-intl';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changeSalesRepresentativeManager,
  deleteSalesRepresentativeManager,
  fetchSalesRepresentativeManager,
  fetchSalesRepresentativeManagerPayload,
  insertSalesRepresentativeManager,
  updateSalesRepresentativeManager
} from '../../redux/actions/act-mrc-salesRepresentativeManager/salesRepresentativeManagerActions';
import {
  fetchUser,
  fetchSatisTemsilcileri
} from '../../redux/actions/act-user/userActions';

import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from '../../helpers/utils';
import { MyCommandCell } from '../sys-toolbox/GridInlineEdit';
import LoadingPanel from '../sys-toolbox/LoadingPanel';
import { filterBy } from "@progress/kendo-data-query";

const primaryKey = 'mrC_SATIS_TEMSILCISI_YONETICI_ID';
const editField = 'inEdit';
const sort = [
  {
    field: primaryKey,
    dir: 'desc'
  }
];

class SalesRepresentativeManagerList extends Component {
  isSalesRepresentativeManagerList = false;
  editField = "inEdit";
  isRejectedData = false;

  state = {
    salesRepresentativeManagerData: this.props.salesRepresentativeManagerData,
    editData: null,
    removeData: null,
    satisTemsilcileri: [],
    loadingSatisTemsilcileri: false,
    satisTemsilcileriYonetici: [],
    loadingSatisTemsilcileriYonetici: false
  };

  onCompletedCallback = (data) => {
    this.setState({
      salesRepresentativeManagerData: data,
    });
  };

  onCompletedSatisTemsilcileri = (data) => {
    this.setState({ satisTemsilcileri: this.props.userList.users });
  };

  dataStateChange = (event) => {
    this.props.fetchSalesRepresentativeManager(event.dataState, this.onCompletedCallback);
  };

  componentDidMount() {
    this.props.fetchSatisTemsilcileri(this.onCompletedSatisTemsilcileri);
    this.getSalesRepresentativeManagerList();
  }

  getSalesRepresentativeManagerList = (search) => {
    var take = this.props.salesRepresentativeManagerList.dataState
      ? this.props.salesRepresentativeManagerList.dataState.take
      : 6;
    var skip = this.props.salesRepresentativeManagerList.dataState
      ? this.props.salesRepresentativeManagerList.dataState.skip
      : 0;

    this.props.fetchSalesRepresentativeManager(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onCompletedCallback
    );
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  itemChange = (event) => {
    const data = this.state.salesRepresentativeManagerData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    this.setState({
      salesRepresentativeManagerData: { ...this.state.salesRepresentativeManagerData, data },
    });
  };

  addNewSalesRepresentativeManager = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = this.state.salesRepresentativeManagerData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        salesRepresentativeManagerData: {
          ...this.state.salesRepresentativeManagerData,
          data: [newDataItem, ...this.state.salesRepresentativeManagerData.data],
        },
      });
    }
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.salesRepresentativeManagerData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.salesRepresentativeManagerData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        salesRepresentativeManagerData: { ...this.state.salesRepresentativeManagerData, data },
      });
    }
  };

  cancel = (dataItem) => {
    const originalItem =
      this.props.salesRepresentativeManagerList.salesRepresentativeManagers.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.salesRepresentativeManagerData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      salesRepresentativeManagerData: { ...this.state.salesRepresentativeManagerData, data },
    });
  };

  discard = () => {
    const data = [...this.state.salesRepresentativeManagerData.data];
    data.splice(0, 1);
    this.setState({
      salesRepresentativeManagerData: { ...this.state.salesRepresentativeManagerData, data },
    });
  };

  add = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.insertSalesRepresentativeManager(
      dataItem,
      this.props.salesRepresentativeManagerList.dataState,
      this.onCompletedCallback
    );
    dataItem.inEdit = false;
  };

  update = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.updateSalesRepresentativeManager(
      dataItem,
      this.props.salesRepresentativeManagerList.dataState,
      this.onCompletedCallback
    );
    dataItem.inEdit = undefined;
  };

  remove = (dataItem) => {
    this.props.deleteSalesRepresentativeManager(
      dataItem[primaryKey],
      this.props.salesRepresentativeManagerList.dataState,
      this.onCompletedCallback
    );
    this.setState({ removeData: dataItem });
  };

  checkValidate = (dataItem) => {
    console.log(dataItem);
    return true;
  };

  setSalesRepresentativeManagerData = () => {
    this.setState({
      salesRepresentativeManagerData: this.state.salesRepresentativeManagerData.data.map((item) =>
        item[primaryKey] === this.state.editData[primaryKey]
          ? { ...item, inEdit: true }
          : item
      ),
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      add={this.add}
      discard={this.discard}
      update={this.update}
      remove={this.remove}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  filterChangeSatisTemsilcileri = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        satisTemsilcileri: filterBy(this.props.userList.users, event.filter),
        loadingSatisTemsilcileri: false,
      });
    }, 500);

    this.setState({
      loadingSatisTemsilcileri: true,
    });
  };
  SatisTemsilcileriDropDown = (props) => {
    var { dataItem, field, textField } = props;
    var dataValue = dataItem[field] === null ? '' : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.state.satisTemsilcileri}
            required
            textField="adsoyad"
            dataItemKey="kullanicI_ID"
            filterable={true}
            onFilterChange={this.filterChangeSatisTemsilcileri}
            loading={this.state.loadingSatisTemsilcileri}
            onChange={(e) => this.userOnChange(e, props)}
            value={{ adsoyad: this.props.userList.users?.find(u => u.kullanicI_ID === dataValue)?.adsoyad, kullanicI_ID: dataValue }}
          />
        </td>
      );
    } else {
      return <td>{this.props.userList.users?.find(u => u.kullanicI_ID === dataValue)?.adsoyad}</td>;
    }
  };
  filterChangeSatisTemsilcileriYonetici = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        satisTemsilcileriYonetici: filterBy(this.props.userList.users, event.filter),
        loadingSatisTemsilcileriYonetici: false,
      });
    }, 500);

    this.setState({
      loadingSatisTemsilcileriYonetici: true,
    });
  };
  SatisTemsilcileriYoneticiDropDown = (props) => {
    var { dataItem, field, textField } = props;
    var dataValue = dataItem[field] === null ? '' : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.state.satisTemsilcileriYonetici}
            required
            textField="adsoyad"
            dataItemKey="kullanicI_ID"
            filterable={true}
            onFilterChange={this.filterChangeSatisTemsilcileriYonetici}
            loading={this.state.loadingSatisTemsilcileriYonetici}
            onChange={(e) => this.userOnChange(e, props)}
            value={{ adsoyad: this.props.userList.users?.find(u => u.kullanicI_ID === dataValue)?.adsoyad, kullanicI_ID: dataValue }}
          />
        </td>
      );
    } else {
      return <td>{this.props.userList.users?.find(u => u.kullanicI_ID === dataValue)?.adsoyad}</td>;
    }
  };
  userOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value["kullanicI_ID"];
    const data = this.state.salesRepresentativeManagerData.data.map((item) =>
      !item.inEdit
        ? item
        : {
          ...item,
          [props.field]: value,
        }
    );
    this.setState({
      salesRepresentativeManagerData: {
        ...this.state.salesRepresentativeManagerData,
        data,
      },
    });
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Satış Temsilcisi Yönetici</h2>
        {this.props.salesRepresentativeManagerList.fetching && LoadingPanel}
        <LocalizationProvider language="tr">
          <IntlProvider locale="tr">
            <Grid
              ref={(grid) => {
                this._grid = grid;
              }}
              data={this.state.salesRepresentativeManagerData}
              onDataStateChange={this.dataStateChange}
              {...this.props.salesRepresentativeManagerList.dataState}
              sortable={true}
              style={{ minHeight: "400px" }}
              pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
              resizable={true}
              onItemChange={this.itemChange}
              rowRender={(trElement, dataItem) =>
                this.rowRender(trElement, dataItem)
              }
              filterable={false}
              dataItemKey={primaryKey}
              editField={editField}>
              <GridToolbar>
                <Button
                  color="primary"
                  type="button"
                  size="sm"
                  onClick={this.addNewSalesRepresentativeManager}>
                  {" "}
                  <span className="k-icon k-i-plus-circle"></span> Yeni
                </Button>
              </GridToolbar>
              <GridColumn
                cell={this.CommandCell}
                width="100px"
                filterable={false}
              />
              <GridColumn
                field={primaryKey}
                title="Id"
                width="100px"
                editable={false}
                filterable={false}
              ></GridColumn>
              <GridColumn
                field="satiS_TEMSILCISI_ID"
                textField="satiS_TEMSILCISI_ID"
                title="Satış Temsilcisi"
                width="250px"
                filterable={false}
                cell={this.SatisTemsilcileriDropDown}
              ></GridColumn>
              <GridColumn
                field="satiS_TEMSILCISI_YONETICI_ID"
                textField="satiS_TEMSILCISI_YONETICI_ID"
                title="Yönetici"
                width="250px"
                filterable={false}
                cell={this.SatisTemsilcileriYoneticiDropDown}
              ></GridColumn>
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    salesRepresentativeManagerList: state.salesRepresentativeManagerListReducer,
    userList: state.userListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesRepresentativeManager : bindActionCreators(fetchSalesRepresentativeManager, dispatch),
    changeSalesRepresentativeManager: bindActionCreators(changeSalesRepresentativeManager, dispatch),
    insertSalesRepresentativeManager: bindActionCreators(insertSalesRepresentativeManager, dispatch),
    updateSalesRepresentativeManager: bindActionCreators(updateSalesRepresentativeManager, dispatch),
    deleteSalesRepresentativeManager: bindActionCreators(deleteSalesRepresentativeManager, dispatch),
    fetchSatisTemsilcileri: bindActionCreators(fetchSatisTemsilcileri, dispatch),
    fetchUser: bindActionCreators(fetchUser, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesRepresentativeManagerList);