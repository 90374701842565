import React, { Component } from "react";
import { Link } from "react-router-dom";
import LanguageSelect from "../sys-toolbox/LanguageSelect";
import { withTranslation } from "react-i18next";
import { BASE_URL } from "../../config/globalVariables";

class Header extends Component {
  render() {
    const { t } = this.props;
    return (
      <nav className="main-header navbar navbar-expand navbar-blue navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              data-widget="pushmenu"
              href="{#}"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="{#}">
              <i className="fas fa-user"></i>
            </a>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <div className="dropdown-divider"></div>
              <Link to={BASE_URL + "Logout"} className="dropdown-item">
                <i className="fa fa-sign-out mr-2"></i> {t("logout")}
              </Link>
            </div>
          </li>
          <li className="nav-item dropdown">
            {window.location.pathname.includes("offer-cis") ? (
              <LanguageSelect></LanguageSelect>
            ) : undefined}
          </li>
        </ul>
      </nav>
    );
  }
}

export default withTranslation()(Header);
