import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";

export const manuelVadeEslemeReportManagerGridColumns = [
  {
    field: "proC_ID",
    title: "PROC ID",
    width: "150px",
    type: "numeric",
    filter: "numeric",
  },
  {
    field: "kayiT_TURU",
    title: "KAYIT TURU",
    width: "256px",
    type: "string",
  },
  {
    field: "o_FMVE_CUSTOMER",
    title: "O CUSTOMER",
    width: "256px",
  },
  {
    field: "o_FMVE_CUSTOMER_NAME",
    title: "O CUSTOMER NAME",
    width: "256px",
  },
  {
    field: "o_FMVE_SALES_ENGINEER",
    title: "O SALES ENGINEER",
    width: "256px",
  },
  {
    field: "o_FMVE_POLICY_NUMBER",
    title: "O POLICY NUMBER",
    width: "256px",
  },
  {
    field: "o_FMVE_CREDIT_REP_GROUP",
    title: "O CREDIT REP GROUP",
    width: "256px",
  },
  {
    field: "o_FMVE_COUNTRY",
    title: "O COUNTRY",
    width: "256px",
  },
  {
    field: "o_FMVE_PAYMENT_TERMS",
    title: "O PAYMENT TERMS",
    width: "256px",
  },
  {
    field: "o_FMVE_CREDIT_LIMIT",
    title: "O CREDIT LIMIT",
    width: "256px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    type: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_CURRENCY",
    title: "O CURRENCY",
    width: "256px",
  },
  {
    field: "o_FMVE_0_30",
    title: "O 0-30",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_30_60",
    title: "O 30-60",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_60_90",
    title: "O 60-90",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_90_120",
    title: "O 90-120",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_120_150",
    title: "O 120-150",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_150_180",
    title: "O 150-180",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_180_210",
    title: "O_180-210",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_210_240",
    title: "O_210-240",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_240_270",
    title: "O 240-270",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_270_300",
    title: "O 270-300",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_300_330",
    title: "O 300-330",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_330_360",
    title: "O 330-360",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_360",
    title: "O 360",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_TOTAL_OVERDUE",
    title: "O TOTAL OVERDUE",
    width: "200px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_TOTAL_NOT_YET_DUE",
    title: "O TOTAL NOT YET DUE",
    width: "200px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_TOTAL_OUTSTANDING",
    title: "O TOTAL OUTSTANDING",
    width: "200px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "o_FMVE_CURRENCY2",
    title: "O CURRENCY2",
    width: "128px",
  },
  {
    field: "o_FMVE_CREDIT_CONT_AREA",
    title: "O CREDIT CONT AREA",
    width: "128px",
  },
  {
    field: "o_FMVE_RISK_CATEGORY",
    title: "O RISK CATEGORY",
    width: "128px",
  },
  {
    field: "m_FMVE_CUSTOMER",
    title: "M CUSTOMER",
    width: "128px",
  },
  {
    field: "m_FMVE_CUSTOMER_NAME",
    title: "M CUSTOMER NAME",
    width: "128px",
  },
  {
    field: "m_FMVE_SALES_ENGINEER",
    title: "M SALES ENGINEER",
    width: "128px",
  },
  {
    field: "m_FMVE_POLICY_NUMBER",
    title: "M POLICY NUMBER",
    width: "128px",
  },
  {
    field: "m_FMVE_CREDIT_REP_GROUP",
    title: "M CREDIT REP GROUP",
    width: "128px",
  },
  {
    field: "m_FMVE_COUNTRY",
    title: "M COUNTRY",
    width: "128px",
  },
  {
    field: "m_FMVE_PAYMENT_TERMS",
    title: "M PAYMENT TERMS",
    width: "128px",
  },
  {
    field: "m_FMVE_CREDIT_LIMIT",
    title: "M CREDIT LIMIT",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_CURRENCY",
    title: "M CURRENCY",
    width: "128px",
  },
  {
    field: "m_FMVE_0_30",
    title: "M 0-30",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_30_60",
    title: "M 30-60",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_60_90",
    title: "M 60-90",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_90_120",
    title: "M 90-120",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_120_150",
    title: "M 120-150",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_150_360",
    title: "M 150-360",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_360",
    title: "M 360",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_TOTAL_OVERDUE",
    title: "M TOTAL OVERDUE",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_TOTAL_NOT_YET_DUE",
    title: "M TOTAL NOT YET DUE",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_TOTAL_OUTSTANDING",
    title: "M TOTAL OUTSTANDING",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
    type: "numeric",
    filterable: false,
  },
  {
    field: "m_FMVE_CURRENCY2",
    title: "M CURRENCY2",
    width: "128px",
  },
  {
    field: "m_FMVE_CREDIT_CONT_AREA",
    title: "M CREDIT CONT AREA",
    width: "128px",
  },
  {
    field: "m_FMVE_RISK_CATEGORY",
    title: "M RISK CATEGORY",
    width: "128px",
  },
  {
    field: "crE_USER",
    title: "OLUŞTURAN KULLANICI",
    width: "128px",
  },
  {
    field: "crE_DATE",
    title: "OLUŞTURULMA TARİHİ",
    width: "256px",
    filter: "date",
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    filterable: false,
  },
];
