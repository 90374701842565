import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  TabStrip,
  TabStripTab,
} from "@progress/kendo-react-layout";
import UserRightsList from "./UserRightsList";
import UserSalesRightsList from "./UserSalesRightsList";

class UserRights extends Component {
  state = {
    selected: 0,
  };

  handleSelect = (e) => {
    this.setState({ selected: e.selected });
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Kullanıcı Hakları</h2>
        <Card style={{ width: "100%" }}>
          <CardBody>
            <TabStrip
              selected={this.state.selected}
              onSelect={this.handleSelect}
            >
              <TabStripTab title="Kullanıcı Genel Hakları">
                <UserRightsList></UserRightsList>
              </TabStripTab>
              <TabStripTab title="Kullanıcı Satış Hakları">
                <UserSalesRightsList></UserSalesRightsList>
              </TabStripTab>
            </TabStrip>
          </CardBody>
        </Card>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRights);
