import { Button } from "@progress/kendo-react-buttons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getGridFilterProperties,
} from "../../helpers/utils";
import {
  changeProductPrice,
  fetchProductPrice,
  fetchProductPriceForExcel,
  updateProductPrice,
} from "../../redux/actions/act-productPrice/productPriceActions";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import ProductPriceListLog from "./ProductPriceListLog";

class ProductPriceList extends Component {
  editField = "inEdit";
  isLoadProductPriceData = false;
  isGetProductPrice = false;

  state = {
    productPriceData: [],
    visibleProductPrice: false,
    code: "",
  };

  componentDidMount() {
    var take = this.props.productPriceList.dataState
      ? this.props.productPriceList.dataState.take
      : 6;
    var skip = this.props.productPriceList.dataState
      ? this.props.productPriceList.dataState.skip
      : 0;
    this.props.fetchProductPrice({ take: take, skip: skip });
  }

  componentDidUpdate() {
    if (
      this.props.productPriceList.fetching === false &&
      this.isLoadProductPriceData === false
    ) {
      this.setState({
        productPriceData: this.props.productPriceList.productPrices,
      });
      this.isLoadProductPriceData = true;
    }

    if (
      this.isGetProductPrice === false &&
      this.props.productPrice.dataUpdated === true
    ) {
      this.isGetProductPrice = true;
      this.isLoadProductPriceData = false;
      var take = this.props.productPriceList.dataState
        ? this.props.productPriceList.dataState.take
        : 6;
      var skip = this.props.productPriceList.dataState
        ? this.props.productPriceList.dataState.skip
        : 0;
      this.props.fetchProductPrice({ take: take, skip: skip });
    }
  }

  CommandCell = (props) => (
    <MyCommandCellWithoutDelete
      {...props}
      edit={this.enterEdit}
      discard={this.discard}
      update={this.update}
      cancel={this.cancel}
      editField={this.editField}
      idField="uruN_TANIM_FIYAT_ID"
    />
  );

  update = (dataItem) => {
    this.props.updateProductPrice(dataItem);
    dataItem.inEdit = undefined;
    this.isGetProductPrice = false;
  };

  discard = (dataItem) => {
    var data = [...this.state.productPriceData];
    data.splice(0, 1);
    this.setState({ productPriceData: data });
  };

  cancel = (dataItem) => {
    var originalItem = this.props.productPriceList.productPrices.data.find(
      (p) => p.uruN_TANIM_FIYAT_ID === dataItem.uruN_TANIM_FIYAT_ID
    );

    var dataState = Object.assign({}, this.state.productPriceData);

    dataState.data = dataState.data.map((item) =>
      item.uruN_TANIM_FIYAT_ID === originalItem.uruN_TANIM_FIYAT_ID
        ? originalItem
        : item
    );

    this.setState({ productPriceData: dataState });
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.productPriceData.data.find(
      (a) => a.inEdit === true
    );

    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      var dataState = Object.assign({}, this.state.productPriceData);
      dataState.data = dataState.data.map((item) =>
        item.uruN_TANIM_FIYAT_ID === dataItem.uruN_TANIM_FIYAT_ID
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        productPriceData: dataState,
      });
    }
  };

  itemChange = (event) => {
    var dataState = Object.assign({}, this.state.productPriceData);
    dataState.data = dataState.data.map((item) =>
      item.uruN_TANIM_FIYAT_ID === event.dataItem.uruN_TANIM_FIYAT_ID
        ? { ...item, [event.field]: event.value }
        : item
    );

    this.setState({ productPriceData: dataState });
  };

  export = () => {
    this.props.fetchProductPriceForExcel(
      this._export,
      this._grid.columns,
      this.props.productPriceList.dataState
    );
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.isLoadProductPriceData = false;
    this.props.fetchProductPrice(event.dataState);
    this.props.changeProductPrice(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onChangeSearch = (event) => {
    var searchFilter = Object.assign(
      {},
      this.props.productPriceList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );

    this.isLoadProductPriceData = false;
    this.props.fetchProductPrice(searchFilter);
    this.props.changeProductPrice(searchFilter);
  };

  toggleDialogProductPrice = (dataItem) => {
    this.setState({
      visibleProductPrice: !this.state.visibleProductPrice,
      code: dataItem.uruN_KODU,
    });
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        {this.props.productPriceList.fetching && LoadingPanel}
        {this.state.visibleProductPrice ? (
          <ProductPriceListLog
            toggleDialog={this.toggleDialogProductPrice}
            code={this.state.code}
          ></ProductPriceListLog>
        ) : (
          ""
        )}
        <Card>
          <CardHeader>
            <CardTitle>Ürün Fiyat Tanım</CardTitle>
          </CardHeader>
          <CardBody>
            <ExcelExport ref={(exporter) => (this._export = exporter)}>
              <Grid
                ref={(grid) => {
                  this._grid = grid;
                }}
                data={this.state.productPriceData}
                {...this.props.productPriceList.dataState}
                onDataStateChange={this.dataStateChange}
                sortable={true}
                style={{ height: "470px" }}
                onItemChange={this.itemChange}
                editField={this.editField}
                pageable={{
                  buttonCount: 3,
                  pageSizes: [6, 10, 20, 50, 100],
                }}
                resizable={true}
                filterable
                rowRender={(trElement, dataItem) =>
                  this.rowRender(trElement, dataItem)
                }
              >
                <GridToolbar>
                  <button
                    title="Export Excel"
                    className="btn btn-info btn-sm"
                    onClick={this.export}
                  >
                    Excel
                  </button>
                </GridToolbar>
                <GridColumn
                  filterable={false}
                  width="60px"
                  cell={(props) => (
                    <td>
                      <Button
                        icon="clock"
                        look="outline"
                        className="k-button k-grid-cancel-command"
                        onClick={(event) =>
                          this.toggleDialogProductPrice(props.dataItem)
                        }
                      />
                    </td>
                  )}
                />
                <GridColumn
                  cell={this.CommandCell}
                  width="100px"
                  filterable={false}
                />
                <GridColumn
                  field="uruN_KODU"
                  title="Ürün Kodu"
                  width="120px"
                  editable={false}
                ></GridColumn>
                <GridColumn
                  field="mrC_URUN_GRUBU"
                  title="MRC Ürün Grubu"
                  width="120px"
                  editable={false}
                ></GridColumn>
                <GridColumn
                  field="brY_URUN_GRUBU"
                  title="BRY Ürün Grubu"
                  width="120px"
                  editable={false}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_DOM_TRY"
                  title="Seçim Fiyatı SAP DOM TRY"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_DOM_USD"
                  title="Seçim Fiyatı SAP DOM USD"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_DOM_EUR"
                  title="Seçim Fiyatı SAP DOM EUR"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_EXP_TRY"
                  title="Seçim Fiyatı SAP EXP TRY"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_EXP_USD"
                  title="Seçim Fiyatı SAP EXP USD"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="seciM_FIYATI_SAP_EXP_EUR"
                  title="Seçim Fiyatı SAP EXP EUR"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_DOM_TRY"
                  title="Liste Fiyatı SAP DOM TRY"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_DOM_USD"
                  title="Liste Fiyatı SAP DOM USD"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_DOM_EUR"
                  title="Liste Fiyatı SAP DOM EUR"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_EXP_TRY"
                  title="Liste Fiyatı SAP EXP TRY"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_EXP_USD"
                  title="Liste Fiyatı SAP EXP USD"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="listE_FIYATI_SAP_EXP_EUR"
                  title="Liste Fiyatı SAP EXP EUR"
                  width="120px"
                  editor="numeric"
                  format="{0:N2}"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="kdv"
                  title="KDV"
                  width="120px"
                  filter={"numeric"}
                ></GridColumn>
                <GridColumn
                  field="orjinaL_KUR"
                  title="Orj. Kur"
                  width="90px"
                  editable={false}
                ></GridColumn>
                <GridColumn
                  field="orjinaL_KUR_EXP"
                  title="Orj. Kur Exp"
                  width="90px"
                  editable={false}
                ></GridColumn>
              </Grid>
            </ExcelExport>
          </CardBody>
        </Card>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productPriceList: state.productPriceListReducer,
    productPrice: state.productPriceReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProductPrice: bindActionCreators(fetchProductPrice, dispatch),
    fetchProductPriceForExcel: bindActionCreators(
      fetchProductPriceForExcel,
      dispatch
    ),
    changeProductPrice: bindActionCreators(changeProductPrice, dispatch),
    updateProductPrice: bindActionCreators(updateProductPrice, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceList);
