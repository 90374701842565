import { Window } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import CustomerService from "../../services/srv-customer/CustomerService";
import {
  defaultAlertMessages,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";

export function CustomerMerge({ toggleDialog }) {
  const [customerMerge, setCustomerMerge] = useState({});

  function mergeCustomer() {
    CustomerService.merge(customerMerge, callback);
  }

  function callback(axiosResult) {
    axiosResult.data && toggleDialog();
    defaultAlertMessages.MessageDetailSuccess = axiosResult.data;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  }

  const CustomerDropDownList = ({
    id,
    label,
    value,
    required,
    disabled,
    onChange,
  }) => {
    var pendingRequest = undefined;
    const [filteredData, setFilteredData] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
      if (value && filteredData === false) {
        var filter = [];
        filter.push({
          field: "muS_KODU",
          operator: "eq",
          value: value,
        });
        getDealersByFilter(filter);
      }
    }, [value, filteredData]);

    const onCompletedGetData = (data) => {
      setData(data.data);
      setFilteredData(true);
      setDataLoading(false);
    };

    const getDealersByFilter = (filters) => {
      setDataLoading(true);
      CustomerService.getCustomersWithTemp(
        {
          filter: { logic: "or", filters: filters },
        },
        onCompletedGetData
      );
    };

    const dealerFilter = (event) => {
      if (dataLoading) {
        clearTimeout(pendingRequest);
        pendingRequest = setTimeout(() => {
          dealerFilter(event);
        }, 50);
        return;
      }

      var filters = [];
      filters.push({
        field: "muS_KODU",
        operator: "contains",
        value: event.filter.value,
      });
      filters.push(event.filter);
      getDealersByFilter(filters);
    };

    const onFilterDealer = (event) => {
      if (event.filter.value.length > 2) {
        dealerFilter(event);
      }
    };

    const itemRender = (li, itemProps) => {
      var itemChildren = (
        <div>
          <div>
            <span style={{ fontSize: "15px" }}>{li.props.children}</span>
          </div>
          <div>
            <span style={{ fontSize: "12px", color: "blue" }}>
              ({itemProps.dataItem.muS_KODU}){" "}
              {itemProps.dataItem.bayI_KODU &&
                `- (${itemProps.dataItem.bayI_KODU})`}
            </span>
          </div>
        </div>
      );

      return React.cloneElement(li, li.props, itemChildren);
    };

    return (
      <DropDownList
        name={id}
        id={id}
        label={label ? label : undefined}
        data={data}
        textField="muS_ADI"
        dataItemKey="muS_KODU"
        style={{ width: "100%" }}
        onChange={onChange}
        required={required}
        disabled={disabled}
        itemRender={itemRender}
        filterable={true}
        onFilterChange={(event) => onFilterDealer(event)}
        loading={dataLoading}
        value={value ? data.find((v) => v.muS_KODU === value) : ""}
      ></DropDownList>
    );
  };

  return (
    <Window
      title="Müşteri Birleştir"
      onClose={toggleDialog}
      initialHeight={"35vh"}
      initialWidth={"45%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <Row>
        <Col>
          <CustomerDropDownList
            label="Eski Müşteri Kodu"
            id="eskI_MUS_KODU"
            name="eskI_MUS_KODU"
            value={customerMerge.eskI_MUS_KODU}
            onChange={(e) =>
              setCustomerMerge({
                ...customerMerge,
                eskI_MUS_KODU: e.value.muS_KODU,
              })
            }
          />
          <CustomerDropDownList
            label="Yeni Müşteri Kodu"
            id="yenI_MUS_KODU"
            name="yenI_MUS_KODU"
            value={customerMerge.yenI_MUS_KODU}
            onChange={(e) =>
              setCustomerMerge({
                ...customerMerge,
                yenI_MUS_KODU: e.value.muS_KODU,
              })
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <button className="btn btn-primary" onClick={() => mergeCustomer()}>
            Birleştir
          </button>
        </Col>
      </Row>
    </Window>
  );
}
