import React, { Component } from "react";
import { connect } from "react-redux";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { getProductsByFilter } from "../../redux/actions/act-order/orderProductActions";
import { bindActionCreators } from "redux";

class OrderProductAutoComplete extends Component {
  state = {
    data: [],
    value: "",
    isEmptyData: true,
  };

  componentDidMount() {
    this.setState({ value: this.props.cellProps.dataItem.uruN_KODU });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.orderProductOptionList.products &&
      props.orderProductOptionList.products.data &&
      state.isEmptyData === false &&
      props.orderProductOptionList.products.data !== state.data
    ) {
      return { data: props.orderProductOptionList.products.data };
    }
    return null;
  }

  onChange = (event) => {
    this.setState({ value: event.value, isEmptyData: event.value.length <= 2 });

    if (event.value.length > 2) {
      var urunKoduAciklamasiFilter = [];
      urunKoduAciklamasiFilter.push({
        field: "uruN_KODU",
        operator: "contains",
        value: event.value,
      });
      urunKoduAciklamasiFilter.push({
        field: "uruN_ADI",
        operator: "contains",
        value: event.value,
      });
      this.props.getProductsByFilter(
        {
          filter: { logic: "or", filters: urunKoduAciklamasiFilter },
        },
        this.props.order.sipariS_TURU,
        this.props.order.brY_SIPARIS_ID
      );

      this.props.cellProps.onChange({
        dataItem: this.props.cellProps.dataItem,
        field: this.props.cellProps.field,
        syntheticEvent: event.syntheticEvent,
        value: event.value,
      });
    } else {
      this.setState({ data: [] });
    }
  };

  itemRender = (li, itemProps) => {
    var isEven = itemProps.index % 2 === 0;
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "9px", color: "blue" }}>
            ({itemProps.dataItem.uruN_ADI})
          </span>
        </div>
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  render() {
    return (
      <AutoComplete
        data={this.state.data}
        value={this.state.value}
        onChange={this.onChange}
        style={{ width: "80%" }}
        itemRender={this.itemRender}
        textField="uruN_KODU"
        placeholder="En az 3 harf giriniz."
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderProductOptionList: state.orderProductOptionReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getProductsByFilter: bindActionCreators(getProductsByFilter, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderProductAutoComplete);
