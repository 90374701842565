import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class GeographicRegionService {
  getGeographicRegions(ulekKodu, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/cografibolge/get-list-by-ulkekodu/${ulekKodu}`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new GeographicRegionService();
