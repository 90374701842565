import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Fragment, useEffect, useRef, useState } from "react";
import UrunTanimFiyatExcelLogService from "../../services/srv-urunTanimFiyatExcelLogService/UrunTanimFiyatExcelLogService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

import { ExcelExport } from "@progress/kendo-react-excel-export";

export function UrunTanimFiyatExcelLogList(props) {
  const _export = useRef(null);
  const _grid = useRef(null);
  const initialDataState = {
    sort: [{ field: "uruN_TANIM_FIYAT_EXCEL_LOG_ID", dir: "esc" }],
    take: 1000,
    skip: 0,
  };
  const [dataState, setDataState] = useState(initialDataState);
  const [isLoading, setIsLoading] = useState(false);
  const [excelLogList, setExcelLogList] = useState(null);

  const onCompletedGetByProcId = (data, hasError) => {
    if (!hasError) {
      setExcelLogList(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    UrunTanimFiyatExcelLogService.getByProcId(
      props.match.params.procId,
      onCompletedGetByProcId
    );
  }, [props.match.params.procId]);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardHeader>
          <CardTitle>Ürün Tanim Fiyat Exel Log</CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading && LoadingPanel}
          <ExcelExport data={excelLogList} ref={_export}>
            <Grid
              ref={_grid}
              data={excelLogList}
              dataItemKey={"uruN_TANIM_FIYAT_EXCEL_LOG_ID"}
              {...dataState}
              onDataStateChange={(e) => {
                setDataState(e.dataState);
              }}
              sortable={true}
              resizable={true}
              filterable={true}
              style={{ minHeight: "400px", fontSize: "11px" }}
              pageable={{
                buttonCount: 3,
                pageSizes: [100, 200, 500, 1000],
              }}
            >
              <GridToolbar>
                <button
                  title="Export Excel"
                  className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                  onClick={excelExport}
                >
                  Export to Excel
                </button>
              </GridToolbar>
              <GridColumn
                field="uruN_TANIM_FIYAT_EXCEL_LOG_ID"
                title="ID"
                width="100px"
                filterable={false}
              />
              <GridColumn field="uruN_KODU" title="Ürün Kodu" width="150px" />
              <GridColumn field="proC_ID" title="ProcID" width="150px" />
              <GridColumn
                field="fiyaT_GUNCELLEME_BILGISI"
                title="Güncelleme Bilgisi"
              />
              <GridColumn field="crE_USER" title="Kullanıcı" width="120px" />
              <GridColumn
                field="crE_DATE"
                title="Eklenme Tarihi"
                width="150px"
                filter="date"
                cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
              />
            </Grid>
          </ExcelExport>
        </CardBody>
      </Card>
    </Fragment>
  );
}
