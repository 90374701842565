import axios from 'axios';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";

class BryProductTypeService {
  getProductTypes(dataState, callback) {
    return axios.get(`${API_GATEWAY + PR_DEX_BIREYSEL}/uruntipi/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
}

export default new BryProductTypeService();