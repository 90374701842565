import React from "react";
import { Input } from "@progress/kendo-react-inputs";
import { Hint } from "@progress/kendo-react-labels";

const CommonInput = (props) => {
  return (
    <div>
      <Input
        label={props.label}
        name={props.id}
        id={props.id}
        placeholder={props.placeholder}
        style={{ width: "100%" }}
        onChange={props.onChange}
        value={props.value}
        required={props.required}
        maxLength={props.maxLength}
        readOnly={props.readOnly}
      ></Input>
      {props.maxLength ? (
        <Hint direction={"end"}>
          {props.value.length} / {props.maxLength}
        </Hint>
      ) : undefined}
    </div>
  );
};

export default CommonInput;
