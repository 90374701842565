import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Stepper,
  Step,
} from "@progress/kendo-react-layout";
import { useRef, useState } from "react";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import IzlenebilirlikService from "../../services/srv-izlenebilirlik/IzlenebilirlikService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { serialNumberGridColumns } from "./SerialNumberGridColumns";
import { confirmAlert } from "react-confirm-alert";
import { SerialNumberStepper } from "./SerialNumberStepper";
import { Button } from "reactstrap";

export function SerialNumber() {
  const _grid = useRef(null);
  const _export = useRef(null);
  const [serialNumberDataState, setSerialNumberDataState] = useState({
    take: 10,
    skip: 0,
  });
  const [serialNumberLoading, setSerialNumbersLoading] = useState(false);
  const [serialNumber, setSerialNumbers] = useState([]);
  const [search, setSearch] = useState(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(serialNumber, _grid.current.columns);
    }
  };
  const onGetDataCompleted = (data) => {
    setSerialNumbers(data);
    setSerialNumbersLoading(false);
  };

  const onDataStateChange = (event) => {
    setSerialNumberDataState(event.dataState);
  };

  const onClickSearch = (e) => {
    if (search == "" || search == null) {
      defaultAlertMessages.MessageDetailError =
        "Arama kısmına Seri Numarası girmeniz gerekmektedir!";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setSerialNumbersLoading(true);
      IzlenebilirlikService.getSerialNumbersBySeriNo(
        search,
        onGetDataCompleted
      );
    }
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <div className="k-card-list">
        <CardTitle>Seri No ile İzlenebilirlik</CardTitle>
        <Card>
          <CardBody>
            <div class="row justify-content-md-center">
              <Input
                name="search"
                placeholder="Lütfen Seri No giriniz"
                onChange={(e) => setSearch(e.value)}
                style={{
                  width: "250px",
                  height: "50px",
                  fontSize: "20px",
                  marginRight: "15px",
                }}
              ></Input>
              <Button
                type="button"
                color="warning"
                size="lg"
                onClick={onClickSearch}
              >
                <i class="fa fa-search" aria-hidden="true"></i> Ara
              </Button>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {serialNumberLoading && LoadingPanel}
            <SerialNumberStepper data={serialNumber}></SerialNumberStepper>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            {serialNumberLoading && LoadingPanel}
            <ExcelExport ref={_export} />
            <Grid
              data={serialNumber?.slice(
                serialNumberDataState.skip,
                serialNumberDataState.skip + serialNumberDataState.take
              )}
              onPageChange={(e) => setSerialNumberDataState(e.page)}
              ref={_grid}
              skip={serialNumberDataState.skip}
              pageSize={serialNumberDataState.take}
              total={serialNumber.length}
              dataItemKey={"alL_SERIAL_NUMBER_ID"}
              {...serialNumberDataState}
              onDataStateChange={onDataStateChange}
              sortable={false}
              resizable={true}
              filterable={false}
              style={{ minHeight: "400px", fontSize: "11px" }}
              pageable={{
                buttonCount: 3,
                pageSizes: [10, 20, 50, 100],
              }}
            >
              <GridToolbar>
                <button
                  title="Export Excel"
                  className="btn btn-success btn-md"
                  onClick={excelExport}
                >
                  <i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel
                </button>
              </GridToolbar>
              {serialNumberGridColumns.map((column) => (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  filter={column.filter}
                  filterable={column.filterable ? column.filterable : false}
                  cell={column.cell}
                  filterCell={column.filterCell}
                />
              ))}
            </Grid>
          </CardBody>
        </Card>
      </div>
    </main>
  );
}
