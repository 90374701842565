export const userGridColumns = [
  {
    field: "dkN_SICIL_NO",
    title: "SİCİL NO",
    width: "144px",
  },
  {
    field: "ad",
    title: "AD",
    width: "144px",
  },
  {
    field: "ad",
    title: "AD",
    width: "144px",
  },
  {
    field: "soyad",
    title: "SOYAD",
    width: "144px",
  },
  {
    field: "unvan",
    title: "ÜNVAN",
    width: "192px",
  },
  {
    field: "email",
    title: "E-POSTA",
    width: "144px",
  },
  {
    field: "kullanicI_TIPI",
    title: "KULLANICI TİPİ",
    width: "144px",
  },
  {
    field: "bayI_KODU",
    title: "BAYİ",
    width: "256px",
  },
  {
    field: "gecerli",
    title: "GEÇERLİ",
    width: "192px",
  },
  {
    field: "telefon",
    title: "TELEFON",
    width: "192px",
  },
  {
    field: "daikiN_CALISANI_MI",
    title: "DAIKIN ÇALIŞANI MI",
    width: "192px",
  },
  {
    field: "deX_HAKKI",
    title: "DEX HAKKI",
    width: "192px",
  },
  {
    field: "mercurY_HAKKI",
    title: "MERCURY HAKKI",
    width: "192px",
  },
  {
    field: "smartY_HAKKI",
    title: "SMARTY HAKKI",
    width: "192px",
  },
  {
    field: "finanS_HAKKI",
    title: "FİNANS HAKKI",
    width: "192px",
  },
  {
    field: "tickeT_ID",
    title: "TİCKET İD",
    width: "192px",
  },
];
