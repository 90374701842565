import React from "react";
import { useParams } from "react-router-dom";
import FreeStockRDD from "../cmp-freeStock/FreeStockRDD";

const ExtFreeStockRDD = () => {
  const { teklifId } = useParams();
  return (
    <FreeStockRDD
      teklifSiparis="TEKLIF"
      teklifSiparisId={teklifId}
    ></FreeStockRDD>
  );
};

export default ExtFreeStockRDD;
