import { PR_DEX } from '../../config/globalVariables';
import { ServiceBase } from '../srv-base/ServiceBase';

class DiscountApproveMatrixService extends ServiceBase {
  constructor() {
    super(PR_DEX, '/discountonaymatris');
  }
}

export default new DiscountApproveMatrixService();
