import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

export function fetchCampaigns() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_CAMPAIGNS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/kampanya/get-all`)
        .then((result) => result.data),
    });
  };
}

export function getCampaignsByDate(date) {
  date = date ? new Date(date).toISOString() : new Date().toISOString();
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_CAMPAIGNS,
      payload: axios
        .get(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/kampanya/get-list-by-date/${date}`
        )
        .then((result) => result.data),
    });
  };
}

export function getCampaignsByFilter(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGNS,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/kampanya/get-list-by-filter?${toDataSourceRequestString(dataState)}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function getCampaign(campaignId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGN,
      payload: campaignId
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/kampanya/get-by-id/${campaignId}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function changeCampaign(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_BRY_CAMPAIGN,
      payload: event,
    });
  };
}

export function setCampaigns(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_BRY_CAMPAIGN,
      payload: event,
    });
  };
}

export function insertCampaign({ _campaign }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RETURN_ADD_CAMPAIGN,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/kampanya/add-campaign`, {
          ..._campaign,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function updateCampaign({ _campaign }) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CAMPAIGN,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/kampanya/update-campaign`, {
          ..._campaign,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}
