import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  changeCampaignProduct,
  deleteCampaignProduct,
  fetchCampaignProduct,
} from "../../redux/actions/act-bry-campaignProduct/campaignProductActions";
import GridToolTipCell from "../sys-common/GridToolTipCell";
import { ColumnMenu } from "../sys-toolbox/ColumnMenu";
import GridTooltipCellHeader from "../sys-toolbox/GridTooltipCellHeader";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class CampaignProducts extends Component {
  componentDidMount() {
    this.props.fetchCampaignProduct(this.props.code);
  }

  _export;
  export = () => {
    this._export.save();
  };

  dataStateChange = (event) => {
    this.props.changeCampaignProduct(event.dataState);
  };

  OnDelete = (data) => {
    this.props.deleteCampaignProduct(data, this.props.code);
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        {this.props.campaignProductList.fetching && LoadingPanel}
        <Tooltip anchorElement="element" position="bottom">
          <ExcelExport
            data={this.props.campaignProductList.campaignProducts}
            ref={(exporter) => (this._export = exporter)}
          >
            <Grid
              data={this.props.campaignProductList.result}
              {...this.props.campaignProductList.dataState}
              onDataStateChange={this.dataStateChange}
              sortable={true}
              pageable={true}
              resizable={true}
              pageSize={6}
              style={{ height: "400px" }}
            >
              <GridToolbar>
                <button
                  title="Add New"
                  onClick={this.props.toggleDialog}
                  className="btn btn-primary"
                >
                  Yeni Ekle
                </button>
                <button
                  title="Export Excel"
                  className="btn btn-info"
                  onClick={this.export}
                >
                  Excel
                </button>
              </GridToolbar>

              <GridColumn
                field="brY_URUN_KATEGORI"
                title="Ürün Kategori"
                filter={"numeric"}
                columnMenu={ColumnMenu}
                width="150vw"
              />
              <GridColumn
                field="brY_URUN_GRUBU"
                title="Ürün Grubu"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="150vw"
              />
              <GridColumn
                field="brY_URUN_TIPI"
                title="Ürün Tipi"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="150vw"
              />
              <GridColumn
                field="brY_URUN_MODELI"
                title="Ürün Model"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="160vw"
              />
              <GridColumn
                field="brY_URUN_MODELI_DETAY"
                title="Ürün Model Detay"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="160vw"
              />
              <GridColumn
                field="uruN_KODU"
                title="Ürün Kodu"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="120vw"
              />
              <GridColumn
                field="brY_SATIS_MARKA"
                title="Ürün Marka"
                columnMenu={ColumnMenu}
                cell={GridToolTipCell}
                headerCell={GridTooltipCellHeader}
                width="120vw"
              />
              {this.props.campaign._campaign.kampanyA_TURU ===
              "ADETE_BAGLI_PAKET" ? (
                <GridColumn
                  field="adet"
                  title="Adet"
                  columnMenu={ColumnMenu}
                  cell={GridToolTipCell}
                  headerCell={GridTooltipCellHeader}
                  width="100vw"
                />
              ) : undefined}

              <GridColumn
                filterable={false}
                width="100vw"
                cell={(props) => (
                  <td>
                    <button
                      title="Delete"
                      className="btn btn-danger btn-sm"
                      onClick={() => this.OnDelete(props.dataItem)}
                    >
                      Sil
                    </button>
                  </td>
                )}
              />
            </Grid>
          </ExcelExport>
        </Tooltip>
        <br></br>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignProductList: state.campaignProductListReducer,
    campaign: state.campaignReducer,
  };
};

const mapDispatchToProps = {
  changeCampaignProduct,
  fetchCampaignProduct,
  deleteCampaignProduct,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CampaignProducts));
