import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import OrderDiscountProductGroupService from "../../services/srv-bry-orderDiscountProductGroup/OrderDiscountProductGroupService";
import GridRowRenderDefault from "../sys-toolbox/GridRowRenderDefault";

const OrderDiscountProductGroupList = (props) => {
  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState({
    take: 10000,
    skip: 0,
  });

  useEffect(() => {
    OrderDiscountProductGroupService.getOrderDiscountProductGroups(
      props.orderId,
      callback
    );
  }, []);

  const NumberFormatCell = (value, dataItem) => {
    if (dataItem.inEdit) {
      return <td></td>;
    }

    return (
      <td>
        %
        <NumberFormat
          thousandSeparator={"."}
          value={value}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="5"
        ></NumberFormat>
      </td>
    );
  };

  const NumberCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  const callback = (discountData) => {
    setData(discountData);
  };

  return (
    <div>
      <Grid
        data={data}
        {...dataState}
        sortable={false}
        resizable
        rowRender={(trElement, dataItem) =>
          GridRowRenderDefault(trElement, dataItem)
        }
      >
        <GridColumn
          field="discounT_URUN_GRUBU"
          title="Discount Ürün Grubu"
          width="160px"
        ></GridColumn>
        <GridColumn
          field="indiriM_ORANI"
          title="İndirim Oranı"
          width="120px"
          filterable={false}
          editor="numeric"
          cell={NumberCell}
        ></GridColumn>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderDiscountProductGroupList);
