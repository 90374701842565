import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import ProductService from "../../services/srv-product/ProductService";
import BryProductCategoryDropDownList from "../sys-common/BryProductCategoryDropDownList";
import BryProductGroupDropDownList from "../sys-common/BryProductGroupDropDownList";
import BryProductModelDetailDropDownList from "../sys-common/BryProductModelDetailDropDownList";
import BryProductModelDropDownList from "../sys-common/BryProductModelDropDownList";
import BryProductTypeDropDownList from "../sys-common/BryProductTypeDropDownList";
import CommonInput from "../sys-common/CommonInput";
import MrcProductGroupDropDownList from "../sys-common/MrcProductGroupDropDownList";
import MrcProductKindDropDownList from "../sys-common/MrcProductKindDropDownList";
import MrcProductStructureDropDownList from "../sys-common/MrcProductStructureDropDownList";
import MrcProductTypeDropDownList from "../sys-common/MrcProductTypeDropDownList";
import ProductGroupDropDownList from "../sys-common/ProductGroupDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { LockedMyCommandCellWithoutDelete } from "../sys-toolbox/LockedGridInlineEditWitouthDelete";
import { productGridColums } from "./ProductGridColumns";
import { ProductSalesDepartment } from "./ProductSalesDepartment";

const ProductList = () => {
  const _grid = useRef(null);
  const primaryKey = "uruN_ID";
  const editField = "inEdit";
  const sort = [
    {
      field: primaryKey,
      dir: "desc",
    },
  ];
  const search = "";
  const selectedField = "selected";

  const [selectedState, setSelectedState] = useState({});
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductName, setSelectedProductName] = useState("");

  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...search,
  });
  const [originalProducts, setOriginalProducts] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    setProductsLoading(true);
    ProductService.getProducts(productsDataState, onCompletedGetProducts);
  }, [productsDataState]);

  useEffect(() => {
    setProductsLoading(true);
    ProductService.getProducts(productsDataState, onCompletedGetProducts);
  }, []);

  const onCompletedGetProducts = (data) => {
    setProducts(data);
    setOriginalProducts(data);
    if (data.dataState) setProductsDataState(data.dataState);
    setProductsLoading(false);
  };

  const dataStateChange = (event) => {
    filterLocalization(event);
    setProductsDataState(event.dataState);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: "uruN_KODU",
      });

      setSelectedState(newSelectedState);
      setSelectedProduct(Object.keys(newSelectedState)[0]);
    },
    [selectedState]
  );
  function onRowClick(event) {
    const { dataItem } = event;
    setSelectedProductName(dataItem.uruN_ADI);
  }

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const enterEditCommand = (dataItem) => {
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = products.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setProducts({ ...products, data });
    }
  };

  const addNewProduct = () => {
    const newDataItem = {
      inEdit: true,
      mrC_URUNU: "EVET",
      checksuM_VAR: "EVET",
    };
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setProducts({
        ...products,
        data: [newDataItem, ...products.data],
      });
    }
  };

  const addCommand = (dataItem) => {
    ProductService.insert(dataItem, onCompletedGetProducts);
    dataItem.inEdit = false;
    setProducts({ ...products });
  };

  const discardCommand = (dataItem) => {
    const data = [...products.data];
    data.splice(0, 1);
    setProducts({ ...products, data });
  };

  const updateCommand = (dataItem) => {
    ProductService.update(dataItem, undefined);
    dataItem.inEdit = false;
    setProducts({ ...products });
  };

  const cancelCommand = (dataItem) => {
    const originalItem = originalProducts.data.find(
      (p) => p[primaryKey] === dataItem[primaryKey]
    );
    const data = products.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    setProducts({ ...products, data });
  };

  const CommandCell = (props) => {
    return (
      <LockedMyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={editField}
        idField={primaryKey}
        nameField={primaryKey}
      />
    );
  };
  const ProductGroupCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <ProductGroupDropDownList
            id={"urunGrubu" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.uruN_GRUBU;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const BryProductCategoryCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryProductCategoryDropDownList
            id={"bryUrunKategori" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_KATEGORI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const BryProductGroupCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryProductGroupDropDownList
            id={"bryUrunGrubu" + field}
            filterField={"brY_URUN_KATEGORI"}
            filterValue={dataItem["brY_URUN_KATEGORI"]}
            name={field}
            value={dataValue}
            readOnly={dataItem.brY_URUN_KATEGORI == undefined ? true : false}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_GRUBU;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const BryProductTypeCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryProductTypeDropDownList
            id={"bryUrunTipi" + field}
            filterField={"brY_URUN_KATEGORI"}
            filterValue={dataItem["brY_URUN_KATEGORI"]}
            filterField2={"brY_URUN_GRUBU"}
            filterValue2={dataItem["brY_URUN_GRUBU"]}
            readOnly={dataItem.brY_URUN_GRUBU == undefined ? true : false}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_TIPI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const BryProductModelCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryProductModelDropDownList
            id={"bryUrunModeli" + field}
            filterField={"brY_URUN_KATEGORI"}
            filterValue={dataItem["brY_URUN_KATEGORI"]}
            filterField2={"brY_URUN_GRUBU"}
            filterValue2={dataItem["brY_URUN_GRUBU"]}
            filterField3={"brY_URUN_TIPI"}
            filterValue3={dataItem["brY_URUN_TIPI"]}
            readOnly={dataItem.brY_URUN_TIPI == undefined ? true : false}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_MODELI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const BryProductModelDetailCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryProductModelDetailDropDownList
            id={"bryUrunModeliDetay" + field}
            filterField={"brY_URUN_KATEGORI"}
            filterValue={dataItem["brY_URUN_KATEGORI"]}
            filterField2={"brY_URUN_GRUBU"}
            filterValue2={dataItem["brY_URUN_GRUBU"]}
            filterField3={"brY_URUN_TIPI"}
            filterValue3={dataItem["brY_URUN_TIPI"]}
            filterField4={"brY_URUN_MODELI"}
            filterValue4={dataItem["brY_URUN_MODELI"]}
            readOnly={dataItem.brY_URUN_MODELI == undefined ? true : false}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_MODELI_DETAY;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const MrcProductGroupCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <MrcProductGroupDropDownList
            id={"mrcUrunGrubu" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.mrC_URUN_GRUBU;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const MrcProductStructureCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <MrcProductStructureDropDownList
            id={"mrcUrunYapisi" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.mrC_URUN_YAPISI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const MrcProductTypeCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <MrcProductTypeDropDownList
            id={"mrcUrunTipi" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.mrC_URUN_TIPI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const MrcProductKindCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <MrcProductKindDropDownList
            id={"mrcUrunCinsi" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.mrC_URUN_CINSI;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  const YesNoCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={"gecerli" + field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const onItemChange = (event) => {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  };

  const [visibleProductSalesDepartment, setVisibleProductSalesDepartment] =
    useState(false);
  const productSalesDepartmentToggle = () => {
    setVisibleProductSalesDepartment(!visibleProductSalesDepartment);
  };

  const CustomLockedCell = (props) => {
    var { dataItem, field } = props;
    const dataValue = props.dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      if (dataItem.uruN_ID > 0) {
        return (
          <td style={props.style} className={props.className}>
            <CommonInput
              id="uruN_KODU"
              name="uruN_KODU"
              readOnly={true}
              value={dataValue}
            />
          </td>
        );
      } else {
        return (
          <td style={props.style} className={props.className}>
            <CommonInput
              id="uruN_KODU"
              name="uruN_KODU"
              readOnly={false}
              value={dataValue}
              onChange={(e) => {
                dataItem[field] = e.value;
              }}
            />
          </td>
        );
      }
    } else {
      return (
        <td style={props.style} className={props.className}>
          {dataValue === null ? "" : props.dataItem[field]}
        </td>
      );
    }
  };

  return (
    <main style={{ flexGrow: "1" }}>
      {visibleProductSalesDepartment ? (
        <ProductSalesDepartment
          modalToggle={productSalesDepartmentToggle}
          product={selectedProduct}
          productName={selectedProductName}
        ></ProductSalesDepartment>
      ) : (
        ""
      )}

      <Card>
        <CardHeader>
          <CardTitle>Ürün Tanım</CardTitle>
        </CardHeader>
        <CardBody>
          {productsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={products}
            {...productsDataState}
            onDataStateChange={dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{
              buttonCount: 3,
              pageSizes: [6, 10, 20, 50, 100],
            }}
            resizable={true}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            onItemChange={onItemChange}
            onSelectionChange={onSelectionChange}
            onRowClick={onRowClick}
            filterable
            dataItemKey={primaryKey}
            editField={editField}
            selectable={{
              enabled: true,
              cell: false,
              mode: "single",
            }}
            selectedField={selectedField}
          >
            <GridToolbar>
              <button
                className="btn btn-primary btn-sm"
                title="Yeni Ürün"
                onClick={addNewProduct}
              >
                Yeni Kayıt
              </button>
              <ExportExcel
                data={products}
                columns={productGridColums}
                loadingFunction={setProductsLoading}
                dataState={productsDataState}
                serviceMethod={ProductService.getProductsForExcel}
                fileName="UrunListesi"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn
              width="100px"
              filterable={false}
              cell={CommandCell}
              locked={true}
            />
            <GridColumn
              field="uruN_KODU"
              title="Ürün Kodu"
              width="200px"
              cell={CustomLockedCell}
              locked={true}
            ></GridColumn>
            <GridColumn
              field="uruN_ADI"
              title="Ürün Adı"
              width="300px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="uruN_GRUBU"
              title="Ürün Grubu"
              width="192px"
              cell={ProductGroupCell}
            ></GridColumn>
            <GridColumn
              field="marka"
              title="Marka"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="model"
              title="Model"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="kapasite"
              title="Kapasite"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="unite"
              title="Ünite"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="stoK_MIKTARI"
              title="Stok Miktarı"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="boM_URUN"
              title="BOM Ürünü"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="serI_PROFILI"
              title="Seri Profili"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="indeks"
              title="Indeks"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uyari"
              title="Uyarı"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="bayI_STOK_BILDIRIM"
              title="Bayi Stok Bildirim"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="gecerli"
              title="Geçerli"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_ADI_TR"
              title="MRC Ürün Adı (TR)"
              width="192px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_ADI_EN"
              title="MRC Ürün Adı (EN)"
              width="192px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="mrC_LISTE_FIYATI"
              title="MRC Liste Fiyatı (Domestic)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="mrC_SECIM_FIYATI"
              title="Seçim Fiyatı (Domestic)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_GRUBU"
              title="MRC Ürün Grubu"
              width="192px"
              cell={MrcProductGroupCell}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_YAPISI"
              title="MRC Ürün Yapısı"
              width="192px"
              cell={MrcProductStructureCell}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_TIPI"
              title="MRC Ürün Tipi"
              width="192px"
              cell={MrcProductTypeCell}
            ></GridColumn>
            <GridColumn
              field="mrC_URUN_CINSI"
              title="MRC Ürün Cinsi"
              width="192px"
              cell={MrcProductKindCell}
            ></GridColumn>
            <GridColumn
              field="mrC_SECIM_FIYATI_EUR"
              title="Seçim Fiyatı (Export)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="mrC_LISTE_FIYATI_EUR"
              title="MRC Liste Fiyatı (Export)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="saP_URUNU"
              title="SAP Ürünü"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="mrC_URUNU"
              title="MRC Ürünü"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="sirA_NO"
              title="Sıra No"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="anliK_FIYAT_MI"
              title="Anlık Fiyat"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="uruN_CARPANI"
              title="Ürün Çarpanı"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uruN_CARPANI_EXP"
              title="Ürün Çarpanı Exp."
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uruN_CARPANI_2"
              title="Ürün Çarpanı 2"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uruN_CARPANI_EXP_2"
              title="Ürün Çarpanı Exp. 2"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="stocK_QUAN"
              title="Stok Adedi"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="seciM_PRG_URUN_KODU"
              title="Seçim PRG Ürün Kodu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="smartY_GOND_TARIH"
              title="Smarty Gönderi Tarihi"
              width="128px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="uruN_KODU_SAP"
              title="Ürün Kodu SAP"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_MODELI_PCM"
              title="BRY Ürün Modeli PCM"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_DOM_TRY"
              title="SAP Liste Fiyatı TRY (Domestic)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_DOM_USD"
              title="SAP Liste Fiyatı USD (Domestic)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_DOM_EUR"
              title="SAP Liste Fiyatı EUR (Domestic)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_EXP_TRY"
              title="SAP Liste Fiyatı TRY (Export)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_EXP_USD"
              title="SAP Liste Fiyatı USD (Export)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="listE_FIYATI_SAP_EXP_EUR"
              title="SAP Liste Fiyatı EUR (Export)"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="brY_SATIS_MARKA"
              title="BRY Satış Marka"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="brY_MARKA_SEGMENT"
              title="BRY Marka Segment"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_KATEGORI"
              title="BRY Ürün Kategori"
              width="192px"
              cell={BryProductCategoryCell}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_GRUBU"
              title="BRY Ürün Grubu"
              width="192px"
              cell={BryProductGroupCell}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_TIPI"
              title="BRY Ürün Tipi"
              width="192px"
              cell={BryProductTypeCell}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_MODELI"
              title="BRY Ürün Modeli"
              width="192px"
              cell={BryProductModelCell}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_MODELI_DETAY"
              title="BRY Ürün Modeli Detay"
              width="192px"
              cell={BryProductModelDetailCell}
            ></GridColumn>
            <GridColumn
              field="budgeT_REVISED_STATUS"
              title="Bütçe Revize Durumu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="vendoR_ID"
              title="Vendor"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="vendoR_NAME"
              title="Vendor Adı"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="vendoR_GROUP"
              title="Vendor Grubu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="plc"
              title="PLC"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="plC_TEXT"
              title="PLC Yazısı"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="producT_OWNER_KULLANICI_ID"
              title="Ürün Sahibi"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="scP_OWNER_KULLANICI_ID"
              title="SCP Sahibi"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="slP_OWNER_KULLANICI_ID"
              title="SLP Sahibi"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="mtO_MTS"
              title="MTO MTS"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="transitioN_CODE_AGGREGATE"
              title="Transition Code Aggregate"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="sB_MB"
              title="SB MB"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="sB_QTY"
              title="SB QTY"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="mB_DAY"
              title="MB DAY"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="budgeT_CODE"
              title="Bütçe Kodu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="budgeT_DUMMY_CODE"
              title="Bütçe Dummy Kodu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="annueL_POWER_ENERGY_CONSUMPTION"
              title="Annuel Power Energy Consumption"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="yukseklik"
              title="Yükseklik"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uzunluk"
              title="Uzunluk"
              width="128px"
              editor={"numeric"}
            ></GridColumn>
            <GridColumn
              field="uruN_MODEL_YON"
              title="Ürün Model Yön"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="uruN_TIPI"
              title="Ürün Tipi"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="uruN_CINSI"
              title="Ürün Cinsi"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="gtip"
              title="GTIP"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="discounT_URUN_GRUBU"
              title="Discount Ürün Grubu"
              width="128px"
              editor={"text"}
            ></GridColumn>
            <GridColumn
              field="checksuM_VAR"
              title="Checksum Var"
              width="128px"
              cell={YesNoCell}
            ></GridColumn>
            <GridColumn
              field="miN_SIPARIS_ZAMANI"
              title="Min. Sipariş Zamanı"
              width="128px"
              editor={"text"}
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
};

export default ProductList;
