import {
  API_GATEWAY,
  CDN_PROJECT_NAME,
  MS_SENDER,
} from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function fetchOrderFiles(tableId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ORDER_FILES,
      payload: axios
        .get(
          `${
            API_GATEWAY + MS_SENDER
          }/file/get-by-details/${CDN_PROJECT_NAME}/DKN_BRY_SIPARIS/${tableId}`
        )
        .then((result) => result.data),
    });
  };
}

export function deleteOrderFile(fileid) {
  axios
    .post(
      `${API_GATEWAY + MS_SENDER}/file/delete/${CDN_PROJECT_NAME}/${fileid}`
    )
    .then((result) => result.data);
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ORDER_FILES,
      payload: fileid,
    });
  };
}

export function downloadOrderFile(cdn_file) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DOWNLOAD_ORDER_FILES,
      payload: axios
        .get(
          `${
            API_GATEWAY + MS_SENDER
          }/file/download/${cdn_file}/${CDN_PROJECT_NAME}`
        )
        .then((result) => window.open(result.data)),
    });
  };
}
