import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";
import { catchError } from "../srv-base/ServiceBase";

class OrderAddressService {
  getOrdersInfo(orderId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparisadresbilgileri/get-by-orderId/${orderId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      });
  }

  upsertOrderAddressInfo(addressInfo, callback, onErrorCallback) {
    return axios
      .post(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparisadresbilgileri/upsert-address-info`,
        {
          ...addressInfo,
        }
      )
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      })
      .catch((error) =>
        catchError(error, onErrorCallback(error.response.data))
      );
  }
  upsertOrderAddressInfoWithOrder(addressInfo, callback, onErrorCallback) {
    return axios
      .post(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparisadresbilgileri/upsert-address-info-with-order`,
        {
          ...addressInfo,
        }
      )
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      })
      .catch((error) =>
        catchError(error, onErrorCallback(error.response.data))
      );
  }
}

export default new OrderAddressService();
