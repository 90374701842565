import React, { useState, useEffect, useRef } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import GridFooterSumCell from "../sys-toolbox/GridFooterSumCell";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import CommonNumericTextBox from "../sys-common/CommonNumericTextBox";
import OffsetService from "../../services/srv-fin-offset/OffsetService";
import CommonInput from "../sys-common/CommonInput";
import { DateInput } from "@progress/kendo-react-dateinputs";
import FormatNumber from "../sys-toolbox/NumberFormat";
import { useTranslation } from "react-i18next";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { confirmAlert } from "react-confirm-alert";
import { TermOffsetSummaryColumns } from "./TermOffsetSummaryColumns";

const TermOffsetSummary = (props) => {
  const { t } = useTranslation();
  const [dbsData, setDbsData] = useState([]);
  const [sanalPosData, setSanalPosData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [toplamTutarDBS, setToplamTutarDBS] = useState(null);
  const [toplamTutarSanalPos, setToplamTutarSanalPos] = useState(null);
  const [offsetDetail, setOffsetDetail] = useState({});
  const [offsetDA, setOffsetDA] = useState([]);
  const exportRef = useRef(null);

  useEffect(() => {
    OffsetService.getByOffsetCode(props.mahsupKodu, onCompletedGetOffset);
    OffsetService.getByOffsetCodeByDA(
      props.mahsupKodu,
      onCompletedGetOffsetByDA
    );
    OffsetService.getDetailByOffsetCode(
      props.mahsupKodu,
      onCompletedGetOffsetDetail
    );
  }, []);

  const onCompletedGetOffsetByDA = (data) => {
    setOffsetDA(data);
  };

  const onCompletedGetOffset = (data) => {
    setExcelData(data);
    var sanalPosData = data.filter((a) => a.mahsuP_TIPI === "SANAL_POS");
    var dbsData = data.filter((a) => a.mahsuP_TIPI === "DBS");
    var sumSanalPos = sanalPosData.reduce(
      (acc, cur) => acc + cur["mahsuP_EDILEN_TUTAR"],
      0
    );
    var toplamDBS = dbsData.reduce((acc, current) => acc + current["tutar"], 0);
    setSanalPosData(sanalPosData);
    setDbsData(dbsData);
    setToplamTutarDBS(toplamDBS);
    setToplamTutarSanalPos(sumSanalPos);
  };

  const onCompletedGetOffsetDetail = (data) => {
    setOffsetDetail(data);
  };

  const GridFooterAverageDatetimeCell = (props, format, datatype) => {
    const value =
      datatype === "SANAL_POS"
        ? sanalPosData
            .map(
              (a) => new Date(a.vadE_TARIHI).getTime() * a.mahsuP_EDILEN_TUTAR
            )
            .reduce((prev, curr) => prev + curr, 0) / toplamTutarSanalPos
        : dbsData
            .map(
              (a) => new Date(a.vadE_TARIHI).getTime() * a.mahsuP_EDILEN_TUTAR
            )
            .reduce((prev, curr) => prev + curr, 0) / toplamTutarDBS;

    return (
      <td colSpan={props.colSpan} style={props.style}>
        <b>
          Ort. Vade : <br></br>
          {formatDate(new Date(value), format)}
        </b>
      </td>
    );
  };

  async function exportExcel() {
    if (exportRef !== null) {
      confirmAlert({
        title: "Uyarı",
        message:
          "Listenen satır sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
        buttons: [
          {
            label: "Evet",
            onClick: async () => {
              exportRef.current.save(excelData);
            },
          },
          {
            label: "Hayır",
            onClick: () => {},
          },
        ],
      });
    }
  }

  return (
    <Window
      title="Vade Farkı Hesabı"
      onClose={props.toggleDialogOffsetSummary}
      initialHeight={"90vh"}
      initialWidth={"60%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <Row>
        <Col lg="12" md="12" xs="12">
        <ExcelExport ref={exportRef}>
            {TermOffsetSummaryColumns.map((column, index) => (
              <ExcelExportColumn
                key={"column_" + index.toString()}
                field={column.field}
                title={t(column.title) ? t(column.title) : column.title}
                width={200}
              />
            ))}
          </ExcelExport>
          <button
            title="Excel'e Kaydet"
            className="btn btn-success btn-sm"
            onClick={exportExcel}
          >
            Excel'e Kaydet
          </button></Col>
      </Row>
      <Row>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>DBS</h6>
            </CardHeader>
            <CardBody>
              <Grid style={{ height: "200px" }} data={dbsData}>
                <GridColumn
                  field="vadE_TARIHI"
                  title="Vade Tarihi"
                  width="180px"
                  cell={(subProps) =>
                    GridDateFormatCell(subProps, "dd.MM.yyyy HH:mm")
                  }
                  footerCell={(subProps) =>
                    GridFooterAverageDatetimeCell(
                      subProps,
                      "dd.MM.yyyy HH:mm",
                      "DBS"
                    )
                  }
                />
                <GridColumn
                  field="tutar"
                  title="Tutar"
                  width="180px"
                  cell={(subProps) => GridNumberFormatCell(subProps, 2)}
                  footerCell={(subProps) =>
                    GridFooterSumCell(subProps, dbsData, 2)
                  }
                />
                <GridColumn
                  field="parA_BIRIMI"
                  title="Para Birimi"
                  width="90px"
                />
              </Grid>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>SANAL POS</h6>
            </CardHeader>
            <CardBody>
              <Grid style={{ height: "200px" }} data={sanalPosData}>
                <GridColumn
                  field="vadE_TARIHI"
                  title="Vade Tarihi"
                  width="180px"
                  cell={(subProps) =>
                    GridDateFormatCell(subProps, "dd.MM.yyyy HH:mm")
                  }
                  footerCell={(subProps) =>
                    GridFooterAverageDatetimeCell(
                      subProps,
                      "dd.MM.yyyy HH:mm",
                      "SANAL_POS"
                    )
                  }
                />
                <GridColumn
                  field="mahsuP_EDILEN_TUTAR"
                  title="Tutar"
                  width="180px"
                  cell={(subProps) => GridNumberFormatCell(subProps, 2)}
                  footerCell={(subProps) =>
                    GridFooterSumCell(subProps, sanalPosData, 2)
                  }
                />
                <GridColumn
                  field="parA_BIRIMI"
                  title="Para Birimi"
                  width="90px"
                />
              </Grid>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>VADE FARKI HESABI</h6>
            </CardHeader>
            <CardBody>
              <CommonNumericTextBox
                label="Valör Gün"
                id="valor_gun"
                value={offsetDetail.valoR_GUN}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="Borç Tutarı"
                id="borc_tutari"
                value={toplamTutarDBS}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="Vade Farkı Oranı"
                id="vade_farki_orani"
                value={offsetDetail.vadE_FARKI_ORANI}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="Vade Farkı Tutarı"
                id="vade_farki_tutari"
                value={offsetDetail.vadE_FARKI_TUTARI}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="KDV Oranı"
                id="kdV_ORANI"
                value={offsetDetail.kdV_ORANI}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="KDV li Vade Farkı Tutarı"
                id="kdV_LI_TUTAR"
                value={
                  offsetDetail.vadE_FARKI_TUTARI *
                  (1 + offsetDetail.kdV_ORANI / 100)
                }
                readOnly={true}
              ></CommonNumericTextBox>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" md="6" xs="6">
          {offsetDA ? (
            <Card>
              <CardHeader>
                <h6>Artan Sanal Pos (DA)</h6>
              </CardHeader>
              <CardBody>
                <span>
                  <b>Toplam Tutar :</b>
                  {FormatNumber(offsetDA.tutar, 2)} {offsetDA.parA_BIRIMI}
                </span>
                <br></br>
                <br></br>
                <b>Vade Tarihi :</b>{" "}
                {new Date(offsetDA.vadE_TARIHI).toLocaleDateString()}
              </CardBody>
            </Card>
          ) : undefined}
        </Col>
      </Row>
    </Window>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(TermOffsetSummary);
