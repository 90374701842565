import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filterLocalization from "../../helpers/filterLocalization";
import {
  changeComplaint,
  fetchComplaint,
  fetchComplaintForExcel,
} from "../../redux/actions/act-complaintManagement/complaintActions";
import GridDateCell from "../sys-common/GridDateCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import ComplaintUpdate from "./ComplaintUpdate";

class ComplaintGrid extends Component {
  state = {
    complaintId: null,
    visibleComplaint: false,
    isCMY:
      Array.isArray(this.props.auth.roles) &&
      this.props.auth.roles.includes("DAIKIN_CAGRI_MERKEZI_YONETICISI"),
    isDepartmanSorumlusu:
      Array.isArray(this.props.auth.roles) &&
      this.props.auth.roles.filter((a) => a.includes("DEPARTMAN_SORUMLUSU"))
        .length > 0,
  };

  _export;
  _grid;

  componentDidMount() {
    var take = this.props.complaintList.dataState
      ? this.props.complaintList.dataState.take
      : 6;
    var skip = this.props.complaintList.dataState
      ? this.props.complaintList.dataState.skip
      : 0;
    var dataState = {
      take: take,
      skip: skip,
    };
    this.getComplaintList(dataState);
  }

  getComplaintType = () => {
    if (window.location.pathname === "/complaint-bayi") {
      return "BAYI";
    } else {
      return this.state.isCMY ? "CMY" : "DEPARTMAN";
    }
  };

  getComplaintList = (dataState) => {
    var type = this.getComplaintType();
    this.props.fetchComplaint(type, dataState);
  };

  export = () => {
    this.props.fetchComplaintForExcel(
      this._export,
      this._grid.columns,
      this.props.complaintList.dataState,
      this.getComplaintType()
    );
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.getComplaintList(event.dataState);
    this.props.changeComplaint(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
      onDoubleClick: () => {
        this.setState({
          visibleComplaint: !this.state.visibleComplaint,
          complaintId: dataItem.dataItem.bayI_SIKAYET_ID,
        });
      },
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  openUpdateComplaint = (dataItem) => {
    this.setState({
      visibleComplaint: !this.state.visibleComplaint,
      complaintId: dataItem.bayI_SIKAYET_ID,
    });
  };

  toggleDialogComplaint = () => {
    this.setState({
      visibleComplaint: !this.state.visibleComplaint,
      complaintId: null,
    });
  };

  render() {
    return (
      <div>
        {this.state.visibleComplaint ? (
          <ComplaintUpdate
            toggleDialog={this.toggleDialogComplaint}
            complaintId={this.state.complaintId}
            isCMY={this.state.isCMY}
            isDepartmanSorumlusu={this.state.isDepartmanSorumlusu}
            complaintGrid={this._grid}
          ></ComplaintUpdate>
        ) : (
          ""
        )}
        {this.props.complaintList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.complaintList.complaints}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.complaintList.result}
            {...this.props.complaintList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ height: "420px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable
            filterable
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="100px"
              cell={(props) => (
                <td>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => this.openUpdateComplaint(props.dataItem)}
                  >
                    Detay
                  </button>
                </td>
              )}
            />
            <GridColumn
              field="dasU_CAGRI_ID"
              title="DASU Çağrı ID"
              width="120px"
            ></GridColumn>
            <GridColumn
              field="musterI_AD_SOYAD"
              title="Müşteri"
              width="150px"
            ></GridColumn>
            <GridColumn field="bayI_ADI" title="Bayi Adı"></GridColumn>
            <GridColumn
              field="departmaN_KODU"
              title="Departman"
              width="130px"
            ></GridColumn>
            <GridColumn field="durum" title="Durum" width="110px"></GridColumn>
            <GridColumn
              field="crE_DATE"
              title="Şikayet Tarihi"
              filter="date"
              width="120px"
              cell={GridDateCell}
            ></GridColumn>
            <GridColumn
              field="sikayeT_ACIKLAMASI"
              title="Şikayet Açıklaması"
              width="250px"
            ></GridColumn>
            <GridColumn
              field="kapatmA_TIPI"
              title="Kapatma Tipi"
              width="180px"
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    complaintList: state.complaintListReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchComplaint: bindActionCreators(fetchComplaint, dispatch),
    fetchComplaintForExcel: bindActionCreators(
      fetchComplaintForExcel,
      dispatch
    ),
    changeComplaint: bindActionCreators(changeComplaint, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintGrid);
