export const PROJECT_CODE = process.env.REACT_APP_PROJECT_CODE;
export const API_GATEWAY = process.env.REACT_APP_API_GATEWAY;
export const IG_REPORT = process.env.REACT_APP_IG_REPORT;
export const CDN_PROJECT_NAME = process.env.REACT_APP_CDN_PROJECT_NAME;
export const IG_SENDER = process.env.REACT_APP_IG_SENDER;

export const AZURE_ACTIVE_DOMAIN = {
  appId: process.env.REACT_APP_AZURE_APP_ID,
  redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
  tenant: "6cf18ffb-42c6-4d7d-9874-5c515ab0c6ae", //Local, Ortak IIS, Bulutistan & Canlı
  scopes: ["user.read"],
};

export const BASE_URL = "/";
export const PR_DEX = "dex-service";
export const PR_DEX_BAYI_SIKAYET = "dex-bayisikayet-service";
export const PR_DEX_BIREYSEL = "dex-bireysel-service";
export const PR_DEX_MERCURY = "dex-mercury-service";
export const PR_DEX_BIGG_LOYALTY = "dex-biggloyalty-service";
export const PR_MAHSUPLASMA = "mahsuplasma-service";
export const PR_FINANS = "finans-service";
export const MS_USERSDEX = "users-dex-service";
export const MS_SENDER = "sender-service";
export const MFA = "mfa-service";

export const TIMEZONE = "Europe/Istanbul";

export const REQUIRED = "Lütfen bu alanı doldurun";
export const REQUIREDCHOOSE = "Lütfen bir seçim yapınız";

export const KAPATMA_TIPLERI = [
  { text: "Müş. Memnuniyeti Sağlandı", id: "MUSTERI_MEMNUNIYETI_SAGLANDI" },
  {
    text: "Müş. Memnuniyeti Sağlanamadı - Satış Bölümüne İletildi",
    id: "SATIS_BOLUMUNE_ILETILDI",
  },
  {
    text: "Müş. Memnuniyeti Sağlanamadı - Kapatıldı - Bilgi Verildi",
    id: "KAPATILDI",
  },
];

export const DATASIPARISTURU = [
  { text: "STANDART", id: "STANDART" },
  { text: "KAMPANYA", id: "KAMPANYA" },
  { text: "DTAS_OTHERS", id: "DTAS_OTHERS" },
];

export const DATAPARABIRIMI = [
  { text: "TRY", id: "TRY" },
  { text: "USD", id: "USD" },
  { text: "EUR", id: "EUR" },
];

export const DATAODEMETIPI = [
  { text: "NAKIT", id: "NAKIT", type: "GENERAL" },
  { text: "DBS", id: "DBS", type: "GENERAL" },
  { text: "ÇEK", id: "CEK", type: "GENERAL" },
  { text: "SANAL POS", id: "SANAL_POS", type: "GENERAL" },
  { text: "VINOV", id: "VINOV", type: "GENERAL" },
  { text: "TEMINAT MEKTUBU", id: "TEMINAT_MEKTUBU", type: "GENERAL" },
  { text: "COFACE", id: "COFACE", type: "GENERAL" },

  { text: "L/C", id: "L_C", type: "EXPORT" },
  { text: "COFACE TOPLINER", id: "COFACE_TOPLINER", type: "EXPORT" },
  { text: "EXIM", id: "EXIM", type: "EXPORT" },
  { text: "HERMES", id: "HERMES", type: "EXPORT" },
  { text: "LG", id: "LG", type: "EXPORT" },
  { text: "OPEN ACCOUNT", id: "OPEN_ACCOUNT", type: "EXPORT" },
  { text: "CREDIT CARD", id: "CREDIT_CARD", type: "EXPORT" },
  { text: "BOND", id: "BOND", type: "EXPORT" },
];

export const DATAODEMEVADESI = [
  { text: "FATURA VADE", id: "FATURA_VADESI" },
  { text: "SABIT VADE", id: "NOKTA_VADE" },
];

export const DATALISTEVADESI = [
  { text: "Nakit", id: "0" },
  { text: "Peşin", id: "1" },
  { text: "30 Gün", id: "30" },
  { text: "60 Gün", id: "60" },
  { text: "90 Gün", id: "90" },
  { text: "120 Gün", id: "120" },
];

export const DATAFATURAVADESI = [
  { text: "Nakit", id: "0" },
  { text: "Peşin", id: "1" },
  { text: "30 Gün", id: "30" },
  { text: "60 Gün", id: "60" },
  { text: "90 Gün", id: "90" },
  { text: "120 Gün", id: "120" },
  { text: "150 Gün", id: "150" },
  { text: "180 Gün", id: "180" },
];

export const DATABANKATAKSIT = [
  { text: "Tek Çekim", id: "1" },
  { text: "3 Taksit", id: "3" },
  { text: "5 Taksit", id: "5" },
  { text: "7 Taksit", id: "7" },
  { text: "9 Taksit", id: "9" },
];

export const DATASATISDEPARTMAN = [
  { text: "VRV", id: "VRV" },
  { text: "HEATING", id: "HEATING" },
  { text: "REFRIGERATION", id: "REFRIGERATION" },
  { text: "RAC_SKYAIR", id: "RAC_SKYAIR" },
  { text: "EXPORT_UPG", id: "EXPORT_UPG" },
  { text: "SSHD", id: "SSHD" },
  { text: "EXPORT_ESG", id: "EXPORT_ESG" },
  { text: "APP", id: "APP" },
];

export const DATABRYSATISDEPARTMAN = [
  { text: "ISITMA", id: "HEATING" },
  { text: "KLIMA", id: "RAC_SKYAIR" },
  { text: "EXPORT", id: "EXPORT_UPG" },
];

export const DATASAPDURUM = [
  { text: "YENI", id: "YENI" },
  { text: "ISLEMDE", id: "ISLEMDE" },
  { text: "KAPALI", id: "KAPALI" },
  { text: "IPTAL", id: "IPTAL" },
  { text: "REVIZE", id: "REVIZE" },
];

export const DATABRYCAMPAIGNTYPE = [
  { text: "Adete Bağlı Paket", id: "ADETE_BAGLI_PAKET" },
  { text: "İndirim", id: "INDIRIM" },
];

export const DATAEXPORTSIPARISTIPI = [
  { text: "Standart Order", id: "OR" },
  { text: "Blocked NC Order", id: "ZBNC" },
  { text: "Konsinye (KB)", id: "KB" },
];

export const DATAEXPORTBOLGE = [
  { text: "DTAS CIS", id: "DTAS_CIS" },
  { text: "DTAS Mid. E-Africa", id: "DTAS_MID_EAST_EUROPE" },
  { text: "DTAS Europe", id: "DTAS_EUROPE" },
  { text: "DTAS Russian Region", id: "DTAS_RUSSIAN_REGION" },
];

export const DATAEXPORTSIPARISTURU = [
  { text: "Direkt İhracat", id: "DIREKT_IHRACAT" },
  { text: "Transit Ticaret", id: "TRANSIT_TICARET" },
  { text: "Gümrükte Devir", id: "GUMRUKTE_DEVIR" },
  { text: "Local KDV'li", id: "LOCAL_KDVLI" },
  { text: "İhraç Kayıtlı Satış", id: "IHRAC_KAYITLI_SATIS" },
  { text: "Bedelsiz Direkt İhracat", id: "BEDELSIZ_DIREKT_IHRACAT" },
  { text: "Bedelsiz Local KDV'li", id: "BEDELSIZ_LOCAL_KDVLI" },
  { text: "Bedelsiz Transit Ticaret", id: "BEDELSIZ_TRANSIT_TICARET" },
  { text: "Bedelsiz Gümrükte Devir", id: "BEDELSIZ_GUMRUKTE_DEVIR" },
  { text: "Yurtdışı Transit Ticaret", id: "YURTDISI_TRANSIT_TICARET" },
  { text: "Serbest Bölge", id: "SERBEST_BOLGE" },
];

export const DATAINCOTERMS = [
  { text: "CFR (Costs and freight)", id: "CFR" },
  { text: "CIF (Costs, insurance & freight)", id: "CIF" },
  { text: "CIP (Carriage and insurance paid to)", id: "CIP" },
  { text: "CPT (Carriage paid to)", id: "CPT" },
  { text: "DAF (Delivered at frontier)", id: "DAF" },
  { text: "DAP (Delivered at place)", id: "DAP" },
  { text: "DAT (Delivered at terminal)", id: "DAT" },
  { text: "DDP (Delivered Duty Paid)", id: "DDP" },
  { text: "DDU (Delivered Duty Unpaid)", id: "DDU" },
  { text: "DEQ (Delivered ex quay (duty paid))", id: "DEQ" },
  { text: "DES (Delivered ex ship)", id: "DES" },
  { text: "EXW (Ex Works)", id: "EXW" },
  { text: "FAS (Free Alongside Ship)", id: "FAS" },
  { text: "FCA (Free Carrier)", id: "FCA" },
  { text: "FH (Free house)", id: "FH" },
  { text: "FOB (Free on board)", id: "FOB" },
  { text: "UN (Not Free)", id: "UN" },
];

export const URUN_SEGMENT = ["Non-Daikin", "OEM", "Daikin"];

export const DATASEVKADRESITIPI = [
  { adreS_BASLIGI: "SAP_ADRES" },
  { adreS_BASLIGI: "YENI_ADRES" },
];

export const DATADIL = [
  { text: "TR Turkish", id: "TR Turkish" },
  { text: "EN English", id: "EN English" },
];

export const YES_NO = ["EVET", "HAYIR"];

export const FILE_STATUSES = [
  "UploadFailed",
  "Initial",
  "Selected",
  "Uploading",
  "Uploaded",
  "RemoveFailed",
  "Removing",
];

export const USER_TYPES = [
  "BAYI_YETKILISI",
  "BAYI_CALISANI",
  "DAIKIN_ADMIN",
  "DAIKIN_ICI",
  "DAIKIN_SATIS_DESTEK",
  "DAIKIN_SATIS_TEMSILCISI",
];

export const DAIKIN_USER_TYPES = ["DAIKIN", "NON_DAIKIN"];

export const DATAMONTH = [
  { text: "Ocak", id: "1" },
  { text: "Şubat", id: "2" },
  { text: "Mart", id: "3" },
  { text: "Nisan", id: "4" },
  { text: "Mayıs", id: "5" },
  { text: "Haziran", id: "6" },
  { text: "Temmuz", id: "7" },
  { text: "Ağustos", id: "8" },
  { text: "Eylül", id: "9" },
  { text: "Ekim", id: "10" },
  { text: "Kasım", id: "11" },
  { text: "Aralık", id: "12" },
];

export const DATAFIRMATIPI = [
  { text: "DAIKIN-BAYI", id: "DAIKIN-BAYI" },
  { text: "INSAAT FIRMASI", id: "INSAAT FIRMASI" },
  { text: "KURUMSAL MUSTERI", id: "KURUMSAL MUSTERI" },
  { text: "MEKANIK TAAHHUT", id: "MEKANIK TAAHHUT" },
  { text: "MIMAR", id: "MIMAR" },
  { text: "PROJECI", id: "PROJECI" },
  { text: "RAKIP FIRMA", id: "RAKIP FIRMA" },
  { text: "RAKIP FIRMA - BAYI", id: "RAKIP FIRMA - BAYI" },
  { text: "RAKIP FIRMA - Y.SERVIS", id: "RAKIP FIRMA - Y.SERVIS" },
  { text: "RESMI KURUM", id: "RESMI KURUM" },
  { text: "YATIRIMCI", id: "YATIRIMCI" },
  { text: "YETKİLİ SERVİS", id: "YETKİLİ SERVİS" },
  { text: "TOPTANCI", id: "TOPTANCI" },
  { text: "AIRFEL DISTRIBUTOR", id: "AIRFEL DISTRIBUTOR" },
  { text: "AIRFEL BAYİ", id: "AIRFEL BAYİ" },
];

export const DATAMUSFIRMATIPI = [
  { text: "Cons. Comp.", id: "Cons. Comp." },
  { text: "Daikin Pro", id: "Daikin Pro" },
  { text: "Designer", id: "Designer" },
  { text: "Investor", id: "Investor" },
  { text: "Mec. Cont.", id: "Mec. Cont." },
  { text: "Others", id: "Others" },
  { text: "Service", id: "Service" },
  { text: "UPG Airfel Dealer", id: "UPG Airfel Dealer" },
  { text: "UPG-Airfel Distributor", id: "UPG-Airfel Distributor" },
  { text: "UPG-Daikin Dealer", id: "UPG-Daikin Dealer" },
  { text: "UPG-Daikin Distributor", id: "UPG-Daikin Distributor" },
];

export const DATAMUSTIPI = [
  { text: "Dealer", id: "Dealer" },
  { text: "Sub Dealer", id: "Sub Dealer" },
  { text: "Distributor", id: "Distributor" },
];

export const DATASERVISTIPI = [
  { text: "Authorized Service", id: "Authorized Service" },
  { text: "Dealer-Service", id: "Dealer-Service" },
  { text: "Exclusive Service", id: "Exclusive Service" },
];

export const DATAPROTIPI = [
  { text: "Aday Senior", id: "ADAY_SENIOR" },
  { text: "Aday Master", id: "ADAY_MASTER" },
  { text: "Senior", id: "SENIOR" },
  { text: "Master", id: "MASTER" },
  { text: "Stratejik", id: "STRATEJIK" },
];

export const DATAFIRMAKIMLIGI = [
  { text: "Daikin Heating Bayi", id: "DAIKIN_HEATING_BAYI" },
  { text: "Daikin Toptancı", id: "DAIKIN_TOPTANCI" },
  { text: "Mekanik Taahhüt", id: "MEKANIK_TAAHHUT" },
  { text: "Daikin Servis", id: "DAIKIN_SERVIS" },
  { text: "Daikin UPG Bayi", id: "DAIKIN_UPG_BAYI" },
];

export const DATAPRODURUMU = [
  { text: "Aktif", id: "AKTIF" },
  { text: "Pasif", id: "PASIF" },
  { text: "Aday", id: "ADAY" },
];

export const DATAMUSFIRMATIPI2 = [
  { text: "Cons. Comp.", id: "Cons. Comp." },
  { text: "Designer", id: "Designer" },
  { text: "Investor", id: "Investor" },
  { text: "Mec. Cont.", id: "Mec. Cont." },
  { text: "Others", id: "Others" },
];

export const DATAPLANT = [
  { text: "5840", id: "5840" },
  { text: "5820", id: "5820" },
];

export const DATASALESYEARS = [
  { label: "2018", value: "2018" },
  { label: "2019", value: "2019" },
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
];

export const DATASALESMONTHS = [
  { value: "1", label: "Ocak" },
  { value: "2", label: "Şubat" },
  { value: "3", label: "Mart" },
  { value: "4", label: "Nisan" },
  { value: "5", label: "Mayıs" },
  { value: "6", label: "Haziran" },
  { value: "7", label: "Temmuz" },
  { value: "8", label: "Ağustos" },
  { value: "9", label: "Eylül" },
  { value: "10", label: "Ekim" },
  { value: "11", label: "Kasım" },
  { value: "12", label: "Aralık" },
];

export const DATACECSERVICECATEGORY = [
  { text: "Diğer Talepler", id: "SO-3P", teamId: "SERV_5801" },
  { text: "Stok Sorgusu", id: "SO-AV", teamId: "SERV_5801" },
  { text: "Cari Hesap İşlemleri", id: "FA-AS", teamId: "SERV_5808" },
  { text: "Isıtma Yedek Parça Sipariş", id: "SP-NO", teamId: "SERV_5801" },
  { text: "Sipariş İptali", id: "SO-CO", teamId: "SERV_5801" },
  { text: "Sipariş Revizesi", id: "SO-AO", teamId: "SERV_5801" },
  { text: "Sevk Talebi", id: "SO-DI", teamId: "SERV_5801" },
  { text: "İade İşlemi", id: "SO-RT", teamId: "SERV_5801" },
  { text: "Sorunlu Teslimat", id: "SO-CM", teamId: "SERV_5806" },
  {
    text: "Üretim Kaynaklı Yedek Parça Gönderimi",
    id: "SP-CM",
    teamId: "SERV_5801",
  },
];

export const DATACECSERVICECATEGORYFORDAIKIN = [
  { text: "Diğer Talepler", id: "SO-3P", teamId: "SERV_5801" },
  { text: "Stok Sorgusu", id: "SO-AV", teamId: "SERV_5801" },
  { text: "Cari Hesap İşlemleri", id: "FA-AS", teamId: "SERV_5808" },
  { text: "Isıtma Yedek Parça Sipariş", id: "SP-NO", teamId: "SERV_5801" },
  { text: "Ödeme Sorgulama ve İşletme", id: "FA-PM", teamId: "SERV_5807" },
  { text: "Sipariş İptali", id: "SO-CO", teamId: "SERV_5801" },
  { text: "Sipariş Revizesi", id: "SO-AO", teamId: "SERV_5801" },
  { text: "Sevk Talebi", id: "SO-DI", teamId: "SERV_5801" },
  { text: "İade İşlemi", id: "SO-RT", teamId: "SERV_5801" },
  { text: "Sorunlu Teslimat", id: "SO-CM", teamId: "SERV_5801" },
  {
    text: "Üretim Kaynaklı Yedek Parça Gönderimi",
    id: "SP-CM",
    teamId: "SERV_5801",
  },
  { text: "Ödeme İşleme ve Arbitraj", id: "FA-AC", teamId: "SERV_5807" },
];

export const DATACECRETURNTYPE = [
  {
    text: "Sistemsel İade İşlemi (Yeniden Faturalama)",
    id: "Sistemsel İade İşlemi (Yeniden Faturalama)",
  },
  { text: "Ürün İadesi", id: "Ürün İadesi" },
];

export const DATACECRETURNREASON = [
  { text: "Hasarlı Ürün", id: "HASARLI_URUN" },
  { text: "Hatalı Ürün", id: "HATALI_URUN" },
];

export const DATAREVIZENEDENLERI = [
  { id: "BIRIM_FIYAT_HATASI", text: "Birim Fiyat Hatası" },
  {
    id: "HATALI_PROFORMA_SIPARIS",
    text: "Hatalı Proforma, Sipariş Vb. Nedenler",
  },
  { id: "TEKLIF_SIPARIS_REVIZESI", text: "Teklif/Sipariş Revizesi" },
  { id: "ODEME_KOSULLARI", text: "Ödeme Koşulları" },
  { id: "DIGER", text: "Diğer" },
];

export const DATACECIHTARALANI = [
  { id: "18 VRV", text: "18 ESG VRV" },
  { id: "19 APP", text: "19 ESG Applied" },
  { id: "20 REF", text: "20 ESG Refrigeration" },
  { id: "21 AS SP", text: "21 After Sales Spare Parts" },
  { id: "22 AS CS", text: "22 After Sales Corporate Sales" },
  { id: "23 AS APP", text: "23 After Sales Applied Systems" },
  { id: "24 SS SERV", text: "24 After Sales services" },
  { id: "25 AS TRA", text: "25 After Sales Training" },
  { id: "26 AS TECH", text: "26 After Sales Technical Section" },
  { id: "27 EXP UNI", text: "27 Export Unitary" },
  { id: "28 EXP ENG", text: "28 Export Engineering" },
  { id: "16 INT.COMP.", text: "16 Intercompany" },
  { id: "17 OTHERS", text: "17 Others" },
  { id: "29 TANIMSIZ", text: "29 Tanımsız Ödeme" },
  { id: "30 DK HEAT", text: "30 UPG Daikin Heating" },
  { id: "32 AR HEAT", text: "32 UPG Airfel Heating" },
  { id: "33 UPG AR SPL", text: "33 UPG Airfel Split" },
  { id: "34 UPG PRJ", text: "34 UPG Project" },
  { id: "36 UPG KA", text: "36 UPG Key Account" },
  { id: "37 ECOM TR", text: "37 eCommerce Turkey" },
];

export const DATACECCARIHESAPISLEM = [
  { text: "Kredi Kontrol Diğer Talepler", id: "Kredi Kontrol Diğer Talepler" },
  {
    text: "Mutabakat-Cari Hesap Ekstresi Talebi",
    id: "Mutabakat-Cari Hesap Ekstresi Talebi",
  },
];

export const DATACECCARIHESAPISLEMFORDAIKIN = [
  { text: "Kredi Kontrol Diğer Talepler", id: "Kredi Kontrol Diğer Talepler" },
  {
    text: "Mutabakat-Cari Hesap Ekstresi Talebi",
    id: "Mutabakat-Cari Hesap Ekstresi Talebi",
  },
  { text: "Sanal Pos Virman", id: "Sanal Pos Virman" },
  { text: "Cariler Arası Virman", id: "Cariler Arası Virman" },
  { text: "Arbitraj", id: "Arbitraj" },
];

export const DATACECTALEPNEDENI = [
  { text: "Diğer", id: "Diğer" },
  { text: "Hatalı Kayıt", id: "Hatalı Kayıt" },
  { text: "Satış Sonrası İşlemleri", id: "Satış Sonrası İşlemleri" },
  { text: "Ürün Çıkışı", id: "Ürün Çıkışı" },
  { text: "Vade Yapılandırma", id: "Vade Yapılandırma" },
  { text: "Yedek Parça İşlemleri", id: "Yedek Parça İşlemleri" },
];

export const DATACECARBITRAJISLEMYAPILACAKKUR = [
  { text: "Fatura Tarihindeki TCMB Döviz Satış Kuru", id: "FATURA" },
  {
    text: "Ödeme Tarihinden Bir Gün Öncesine Ait TCMB Döviz Satış Kuru",
    id: "ODEME",
  },
];

export const DATACECODEMEISLEMEARBITRAJISLEMNEDENLERI = [
  { text: "Nakit/Havale Cariye Henüz İşlenmemiş İse", id: "Nakit/Havale" },
  { text: "Sanal Pos Cariye Henüz İşlenmemiş İse", id: "Sanal Pos" },
];

export const DATASALESAUTH = [
  "TEKLIF_DETAYI_GOREBILIR",
  "TEKLIF_BASLIGI_GOREBILIR",
];

export const DATASEVKEDILMEMENEDENI = [
  { text: "Ödeme Problemi", id: "ODEME_PROBLEMI" },
  { text: "Depo/Şantiye Problemi", id: "DEPO_SANTIYE_PROBLEMI" },
  { text: "Müşteri İstemiyor", id: "MUSTERI_ISTEMIYOR" },
  { text: "Stok Problemi", id: "STOK_PROBLEMI" },
  { text: "Teşvik Belgesi Gecikmesi", id: "TESVIK_BELGESI_GECIKMESI" },
  { text: "Sipariş Süreci Problemi", id: "SIPARIS_SURECI_PROBLEMI" },
];

export const DATASEVKEDILMEMEDETAYI = [
  {
    text: "Çek Ödeme Süreci",
    id: "CEK_ODEME_SURECI",
    parent: "ODEME_PROBLEMI",
  },
  {
    text: "Dbs Ödeme Süreci",
    id: "DBS_ODEME_SURECI",
    parent: "ODEME_PROBLEMI",
  },
  {
    text: "Satış Onayı Uyumsuzluğu",
    id: "SATIS_ONAYI_UYUMSUZLUĞU",
    parent: "ODEME_PROBLEMI",
  },
  {
    text: "Farklı Birim Ödeme Kullanımı",
    id: "FARKLI_BIRIM_ODEME_KULLANIMI",
    parent: "ODEME_PROBLEMI",
  },
  {
    text: "Teşvikli/Leasing/Akreditif",
    id: "TESVIKLI_LEASING_AKREDITIF",
    parent: "ODEME_PROBLEMI",
  },
  {
    text: "Swift Ödeme Süreci",
    id: "SWIFT_ODEME_SURECI",
    parent: "ODEME_PROBLEMI",
  },
  { text: "Cari Ödeme", id: "CARI_ODEME", parent: "ODEME_PROBLEMI" },

  {
    text: "Depo Müsait Değil",
    id: "DEPO_MUSAIT_DEGIL",
    parent: "DEPO_SANTIYE_PROBLEMI",
  },
  {
    text: "Şantiye Müsait Değil",
    id: "SANTIYE_MUSAIT_DEGIL",
    parent: "DEPO_SANTIYE_PROBLEMI",
  },

  {
    text: "Müşteri İstemiyor",
    id: "MUSTERI_ISTEMIYOR",
    parent: "MUSTERI_ISTEMIYOR",
  },
  {
    text: "Sevk Adresi Bekleniyor",
    id: "SEVK_ADRESI_BEKLENIYOR",
    parent: "MUSTERI_ISTEMIYOR",
  },
  {
    text: "Müşteriden Bilgi Bekleniyor",
    id: "MUSTERIDEN_BILGI_BEKLENIYOR",
    parent: "MUSTERI_ISTEMIYOR",
  },

  {
    text: "Stoğa Giriş Bekleniyor",
    id: "STOK_GIRIS_BEKLENIYOR",
    parent: "STOK_PROBLEMI",
  },
  {
    text: "Gelecek Ay/Aylar Konfirme",
    id: "GELECEK_AY_KONFIRME",
    parent: "STOK_PROBLEMI",
  },
  { text: "Stok Yok", id: "STOK_YOK", parent: "STOK_PROBLEMI" },
  { text: "Kısmi Stok", id: "STOK_KISMI", parent: "STOK_PROBLEMI" },
  {
    text: "Ürün Önceliklendirmesi",
    id: "URUN_ONCELIKLENDIRME",
    parent: "STOK_PROBLEMI",
  },
  { text: "Stok Kontrolü", id: "STOK_KONTROLU", parent: "STOK_PROBLEMI" },
  { text: "Stok Dağılımı", id: "STOK_DAGILIMI", parent: "STOK_PROBLEMI" },

  {
    text: "Teşvik Belgesi Revize Edilecek",
    id: "TESVIK_BELGESI_REVIZE_EDILECEK",
    parent: "TESVIK_BELGESI_GECIKMESI",
  },
  {
    text: "Teşvik Belgesi Onayda",
    id: "TESVIK_BELGESI_ONAYDA",
    parent: "TESVIK_BELGESI_GECIKMESI",
  },

  {
    text: "Sipariş Sorunu",
    id: "SIPARIS_SORUNU",
    parent: "SIPARIS_SURECI_PROBLEMI",
  },
  {
    text: "Merkezi Sipariş",
    id: "MERKEZI_SIPARIS",
    parent: "SIPARIS_SURECI_PROBLEMI",
  },
];

export const DATASEVKSEKLI = [
  { text: "Hazır Oldukça Sevk Edilebilinir", id: "HAZIR_OLDUKCA_SEVK_EDILIR" },
  { text: "Parti Parti", id: "PARTI_PARTI" },
  { text: "Tamamı Aynı Gün", id: "TAMAMI_AYNI_GUN" },
];

export const DATASEVKARACTIPI = [
  { text: "Fark Etmez", id: "FARK_ETMEZ" },
  { text: "Kamyon İle", id: "KAMYON" },
  { text: "Tır İle", id: "TIR" },
];

export const DATASEVKZAMANI = [
  { text: "Fark Etmez", id: "FARK_ETMEZ" },
  { text: "Hafta İçi", id: "HAFTA_ICI" },
  { text: "Hafta Sonu", id: "HAFTA_SONU" },
];

export const DATAFATURAKURU = [
  { text: "TL Fatura", id: "TRY" },
  { text: "Döviz Fatura", id: "DOVIZ_FATURA" },
];

export const DATAYESILBINASERTIFIKA = [
  { text: "LEED", id: "LEED" },
  { text: "BREEAM", id: "BREEAM" },
  { text: "WELL", id: "WELL" },
  { text: "NSEB", id: "NSEB" },
];
