import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";

class FreeStockProductService {
  getByTeklifSiparisId(teklifSiparis, teklifSiparisId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestockurun/get-by-teklif-siparis-id/${teklifSiparis}/${teklifSiparisId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
}

export default new FreeStockProductService();
