import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderProductReducer(
  state = initialState.orderProduct,
  action
) {
  switch (action.type) {
    case actionTypes.ADD_ORDER_PRODUCT:
      return {
        dataUpdated: false,
        rejectedData: false,
      };
    case actionTypes.ADD_ORDER_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
        rejectedData: false,
      };
    case actionTypes.ADD_ORDER_PRODUCT_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.ADD_ORDER_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        rejectedData: true,
        fetching: false,
      };
    case actionTypes.EDIT_ORDER_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.EDIT_ORDER_PRODUCT_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.EDIT_ORDER_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        rejectedUpdateData: true,
        fetching: false,
      };
    case actionTypes.REMOVE_ORDER_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.REMOVE_ORDER_PRODUCT_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.REMOVE_ORDER_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
