import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import UserRoleService from "../../services/srv-user/UserRoleService";
import UserMenuAddonsService from "../../services/srv-user/UserMenuAddonsService";
import UserDealerService from "../../services/srv-user/UserDealerService";
import UserSalesDepartmentService from "../../services/srv-user/UserSalesDepartmentService";
import UserSalesZoneService from "../../services/srv-user/UserSalesZoneService";
import UserProjectResponsibilityService from "../../services/srv-user/UserProjectResponsibilityService";
import UserFinoRoleService from "../../services/srv-user/UserFinoRoleService";
import UserFinoSubUserService from "../../services/srv-user/UserFinoSubUserService";
import filterLocalization from "../../helpers/filterLocalization";

export function UserGenericModal({
  modalTitle,
  modalToggle,
  columnData,
  gridFor,
  gridPrimaryKey,
}) {
  const exportRef = useRef(null);
  const gridRef = useRef(null);
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";

  const [data, setData] = useState(null);
  const [dataState, setDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    if (gridFor === "userRoles") {
      UserRoleService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userMenuAddons") {
      UserMenuAddonsService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userDealers") {
      UserDealerService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userSalesDepartments") {
      UserSalesDepartmentService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userSalesZones") {
      UserSalesZoneService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userProjectResponsibilities") {
      UserProjectResponsibilityService.getByFilter(
        dataState,
        onCompletedGetData
      );
    }
    if (gridFor === "userFinoRoles") {
      UserFinoRoleService.getByFilter(dataState, onCompletedGetData);
    }
    if (gridFor === "userFinoSubUsers") {
      UserFinoSubUserService.getByFilter(dataState, onCompletedGetData);
    }

    //gridFor(dataState, onCompletedGetData);
  }, [dataState]);

  function onCompletedGetData(result) {
    if (result && result) {
      setData(result);
      if (result.dataState) setDataState(result.dataState);
    }
    setDataLoading(false);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setDataState(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  async function exportExcel() {
    if (exportRef !== null) {
      confirmAlert({
        title: "Uyarı",
        message:
          "Listenen satır sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
        buttons: [
          {
            label: "Evet",
            onClick: async () => {
              setDataLoading(true);
              if (gridFor === "userRoles") {
                UserRoleService.getByFilter(dataState, onCompletedGetData);
              }
              if (gridFor === "userMenuAddons") {
                UserMenuAddonsService.getByFilter(
                  dataState,
                  onCompletedGetData
                );
              }
              if (gridFor === "userDealers") {
                UserDealerService.getByFilter(dataState, onCompletedGetData);
              }
              if (gridFor === "userSalesDepartments") {
                UserSalesDepartmentService.getByFilter(
                  dataState,
                  onCompletedGetData
                );
              }
              if (gridFor === "userSalesZones") {
                UserSalesZoneService.getByFilter(dataState, onCompletedGetData);
              }
              if (gridFor === "userProjectResponsibilities") {
                UserProjectResponsibilityService.getByFilter(
                  dataState,
                  onCompletedGetData
                );
              }
              if (gridFor === "userFinoRoles") {
                UserFinoRoleService.getByFilter(dataState, onCompletedGetData);
              }
              if (gridFor === "userFinoSubUsers") {
                UserFinoSubUserService.getByFilter(
                  dataState,
                  onCompletedGetData
                );
              }
              var data = await UserRoleService.getProjectReports({
                ...dataState,
                skip: null,
                take: null,
              });
              exportRef.current.save(data.data.data, gridRef.current.columns);
              setDataLoading(false);
            },
          },
          {
            label: "Hayır",
            onClick: () => {},
          },
        ],
      });
    }
  }

  return (
    <Window
      title={modalTitle}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {dataLoading && LoadingPanel}
      <ExcelExport data={data} ref={exportRef}>
        <Grid
          sortable={true}
          resizable={true}
          filterable={true}
          data={data}
          {...dataState}
          dataItemKey={gridPrimaryKey}
          pageable={gridPageable}
          ref={gridRef}
          onDataStateChange={onDataStateChange}
          rowRender={(row, dataItem) => rowRender(row, dataItem)}
        >
          <GridToolbar>
            <button
              title="Excel"
              className="btn btn-success btn-sm"
              onClick={exportExcel}
            >
              Excel
            </button>
          </GridToolbar>
          <GridColumn
            field={gridPrimaryKey}
            title="Id"
            width="144px"
            filter={"numeric"}
            editable={false}
          />
          {columnData &&
            columnData.map((cd, index) => {
              return (
                <GridColumn
                  field={cd.field}
                  title={cd.title}
                  width={cd.width}
                />
              );
            })}
        </Grid>
      </ExcelExport>
    </Window>
  );
}
