import React from "react";
import { useParams } from "react-router-dom";
import FreeStock from "../cmp-freeStock/FreeStock";

const ExtFreeStock = () => {
  const { teklifId, teklifUrunId } = useParams();
  return (
    <FreeStock
      teklifSiparis="TEKLIF"
      teklifSiparisId={teklifId}
      teklifSiparisUrunId={teklifUrunId}
    ></FreeStock>
  );
};

export default ExtFreeStock;
