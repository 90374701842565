import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button, Col, Row } from "reactstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import FreeStockService from "../../services/srv-freeStock/FreeStockService";
import { groupBy } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
  getGroupIds,
} from "@progress/kendo-react-data-tools";
import { useInternationalization } from "@progress/kendo-react-intl";

const initialGroup = [
  {
    field: "uruN_KODU",
  },
  // {
  //   field: "tekliF_SIPARIS_UID",
  // },
];

const aggregates = [
  {
    field: "mmD_ADET",
    aggregate: "sum",
  },
  {
    field: "mmdH_ADET",
    aggregate: "sum",
  },
];
const processWithGroups = (data, group) => {
  if (group) {
    group.map((g) => (g.aggregates = aggregates));
  }
  const newDataState = groupBy(data, group);
  setGroupIds({
    data: newDataState,
    group: group,
  });
  return newDataState;
};

const FreeStockReserved = (props) => {
  const intl = useInternationalization();
  const [group, setGroup] = useState(initialGroup);
  const [resultState, setResultState] = useState([]);
  var initialData = [];
  const [collapsedState, setCollapsedState] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setDataLoading(true);
    FreeStockService.getByTeklifSiparisRezerve(onCallBackGetData);
  };
  const onCallBackGetData = ({ data }) => {
    var updatedData = data.map((a) => ({
      ...a,
      rdD_DATE: intl.formatDate(new Date(a.rdD_DATE), "d.MM.y"),
      conF_DATE: intl.formatDate(new Date(a.conF_DATE), "d.MM.y"),
    }));
    initialData = updatedData;
    var cResultState = processWithGroups(updatedData, initialGroup);
    setResultState(cResultState);
    setCollapsedState(getGroupIds({ data: cResultState }));
    setDataLoading(false);
  };

  const onExpandChange = React.useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);
        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  const onGroupChange = React.useCallback((event) => {
    const newDataState = processWithGroups(initialData, event.group);
    setGroup(event.group);
    setResultState(newDataState);
  }, []);

  const cellRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let children = (
        <span>
          {td.props.children.props.children} {"("}MMD Adet:
          {props.dataItem.aggregates.mmD_ADET.sum} {" - "} MMDH Adet:
          {props.dataItem.aggregates.mmdH_ADET.sum}
          {")"}
        </span>
      );
      return React.cloneElement(td, td.props, children);
    }
    return td;
  };

  const newData = setExpandedState({
    data: resultState,
    collapsedIds: collapsedState,
  });

  return (
    <div>
      {dataLoading && LoadingPanel}

      <Row style={{ paddingTop: "20px" }}>
        <Col lg="12" md="12" xs="12">
          <Card>
            <CardHeader>
              <CardTitle>
                <i class="fa fa-info-circle"></i> Stok Rezerve Edilenler
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Grid
                data={newData}
                sortable={false}
                onGroupChange={onGroupChange}
                groupable={true}
                group={group}
                onExpandChange={onExpandChange}
                expandField="expanded"
                // cellRender={cellRender}
              >
                <GridColumn
                  field="uruN_KODU"
                  title="Ürün Kodu"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="mmD_ADET"
                  title="MMD Adet"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="mmdH_ADET"
                  title="MMDH Adet"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="tekliF_SIPARIS"
                  title="Teklif/Sipariş"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="tekliF_SIPARIS_ID"
                  title="Teklif/Sipariş Id"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="conF_DATE"
                  title="Conf. Date"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="rdD_DATE"
                  title="RDD Date"
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="rezervE_TARIHI"
                  title="Rezerve Tarihi"
                  cell={(props) =>
                    GridDateFormatCell(props, "dd.MM.yyyy HH:mm")
                  }
                  width={200}
                ></GridColumn>
                <GridColumn
                  field="rezervE_GUN"
                  title="Rezerve Gün"
                  width={200}
                ></GridColumn>
              </Grid>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(FreeStockReserved);
