import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { PR_DEX_MERCURY } from "../../../config/globalVariables";
import {
  serviceMessageError,
  serviceMessageSuccess,
} from "../../../helpers/utils";
import { rawRequest, ServiceBase } from "../../srv-base/ServiceBase";

class OfferDenvService extends ServiceBase {
  constructor() {
    super(PR_DEX_MERCURY, "/teklifdenvonayurun");
  }

  getByOfferDenvId(id, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/teklifdenvonayurun",
      `/get-by-teklif-denv-id/${id}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getOfferDenvProductListForExcel(
    dataState,
    onSuccessCallback,
    onErrorCallback
  ) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/teklifdenvonayurun",
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new OfferDenvService();
