import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function productModelListReducer(
  state = initialState.productModelList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_BRY_PRODUCT_MODEL_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_BRY_PRODUCT_MODEL_FULFILLED:
      return {
        ...state,
        productModels: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_BRY_PRODUCT_MODEL_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
