import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { YES_NO } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import DasuService from "../../services/srv-dasu/DasuService";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { dasuGridColumns } from "./DasuGridColumns";

export default function DasuList() {
  const _export = useRef(null);
  const _grid = useRef(null);
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };

  const editField = "inEdit";
  const primaryKey = "uruN_MATERIAL_PRODUCT_TANIM_ID";
  const search = "";
  const sort = [
    {
      field: primaryKey,
      dir: "desc",
    },
  ];

  // Data Related Functions Begin
  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...search,
  });

  function onCompletedGetProducts(result) {
    setProducts(result);
    setOriginalProducts(result);
    if (result.dataState) {
      setProductsDataState(result.dataState);
    }
    setProductsLoading(false);
  }

  useEffect(() => {
    getProductsByFilter();
  }, [productsDataState]);

  function getProductsByFilter() {
    setProductsLoading(true);
    DasuService.getByFilter(productsDataState, onCompletedGetProducts);
  }

  function onLoadingCompleted(result) {
    setProductsLoading(false);
    getProductsByFilter();
  }
  function onDataStateChange(event) {
    filterLocalization(event);
    setProductsDataState(event.dataState);
  }

  function onItemChange(event) {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }
  function addNewProduct() {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setProducts({
        ...products,
        data: [newDataItem, ...products.data],
      });
    }
  }
  function enterEditCommand(dataItem) {
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = products.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setProducts({ ...products, data });
    }
  }
  function addCommand(dataItem) {
    setProductsLoading(true);
    DasuService.create(dataItem, onLoadingCompleted, onLoadingCompleted);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...products.data];
    data.splice(0, 1);
    setProducts({ ...products, data });
  }
  function updateCommand(dataItem) {
    setProductsLoading(true);
    DasuService.update(dataItem, onLoadingCompleted, onLoadingCompleted);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = originalProducts.data.find(
      (p) => p[primaryKey] === dataItem[primaryKey]
    );
    const data = products.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    setProducts({ ...products, data });
  }
  function CommandCell(props) {
    return (
      <MyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={editField}
        idField={primaryKey}
        nameField={primaryKey}
      />
    );
  }

  function YesNoCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setProducts({ ...products });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function YesNoFilterCell(props) {
    return (
      <DropdownFilterCell {...props} data={YES_NO} defaultValue={"Seçiniz"} />
    );
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>DASU Ürün Tanım</CardTitle>
        </CardHeader>
        <CardBody>
          {productsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={products}
            {...productsDataState}
            pageable={gridPageable}
            dataItemKey={primaryKey}
            editField={editField}
            onDataStateChange={onDataStateChange}
            onItemChange={onItemChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px" }}
          >
            <GridToolbar>
              <button
                title="Yeni Dasu Ürün Tanım"
                className="btn btn-primary btn-sm"
                onClick={addNewProduct}
              >
                Yeni Kayıt
              </button>
              <ExportExcel
                data={products}
                columns={dasuGridColumns}
                loadingFunction={setProductsLoading}
                dataState={productsDataState}
                serviceMethod={DasuService.getDasuForExcel}
                fileName="DasuUrunTanim"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn cell={CommandCell} width="100px" filterable={false} />
            <GridColumn field="matnr" title="MATNR" width="256px"></GridColumn>
            <GridColumn
              field="producT_NO"
              title="PRODUCT NO"
              width="256px"
            ></GridColumn>
            <GridColumn
              field="producT_NO_DASU"
              title="PRODUCT NO DASU"
              width="256px"
            ></GridColumn>
            <GridColumn
              field="dasU_SERI_KONTROL"
              title="DASU SERI KONTROL"
              width="256px"
              filterCell={YesNoFilterCell}
              cell={YesNoCell}
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
