import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class StockInquiryService {
  getStocks(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/stoksorgu/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getStocksDealer(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/stoksorgu/get-list-by-filter-dealer?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
}

export default new StockInquiryService();
