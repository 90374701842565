import React, { Component } from "react";
import { IG_REPORT } from "../../config/globalVariables";

export default class ReportViewer extends Component {
  componentDidMount() {
    window.jQuery("#reportViewer1").telerik_ReportViewer({
      serviceUrl: `${IG_REPORT}`,
      reportSource: this.props.reportSource,
      scaleMode: "FIT_PAGE_WIDTH",
      scale: 1.0,
      viewMode: "INTERACTIVE",
      printMode: "SPECIFIC",
      sendEmail: {
        enabled: false,
      },
      enableAccessibility: false,
      authenticationToken: localStorage.getItem("jwtToken"),
    });
  }

  render() {
    return (
      <div>
        {this.props.openPopup ? (
          ""
        ) : (
          <div>
            <link
              href="http://kendo.cdn.telerik.com/2020.3.1118/styles/kendo.common.min.css"
              rel="stylesheet"
              id="common-css"
            />
            <link
              href="http://kendo.cdn.telerik.com/2020.3.1118/styles/kendo.material.min.css"
              rel="stylesheet"
              id="default-css"
            />
          </div>
        )}

        <div
          style={
            this.props.reportRelative
              ? {
                  top: "10px",
                  bottom: "10px",
                  left: "20px",
                  right: "20px",
                  position: "relative",
                  width: window.innerWidth * 0.85,
                  height: "900px",
                }
              : this.props.openPopup
              ? {
                  top: "10px",
                  bottom: "10px",
                  left: "20px",
                  right: "20px",
                }
              : { top: "10px", bottom: "70px", left: "270px", right: "20px" }
          }
          id="reportViewer1"
        ></div>
      </div>
    );
  }
}
