export const dasuGridColumns = [
  {
    field: "matnr",
    title: "MATNR",
    width: "256px"
  },
  {
    field: "producT_NO",
    title: "PRODUCT NO",
    width: "256px"
  },
  {
    field: "producT_NO_DASU",
    title: "PRODUCT NO DASU",
    width: "256px"
  },
  {
    field: "dasU_SERI_KONTROL",
    title: "DASU SERI KONTROL",
    width: "256px"
  }
];
