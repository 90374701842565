import * as React from "react";

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { AutoComplete } from "@progress/kendo-react-dropdowns";

import LoadingPanel from "../sys-toolbox/LoadingPanel";

/**
 * Gridcell için yapılmış autocomplate
 */
const CustomAutoComplateForCell = ({
  cellProps: {
    dataItem: { inEdit },
    dataItem,
    onChange: gridCellOnChange,
    field,
  },
  secondFieldName,
  reducerName,
  action,
  listName,
  mainField,
}) => {
  const fielldValue = dataItem[field];
  const [value, setValue] = React.useState(fielldValue);
  const select = useSelector((state) => state[reducerName]);
  const { dataState = { skip: 0, take: 8 }, fetching } = select;
  const dispatch = useDispatch();
  const getList = (currrentDataState) => dispatch(action(currrentDataState));

  const getData = (v) =>
    getValidList().find((d) => d[mainField || field] === v);

  const onChange = (event) => {
    const value = event.target.value;
    const data = getData(value);
    const { syntheticEvent } = event;
    const { type } = syntheticEvent;
    // console.group("---autocomplate---");
    // console.log("event.syntheticEvent.type :", type);
    // console.log("List :", getValidList());
    // console.log("data : ", data);
    // console.groupEnd();

    if (value.length > 3) {
      if (type === "click" || type === "keydown" || data) {
        gridCellOnChange(
          {
            dataItem,
            field,
            syntheticEvent,
            value,
          },
          data
        );
      } else {
        const filters = [
          {
            field: mainField || field,
            operator: "contains",
            value,
          },
        ];
        secondFieldName &&
          filters.push({
            field: secondFieldName,
            operator: "contains",
            value,
          });
        getList({
          filter: {
            logic: "or",
            filters,
          },
          ...dataState,
        });
      }
    }
    setValue(value);
  };
  const getValidList = () =>
    Array.isArray(select[listName]?.data)
      ? select[listName].data
      : Array.isArray(select[listName])
      ? select[listName]
      : [];
  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        {secondFieldName && (
          <div>
            <span style={{ fontSize: "9px", color: "blue" }}>
              ({itemProps.dataItem[secondFieldName]})
            </span>
          </div>
        )}
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };
  return (
    <React.Fragment>
      <AutoComplete
        data={getValidList()}
        value={value}
        footer={
          fetching && (
            <span
              style={{
                marginLeft: "60px",
                height: "60px",
                position: "relative",
              }}
            >
              <div style={{ position: "absolute", left: "25%", top: "30px" }}>
                {LoadingPanel}
              </div>
            </span>
          )
        }
        onChange={onChange}
        loading={fetching}
        textField={mainField || field}
        disabled={!inEdit}
        required
        placeholder="En az 3 karakter giriniz."
        itemRender={itemRender}
        valid={!!getData()}
        onClose={onChange}
      />
    </React.Fragment>
  );
};

CustomAutoComplateForCell.propTypes = {
  /** rootReducer.js içerisindeki reducer'un adı */
  reducerName: PropTypes.string.isRequired,
  /** initialState.js içerisine tanımlanmış array tipinde olan alanın adı */
  listName: PropTypes.string.isRequired,
  /** Redux action */
  action: PropTypes.func.isRequired,
  /** GridColumnProps */
  cellProps: PropTypes.object.isRequired,
  /** listede gözükmesini istediğiniz 2. alan */
  secondFieldName: PropTypes.string,
  /** Eğer düzünlenen input'un adı ile çekilen datanın içerisindeki alın adı aynı ise bu alana gerek yok farklı ise çekilen datanın alan adı girilmesi gerekiyor */
  mainField: PropTypes.string,
};

export default CustomAutoComplateForCell;
