import axios from "axios";

import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function getCitiesByCountryCode(countryCode, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CITIES,
      payload: axios
        .get(
          `${API_GATEWAY + PR_DEX}/sehir/get-list-by-ulkeKodu/${countryCode}`
        )
        .then((result) => {
          if (callback) {
            callback(result.data);
          }
          return result.data;
        }),
    });
  };
}

export function getCitiesByRegionCode(regionCode, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_CITIES,
      payload: axios
        .get(
          `${API_GATEWAY + PR_DEX}/sehir/get-list-by-bolgekodu/${regionCode}`
        )
        .then((result) => {
          if (callback) {
            callback(result.data);
          }
          return result.data;
        }),
    });
  };
}
