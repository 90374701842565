import React, { Component } from "react";
import { connect } from "react-redux";
import CampaignList from "./CampaignList";

class Campaign extends Component {
  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Kampanya Listesi</h2>
        <CampaignList history={this.props.history}></CampaignList>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);
