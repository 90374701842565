import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class FreeStockMstService {
  getByTeklifSiparisId(teklifSiparis, teklifSiparisId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestockmst/get-by-teklif-siparis-id/${teklifSiparis}/${teklifSiparisId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }

  getByTeklifSiparisRezerve(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/freestockmst/get-by-teklif-siparis-rezerve`)
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
}

export default new FreeStockMstService();
