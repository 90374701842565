import { Button } from "@progress/kendo-react-buttons";
import * as React from "react";

export const LockedGridInlineEdit = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.idField] === undefined;
  return inEdit ? (
    <td style={props.style} className={props.className}>
      <Button
        icon="check"
        look="outline"
        className="k-button k-grid-save-command"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      />
      <Button
        icon="times"
        look="outline"
        className="k-button k-grid-cancel-command"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
      />
    </td>
  ) : (
    <td style={props.style} className={props.className}>
      <Button
        icon="edit"
        look="outline"
        className="k-button k-grid-edit-command"
        onClick={() => props.edit(dataItem)}
      />
      <Button
        icon="trash"
        look="outline"
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Kayıt silinecektir. Onaylıyor musunuz?"
          ) && props.remove(dataItem)
        }
      />
    </td>
  );
};
