import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import ProductBomService from "../../services/srv-mrc-productBom/ProductBomService";
import ProductDropDownList from "../sys-common/ProductDropDownList";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import { GridInlineEditNumericCell } from "../sys-toolbox/GridInlineEditNumericCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

import ExportExcel from "../sys-toolbox/ExcelExport";
import { productBomGridColumns } from "./ProductBomGridColumns";

export function ProductBomList() {
  const gridPrimaryKey = "mrC_URUN_BOM_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [productBomData, setProductBomData] = useState([]);
  const [originalProductBomData, setOriginalProductBomData] = useState([]);
  const [productBomDataLoading, setProductBomDataLoading] = useState(false);
  const [productBomDataState, setProductBomDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
  });

  function onGetByFilterCompleted(result) {
    setProductBomData(result);
    setOriginalProductBomData(result);
    if (result.dataState) setProductBomDataState(result.dataState);
    setProductBomDataLoading(false);
  }

  useEffect(() => {
    getProductBomsByFilter();
  }, [productBomDataState]);

  function getProductBomsByFilter() {
    setProductBomDataLoading(true);
    ProductBomService.getByFilter(productBomDataState, onGetByFilterCompleted);
  }

  function onSuccess(result) {
    setProductBomDataLoading(false);
    getProductBomsByFilter();
  }

  function onError() {
    setProductBomDataLoading(false);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setProductBomDataState(event.dataState);
  }

  function onItemChange(event) {
    const data = productBomData.data.map((item) =>
      item[gridPrimaryKey] === event.dataItem[gridPrimaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProductBomData({ ...productBomData, data });
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function addNewProductBom() {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = productBomData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setProductBomData({
        ...productBomData,
        data: [newDataItem, ...productBomData.data],
      });
    }
  }

  function enterEditCommand(dataItem) {
    var isOpenEdit = productBomData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = productBomData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setProductBomData({ ...productBomData, data });
    }
  }

  function addCommand(dataItem) {
    setProductBomDataLoading(true);
    ProductBomService.create(dataItem, onSuccess, onError);
    dataItem.inEdit = false;
  }

  function removeCommand(dataItem) {
    setProductBomDataLoading(true);
    ProductBomService.delete(dataItem, onSuccess, onError);
    dataItem.inEdit = false;
  }

  function discardCommand() {
    const data = [...productBomData.data];
    data.splice(0, 1);
    setProductBomData({ ...productBomData, data });
  }

  function updateCommand(dataItem) {
    setProductBomDataLoading(true);
    ProductBomService.update(dataItem, onSuccess, onError);
    dataItem.inEdit = false;
  }

  function cancelCommand(dataItem) {
    const originalItem = originalProductBomData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = productBomData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setProductBomData({ ...productBomData, data });
  }

  function CommandCell(props) {
    return (
      <MyCommandCell
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        remove={removeCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={gridEditField}
        idField={gridPrimaryKey}
        nameField={gridPrimaryKey}
      />
    );
  }

  function ProductCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <ProductDropDownList
            id={field + dataItem[gridPrimaryKey]}
            name={field + dataItem[gridPrimaryKey]}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.uruN_KODU;
              setProductBomData({ ...productBomData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem[field]}</td>;
    }
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Ürün Bom Tanım</CardTitle>
        </CardHeader>
        <CardBody>
          {productBomDataLoading && LoadingPanel}
          <Grid
            ref={gridRef}
            data={productBomData}
            {...productBomDataState}
            pageable={gridPageable}
            dataItemKey={gridPrimaryKey}
            editField={gridEditField}
            onDataStateChange={onDataStateChange}
            onItemChange={onItemChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px" }}
          >
            <GridToolbar>
              <button
                title="Yeni Ürün Bom Tanım"
                className="btn btn-primary btn-sm"
                onClick={addNewProductBom}
              >
                Yeni Kayıt
              </button>
              <ExportExcel
                data={productBomData}
                columns={productBomGridColumns}
                loadingFunction={setProductBomDataLoading}
                dataState={productBomDataState}
                serviceMethod={ProductBomService.getProductBomsForExcel}
                fileName="UrunBomTanim"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn cell={CommandCell} width="100px" filterable={false} />
            <GridColumn
              field="uruN_KODU_MST"
              title="MASTER"
              width="256px"
              cell={ProductCell}
            />
            <GridColumn
              field="uruN_KODU_DET"
              title="BOM"
              width="256px"
              cell={ProductCell}
            />
            <GridColumn
              field="adet"
              title="ADET"
              width="256px"
              cell={GridInlineEditNumericCell}
            />
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
