export const orderCopGridColumns = [
  {
    field: "brY_SIPARIS_ID",
    title: "Sipariş Id",
    width: "256px"
  },
  {
    field: "crE_DATE",
    title: "Sipariş Giriş Tarihi",
    width: "256px"
  },
  {
    field: "bayI_KODU",
    title: "Bayi Kodu",
    width: "256px"
  },
  {
    field: "bayI_ADI",
    title: "Bayi Adı",
    width: "256px"
  },
  {
    field: "satiS_DEPARTMANI_ADI",
    title: "Satış Departmanı",
    width: "256px"
  },
  {
    field: "brY_KANAL",
    title: "Kanal",
    width: "256px"
  },
  {
    field: "parA_BIRIMI",
    title: "Para Birimi",
    width: "256px"
  },
  {
    field: "satiS_TEMSILCISI",
    title: "Satış Temsilcisi",
    width: "256px"
  },
  {
    field: "saP_DURUM",
    title: "SAP Sipariş Durumu",
    width: "256px"
  },
  {
    field: "saP_SIPARIS_NO",
    title: "SAP Sipariş No",
    width: "256px"
  },
  {
    field: "saP_GIRIS_TARIHI",
    title: "SAP Giriş Tarihi",
    width: "256px"
  },
];
