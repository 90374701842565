import { Window } from "@progress/kendo-react-dialogs";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import UserFinoRoleService from "../../services/srv-user/UserFinoRoleService";
import UserDropDownList from "../sys-common/UserDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export function UserFinoRole({ modalToggle, user, userName }) {
  const [userRoleData, setUserRoleData] = useState(null);
  const [userRoleDataLoading, setUserRoleDataLoading] = useState(false);

  useEffect(() => {
    getUsersByFilter();
  }, []);

  function getUsersByFilter() {
    setUserRoleDataLoading(true);
    UserFinoRoleService.getByKullaniciId(user, onCompletedGetData);
  }

  function onCompletedGetData(result) {
    result
      ? setUserRoleData({ ...result, gecerli: "EVET" })
      : setUserRoleData({
          ...userRoleData,
          gecerli: "EVET",
          kullanicI_ID: user,
        });
    setUserRoleDataLoading(false);
  }

  function onCompletedChange() {
    getUsersByFilter();
  }

  function saveCustomer() {
    if (userRoleData.finO_KULLANICI_ROLE_ID > 0) {
      userRoleData.yoneticI_KULLANICI_ID == 0 ||
      userRoleData.gmY_KULLANICI_ID == 0
        ? UserFinoRoleService.delete(userRoleData, onCompletedChange)
        : UserFinoRoleService.update(userRoleData, onCompletedChange);
    } else {
      UserFinoRoleService.create(userRoleData, onCompletedChange);
    }
  }

  return (
    <Window
      title={`${userName} Fin. Öd. Portalı Hakları`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <Card>
        <CardBody>
          {userRoleDataLoading && LoadingPanel}
          <Row>
            <Col lg="6" md="6" xs="12">
              <UserDropDownList
                label="Yönetici Adı"
                placeholder="Lütfen Seçiniz"
                id="yoneticI_KULLANICI_ID"
                value={userRoleData && userRoleData.yoneticI_KULLANICI_ID}
                onChange={(e) => {
                  setUserRoleData({
                    ...userRoleData,
                    yoneticI_KULLANICI_ID: e.value.kullanicI_ID,
                  });
                }}
              />
              <UserDropDownList
                label="GMY Adı"
                placeholder="Lütfen Seçiniz"
                id="gmY_KULLANICI_ID"
                value={userRoleData && userRoleData.gmY_KULLANICI_ID}
                onChange={(e) => {
                  setUserRoleData({
                    ...userRoleData,
                    gmY_KULLANICI_ID: e.value.kullanicI_ID,
                  });
                }}
              />
              <YesNoDropDownList
                label="Executive Committee"
                placeholder="Lütfen Seçiniz"
                id={"executivE_COMMITTEE"}
                name={"executivE_COMMITTEE"}
                value={userRoleData && userRoleData.executivE_COMMITTEE}
                onChange={(e) => {
                  setUserRoleData({
                    ...userRoleData,
                    executivE_COMMITTEE: e.value,
                  });
                }}
              />
              <YesNoDropDownList
                label="Finans Hakkı"
                placeholder="Lütfen Seçiniz"
                id={"finanS_HAKKI"}
                name={"finanS_HAKKI"}
                value={userRoleData && userRoleData.finanS_HAKKI}
                onChange={(e) => {
                  setUserRoleData({
                    ...userRoleData,
                    finanS_HAKKI: e.value,
                  });
                }}
              />
              <YesNoDropDownList
                label="İrtibat Kişisi"
                placeholder="Lütfen Seçiniz"
                id={"irtibaT_KISISI"}
                name={"irtibaT_KISISI"}
                value={userRoleData && userRoleData.irtibaT_KISISI}
                onChange={(e) => {
                  setUserRoleData({
                    ...userRoleData,
                    irtibaT_KISISI: e.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="btn btn-primary"
                onClick={() => saveCustomer()}
              >
                Kaydet
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Window>
  );
}
