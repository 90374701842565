import { TIMEZONE } from "../../../config/globalVariables";
import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";
import { getLocalDate } from "../../../helpers/utils";

export default function campaignReducer(state = initialState.campaign, action) {
  switch (action.type) {
    case actionTypes.GET_BRY_CAMPAIGN:
      return {
        ...state,
        _campaign: action.payload,
      };
    case actionTypes.GET_BRY_CAMPAIGN_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_CAMPAIGN_FULFILLED:
      return {
        ...state,
        _campaign: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_CAMPAIGN_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.RETURN_ADD_CAMPAIGN_PENDING:
      return {
        ...state,
        fetching: true,
        dataInserted: false,
      };
    case actionTypes.RETURN_ADD_CAMPAIGN_FULFILLED:
      return {
        ...state,
        _campaign: action.payload.data,
        dataInserted: true,
        fetching: false,
      };
    case actionTypes.RETURN_ADD_CAMPAIGN_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    case actionTypes.UPDATE_CAMPAIGN_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_CAMPAIGN_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.UPDATE_CAMPAIGN_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.SET_BRY_CAMPAIGN:
      var newCampaigns;
      if (action.payload.target.name) {
        newCampaigns = {
          [action.payload.target.name]: action.payload.target.value.id
            ? action.payload.target.value.id
            : action.payload.target.value,
        };
      }
      if (
        action.payload.target.props.name === "faturA_VADESI" ||
        action.payload.target.props.name === "listE_FIYATI_VADESI"
      ) {
        newCampaigns[action.payload.target.props.name] = parseInt(
          action.payload.target.value.id
        );
      }

      if (action.payload.target.dateInput) {
        newCampaigns[action.payload.target.name] = getLocalDate(
          action.payload.target.value,
          TIMEZONE
        );
      }

      return {
        ...state,
        _campaign: { ...state._campaign, ...newCampaigns },
      };
    default:
      return state;
  }
}
