import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BryProductCategoryService from '../../services/srv-product/BryProductCategoryService';

const BryDealerProductCategoryDropDownList = ({
    id,
    label,
    required,
    onChange,
    defaultValue,
    value
  }) => {
    const [filteredData, setFilteredData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState([]);
  
    useEffect(() => {
      setDataLoading(true);
      BryProductCategoryService.getProductCategories(
        {
          filter: { logic: "or", filters: [] },
        },
        defaultDataHandler
      );
    }, []);
  
    const defaultDataHandler = ({data}) => {
      data.sort((a, b) => (a.brY_URUN_KATEGORI_ACIKLAMA > b.brY_URUN_KATEGORI_ACIKLAMA ? 1 : -1))
      setData(data);
      setFilteredData(data);
      setDataLoading(false);
  
    };
  
    const filterData = (e) => {
      setFilteredData([
        ...data.filter((x) =>
          x.brY_URUN_KATEGORI_ACIKLAMA
            .toLowerCase()
            .includes(e.filter.value.toLowerCase())
        ),
      ]);
    };
  
    const itemRender = (li, itemProps) => {
      var itemChildren = (
        <div>
          <div>
            <span style={{ fontSize: "15px" }}>{li.props.children}</span>
          </div>        
        </div>
      );
  
      return React.cloneElement(li, li.props, itemChildren);
    };
  
    const onChangeTest=(e)=>{
      onChange(e)
    }
  
    return (
      <DropDownList
        name={id}
        id={id}
        label={label ? label : undefined}
        data={filteredData}
        textField="brY_URUN_KATEGORI_ACIKLAMA"
        dataItemKey="brY_URUN_KATEGORI"
        style={{ width: "100%" }}
        onChange={onChangeTest}
        defaultValue={defaultValue}
        required={required}
        itemRender={itemRender}
        filterable={true}
        onFilterChange={filterData}
        loading={dataLoading}
        value={value}
  
      ></DropDownList>
    );
  };

export default BryDealerProductCategoryDropDownList;