import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filterLocalization from "../../helpers/filterLocalization";
import { getGridFilterProperties } from "../../helpers/utils";
import {
  changeUserRights,
  fetchUserRights,
  fetchUserRightsAll,
} from "../../redux/actions/act-userRights/userRightsActions";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class UserRightsList extends Component {
  isGetUserRightss = false;

  state = {};

  componentDidMount() {
    this.getUserRightsList();
  }

  componentDidUpdate() {
    if (
      this.isGetUserRightss === false &&
      this.props.userRightsList.dataRemoved === true
    ) {
      this.isGetUserRightss = true;
      this.getUserRightsList();
    }
  }

  getUserRightsList = () => {
    var take = this.props.userRightsList.dataState
      ? this.props.userRightsList.dataState.take
      : 6;
    var skip = this.props.userRightsList.dataState
      ? this.props.userRightsList.dataState.skip
      : 0;

    this.props.fetchUserRights({ take: take, skip: skip });
  };

  export = () => {
    this.props.fetchUserRightsAll(this._export, this._grid.columns);
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchUserRights(event.dataState);
    this.props.changeUserRights(event.dataState);
  };

  onChangeSearch = (event) => {
    var searchFilter = Object.assign(
      {},
      this.props.userRightsList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );
    this.props.fetchUserRights(searchFilter);
    this.props.changeUserRights(searchFilter);
  };

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        {this.props.userRightsList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.userRightsList.userRights}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.userRightsList.result}
            {...this.props.userRightsList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              <Input
                name="search"
                label="Hızlı arama için enter'a basınız"
                style={{ width: "40%", float: "right" }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    this.onChangeSearch(event);
                  }
                }}
              ></Input>
            </GridToolbar>
            <GridColumn field="ad" title="AD" width="100px"></GridColumn>
            <GridColumn field="soyad" title="Soyad"></GridColumn>
            <GridColumn field="saP_NO" title="Bayi Kodu"></GridColumn>
            <GridColumn
              field="daikiN_CALISANI_MI"
              title="Daikin Çalışanı mı"
            ></GridColumn>
            <GridColumn field="email" title="Email" width="250px"></GridColumn>
            <GridColumn field="rolE_KODU" title="Rol Kodu"></GridColumn>
            <GridColumn field="menU_ADI" title="Menü Adı"></GridColumn>
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userRightsList: state.userRightsListReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUserRights: bindActionCreators(fetchUserRights, dispatch),
    fetchUserRightsAll: bindActionCreators(fetchUserRightsAll, dispatch),
    changeUserRights: bindActionCreators(changeUserRights, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRightsList);
