import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import ComplaintGrid from "./ComplaintGrid";

class Complaint extends Component {
  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Bayi Şikayet Listesi</h2>
        <ComplaintGrid></ComplaintGrid>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Complaint);
