import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filterLocalization from "../../helpers/filterLocalization";
import { getGridFilterProperties } from "../../helpers/utils";
import {
  changeUserSalesRights,
  fetchUserSalesRights,
  fetchUserSalesRightsAll,
} from "../../redux/actions/act-userRights/userSalesRightsActions";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class UserSalesRightsList extends Component {
  componentDidMount() {
    var take = this.props.userSalesRightsList.dataState
      ? this.props.userSalesRightsList.dataState.take
      : 6;
    var skip = this.props.userSalesRightsList.dataState
      ? this.props.userSalesRightsList.dataState.skip
      : 0;

    this.props.fetchUserSalesRights({ take: take, skip: skip });
  }
  onChangeSearch = (event) => {
    var searchFilter = Object.assign(
      {},
      this.props.userSalesRightsList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );
    this.props.fetchUserSalesRights(searchFilter);
    this.props.changeUserSalesRights(searchFilter);
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchUserSalesRights(event.dataState);
    this.props.changeUserSalesRights(event.dataState);
  };

  export = () => {
    this.props.fetchUserSalesRightsAll(this._export, this._grid.columns);
  };

  render() {
    return (
      <div style={{ marginTop: "20px" }}>
        {this.props.userSalesRightsList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.userSalesRightsList.userRights}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.userSalesRightsList.result}
            {...this.props.userSalesRightsList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
          >
            <GridToolbar>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              <Input
                name="search"
                label="Hızlı arama için enter'a basınız"
                style={{ width: "40%", float: "right" }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    this.onChangeSearch(event);
                  }
                }}
              ></Input>
            </GridToolbar>
            <GridColumn
              field="kullanicI_ADI"
              title="Kullanıcı"
              width="250px"
            ></GridColumn>

            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="Satış Departmanı"
              width="250px"
            ></GridColumn>
            <GridColumn field="yetki" title="Yetki"></GridColumn>
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userSalesRightsList: state.userSalesRightsListReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchUserSalesRights: bindActionCreators(fetchUserSalesRights, dispatch),
    fetchUserSalesRightsAll: bindActionCreators(
      fetchUserSalesRightsAll,
      dispatch
    ),
    changeUserSalesRights: bindActionCreators(changeUserSalesRights, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSalesRightsList);
