import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class ProductService {
  getProducts(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/urun/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getProductsArtiGun(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/urun/get-list-arti-gun-by-filter?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getProductsForExcel(dataState) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/urun/get-list-for-excel?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => response.data);
  }
  
  getArtiGunProductsForExcel(dataState) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/urun/get-list-arti-gun-for-excel?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => response.data);
  }

  getProductById(id, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/urun/get-by-id/${id}`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  insert(product, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/urun/add-product`, product)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  update(product, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/urun/update-product`, product)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  updateArtiGun(product, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/urun/update-product-arti-gun`, product)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new ProductService();
