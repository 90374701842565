import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import filterLocalization from "../../helpers/filterLocalization";
import UpgReportService from "../../services/srv-upgReport/UpgReportService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import ExportExcel from "../sys-toolbox/ExcelExport";
import { upgReportManagerGridColumns } from "./UpgReportManagerGridColumns";

export function UpgReportManager() {
  const _grid = useRef(null);

  const [upgReportsLoading, setUpgReportsLoading] = useState(false);
  const [upgReports, setUpgReports] = useState(null);
  const [upgReportsDataState, setUpgReportsDataState] = useState({
    take: 10,
    skip: 0,
  });

  useEffect(() => {
    setUpgReportsLoading(true);
    var newDataState = {
      take: upgReportsDataState ? upgReportsDataState.take : 10,
      skip: upgReportsDataState ? upgReportsDataState.skip : 0,
    };
    UpgReportService.getUpgReports(newDataState, onGetDataCompleted);
  }, []);

  const onGetDataCompleted = (data) => {
    setUpgReports(data);
    setUpgReportsLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setUpgReportsLoading(true);
    setUpgReportsDataState(event.dataState);
    UpgReportService.getUpgReports(event.dataState, onGetDataCompleted);
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>UPG Rapor Yönetici</CardTitle>
        </CardHeader>
        <CardBody>
          {upgReportsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={upgReports}
            dataItemKey={"mrC_TEKLIF_ID"}
            {...upgReportsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ExportExcel
                data={upgReports}
                columns={upgReportManagerGridColumns}
                loadingFunction={setUpgReportsLoading}
                dataState={upgReportsDataState}
                serviceMethod={UpgReportService.getUpgReportsForExcel}
                fileName="UpgRaporYonetici"
              ></ExportExcel>
            </GridToolbar>
            {upgReportManagerGridColumns.map((column, index) => (
              <GridColumn
                field={column.field}
                title={column.title}
                width={column.width}
                filter={column.filter}
                filterable={column.filterable ? column.filterable : true}
                cell={column.cell}
                filterCell={column.filterCell}
              />
            ))}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
