export const brandGridColumns = [
    {
      field: "geneL_MARKA_TANIM_ID",
      title: "Id",
      width: "256px"
    },
    {
      field: "markA_KODU",
      title: "Marka Kodu",
      width: "256px"
    },
    {
      field: "markA_ADI",
      title: "Marka Adı",
      width: "256px"
    },
    {
      field: "sektoR_KODU",
      title: "Sektör Kodu",
      width: "256px"
    },
    {
      field: "pazaR_SEGMENTI_KODU",
      title: "Pazar Segmenti Kodu",
      width: "256px"
    },
    {
      field: "gecerli",
      title: "Geçerli",
      width: "128px"
    }
];