import React, { Component } from "react";
import { connect } from "react-redux";
import { Window } from "@progress/kendo-react-dialogs";
import OrderDiscountProductGroupList from "./OrderDiscountProductGroupList";

class OrderDiscountProductGroupModal extends Component {
  render() {
    return (
      <Window
        title="Sipariş Discount Ürün Grubu Listesi"
        onClose={this.props.toggleDialog}
        initialHeight={"40vh"}
        initialWidth={"35%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <OrderDiscountProductGroupList
          orderId={this.props.orderId}
        ></OrderDiscountProductGroupList>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderDiscountProductGroupModal);
