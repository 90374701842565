import axios from "axios";

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

import * as actionTypes from "../../actions/sys-configure/actionTypes";
import { API_GATEWAY, PR_DEX_MERCURY } from "../../../config/globalVariables";

export function fetchSalesDepartmant() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_DEPARTMANT,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_MERCURY}/satisdepartmani/get-all`)
        .then((result) => result.data),
    });
  };
}

export function fetchSalesDepartment(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_DEPARTMENT,
      payload: fetchSalesDepartmentPayload(dataState, callback),
    });
  };
}
export function changeSalesDepartment(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_SALES_DEPARTMENT,
      payload: event,
    });
  };
}

export function fetchSalesDepartmentPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_MERCURY
      }/satisdepartmani/get-by-filter?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

export function updateSalesDepartment(salesDepartment) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_UPDATE_SALES_DEPARTMENT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_MERCURY
          }/satisdepartmani/execute-satis-onay-vekalet`,
          {
            ...salesDepartment,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}
