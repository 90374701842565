export default function GeneralExcelTemplate({ sablon }) {
  const templates = {
    FINANSAL_BILGILER: ["BAYI_KODU", "HESAP_ADI", "CEK_RISKI"],
    BAYI_COFACE: ["BAYI_KODU", "COFACE"],
    BAYI_EXIM: ["BAYI_KODU", "EXIM"],
    CEK_SENET_KONTROL_GIRISI: [
      "TCKN_VERGINO",
      "ADSOYAD_UNVAN",
      "TALEP_EDILEN_TUTAR",
      "TALEP_SAHIBI_ADI",
      "TALEP_SAHIBI_MAIL",
    ],
    CEK_SENET_ONAY_SONUCU: [
      "TCKN_VERGINO",
      "ADSOYAD_UNVAN",
      "TALEP_EDILEN_TUTAR",
      "SONUC",
      "BAYI_KODU",
      "BAYI_ADI",
      "MEVCUT_DTAS_RISKI_ISLEM_ANI",
      "TEMINATLAR_TOPLAMI_ISLEM_ANI",
    ],
    MUSTERI_TANIM: [
      "MUS_ADI",
      "MUS_TELEFON",
      "MUS_EMAIL",
      "MUS_ADRES",
      "COGRAFI_BOLGE_KODU",
      "DAIKIN_IL_KODU",
      "ULKE_KODU",
      "BAYI_KODU",
      "MERCURY_MUSTERI_ID",
      "LATITUDE",
      "LONGITUDE",
    ],
    TEMINAT_MEKTUBU: [
      "BAYI_KODU",
      "ESG_VRV",
      "BAYI_ADI",
      "BANKA_ADI",
      "REFERANS_NO",
      "TUTAR",
      "PARA_BIRIMI",
      "YEREL_TUTAR",
      "BASLANGIC_TARIHI",
      "VADE_TARIHI",
      "LETTER_OF_GUARANTEE",
      "BORCLU",
      "BILGI",
      "DUNING_AREA",
    ],
    MUSTERI_URUN_INDIRIM: [
      "DEPARTMAN KODU",
      "SATIŞ KANAL KODU",
      "CUSTOMER SAP CODE",
      "PRODUCT CODE",
      "DISCOUNT",
      "KANAL",
    ],
    URUN_ARTI_FREE_STOCK_GUN_GUNCELLE: ["URUN KODU", "URUN_ADI", "ARTI GUN"],
    MERCURY_URUN_TANIM: [
      "URUN_KODU",
      "MRC_SECIM_FIYATI",
      "MRC_SECIM_FIYATI_EUR",
      "MRC_URUN_ADI_TR",
      "MRC_URUN_ADI_EN",
      "MRC_URUN_GRUBU",
      "MRC_URUN_YAPISI",
      "MRC_URUN_TIPI",
      "MRC_URUN_CINSI",
      "ANLIK_FIYAT_MI",
      "URUN_CARPANI",
      "URUN_CARPANI_2",
      "URUN_CARPANI_EXP",
      "URUN_CARPANI_EXP_2",
      "GECERLI",
      "URUN_KODU_SAP",
      "BRY_SATIS_MARKA",
      "BRY_MARKA_SEGMENT",
      "BRY_URUN_KATEGORI",
      "BRY_URUN_GRUBU",
      "BRY_URUN_TIPI",
      "BRY_URUN_MODELI",
      "BRY_URUN_MODELI_DETAY",
      "SECIM_PRG_URUN_KODU",
      "KAPASITE",
      "CHECKSUM_VAR",
      "DISCOUNT_URUN_GRUBU",
    ],
    URUN_FIYAT_GUNCELLEME: [
      "URUN_KODU",
      "SECIM_FIYATI_SAP_DOM_TRY",
      "SECIM_FIYATI_SAP_DOM_USD",
      "SECIM_FIYATI_SAP_DOM_EUR",
      "SECIM_FIYATI_SAP_EXP_TRY",
      "SECIM_FIYATI_SAP_EXP_USD",
      "SECIM_FIYATI_SAP_EXP_EUR",
      "LISTE_FIYATI_SAP_DOM_TRY",
      "LISTE_FIYATI_SAP_DOM_USD",
      "LISTE_FIYATI_SAP_DOM_EUR",
      "LISTE_FIYATI_SAP_EXP_TRY",
      "LISTE_FIYATI_SAP_EXP_USD",
      "LISTE_FIYATI_SAP_EXP_EUR",
      "KDV",
      "ORJINAL_KUR",
      "ORJINAL_KUR_EXP",
    ],
    BIREYSEL_URUN_MARKA: [
      "URUN_KODU",
      "KLIMA DAIKIN",
      "KLIMA_AIRFEL",
      "KLIMA_DAYLUX",
      "DEX_EXPORT",
      "ISITMA_DAIKIN",
      "ISITMA_AIRFEL",
      "ISITMA_DAYLUX",
    ],
    MERCURY_SATIS_DEPARTMANI_URUN: [
      "URUN_KODU",
      "SATIS_DEPARTMANI_KODU",
      "EVET-HAYIR",
    ],
    URUN_BOM_TANIMLAMA: ["URUN_KODU_MASTER", "URUN_KODU_DETAY", "ADET"],
    DKN_BAYI_HEDEF_EKLE: [
      "YEAR",
      "MONTH",
      "SBU_NAME_LEVEL_1",
      "SBU_NAME_LEVEL_2",
      "SALES_GROUP_CODE",
      "SALES_GROUP_NAME",
      "PRODUCT_GROUP",
      "PRODUCT_MODEL",
      "CUSTOMER_CODE",
      "QUANTITY_TARGET",
      "NET_SALES_TARGET",
    ],
    TLS_SERINO_BIGGLOYATY_ICIN: [
      "HANDLING_UNIT",
      "SERIAL_NUMBER",
      "MATERIAL",
      "PRODUCT_NUMBER",
      "MATERIAL_DESCRIPTION",
      "STORAGE_LOCATION",
      "SALES_ORDER",
      "DELIVERY",
      "CUSTOMER",
      "CUSTOMER_NAME",
      "CUSTOMER_CITY",
      "SHIP_TO",
      "SHIP_TO_NAME",
      "SHIP_TO_CITY",
      "ENTRY_DATE",
      "BILLING_DOCUMENT",
      "MOVEMENT_TYPE",
    ],
  };

  const foundTemplate = templates[sablon];
  if (!sablon || !foundTemplate) {
    return <></>;
  }

  return (
    <table className="table">
      <thead>
        <tr>
          {templates &&
            foundTemplate.map((t, i) => {
              return <th>{t}</th>;
            })}
        </tr>
      </thead>
      <tbody>
        {[...Array(3)].map((x) => {
          return (
            <tr>
              {templates &&
                foundTemplate.map((t, i) => {
                  return <td>#veri#</td>;
                })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
