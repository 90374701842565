import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class CustomerSalesDepartmentService {
  getCustomerSalesDepartments(dataState, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/musterisatisdepartmani/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  insert(customer, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/musterisatisdepartmani/add-customer-sales-department`, customer)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  update(customer, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX}/musterisatisdepartmani/update-customer-sales-department`, customer)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new CustomerSalesDepartmentService();
