import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import {
  API_GATEWAY,
  PR_DEX,
  PR_DEX_BIREYSEL,
  PR_DEX_MERCURY,
} from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";

class OrderService {
  getOrdersCop(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparis/get-list-cex?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getOrdersCopForExcel() {
    return axios
      .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/get-list-cex-for-excel`)
      .then((response) => {
        return response.data;
      });
  }

  getOrderCopById(id, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/get-by-id/${id}`)
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      });
  }

  getListMyOrder(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/siparis/get-list-my-order?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getListMyOrderForExcel(dataState) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/siparis/get-list-my-order-for-excel?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        return response.data;
      });
  }

  updateMyOrder(data, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/my-order-update`, data)
      .then((response) => {
        defaultAlertMessages.MessageDetailSuccess = response.message;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }

  sendToRPA(orderId, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/send-to-rpa/${orderId}`)
      .then((response) => {
        defaultAlertMessages.MessageDetailSuccess = response.message;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new OrderService();
