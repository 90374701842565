import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  Col,
  Row,
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import {
  BASE_URL,
  DATAMUSFIRMATIPI2,
  TIMEZONE,
} from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmSuccessMessage,
  getLocalDate,
} from "../../helpers/utils";
import CityService from "../../services/srv-city/CityService";
import CountryService from "../../services/srv-country/CountryService";
import CustomerService from "../../services/srv-customer/CustomerService";
import GeographicRegionService from "../../services/srv-geographicRegion/GeographicRegionService";
import { CustomerSalesDepartment } from "../cmp-customerSalesDepartment/CustomerSalesDepartment";
import BrandMultiSelect from "../sys-common/BrandMultiSelect";
import { CommonCalendar } from "../sys-common/CommonCalendar";
import CommonInput from "../sys-common/CommonInput";
import CommonNumericTextBox from "../sys-common/CommonNumericTextBox";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { CustomerAddressList } from "./CustomerAddressList";

export function CustomerUpdate(props) {
  const customerId = props.match.params.customerId;

  const [customerLoading, setCustomerLoading] = useState(false);
  const [customer, setCustomer] = useState({});
  const [countries, setCountries] = useState([]);
  const [geographicRegions, setGeographicRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    if (customerId > 0) {
      getCustomer();
    }
    getCountries();
  }, []);

  useEffect(() => {
    customer.ulkE_KODU && getGeographicRegions();
  }, [customer.ulkE_KODU]);

  useEffect(() => {
    customer.cografI_BOLGE_KODU && getCities();
  }, [customer.cografI_BOLGE_KODU]);

  function onCompletedGetCustomer(data) {
    if (data) {
      setCustomer(data.dKN_MUS_TANIM);
      setBrands(data.brands);
    }
    setCustomerLoading(false);
  }
  function getCustomer() {
    setCustomerLoading(true);
    CustomerService.getCustomerById(customerId, onCompletedGetCustomer);
  }

  function onCompletedGetCountries(data) {
    if (data) {
      setCountries(data.data);
    }
  }
  function getCountries() {
    CountryService.getCountries(onCompletedGetCountries);
  }

  function onCompletedGetGeographicRegions(data) {
    if (data) {
      setGeographicRegions(data);
    }
  }
  function getGeographicRegions() {
    GeographicRegionService.getGeographicRegions(
      customer.ulkE_KODU,
      onCompletedGetGeographicRegions
    );
  }

  function onCompletedGetCities(data) {
    console.log("ŞEHİR",data)
    if (data) {
      setCities(data);
    }
  }
  function getCities() {
    CityService.getCitiesGenelByRegionCode(
      customer.cografI_BOLGE_KODU,
      onCompletedGetCities
    );
  }

  function onCompletedInsertCustomer(axiosResult) {
    defaultAlertMessages.MessageDetailSuccess = axiosResult.data;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  }
  function onCompletedUpdateCustomer(axiosResult) {
    defaultAlertMessages.MessageDetailSuccess = axiosResult.data;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  }

  function saveCustomer() {
    var customerDto = { dkN_MUS_TANIM: customer, brands: brands };
    if (customerId == 0) {
      CustomerService.insert(customerDto, onCompletedInsertCustomer);
      window.open(BASE_URL + "customer-list");
    } else if (customerId > 0) {
      CustomerService.update(customerDto, onCompletedUpdateCustomer);
    } else {
      alert();
    }
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Müşteri Detay</CardTitle>
        </CardHeader>
        <CardBody>
          {customerLoading && LoadingPanel}
          <Row>
            <Col lg="6" md="6" xs="12">
              {customerId > 0 ? (
                <CommonInput
                  id="muS_KODU"
                  name="muS_KODU"
                  label="Müş. Kodu"
                  readOnly={true}
                  value={customer.muS_KODU}
                />
              ) : (
                <></>
              )}
              <CommonInput
                id="muS_ADI"
                name="muS_ADI"
                label="Müş. Adı"
                value={customer.muS_ADI}
                onChange={(e) =>
                  setCustomer({ ...customer, muS_ADI: e.target.value })
                }
              />
              <CommonInput
                id="taM_UNVAN"
                name="taM_UNVAN"
                label="Tam Ünvan"
                value={customer.taM_UNVAN}
                onChange={(e) =>
                  setCustomer({ ...customer, taM_UNVAN: e.target.value })
                }
              />
              <CommonInput
                id="muS_ADRES"
                name="muS_ADRES"
                label="Müş. Adres"
                value={customer.muS_ADRES}
                onChange={(e) =>
                  setCustomer({ ...customer, muS_ADRES: e.target.value })
                }
              />
              <CommonInput
                id="vergI_DAIRESI"
                name="vergI_DAIRESI"
                label="Vergi Dairesi"
                value={customer.vergI_DAIRESI}
                onChange={(e) =>
                  setCustomer({ ...customer, vergI_DAIRESI: e.target.value })
                }
              />
              <CommonInput
                id="vergI_NO"
                name="vergI_NO"
                label="Vergi No"
                value={customer.vergI_NO}
                onChange={(e) =>
                  setCustomer({ ...customer, vergI_NO: e.target.value })
                }
              />
              <CommonInput
                id="uluS_VERGI_NO"
                name="uluS_VERGI_NO"
                label="Uluslararası Vergi No"
                value={customer.uluS_VERGI_NO}
                onChange={(e) =>
                  setCustomer({ ...customer, uluS_VERGI_NO: e.target.value })
                }
              />
              <CommonNumericTextBox
                id="sermaye"
                name="sermaye"
                label="Sermaye"
                value={customer.sermaye}
                onChange={(e) =>
                  setCustomer({ ...customer, sermaye: e.target.value })
                }
              />
              <CommonNumericTextBox
                id="cofacE_LIMIT"
                name="cofacE_LIMIT"
                label="Coface Limit"
                value={customer.cofacE_LIMIT}
                onChange={(e) =>
                  setCustomer({ ...customer, cofacE_LIMIT: e.target.value })
                }
              />
              <CommonNumericTextBox
                id="exiM_LIMIT"
                name="exiM_LIMIT"
                label="Exim Limit"
                value={customer.exiM_LIMIT}
                onChange={(e) =>
                  setCustomer({ ...customer, exiM_LIMIT: e.target.value })
                }
              />
              <CommonNumericTextBox
                id="kredI_DEGERLENDIRME"
                name="kredI_DEGERLENDIRME"
                label="Kredi Değerlendirme"
                value={customer.kredI_DEGERLENDIRME}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    kredI_DEGERLENDIRME: e.target.value,
                  })
                }
              />
              <DatePicker
                label="Firma Kuruluş Yılı"
                id="firmA_KURULUS_YILI"
                name="firmA_KURULUS_YILI"
                format="yyyy"
                formatPlaceholder="formatPattern"
                calendar={CommonCalendar}
                value={
                  customer.firmA_KURULUS_YILI != null || undefined
                    ? customer.firmA_KURULUS_YILI === ""
                      ? null
                      : new Date(customer.firmA_KURULUS_YILI)
                    : null
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    firmA_KURULUS_YILI: getLocalDate(e.target.value, TIMEZONE),
                  })
                }
              />
              <CommonInput
                id="calisaN_ADI"
                name="calisaN_ADI"
                label="Çalışan Adı"
                value={customer.calisaN_ADI}
                onChange={(e) =>
                  setCustomer({ ...customer, calisaN_ADI: e.target.value })
                }
              />
              <CommonInput
                id="calisaN_MAIL"
                name="calisaN_MAIL"
                label="Çalışan E-Posta"
                value={customer.calisaN_MAIL}
                onChange={(e) =>
                  setCustomer({ ...customer, calisaN_MAIL: e.target.value })
                }
              />
              <CommonInput
                id="calisaN_GSM"
                name="calisaN_GSM"
                label="Çalışan Telefon"
                value={customer.calisaN_GSM}
                onChange={(e) =>
                  setCustomer({ ...customer, calisaN_GSM: e.target.value })
                }
              />
            </Col>
            <Col lg="6" md="6" xs="12">
              <CommonInput
                id="muS_TELEFON"
                name="muS_TELEFON"
                label="Müş. Telefon"
                value={customer.muS_TELEFON}
                onChange={(e) =>
                  setCustomer({ ...customer, muS_TELEFON: e.target.value })
                }
              />
              <CommonInput
                id="muS_EMAIL"
                name="muS_EMAIL"
                label="Müş. Email"
                value={customer.muS_EMAIL}
                onChange={(e) =>
                  setCustomer({ ...customer, muS_EMAIL: e.target.value })
                }
              />
              <CommonInput
                id="kep"
                name="kep"
                label="KEP Adresi"
                value={customer.keP_ADRESI}
                onChange={(e) =>
                  setCustomer({ ...customer, keP_ADRESI: e.target.value })
                }
              />
              <DealerDropDownList
                id="bayI_KODU"
                name="bayI_KODU"
                label="Bayi"
                value={customer.bayI_KODU}
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    bayI_KODU: e.target.value.bayI_KODU,
                  })
                }
              />
              <DropDownList
                id="firmA_TIPI"
                name="firmA_TIPI"
                label="Firma Tipi"
                data={DATAMUSFIRMATIPI2}
                textField="text"
                dataItemKey="id"
                style={{ width: "100%" }}
                onChange={(e) =>
                  setCustomer({ ...customer, firmA_TIPI: e.target.value.id })
                }
                value={
                  customer.firmA_TIPI
                    ? DATAMUSFIRMATIPI2.find(
                        (a) => a.id === customer.firmA_TIPI
                      )
                    : ""
                }
              />
              <DropDownList
                id="ulkE_KODU"
                name="ulkE_KODU"
                label="Ülke"
                data={countries}
                textField="ulkE_ADI"
                dataItemKey="ulkE_KODU"
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    ulkE_KODU: e.target.value.ulkE_KODU,
                  })
                }
                value={
                  customer.ulkE_KODU
                    ? countries.find((a) => a.ulkE_KODU === customer.ulkE_KODU)
                    : ""
                }
              />{" "}
              <DropDownList
                id="cografI_BOLGE_KODU"
                name="cografI_BOLGE_KODU"
                label="Coğrafi Bölge"
                data={geographicRegions}
                textField="cografI_BOLGE_ADI"
                dataItemKey="cografI_BOLGE_KODU"
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    cografI_BOLGE_KODU: e.target.value.cografI_BOLGE_KODU,
                  })
                }
                value={
                  customer.cografI_BOLGE_KODU
                    ? geographicRegions.find(
                        (a) =>
                          a.cografI_BOLGE_KODU === customer.cografI_BOLGE_KODU
                      )
                    : ""
                }
              />
              <DropDownList
                id="iL_KODU"
                name="iL_KODU"
                label="Şehir"
                data={cities}
                textField="iL_ADI"
                dataItemKey="iL_KODU"
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    daikiN_IL_KODU: e.target.value.iL_KODU,
                  })
                }
                value={
                  customer.daikiN_IL_KODU
                    ? cities.find(
                        (a) => a.iL_KODU === customer.daikiN_IL_KODU
                      )
                    : ""
                }
              />
              <CommonNumericTextBox
                id="sermayE_ODENMIS"
                name="sermayE_ODENMIS"
                label="Sermaye Ödenmiş"
                value={customer.sermayE_ODENMIS}
                onChange={(e) =>
                  setCustomer({ ...customer, sermayE_ODENMIS: e.target.value })
                }
              />
              <CommonInput
                id="uluS_TIC_NO"
                name="uluS_TIC_NO"
                label="Uluslararası Ticaret No"
                value={customer.uluS_TIC_NO}
                onChange={(e) =>
                  setCustomer({ ...customer, uluS_TIC_NO: e.target.value })
                }
              />
              <DatePicker
                label="Coface Limit Tarihi"
                id="cofacE_LIMIT_TARIHI"
                name="cofacE_LIMIT_TARIHI"
                format="dd.MM.yyyy"
                formatPlaceholder="formatPattern"
                value={
                  customer.cofacE_LIMIT_TARIHI != null || undefined
                    ? customer.cofacE_LIMIT_TARIHI === ""
                      ? null
                      : new Date(customer.cofacE_LIMIT_TARIHI)
                    : null
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    cofacE_LIMIT_TARIHI: getLocalDate(e.target.value, TIMEZONE),
                  })
                }
              />
              <DatePicker
                label="Exim Limit Tarihi"
                id="exiM_LIMIT_TARIHI"
                name="exiM_LIMIT_TARIHI"
                format="dd.MM.yyyy"
                formatPlaceholder="formatPattern"
                value={
                  customer.exiM_LIMIT_TARIHI != null || undefined
                    ? customer.exiM_LIMIT_TARIHI === ""
                      ? null
                      : new Date(customer.exiM_LIMIT_TARIHI)
                    : null
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    exiM_LIMIT_TARIHI: getLocalDate(e.target.value, TIMEZONE),
                  })
                }
              />
              <DatePicker
                label="Kredi Değerlendirme Tarihi"
                id="kredI_DEGERLENDIRME_TARIHI"
                name="kredI_DEGERLENDIRME_TARIHI"
                format="dd.MM.yyyy"
                formatPlaceholder="formatPattern"
                value={
                  customer.kredI_DEGERLENDIRME_TARIHI != null || undefined
                    ? customer.kredI_DEGERLENDIRME_TARIHI === ""
                      ? null
                      : new Date(customer.kredI_DEGERLENDIRME_TARIHI)
                    : null
                }
                onChange={(e) =>
                  setCustomer({
                    ...customer,
                    kredI_DEGERLENDIRME_TARIHI: getLocalDate(
                      e.target.value,
                      TIMEZONE
                    ),
                  })
                }
              />
              <BrandMultiSelect
                id="markA_KODU"
                name="markA_KODU"
                label="Marka"
                value={brands}
                onChange={(e) =>
                  setBrands(e.target.value.map((x) => x.markA_KODU))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <button
                className="btn btn-primary"
                onClick={() => saveCustomer()}
              >
                Kaydet
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {customer.muS_KODU && (
        <UncontrolledAccordion defaultOpen={["1"]} stayOpen>
          <AccordionItem className="mt-3">
            <AccordionHeader targetId="1">
              <h5>Firma Tipi Detayları (Departmana Göre)</h5>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <Row className="mt-3">
                <Col>
                  <CustomerSalesDepartment musKodu={customer.muS_KODU} />
                </Col>
              </Row>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem className="mt-3">
            <AccordionHeader targetId="2">
              <h5>Adresler</h5>
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <Row className="mt-3">
                <Col>
                  <CustomerAddressList
                    musId={customerId}
                    musKodu={customer.muS_KODU}
                  />
                </Col>
              </Row>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      )}
    </main>
  );
}
