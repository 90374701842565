import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import FinoPaymentTypeService from '../../services/srv-fino-paymentType/FinoPaymentTypeService';

const FinoPaymentTypeDropDownList = ({
  id,
  label,
  required,
  onChange
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue,setSelectedValue]=useState("")

  useEffect(() => {
    setDataLoading(true);
    FinoPaymentTypeService.getPaymentTypes(
      {
        filter: { logic: "or", filters: [] },
      },
      defaultDataHandler
    );
  }, []);

  const defaultDataHandler = ({data}) => {
    data.sort((a, b) => (a.odemE_TIPI_ANA_ACIKLAMA > b.odemE_TIPI_ANA_ACIKLAMA ? 1 : -1))
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.odemE_TIPI_ANA_ACIKLAMA
          .toLowerCase()
          .includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>        
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const onChangeTest=(e)=>{
    setSelectedValue(e.value)
    onChange(e)
  }

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="odemE_TIPI_ANA_ACIKLAMA"
      dataItemKey="odemE_TIPI_ANA_KODU"
      style={{ width: "100%" }}
      onChange={onChangeTest}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={selectedValue}

    ></DropDownList>
  );
};

export default FinoPaymentTypeDropDownList;