import axios from "axios";
import { API_GATEWAY, PR_MAHSUPLASMA } from "../../config/globalVariables";

class TermService {
  getOpenedTerm(callback) {
    return axios
      .get(`${API_GATEWAY + PR_MAHSUPLASMA}/donem/get-opened-term`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new TermService();
