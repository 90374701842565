import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
export class GridInlineNumericCell extends React.Component {
  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange({
        dataIndex: 0,
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  render() {
    const { dataItem } = this.props;
    const field = this.props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    const isEditable = dataItem.puto === "HAYIR" || dataItem.puto === "DET";
    return (
      <td>
        {isEditable ? (
          <NumericTextBox value={dataValue} onChange={this.handleChange} />
        ) : (
          <span
            style={{
              display: "none",
            }}
          >
            0
          </span>
        )}
      </td>
    );
  }
}
