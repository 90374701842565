import { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { Button } from "reactstrap";
import DealerListBox from "../sys-common/DealerListBox";
import UserDealerService from "../../services/srv-user/UserDealerService";
import DealerService from "../../services/srv-dealer/DealerService";

export function UserDealer({ modalToggle, user, userName }) {
  const [dealerData, setDealerData] = useState([]);
  const [initialDealerData, setInitialDealerData] = useState([]);
  const [selectedDealerData, setSelectedDealerData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [userDealerDataLoading, setUserDealerDataLoading] = useState(false);
  const [userDealerDataState, setUserDealerDataState] = useState({
    filter: {
      logic: "or",
      filters: [
        {
          field: "kullanicI_ID",
          operator: "eq",
          value: user,
        },
      ],
    },
  });

  useEffect(() => {
    setUserDealerDataLoading(true);
    getDealers();
    getUsersByFilter();
  }, [userDealerDataState]);

  useEffect(() => {
    if (initialDealerData) {
      setDealerData([
        ...initialDealerData?.filter((d) => {
          const f = selectedDealerData.find(
            (x) => x["bayI_KODU"] === d["bayI_KODU"]
          );
          return !f;
        }),
      ]);
      
    }
  }, [initialDealerData]);

  function getUsersByFilter() {
    UserDealerService.getByFilter(
      userDealerDataState,
      onCompletedGetSelectedData
    );
  }
  function getDealers() {
    DealerService.getDealers(
      {
        filter: { logic: "and", filters: null },
      },
      onCompletedGetDealersData
    );
  }

  function onCompletedGetSelectedData(result) {
    if (result) {
      setSelectedDealerData(result.data);
    }
  }

  function onCompletedGetDealersData(result) {
    if (result) {
      setInitialDealerData(result.data);
      setUserDealerDataLoading(false);
    }
  }

  function onError() {
    setUserDealerDataLoading(false);
  }

  function onLoadingCompleted() {
    getUsersByFilter();
    getDealers();
  }

  const sendData = (data) => {
    setSelectedData(data);
  };

  const btnClickHandler = () => {
    setUserDealerDataLoading(true);
    var _newData = { entities: selectedData, KullaniciID: user };
    UserDealerService.addRange(_newData, onLoadingCompleted, onError);
  };

  return (
    <Window
      title={`${userName} Bayiler`}
      onClose={modalToggle}
      initialHeight={"70vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <div>
      {userDealerDataLoading && LoadingPanel}
        <DealerListBox
          unselectedData={dealerData}
          selectedData={selectedDealerData}
          onChange={sendData}
        />
      </div>
      <div>
        <Button
          style={{ marginTop: "10px" }}
          color="primary"
          onClick={btnClickHandler}
        >
          Kaydet
        </Button>
      </div>
    </Window>
  );
}
