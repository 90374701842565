import React, { useState, useEffect } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

import { DAIKIN_USER_TYPES } from "../../config/globalVariables";

const DaikinUserTypeDropDownList = ({ 
  id,
  name,
  label,
  value,
  required,
  onChange
}) => {

  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(DAIKIN_USER_TYPES);
  }, []);

  return (
    <DropDownList
      name={name}
      id={id}
      label={label ? label : undefined}
      data={data}
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      loading={dataLoading}
      value={ value ? value : "" }></DropDownList>
  )
}

export default DaikinUserTypeDropDownList;