import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { PR_DEX_BIREYSEL } from '../../config/globalVariables';
import { rawRequest, ServiceBase } from '../srv-base/ServiceBase';

class DealerProductGroupService extends ServiceBase {
  constructor() {
    super(PR_DEX_BIREYSEL, '/bayiurungrubu');
  }

  getByDealerCode(bayiKodu, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      PR_DEX_BIREYSEL,
      '/bayiurungrubu',
      `/get-departmants-by-bayiKodu/${bayiKodu}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getDealerProductGroupsForExcel(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      PR_DEX_BIREYSEL,
      '/bayiurungrubu',
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new DealerProductGroupService();