import { process } from "@progress/kendo-data-query";

const baseTake = {
  take: 6,
  skip: 0,
};
const basePromise = {
  fetching: false,
  error: {},
};
const baseList = {
  ...basePromise,
  result: process([].slice(0), { ...baseTake }),
  dataState: { ...baseTake },
};

const initialState = {
  auth: {
    ...basePromise,
    showForm: false,
    account: {},
    userName: "",
    mail: "",
    exp: 0,
    id: "",
    isAuthenticated: false,
    lastPasswordChange: false,
    isMfa: false,
    isDaikin: "",
    isSmarty: "",
    bayiKodu: "",
    roles: [],
    menus: [],
  },
  login: {
    _login: {
      email: "",
      password: "",
      mfa: "Authenticator",
      verificationCode: "",
    },
  },
  menuList: {
    ...baseList,
    _menuList: [],
  },
  productGroupList: {
    ...basePromise,
    productGroups: [],
  },
  stockList: {
    ...basePromise,
    stocks: [],
  },
  complaintList: {
    ...baseList,
    complaints: [],
  },
  complaint: {
    ...basePromise,
    _complaint: [],
    dataUpdated: false,
  },
  complaintHistory: {
    ...basePromise,
    historyList: [],
  },
  orderList: {
    ...baseList,
    orders: [],
    dataRemoved: false,
  },
  orderListDealer: {
    ...baseList,
    orders: [],
    dataRemoved: false,
  },
  productPriceList: {
    ...baseList,
    productPrices: [],
  },
  productPrice: {
    ...basePromise,
    dataUpdated: false,
  },
  productPriceLogList: {
    ...baseList,
    productPriceLogs: [],
  },
  order: {
    ...basePromise,
    _order: [],
    dataInserted: false,
  },
  dealerList: {
    ...basePromise,
    dealers: [],
  },
  orderProductList: {
    ...baseList,
    orderProducts: [],
  },
  campaignListAll: {
    ...basePromise,
    campaigns: [],
  },
  campaignProduct: {
    ...basePromise,
    _campaignProduct: [],
  },
  campaignProductList: {
    ...basePromise,
    campaignProducts: [],
  },
  productCategoryList: {
    ...basePromise,
    productCategories: [],
  },
  productBrandList: {
    ...basePromise,
    productBrands: [],
  },
  productModelDetailList: {
    ...basePromise,
    productModelDetails: [],
  },
  productModelList: {
    ...basePromise,
    productModels: [],
  },
  productTypeList: {
    ...basePromise,
    productTypes: [],
  },
  bryBrandList: {
    ...basePromise,
    brands: [],
  },
  bankList: {
    ...basePromise,
    banks: [],
  },
  bankInstallmentList: {
    ...basePromise,
    bankInstallments: [],
  },
  dealerProductGroupList: {
    ...basePromise,
    dealerProductGroups: [],
  },
  productList: {
    ...basePromise,
    products: [],
  },
  smarty360: {
    ...basePromise,
    _smarty360: [],
  },
  orderProduct: {
    ...basePromise,
    dataUpdated: false,
    rejectedData: false,
  },
  salesDepartmantList: {
    ...basePromise,
    salesDepartmants: [],
  },
  customerGroupList: {
    ...basePromise,
    customerGroups: [],
  },
  campaign: {
    ...basePromise,
    _campaign: [],
    dataInserted: false,
  },
  campaignParticipants: {
    ...basePromise,
    dataUpdated: false,
  },
  bryCampaignChannelList: {
    ...basePromise,
    campaignChannels: [],
  },
  bryCampaignDepartmantList: {
    ...basePromise,
    campaignDepartmants: [],
  },
  bryCampaignCustomerGroupList: {
    ...basePromise,
    campaignCustomerGroups: [],
  },
  bryCampaignDealerList: {
    ...basePromise,
    campaignDealers: [],
  },
  bryCampaignDealerOptionList: {
    ...basePromise,
    campaignDealerOptions: [],
  },
  gaolDetailFiles: {
    ...basePromise,
    files: [],
  },
  campaignList: {
    ...baseList,
    campaigns: [],
  },
  bryChannels: {
    ...basePromise,
    channels: [],
  },
  orderProductOptionList: {
    ...basePromise,
    products: [],
  },
  campaignDiscountCalculate: {
    ...basePromise,
    campaignDiscount: [],
  },
  orderApproval: {
    approveLogs: [],
    approval: [],
    isApproval: "",
  },
  cityList: {
    ...baseList,
    cities: [],
  },
  userList: {
    ...baseList,
    users: [],
  },
  userProjectResponsibilityList: {
    ...baseList,
    userProjectResponsibilities: [],
  },
  dealerProposerList: {
    ...baseList,
    dealerProposers: [],
  },
  salesRepresentativeManagerList: {
    ...baseList,
    salesRepresentativeManagers: [],
  },
  salesApprovalList: {
    ...baseList,
    salesApprovals: [],
  },
  productBomList: {
    ...baseList,
    productBoms: [],
  },
  userRightsList: {
    ...baseList,
    userRights: [],
    dataRemoved: false,
  },
  userSalesRightsList: {
    ...baseList,
    userRights: [],
  },
  customerProductList: {
    ...baseList,
    customerProducts: [],
  },
  customerProductGroupList: {
    ...baseList,
    customerProductGroups: [],
  },
  customerGroupProductList: {
    ...baseList,
    customerGroupProducts: [],
  },
  customerGroupProductGroupList: {
    ...baseList,
    customerGroupProductGroups: [],
  },
  filteredProductList: {
    ...basePromise,
    result: process([].slice(0), {
      take: 100,
      skip: 0,
    }),
    dataState: {
      take: 100,
      skip: 0,
    },
    filteredProducts: [],
  },
  salesChannelProductList: {
    ...baseList,
    salesChannelProducts: [],
  },
  salesChannelProductGroupList: {
    ...baseList,
    salesChannelProductGroups: [],
  },
  filteredChannelList: {
    ...baseList,
    channels: [],
  },
  salesChannelList: {
    ...baseList,
    salesChannels: [],
  },
  countryList: {
    ...basePromise,
    countries: [],
  },
  paymentTermList: {
    ...basePromise,
    paymentTerms: [],
  },
  paymentExpiryList: {
    ...basePromise,
    paymentExpiry: [],
  },
  coefficientList: {
    ...basePromise,
    coefficients: [],
  },
  excel: {
    ...baseList,
    excels: [],
  },
  excelSettings: {
    errorVisibilty: false,
    errorProcId: "",
    errorColumns: [],
  },
  orderAddressInfo: {
    ...basePromise,
    _address: [],
  },
  orderResponsiblePersonList: {
    ...baseList,
    responsiblePersons: [],
  },
  userSalesRegionList: {
    ...basePromise,
    userSalesRegions: [],
  },
};

export default initialState;
