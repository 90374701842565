import * as actionTypes from "../../actions/sys-configure/actionTypes";
import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";

export function fetchAllMenus() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_MENUS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/menu/get-all`)
        .then((result) => result.data),
    });
  };
}

