import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

export function fetchProduct(
  productCategoryCode,
  productGroupCode,
  productTypeCode,
  productModelCode,
  productModelDetailCode
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRODUCTS,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX
          }/urun/get-product-by-code/${productCategoryCode}/${productGroupCode}/${productTypeCode}/${productModelCode}/${productModelDetailCode}`
        )
        .then((result) => result.data),
    });
  };
}
export function fetchProductByFilter(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_PRODUCTS_BY_FILTER,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/urun/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data),
    });
  };
}
export function fetchProductList() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PRODUCT_LIST,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/urun/get-all`)
        .then((result) => result.data),
    });
  };
}