export const productBomGridColumns = [
  {
    field: "uruN_KODU_MST",
    title: "MASTER",
    width: "256px"
  },
  {
    field: "uruN_KODU_DET",
    title: "BOM",
    width: "256px"
  },
  {
    field: "adet",
    title: "ADET",
    width: "256px"
  },
];
