import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Upload } from "@progress/kendo-react-upload";
import alertify from "alertifyjs";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import {
  DATACECARBITRAJISLEMYAPILACAKKUR,
  DATACECCARIHESAPISLEM,
  DATACECCARIHESAPISLEMFORDAIKIN,
  DATACECIHTARALANI,
  DATACECODEMEISLEMEARBITRAJISLEMNEDENLERI,
  DATACECRETURNREASON,
  DATACECRETURNTYPE,
  DATACECSERVICECATEGORY,
  DATACECSERVICECATEGORYFORDAIKIN,
  DATACECTALEPNEDENI,
  DATAPARABIRIMI,
} from "../../config/globalVariables";
import CECService from "../../services/srv-selfServis/CECService";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import CommonNumericTextBox from "../sys-common/CommonNumericTextBox";
import CommonInput from "../sys-common/CommonInput";
import CommonTextArea from "../sys-common/CommonTextArea";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const SelfServis = (props) => {
  const defaultData = {
    kategori: "",
    aciklama: "",
    service_Team_Id: "",
    saP_SIPARIS_NO: "",
    projE_ADI: "",
    bankA_ADI: "",
    bayI_KODU: "",
    odemE_TUTARI: null,
    iadE_TIPI: "",
    iadE_NEDENI: "",
    ilgilI_KISI_BILGELERI: "",
    carI_HESAP_ISLEM: "",
    ihtaR_ALANI: "",
  };
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  function onCompletedCreateTicket(data) {
    setIsLoading(false);
    setData(defaultData);
  }

  function createTicket() {
    var bayiKodu = props.auth.bayI_KODU ? props.auth.bayI_KODU : data.bayI_KODU;
    if (!data.kategori) {
      alertify.error("Kategori alanı zorunludur.");
      return;
    }
    if (!data.aciklama) {
      alertify.error("Açıklama alanı zorunludur.");
      return;
    }
    if (data.kategori === "FA-PM" && files.length === 0) {
      alertify.error("Lütfen en az bir dosya ekleyiniz.");
      return;
    }
    if (data.kategori === "FA-PM" && !data.ihtaR_ALANI) {
      alertify.error("İhtar alanı zorunludur.");
      return;
    }
    if (data.kategori === "FA-PM" && !bayiKodu) {
      alertify.error("Müşteri alanı zorunludur.");
      return;
    }
    if (data.kategori === "FA-AS" && !data.carI_HESAP_ISLEM) {
      alertify.error("Cari hesap işlem zorunludur.");
      return;
    }
    var dto = {
      kategori: data.kategori,
      aciklama:
        data.kategori +
        " - " +
        data.aciklama +
        (data.saP_SIPARIS_NO
          ? "\n SAP Sipariş No: " + data.saP_SIPARIS_NO
          : "") +
        (bayiKodu ? "\n Müşteri: " + bayiKodu : "") +
        (data.carI_HESAP_ISLEM
          ? "\n Cari Hesap İşlem Kategorisi: " + data.carI_HESAP_ISLEM
          : "") +
        (data.ihtaR_ALANI ? "\n İht.Alanı: " + data.ihtaR_ALANI : "") +
        (data.projE_ADI ? "\n Proje Adı: " + data.projE_ADI : "") +
        (data.iadE_TIPI ? "\n İade Tipi: " + data.iadE_TIPI : "") +
        (data.iadE_NEDENI ? "\n İade Nedeni: " + data.iadE_NEDENI : "") +
        (data.ilgilI_KISI_BILGELERI
          ? "\n İlgili Kişi/Adres Bilgileri: " + data.ilgilI_KISI_BILGELERI
          : "") +
        (data.taleP_NEDENI ? "\n Talep Nedeni: " + data.taleP_NEDENI : "") +
        (data.virmaN_TUTARI ? "\n Virman Tutarı: " + data.virmaN_TUTARI : "") +
        // (data.arbitraJ_ISLEM_NEDENI
        //   ? "\n Arbitraj İşlem Nedeni: " + data.arbitraJ_ISLEM_NEDENI
        //   : "") +
        // (data.arbitraJ_YAPILACAK_IHTAR_ALANI
        //   ? "\n Arbitraj Yapılacak İhtar Alanı: " +
        //     data.arbitraJ_YAPILACAK_IHTAR_ALANI
        //   : "") +
        (data.arbitraJ_YAPILACAK_KUR
          ? "\n Ar.Kuru: " + data.arbitraJ_YAPILACAK_KUR
          : "") +
        (data.arbitraJ_YAPILACAK_PARA_BIRIMI
          ? "\n Ar.Para Birimi: " + data.arbitraJ_YAPILACAK_PARA_BIRIMI
          : "") +
        (data.arbitraJ_TUTARI ? "\n Ar.Tutarı: " + data.arbitraJ_TUTARI : "") +
        (data.odemE_ARBITRAJ_ISLEM_NEDENI
          ? "\n Ödeme/Ar.İşl.Nedeni: " + data.odemE_ARBITRAJ_ISLEM_NEDENI
          : ""),
      service_Team_Id: data.service_Team_Id,
      files: files.map((file) => file.getRawFile()),
    };

    setIsLoading(true);
    CECService.createTicket(dto, onCompletedCreateTicket);
  }

  const onChangeHandler = (event) => {
    setFiles(event.newState);
  };
  const onRemoveHandler = (event) => {
    setFiles(event.newState);
  };
  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Self Servis</CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading && LoadingPanel}
          <Row>
            <Col xs="4" md="4" lg="4"></Col>
            <Col xs="4" md="4" lg="4">
              <CommonDropDownList
                id="kategori"
                label="Talep Kategorisi"
                data={
                  props.auth.isDaikin === "DAIKIN"
                    ? DATACECSERVICECATEGORYFORDAIKIN
                    : DATACECSERVICECATEGORY
                }
                onChange={(event) => {
                  setData({
                    ...data,
                    kategori: event.value.id,
                    service_Team_Id: event.value.teamId,
                    saP_SIPARIS_NO: "",
                    projE_ADI: "",
                    bayI_KODU: "",
                    carI_HESAP_ISLEM: "",
                    iadE_TIPI: "",
                    iadE_NEDENI: "",
                    ilgilI_KISI_BILGELERI: "",
                    ihtaR_ALANI: "",
                    // arbitraJ_ISLEM_NEDENI: "",
                    arbitraJ_YAPILACAK_KUR: "",
                    arbitraJ_TUTARI: null,
                    arbitraJ_PARA_BIRIMI: "",
                    // arbitraJ_YAPILACAK_IHTAR_ALANI: "",
                    odemE_ARBITRAJ_ISLEM_NEDENI: "",
                    taleP_NEDENI: "",
                    virmaN_TUTARI: null,
                  });
                  setFiles([]);
                }}
                value={data.kategori}
              />
              {data.kategori === "FA-AC" ? (
                <div>
                  <CommonDropDownList
                    id="ihtaR_ALANI"
                    label="İhtar Alanı"
                    data={DATACECIHTARALANI}
                    onChange={(event) => {
                      setData({ ...data, ihtaR_ALANI: event.value.id });
                    }}
                    value={data.ihtaR_ALANI}
                  />
                  {!props.auth.bayiKodu ? (
                    <DealerDropDownList
                      id="bayI_KODU"
                      name="bayI_KODU"
                      label="Müşteri (En az 3 harf yazınız.)"
                      value={data.bayI_KODU}
                      onChange={(e) =>
                        setData({
                          ...data,
                          bayI_KODU: e.target.value.bayI_KODU,
                        })
                      }
                    />
                  ) : undefined}
                  {/* <CommonDropDownList
                    id="arbitraJ_ISLEM_NEDENI"
                    label="Arbitraj İşlem Nedeni"
                    data={DATACECARBITRAJISLEMNEDENI}
                    onChange={(event) => {
                      setData({
                        ...data,
                        arbitraJ_ISLEM_NEDENI: event.value.id,
                      });
                    }}
                    value={data.arbitraJ_ISLEM_NEDENI}
                  />{" "} */}
                  {/* <CommonDropDownList
                    id="arbitraJ_YAPILACAK_IHTAR_ALANI"
                    label="Arbitraj Yapılacak İhtar Alanı"
                    data={DATACECIHTARALANI}
                    onChange={(event) => {
                      setData({
                        ...data,
                        arbitraJ_YAPILACAK_IHTAR_ALANI: event.value.id,
                      });
                    }}
                    value={data.arbitraJ_YAPILACAK_IHTAR_ALANI}
                  /> */}
                  <CommonDropDownList
                    id="arbitraJ_YAPILACAK_KUR"
                    label="Arbitraj Yapılacak Kur"
                    data={DATACECARBITRAJISLEMYAPILACAKKUR}
                    onChange={(event) => {
                      setData({
                        ...data,
                        arbitraJ_YAPILACAK_KUR: event.value.id,
                      });
                    }}
                    value={data.arbitraJ_YAPILACAK_KUR}
                  />
                  <CommonNumericTextBox
                    label="Arbitraj Tutarı"
                    id="arbitraJ_TUTARI"
                    value={data?.arbitraJ_TUTARI}
                    onChange={(e) => {
                      setData({ ...data, arbitraJ_TUTARI: e.target.value });
                    }}
                  ></CommonNumericTextBox>
                  <CommonDropDownList
                    id="arbitraJ_PARA_BIRIMI"
                    label="Arbitraj Yapılacak Para Birimi"
                    data={DATAPARABIRIMI}
                    onChange={(event) => {
                      setData({
                        ...data,
                        arbitraJ_PARA_BIRIMI: event.value.id,
                      });
                    }}
                    value={data.arbitraJ_PARA_BIRIMI}
                  />
                  <CommonDropDownList
                    id="odemE_ARBITRAJ_ISLEM_NEDENI"
                    label="Ödeme İşleme+Arbitraj İşlemleri Nedenleri"
                    data={DATACECODEMEISLEMEARBITRAJISLEMNEDENLERI}
                    onChange={(event) => {
                      setData({
                        ...data,
                        odemE_ARBITRAJ_ISLEM_NEDENI: event.value.id,
                      });
                    }}
                    value={data.odemE_ARBITRAJ_ISLEM_NEDENI}
                  />
                </div>
              ) : undefined}
              {data.kategori === "FA-PM" ? (
                <div>
                  <CommonDropDownList
                    id="ihtaR_ALANI"
                    label="İhtar Alanı"
                    data={DATACECIHTARALANI}
                    onChange={(event) => {
                      setData({ ...data, ihtaR_ALANI: event.value.id });
                    }}
                    value={data.ihtaR_ALANI}
                  />
                  {!props.auth.bayiKodu ? (
                    <DealerDropDownList
                      id="bayI_KODU"
                      name="bayI_KODU"
                      label="Müşteri (En az 3 harf yazınız.)"
                      value={data.bayI_KODU}
                      onChange={(e) =>
                        setData({
                          ...data,
                          bayI_KODU: e.target.value.bayI_KODU,
                        })
                      }
                    />
                  ) : undefined}
                </div>
              ) : undefined}
              {data.kategori === "FA-AS" ? (
                <div>
                  <CommonDropDownList
                    id="carI_HESAP_ISLEM"
                    label="Cari Hesap İşlem Kategorisi"
                    data={
                      props.auth.isDaikin === "DAIKIN"
                        ? DATACECCARIHESAPISLEMFORDAIKIN
                        : DATACECCARIHESAPISLEM
                    }
                    onChange={(event) => {
                      setData({ ...data, carI_HESAP_ISLEM: event.value.id });
                    }}
                    value={data.carI_HESAP_ISLEM}
                  />
                  {data.carI_HESAP_ISLEM === "Sanal Pos Virman" ? (
                    <div>
                      <CommonDropDownList
                        id="ihtaR_ALANI"
                        label="İhtar Alanı"
                        data={DATACECIHTARALANI}
                        onChange={(event) => {
                          setData({ ...data, ihtaR_ALANI: event.value.id });
                        }}
                        value={data.ihtaR_ALANI}
                      />
                      {!props.auth.bayiKodu ? (
                        <DealerDropDownList
                          id="bayI_KODU"
                          name="bayI_KODU"
                          label="Müşteri (En az 3 harf yazınız.)"
                          value={data.bayI_KODU}
                          onChange={(e) =>
                            setData({
                              ...data,
                              bayI_KODU: e.target.value.bayI_KODU,
                            })
                          }
                        />
                      ) : undefined}
                      <CommonDropDownList
                        id="taleP_NEDENI"
                        label="Talep Nedeni"
                        data={DATACECTALEPNEDENI}
                        onChange={(event) => {
                          setData({ ...data, taleP_NEDENI: event.value.id });
                        }}
                        value={data.taleP_NEDENI}
                      />
                      <CommonNumericTextBox
                        label="Virman Tutarı"
                        id="virmaN_TUTARI"
                        value={data?.virmaN_TUTARI}
                        onChange={(e) => {
                          setData({ ...data, virmaN_TUTARI: e.target.value });
                        }}
                      ></CommonNumericTextBox>
                    </div>
                  ) : undefined}
                  {data.carI_HESAP_ISLEM === "Cariler Arası Virman" ? (
                    <div>
                      <CommonDropDownList
                        id="ihtaR_ALANI"
                        label="İhtar Alanı"
                        data={DATACECIHTARALANI}
                        onChange={(event) => {
                          setData({ ...data, ihtaR_ALANI: event.value.id });
                        }}
                        value={data.ihtaR_ALANI}
                      />
                      {!props.auth.bayiKodu ? (
                        <DealerDropDownList
                          id="bayI_KODU"
                          name="bayI_KODU"
                          label="Müşteri (En az 3 harf yazınız.)"
                          value={data.bayI_KODU}
                          onChange={(e) =>
                            setData({
                              ...data,
                              bayI_KODU: e.target.value.bayI_KODU,
                            })
                          }
                        />
                      ) : undefined}
                      <CommonDropDownList
                        id="taleP_NEDENI"
                        label="Talep Nedeni"
                        data={DATACECTALEPNEDENI}
                        onChange={(event) => {
                          setData({ ...data, taleP_NEDENI: event.value.id });
                        }}
                        value={data.taleP_NEDENI}
                      />
                      <CommonNumericTextBox
                        label="Virman Tutarı"
                        id="virmaN_TUTARI"
                        value={data?.virmaN_TUTARI}
                        onChange={(e) => {
                          setData({ ...data, virmaN_TUTARI: e.target.value });
                        }}
                      ></CommonNumericTextBox>
                    </div>
                  ) : undefined}
                  {data.carI_HESAP_ISLEM === "Arbitraj" ? (
                    <div>
                      <CommonDropDownList
                        id="ihtaR_ALANI"
                        label="İhtar Alanı"
                        data={DATACECIHTARALANI}
                        onChange={(event) => {
                          setData({ ...data, ihtaR_ALANI: event.value.id });
                        }}
                        value={data.ihtaR_ALANI}
                      />
                      {!props.auth.bayiKodu ? (
                        <DealerDropDownList
                          id="bayI_KODU"
                          name="bayI_KODU"
                          label="Müşteri (En az 3 harf yazınız.)"
                          value={data.bayI_KODU}
                          onChange={(e) =>
                            setData({
                              ...data,
                              bayI_KODU: e.target.value.bayI_KODU,
                            })
                          }
                        />
                      ) : undefined}
                      {/* <CommonDropDownList
                        id="arbitraJ_ISLEM_NEDENI"
                        label="Arbitraj İşlem Nedeni"
                        data={DATACECARBITRAJISLEMNEDENI}
                        onChange={(event) => {
                          setData({
                            ...data,
                            arbitraJ_ISLEM_NEDENI: event.value.id,
                          });
                        }}
                        value={data.arbitraJ_ISLEM_NEDENI}
                      /> */}
                      {/* <CommonDropDownList
                        id="arbitraJ_YAPILACAK_IHTAR_ALANI"
                        label="Arbitraj Yapılacak İhtar Alanı"
                        data={DATACECIHTARALANI}
                        onChange={(event) => {
                          setData({
                            ...data,
                            arbitraJ_YAPILACAK_IHTAR_ALANI: event.value.id,
                          });
                        }}
                        value={data.arbitraJ_YAPILACAK_IHTAR_ALANI}
                      /> */}
                      <CommonDropDownList
                        id="arbitraJ_YAPILACAK_KUR"
                        label="Arbitraj Yapılacak Kur"
                        data={DATACECARBITRAJISLEMYAPILACAKKUR}
                        onChange={(event) => {
                          setData({
                            ...data,
                            arbitraJ_YAPILACAK_KUR: event.value.id,
                          });
                        }}
                        value={data.arbitraJ_YAPILACAK_KUR}
                      />
                      <CommonNumericTextBox
                        label="Arbitraj Tutarı"
                        id="arbitraJ_TUTARI"
                        value={data?.arbitraJ_TUTARI}
                        onChange={(e) => {
                          setData({ ...data, arbitraJ_TUTARI: e.target.value });
                        }}
                      ></CommonNumericTextBox>
                      <CommonDropDownList
                        id="arbitraJ_PARA_BIRIMI"
                        label="Arbitraj Yapılacak Para Birimi"
                        data={DATAPARABIRIMI}
                        onChange={(event) => {
                          setData({
                            ...data,
                            arbitraJ_PARA_BIRIMI: event.value.id,
                          });
                        }}
                        value={data.arbitraJ_PARA_BIRIMI}
                      />
                    </div>
                  ) : undefined}
                </div>
              ) : undefined}
              <CommonTextArea
                label="Açıklama"
                id="aciklama"
                rows={5}
                onChange={(event) => {
                  setData({ ...data, aciklama: event.value });
                }}
                value={data.aciklama}
              ></CommonTextArea>
              {data.kategori === "SO-AO" ||
              data.kategori === "FA-PM" ||
              data.kategori === "SO-CO" ||
              data.kategori === "SO-DI" ||
              data.kategori === "SO-RT" ? (
                <CommonInput
                  id="saP_SIPARIS_NO"
                  label="SAP Sipariş No"
                  onChange={(e) => {
                    setData({ ...data, saP_SIPARIS_NO: e.value });
                  }}
                  value={data.saP_SIPARIS_NO}
                ></CommonInput>
              ) : undefined}
              {data.kategori === "SO-AO" ||
              data.kategori === "FA-PM" ||
              data.kategori === "SO-CO" ||
              data.kategori === "SO-DI" ||
              data.kategori === "SO-RT" ? (
                <CommonInput
                  id="projE_ADI"
                  label="Proje Adı"
                  onChange={(e) => {
                    setData({ ...data, projE_ADI: e.value });
                  }}
                  value={data.projE_ADI}
                ></CommonInput>
              ) : undefined}
              {data.kategori === "SO-RT" ? (
                <div>
                  <CommonDropDownList
                    id="iadE_TIPI"
                    label="İade Tipi"
                    data={DATACECRETURNTYPE}
                    onChange={(event) => {
                      setData({ ...data, iadE_TIPI: event.value.id });
                    }}
                    value={data.iadE_TIPI}
                  />
                  <CommonDropDownList
                    id="iadE_NEDENI"
                    label="İade Nedeni"
                    data={DATACECRETURNREASON}
                    onChange={(event) => {
                      setData({ ...data, iadE_NEDENI: event.value.id });
                    }}
                    value={data.iadE_NEDENI}
                  />
                  <CommonTextArea
                    label="İlgili Kişi/Adres Bilgileri"
                    id="ilgilI_KISI_BILGELERI"
                    rows={5}
                    onChange={(event) => {
                      setData({ ...data, ilgilI_KISI_BILGELERI: event.value });
                    }}
                    value={data.ilgilI_KISI_BILGELERI}
                  ></CommonTextArea>
                </div>
              ) : undefined}
              <div style={{ marginTop: "20px" }}>
                {data.kategori === "FA-PM" ? <h4>Dekont/Swift</h4> : undefined}
                <Upload
                  autoUpload={false}
                  showActionButtons={false}
                  files={files}
                  onAdd={onChangeHandler}
                  onRemove={onRemoveHandler}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="4" md="4" lg="4"></Col>
            <Col xs="4" md="4" lg="4" style={{ textAlign: "center" }}>
              <Button color="success" size="md" onClick={createTicket}>
                GÖNDER
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(SelfServis);
