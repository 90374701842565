import axios from "axios";

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

import { API_GATEWAY, PR_DEX_MERCURY } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchSalesRepresentativeManager(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_REPRESENTATIVE_MANAGER,
      payload: fetchSalesRepresentativeManagerPayload(dataState, callback),
    });
  };
}
export function changeSalesRepresentativeManager(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_SALES_REPRESENTATIVE_MANAGER,
      payload: event,
    });
  };
}

export function fetchSalesRepresentativeManagerPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_MERCURY
      }/satistemsilcisiyonetici/get-all?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

export function insertSalesRepresentativeManager(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.INSERT_SALES_REPRESENTATIVE_MANAGER,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_MERCURY
          }/satistemsilcisiyonetici/add-sales-representative-manager`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesRepresentativeManager(dataState, callback));
          return result.data;
        }),
    });
  };
}

export function updateSalesRepresentativeManager(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SALES_REPRESENTATIVE_MANAGER,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_MERCURY
          }/satistemsilcisiyonetici/update-sales-representative-manager`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesRepresentativeManager(dataState, callback));
          return result.data.message;
        }),
    });
  };
}

export function deleteSalesRepresentativeManager(id, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_SALES_REPRESENTATIVE_MANAGER,
      payload: axios
        .delete(
          `${
            API_GATEWAY + PR_DEX_MERCURY
          }/satistemsilcisiyonetici/delete-sales-representative-manager?id=${id}`
        )
        .then((result) => {
          dispatch(fetchSalesRepresentativeManager(dataState, callback));
          return result.data.message;
        }),
    });
  };
}
