import { MS_USERSDEX } from '../../config/globalVariables';
import { ServiceBase, rawRequest } from '../srv-base/ServiceBase';

class UserRoleService extends ServiceBase {
  constructor() {
    super(MS_USERSDEX, '/kullanicirole');
  }
  getSatisTemsilcileri(onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      MS_USERSDEX,
      '/kullanicirole',
      '/get-satis-temsilcileri',
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new UserRoleService();