export const dealerProductGroupGridColumns = [
  {
    field: "bayI_KODU",
    title: "BAYİ",
    width: "256px"
  },
  {
    field: "satiS_DEPARTMANI_KODU",
    title: "SATIŞ DEPARTMANI",
    width: "256px"
  },
  {
    field: "brY_KANAL",
    title: "BRY KANAL",
    width: "256px"
  },
  {
    field: "brY_URUN_KATEGORI",
    title: "BRY ÜRÜN KATEGORİ",
    width: "256px"
  },
  {
    field: "brY_URUN_GRUBU",
    title: "BRY ÜRÜN GRUBU",
    width: "256px"
  },
  {
    field: "satiS_TEMSILCISI_KULLANICI_ADI",
    title: "SATIŞ TEMSİLCİSİ",
    width: "256px"
  },
  {
    field: "bolgE_MUDURU_KULLANICI_ADI",
    title: "BÖLGE MÜDÜRÜ",
    width: "256px"
  },
  {
    field: "gecerli",
    title: "GEÇERLİ",
    width: "256px"
  }
];