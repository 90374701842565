import { PR_DEX_MERCURY } from "../../../config/globalVariables";
import { rawRequest } from "../../srv-base/ServiceBase";

class OfferFilesService {
  getByTeklifId(tableId, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/tekliffiles",
      `/get-by-teklif-id/${tableId}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new OfferFilesService();
