import React, { useRef, useState } from "react";
import alertify from "alertifyjs";
import { Col, Row } from "reactstrap";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Upload } from "@progress/kendo-react-upload";
import ExcelService from "../../services/srv-excel/ExcelService";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import ExcelTypeDropDownList from "../sys-common/ExcelTypeDropDownList";
import { FILE_STATUSES, IG_SENDER } from "../../config/globalVariables";
import GeneralExcelTemplate from "./GeneralExcelTemplate";

export default function GeneralExcelUpload(props) {
  const primaryKey = "dkN_GENEL_EXCEL_UPLOAD_DATA_ID";

  const _grid = useRef(null);
  const _export = useRef(null);
  const [selectedSablon, setSelectedSablon] = useState();
  const [selectedBayi, setSelectedBayi] = useState();
  const [importedValuesLoading, setImportedValuesLoading] = useState(false);
  const [importedValues, setImportedValues] = useState([]);
  const [procId, setProcId] = useState(null);
  const [importedValuesDataState, setImportedValuesDataState] = useState({
    skip: 0,
    take: 10,
  });

  function onCompletedExcelErrors(data) {
    setImportedValues(data);
  }

  function onSuccess(data) {
    setImportedValuesLoading(false);
    setImportedValues(data);
  }

  function onError() {
    setImportedValuesLoading(false);
  }

  function onBeforeUpload(event) {
    event.additionalData = {
      p_bayi_kodu: selectedBayi,
      localpath: "DEX",
      excelTanimKodu: selectedSablon,
    };
  }

  function onStatusChange(event) {
    var respFile = event.affectedFiles[0];
    var status = FILE_STATUSES[respFile.status];
    var procId = null;

    if (status === "UploadFailed") {
      if (
        event.response &&
        event.response.response &&
        event.response.response.status === 400
      ) {
        procId = event.response.response.data.data.p_proc_id;
      } else {
        alertify.error("Sunucuya erişirken sorun yaşandı.");
      }
    }
    if (status === "Uploaded") {
      alertify.success("İşlem başarılı");
      procId = event.response.response.data.p_proc_id;
    }

    setProcId(procId);
    if (procId) {
      var newDataState = {
        ...importedValuesDataState,
        filter: {
          logic: "or",
          filters: [
            {
              field: "DKN_GENEL_EXCEL_UPLOAD_PROC_ID",
              operator: "eq",
              value: procId,
            },
          ],
        },
      };
      setImportedValuesDataState(newDataState);
      getExcelErrorsByFilter(newDataState);
    }
  }

  function getExcelErrorsByFilter(dataState) {
    ExcelService.getExcelErrorsByProcId(dataState, onCompletedExcelErrors);
  }

  function dataStateChange(event) {
    setImportedValuesDataState(event.dataState);
    getExcelErrorsByFilter(event.dataState);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }

  const excelExport = () => {
    debugger;
    if (_export.current !== null) {
      _export.current.save(importedValues, _grid.current.columns);
    }
  };
  return (
    <main style={{ flexGrow: "1" }}>
      <h2>Excel Dosya Yükleme</h2>
      <hr />
      <Row className="mb-3">
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Parametreler</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="3">
                  <b>Şablon Dosya Tipini Seçiniz</b>
                </Col>
                <Col>
                  <ExcelTypeDropDownList
                    id="excelType"
                    name="excelType"
                    value={selectedSablon}
                    onChange={(e) => {
                      setSelectedSablon(e.value.dkN_GENEL_EXCEL_TANIM_KODU);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="3">
                  <b>Bayi</b>
                </Col>
                <Col>
                  <DealerDropDownList
                    id="bayi"
                    name="bayi"
                    value={selectedBayi}
                    onChange={(e) => {
                      setSelectedBayi(e.value.bayI_KODU);
                    }}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="3">
                  <b>Yüklenecek Dosya</b>
                </Col>
                <Col>
                  <Upload
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    restrictions={{
                      maxFileSize: 4000000,
                    }}
                    saveHeaders={{
                      Authorization:
                        "Bearer " + localStorage.getItem("jwtToken"),
                    }}
                    // saveUrl={`${API_GATEWAY + MS_SENDER}/excel/v2/add`}
                    saveUrl={IG_SENDER + `/api/excel/v2/add`}
                    onBeforeUpload={onBeforeUpload}
                    onStatusChange={onStatusChange}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card>
            <CardHeader>
              <CardTitle>Örnek Şablon</CardTitle>
              <CardSubtitle>
                * Excel başlıkları olmalıdır ve dosya uzantısı .xlsx olmalıdır.
              </CardSubtitle>
            </CardHeader>
            <CardBody>
              <GeneralExcelTemplate sablon={selectedSablon} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div>
        {procId ? <h4>Proc Id : {procId} </h4> : ""}

        <ExcelExport ref={_export} />
        <Grid
          ref={_grid}
          data={importedValues}
          {...importedValuesDataState}
          dataItemKey={primaryKey}
          style={{ minHeight: "400px" }}
          pageable={{ buttonCount: 3, pageSizes: [10, 20, 50, 100] }}
          resizable={true}
          sortable={true}
          filterable={false}
          onDataStateChange={dataStateChange}
          rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
        >
          <GridToolbar>
            <button
              title="Export Excel"
              className="btn btn-success btn-md"
              onClick={excelExport}
            >
              <i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel
            </button>
          </GridToolbar>
          <GridColumn field="sonuc" title="Sonuç" width="100px"></GridColumn>
          <GridColumn
            field="proC_DATE"
            title="Aktarma Tarihi"
            width="150px"
          ></GridColumn>
          <GridColumn field="alaN1" title="ALAN 1" width="100px"></GridColumn>
          <GridColumn field="alaN2" title="ALAN 2" width="100px"></GridColumn>
          <GridColumn field="alaN3" title="ALAN 3" width="100px"></GridColumn>
          <GridColumn field="alaN4" title="ALAN 4" width="100px"></GridColumn>
          <GridColumn field="alaN5" title="ALAN 5" width="100px"></GridColumn>
          <GridColumn field="alaN6" title="ALAN 6" width="100px"></GridColumn>
          <GridColumn field="alaN7" title="ALAN 7" width="100px"></GridColumn>
          <GridColumn field="alaN8" title="ALAN 8" width="100px"></GridColumn>
          <GridColumn field="alaN9" title="ALAN 9" width="100px"></GridColumn>
          <GridColumn field="alaN10" title="ALAN 10" width="100px"></GridColumn>
          <GridColumn field="alaN11" title="ALAN 11" width="100px"></GridColumn>
          <GridColumn field="alaN12" title="ALAN 12" width="100px"></GridColumn>
          <GridColumn field="alaN13" title="ALAN 13" width="100px"></GridColumn>
          <GridColumn field="alaN14" title="ALAN 14" width="100px"></GridColumn>
          <GridColumn field="alaN15" title="ALAN 15" width="100px"></GridColumn>
          <GridColumn field="alaN16" title="ALAN 16" width="100px"></GridColumn>
          <GridColumn field="alaN17" title="ALAN 17" width="100px"></GridColumn>
          <GridColumn field="alaN18" title="ALAN 18" width="100px"></GridColumn>
          <GridColumn field="alaN19" title="ALAN 19" width="100px"></GridColumn>
          <GridColumn field="alaN20" title="ALAN 20" width="100px"></GridColumn>
          <GridColumn field="alaN21" title="ALAN 21" width="100px"></GridColumn>
          <GridColumn field="alaN22" title="ALAN 22" width="100px"></GridColumn>
          <GridColumn field="alaN23" title="ALAN 23" width="100px"></GridColumn>
          <GridColumn field="alaN24" title="ALAN 24" width="100px"></GridColumn>
          <GridColumn field="alaN25" title="ALAN 25" width="100px"></GridColumn>
          <GridColumn field="alaN26" title="ALAN 26" width="100px"></GridColumn>
          <GridColumn field="alaN27" title="ALAN 27" width="100px"></GridColumn>
          <GridColumn field="alaN28" title="ALAN 28" width="100px"></GridColumn>
          <GridColumn field="alaN29" title="ALAN 29" width="100px"></GridColumn>
          <GridColumn field="alaN30" title="ALAN 30" width="100px"></GridColumn>
          <GridColumn field="alaN31" title="ALAN 31" width="100px"></GridColumn>
          <GridColumn field="alaN32" title="ALAN 32" width="100px"></GridColumn>
          <GridColumn field="alaN33" title="ALAN 33" width="100px"></GridColumn>
          <GridColumn field="alaN34" title="ALAN 34" width="100px"></GridColumn>
          <GridColumn field="alaN35" title="ALAN 35" width="100px"></GridColumn>
          <GridColumn field="alaN36" title="ALAN 36" width="100px"></GridColumn>
          <GridColumn field="alaN37" title="ALAN 37" width="100px"></GridColumn>
          <GridColumn field="alaN38" title="ALAN 38" width="100px"></GridColumn>
          <GridColumn field="alaN39" title="ALAN 39" width="100px"></GridColumn>
          <GridColumn field="alaN40" title="ALAN 40" width="100px"></GridColumn>
          <GridColumn field="alaN41" title="ALAN 41" width="100px"></GridColumn>
          <GridColumn field="alaN42" title="ALAN 42" width="100px"></GridColumn>
          <GridColumn field="alaN43" title="ALAN 43" width="100px"></GridColumn>
          <GridColumn field="alaN44" title="ALAN 44" width="100px"></GridColumn>
          <GridColumn field="alaN45" title="ALAN 45" width="100px"></GridColumn>
          <GridColumn field="alaN46" title="ALAN 46" width="100px"></GridColumn>
          <GridColumn field="alaN47" title="ALAN 47" width="100px"></GridColumn>
          <GridColumn field="alaN48" title="ALAN 48" width="100px"></GridColumn>
          <GridColumn field="alaN49" title="ALAN 49" width="100px"></GridColumn>
          <GridColumn field="alaN50" title="ALAN 50" width="100px"></GridColumn>
        </Grid>
      </div>
    </main>
  );
}
