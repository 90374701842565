import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";
import CommonTextArea from "../../sys-common/CommonTextArea";
import { useTranslation } from "react-i18next";
import OfferDenvService from "../../../services/mercury/srv-offer-denv/OfferDenvService";
import { useState } from "react";
import CommonInput from "../../sys-common/CommonInput";

const OfferDenvApproveReject = (props) => {
  const { t, i18n } = useTranslation();
  const [description, setDescription] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");

  const approveReject = () => {
    var data = {
      MRC_TEKLIF_DENV_ONAY_ID: props.offer.mrC_TEKLIF_DENV_ONAY_ID,
      MRC_TEKLIF_ID: props.offer.mrC_TEKLIF_ID,
      ISLEM: props.action === "approve" ? "ONAY" : "RED",
    };

    if (props.action === "approve") {
      data["DENV_REFERANS_NO"] = referenceNumber;
    } else {
      data["ACIKLAMA"] = description;
    }
    OfferDenvService.approveReject(data, callbackAfterApproveReject);
  };

  const callbackAfterApproveReject = (result) => {
    props.toggleDialog();
    props.refreshFunc();
  };

  return (
    <Window
      title={t(props.action)}
      onClose={props.toggleDialog}
      initialHeight={"30vh"}
      initialWidth={"30%"}
      style={{
        left: "50%",
        top: "30%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {props.action === "reject" ? (
        <CommonTextArea
          label={t("description")}
          id="aciklama"
          onChange={(e) => setDescription(e.value)}
          value={description}
        ></CommonTextArea>
      ) : (
        <CommonInput
          id="denV_REFERANS_NO"
          label={t("reference_number")}
          onChange={(e) => setReferenceNumber(e.target.value)}
          value={referenceNumber}
        ></CommonInput>
      )}

      <WindowActionsBar>
        <Button
          color="warning"
          size="md"
          style={{ width: "100%" }}
          onClick={props.toggleDialog}
        >
          <i className="k-icon k-i-times-circle"></i>
          {" " + t("cancel")}
        </Button>
        <Button
          color={"primary"}
          size="md"
          onClick={approveReject}
          style={{ width: "100%", marginLeft: "5px" }}
        >
          <i className="k-icon k-i-check-circle"></i>
          {" " + t("save")}
        </Button>
      </WindowActionsBar>
    </Window>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OfferDenvApproveReject);
