import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function coefficientListReducer(
  state = initialState.coefficientList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_COEFFICIENTS:
      return {
        ...state,
        coefficients: action.payload,
      };
    case actionTypes.GET_COEFFICIENTS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_COEFFICIENTS_FULFILLED:
      return {
        ...state,
        coefficients: action.payload,
        fetching: false,
      };
    case actionTypes.GET_COEFFICIENTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
