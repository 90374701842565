import React, { useState, useEffect } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";

import BryProductCategoryService from "../../services/srv-product/BryProductCategoryService";

const BryProductCategoryDropDownList = ({
  id,
  label,
  value,
  required,
  onChange,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    getDataByFilter();
    console.log("value",value)
  }, []);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setInitialData(data.data);
    setDataLoading(false);
  };

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    BryProductCategoryService.getProductCategories(
      {
        filter: { logic: "or", filters: filters },
      },
      onCompletedGetData
    );
  };

  const onFilterData = (event) => {
    if (event.filter.value.length > 1) {
      setTimeout(() => {
        setData({
          ...initialData,
          data: [
            ...initialData.data.filter((x) =>
              x.brY_URUN_KATEGORI
                .toLocaleLowerCase("tr")
                .includes(event.filter.value.toLocaleLowerCase("tr"))
            ),
          ],
        });
      }, 500);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.brY_URUN_KATEGORI_ACIKLAMA})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="brY_URUN_KATEGORI_ACIKLAMA"
      dataItemKey="brY_URUN_KATEGORI"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterData(event)}
      loading={dataLoading}
      value={value ? data.find((v) => v.brY_URUN_KATEGORI === value) : ""}
    ></DropDownList>
  );
};

export default BryProductCategoryDropDownList;
