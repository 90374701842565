import React, { useEffect, useState } from "react";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import FreeStockPutoLogService from "../../services/srv-freeStock/FreeStockPutoLogService";
import { Window } from "@progress/kendo-react-dialogs";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";

const FreeStockPutoLogDetail = (props) => {
  var {
    tekliF_SIPARIS,
    tekliF_SIPARIS_ID,
    tekliF_SIPARIS_URUN_ID,
    mmD_STOK_DETAY,
    plant,
    satiS_DEPARTMANI_KODU,
  } = props.data;

  var logData = [];
  var usingStock = "";

  const [addingColumns, setAddingColumns] = useState([]);
  const [logSonucData, setLogSonucData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (tekliF_SIPARIS) {
      getData();
    }
  }, [tekliF_SIPARIS]);

  const getData = () => {
    setIsLoading(true);
    FreeStockPutoLogService.getPutoLogByTeklifSiparisUrunId(
      tekliF_SIPARIS,
      tekliF_SIPARIS_ID,
      tekliF_SIPARIS_URUN_ID,
      onCallBackGetData
    );

    if (
      satiS_DEPARTMANI_KODU?.includes("EXPORT") ||
      mmD_STOK_DETAY === "EVET"
    ) {
      usingStock = plant === "5840" ? "MMD" : "MMDH";
    }
  };
  const onCallBackGetData = ({ data }) => {
    logData = data;
    FreeStockPutoLogService.getPutoLogSonucByTeklifSiparisUrunId(
      tekliF_SIPARIS,
      tekliF_SIPARIS_ID,
      tekliF_SIPARIS_URUN_ID,
      onCallBackGetSonucData
    );
  };
  const onCallBackGetSonucData = ({ data }) => {
    setIsLoading(false);

    var columns = [];
    var sonucData = data.map((a) => {
      var logItems = logData.filter(
        (x) =>
          new Date(x.conF_DATE).toLocaleDateString() ===
          new Date(a.conF_DATE).toLocaleDateString()
      );

      var sonucItem = {
        conF_DATE: a.conF_DATE,
        cuM_FREE_STOCK: a.cuM_FREE_STOCK,
        freE_STOCK: a.freE_STOCK,
        cuM_MMD_FREE_STOCK: a.cuM_MMD_FREE_STOCK,
        mmD_FREE_STOCK: a.mmD_FREE_STOCK,
        cuM_MMDH_FREE_STOCK: a.cuM_MMDH_FREE_STOCK,
        adet: a.adet,
        mmD_ADET: a.mmD_ADET,
        mmdH_ADET: a.mmdH_ADET,
      };

      logItems.map((s) => {
        var cumAdetText = "Cum " + s.uruN_KODU + " Adet";
        var cumMMDAdetText = "Cum MMD " + s.uruN_KODU + " Adet";
        var cumMMDHAdetText = "Cum MMDH " + s.uruN_KODU + " Adet";
        if (usingStock === "MMD") {
          if (!columns.includes(cumMMDAdetText)) {
            columns.push(cumMMDAdetText);
          }
        } else if (usingStock === "MMDH") {
          if (!columns.includes(cumMMDHAdetText)) {
            columns.push(cumMMDHAdetText);
          }
        } else {
          if (!columns.includes(cumAdetText)) {
            columns.push(cumAdetText);
          }
        }

        sonucItem[cumAdetText] = s.cuM_FREE_STOCK;
        sonucItem[cumMMDAdetText] = s.cuM_MMD_FREE_STOCK;
        sonucItem[cumMMDHAdetText] = s.cuM_MMDH_FREE_STOCK;
      });

      return sonucItem;
    });

    setAddingColumns(columns);
    setLogSonucData(sonucData);
  };
  return (
    <Window
      title={"Puto Log Detay"}
      onClose={props.modalToggle}
      initialHeight={"85vh"}
      initialWidth={"70%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {isLoading && LoadingPanel}
      <div>
        <Grid
          data={logSonucData}
          sortable={false}
          style={{ marginTop: "15px" }}
          resizable
        >
          <GridColumn
            field="conF_DATE"
            title="Ürün Stok Tarihi"
            cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
            // width={100}
          ></GridColumn>
          {addingColumns.map((column) => {
            return (
              <GridColumn
                key={column}
                field={column}
                title={column}
                // width={180}
              />
            );
          })}
          {usingStock === "MMD" ? (
            <GridColumn
              field="cuM_MMD_FREE_STOCK"
              title="Cum. MMD FS"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "MMD" ? (
            <GridColumn
              field="mmD_FREE_STOCK"
              title="MMD FS"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "MMDH" ? (
            <GridColumn
              field="cuM_MMDH_FREE_STOCK"
              title="Cum. MMDH FS"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "MMDH" ? (
            <GridColumn
              field="mmdH_FREE_STOCK"
              title="MMDH FS"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "" ? (
            <GridColumn
              field="cuM_FREE_STOCK"
              title="Cum. FS"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "" ? (
            <GridColumn
              field="freE_STOCK"
              title="FS"
              // width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "MMD" ? (
            <GridColumn
              field="mmD_ADET"
              title="MMD Adet"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "MMDH" ? (
            <GridColumn
              field="mmdH_ADET"
              title="MMDH Adet"
              //   width={100}
            ></GridColumn>
          ) : undefined}
          {usingStock === "" ? (
            <GridColumn
              field="adet"
              title="Adet"
              // width={100}
            ></GridColumn>
          ) : undefined}
        </Grid>
      </div>
    </Window>
  );
};

export default FreeStockPutoLogDetail;
