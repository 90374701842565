import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import FinoSubPaymentTypeService from '../../services/srv-fino-subPaymentType/FinoSubPaymentTypeService';

const FinoSubPaymentTypeDropDownList = ({
  id,
  label,
  required,
  onChange,
  filterField,
  filterValue
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue,setSelectedValue]=useState("")

  useEffect(() => {
    setDataLoading(true);
    if(filterValue){
    var filter = [];
    filter.push({
      field: filterField,
      operator: "eq",
      value: filterValue.toLowerCase(),
    });
    getDataByFilter(filter);
  }
  else if(!filterValue)
  {
    setDataLoading(false);
  }
  }, [filterValue]);

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    FinoSubPaymentTypeService.getSubPaymentTypes(
      {
        filter: { logic: "and", filters: filters },
      },
      defaultDataHandler
    );
  };

  const defaultDataHandler = ({data}) => {
    data.sort((a, b) => (a.odemE_TIPI_ALT_ACIKLAMA > b.odemE_TIPI_ALT_ACIKLAMA ? 1 : -1))
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.odemE_TIPI_ALT_ACIKLAMA
          .toLowerCase()
          .includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>        
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const onChangeTest=(e)=>{
    setSelectedValue(e.value)
    onChange(e)
  }

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="odemE_TIPI_ALT_ACIKLAMA"
      dataItemKey="odemE_TIPI_ALT_KODU"
      style={{ width: "100%" }}
      onChange={onChangeTest}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={selectedValue}

    ></DropDownList>
  );
};
export default FinoSubPaymentTypeDropDownList;
