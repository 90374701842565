import React, { useState, useEffect, useRef, createRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Window } from "@progress/kendo-react-dialogs";
import { connect } from "react-redux";
import { Alert, Button, Col, Row } from "reactstrap";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import GridFooterSumCell from "../sys-toolbox/GridFooterSumCell";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import CommonNumericTextBox from "../sys-common/CommonNumericTextBox";
import OffsetService from "../../services/srv-fin-offset/OffsetService";
import CoefficientService from "../../services/srv-fin-coefficient/CoefficientService";
import { ExcelExport } from "@progress/kendo-react-excel-export";

const OffsetSummary = (props) => {
  const dbsExporter = useRef();
  const sanalPosExporter = useRef();
  const dbsGridRef = useRef();
  const sanalPosGridRef = useRef();
  const toplamTutarDBS = props.dbsData.reduce(
    (acc, current) => acc + current["tutar"],
    0
  );

  const toplamTutarSanalPos = props.sanalPosData.reduce(
    (acc, current) => acc + current["tutar"],
    0
  );

  const ortalamaVadeDBS =
    props.dbsData
      .map((a) => new Date(a.vadE_TARIHI).getTime() * a.tutar)
      .reduce((prev, curr) => prev + curr, 0) / toplamTutarDBS;

  const ortalamaVadeSanalPos =
    props.sanalPosData
      .map((a) => new Date(a.vadE_TARIHI).getTime() * a.tutar)
      .reduce((prev, curr) => prev + curr, 0) / toplamTutarSanalPos;

  const valorGun =
    (ortalamaVadeSanalPos - ortalamaVadeDBS) / (24 * 60 * 60 * 1000);
  const [interestRate, setInterestRate] = useState();
  const [kdvRate, setKDVRate] = useState();
  useEffect(() => {
    CoefficientService.getByCode(
      "VADE_FARKI_ORANI",
      onCompletedGetInterestRate
    );
    CoefficientService.getByCode("KDV_ORANI", onCompletedGetKDV);
  }, []);

  const onCompletedGetInterestRate = (data) => {
    setInterestRate(data);
  };

  const onCompletedGetKDV = (data) => {
    setKDVRate(data);
  };

  const vadeFarkiTutari =
    (valorGun * toplamTutarDBS * interestRate?.katsayI_DEGER) / 100 / 30;

  console.log("Valor Gün => ", valorGun);
  const GridFooterAverageDatetimeCell = (props, format, datatype) => {
    const value =
      datatype === "SANAL_POS" ? ortalamaVadeSanalPos : ortalamaVadeDBS;
    return (
      <td colSpan={props.colSpan} style={props.style}>
        <b>
          Ort. Vade : <br></br>
          {formatDate(new Date(value), format)}
        </b>
      </td>
    );
  };

  function exportExcel(_exporter, gridRef) {
    if (_exporter !== null) {
      confirmAlert({
        title: "Uyarı",
        message:
          "Listenen satır sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
        buttons: [
          {
            label: "Evet",
            onClick: () => {
              _exporter.current.save(
                gridRef.current.props.data,
                gridRef.current.columns
              );
            },
          },
          {
            label: "Hayır",
            onClick: () => {},
          },
        ],
      });
    }
  }

  const doOffset = () => {
    confirmAlert({
      title: "Uyarı",
      message:
        props.sanalPosData[0].doneM_KODU +
        "  ile ilgili DBS-Sanal Pos Mahsuplaşma talebiniz Daikin Kredi Kontrol birimine iletilecektir. Devam etmek istiyor musunuz?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            var data = {
              DBSList: props.dbsData,
              SanalPosList: props.sanalPosData,
              parA_BIRIMI: props.sanalPosData[0].parA_BIRIMI,
              bayI_KODU: props.sanalPosData[0].bayI_KODU,
            };
            OffsetService.matchOffset(data, callbackAfterMatchOffset);
          },
        },
        {
          label: "Hayır",
          onClick: () => {},
        },
      ],
    });
  };

  const callbackAfterMatchOffset = () => {
    window.location.reload();
  };

  return (
    <Window
      title="Vade Farkı Hesabı"
      onClose={props.toggleDialogOffsetSummary}
      initialHeight={"90vh"}
      initialWidth={"60%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <Row>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>DBS</h6>
            </CardHeader>
            <CardBody>
              <ExcelExport fileName="DBS.xlsx" ref={dbsExporter}>
                <Grid
                  ref={dbsGridRef}
                  style={{ height: "300px" }}
                  data={props.dbsData}
                >
                  <GridToolbar>
                    <button
                      title="Excel"
                      className="btn btn-success btn-sm"
                      onClick={() => exportExcel(dbsExporter, dbsGridRef)}
                    >
                      Excel
                    </button>
                  </GridToolbar>
                  <GridColumn
                    field="vadE_TARIHI"
                    title="Vade Tarihi"
                    width="180px"
                    cell={(subProps) =>
                      GridDateFormatCell(subProps, "dd.MM.yyyy HH:mm")
                    }
                    footerCell={(subProps) =>
                      GridFooterAverageDatetimeCell(
                        subProps,
                        "dd.MM.yyyy HH:mm",
                        "DBS"
                      )
                    }
                  />
                  <GridColumn
                    field="tutar"
                    title="Tutar"
                    width="180px"
                    cell={(subProps) => GridNumberFormatCell(subProps, 2)}
                    footerCell={(subProps) =>
                      GridFooterSumCell(subProps, props.dbsData, 2)
                    }
                  />
                  <GridColumn
                    field="parA_BIRIMI"
                    title="Para Birimi"
                    width="90px"
                  />
                </Grid>
              </ExcelExport>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>SANAL POS</h6>
            </CardHeader>
            <CardBody>
              <ExcelExport fileName="SanalPos.xlsx" ref={sanalPosExporter}>
                <Grid
                  ref={sanalPosGridRef}
                  style={{ height: "300px" }}
                  data={props.sanalPosData}
                >
                  <GridToolbar>
                    <button
                      title="Excel"
                      className="btn btn-success btn-sm"
                      onClick={() =>
                        exportExcel(sanalPosExporter, sanalPosGridRef)
                      }
                    >
                      Excel
                    </button>
                  </GridToolbar>
                  <GridColumn
                    field="vadE_TARIHI"
                    title="Vade Tarihi"
                    width="180px"
                    cell={(subProps) =>
                      GridDateFormatCell(subProps, "dd.MM.yyyy HH:mm")
                    }
                    footerCell={(subProps) =>
                      GridFooterAverageDatetimeCell(
                        subProps,
                        "dd.MM.yyyy HH:mm",
                        "SANAL_POS"
                      )
                    }
                  />
                  <GridColumn
                    field="tutar"
                    title="Tutar"
                    width="180px"
                    cell={(subProps) => GridNumberFormatCell(subProps, 2)}
                    footerCell={(subProps) =>
                      GridFooterSumCell(subProps, props.sanalPosData, 2)
                    }
                  />
                  <GridColumn
                    field="parA_BIRIMI"
                    title="Para Birimi"
                    width="90px"
                  />
                </Grid>
              </ExcelExport>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardHeader>
              <h6>VADE FARKI HESABI</h6>
            </CardHeader>
            <CardBody>
              <CommonNumericTextBox
                label="Valör Gün"
                id="valor_gun"
                value={valorGun}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="Borç Tutarı"
                id="borc_tutari"
                value={toplamTutarDBS}
                readOnly={true}
              ></CommonNumericTextBox>
              <CommonNumericTextBox
                label="Vade Farkı Oranı"
                id="vade_farki_orani"
                value={interestRate?.katsayI_DEGER}
                readOnly={true}
              ></CommonNumericTextBox>
              {valorGun >= 1 ? (
                <CommonNumericTextBox
                  label="Vade Farkı Tutarı"
                  id="vade_farki_tutari"
                  value={vadeFarkiTutari}
                  readOnly={true}
                ></CommonNumericTextBox>
              ) : undefined}
              {valorGun >= 1 ? (
                <CommonNumericTextBox
                  label="KDV Oranı"
                  id="kdV_ORANI"
                  value={kdvRate?.katsayI_DEGER}
                  readOnly={true}
                ></CommonNumericTextBox>
              ) : undefined}
              {valorGun >= 1 ? (
                <CommonNumericTextBox
                  label="KDV li Vade Farkı Tutarı"
                  id="kdV_LI_TUTAR"
                  value={vadeFarkiTutari * (1 + kdvRate?.katsayI_DEGER / 100)}
                  readOnly={true}
                ></CommonNumericTextBox>
              ) : undefined}
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" md="6" xs="6">
          <Card>
            <CardBody>
              {valorGun < 1 ? (
                <Alert color="warning">
                  <b>Talebinizde vade farkı oluşmamaktadır.</b>
                </Alert>
              ) : undefined}
              <Button
                color="success"
                style={{ width: "100%" }}
                size="md"
                onClick={doOffset}
              >
                <i className="k-icon k-i-check"></i>
                KREDİ KONTROLE GÖNDER
              </Button>
              <Button
                color="danger"
                style={{ width: "100%", marginTop: "5px" }}
                size="md"
                onClick={props.toggleDialogOffsetSummary}
              >
                <i className="k-icon k-i-redo"></i>
                VAZGEÇ
              </Button>
            </CardBody>
          </Card>
          {toplamTutarDBS > toplamTutarSanalPos ? (
            <Card style={{ marginTop: "10px" }}>
              <CardBody>
                <Alert color="warning" style={{ textAlign: "center" }}>
                  <span
                    class="k-icon k-i-warning"
                    style={{ fontSize: "32px" }}
                  ></span>
                  <h5 className="alert-heading">
                    Toplam DBS tutarı Toplam Sanal Pos tutarından fazla olduğu
                    için mahsup işlemini gerçekleştiremezsiniz.
                  </h5>
                </Alert>
              </CardBody>
            </Card>
          ) : undefined}
        </Col>
      </Row>
    </Window>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OffsetSummary);
