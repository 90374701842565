import React, { Component } from "react";

export default class ResetPassword extends Component {
  componentWillMount() {
    console.log(this.props.match.params);
  }
  render() {
    return <div></div>;
  }
}
