import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import OfferOrderRegionService from "../../services/srv-offerOrderRegion/OfferOrderRegionService";

const OfferOrderRegionDropDownList = ({
  id,
  label,
  value,
  required,
  onChange,
  disabled,
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const onCompletedGetData = (data) => {
    setData(data);
    setDataLoading(false);
  };

  const getData = () => {
    setDataLoading(true);
    OfferOrderRegionService.getRegions(onCompletedGetData);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="bolgE_ACIKLAMA"
      dataItemKey="bolgE_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      disabled={disabled}
      loading={dataLoading}
      value={value ? data.find((v) => v.bolgE_KODU === value) : ""}
    ></DropDownList>
  );
};

export default OfferOrderRegionDropDownList;
