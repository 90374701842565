import React from "react";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { useTranslation } from "react-i18next";

const Footer = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <footer className="footer ">
        <div className="container" style={{ textAlign: "center" }}>
          <span className="text-muted">
            <img
              src="https://www.daikin.com.tr/Content/img/icons/daikin-logo.svg"
              alt="Daikin"
              className="brand-image  elevation-0"
              height="15px"
            />
            {" | "}
            {t(
              "copyright"
            )} <CopyrightIcon fontSize="small"></CopyrightIcon> | DTAS -{" "}
            {t("system-develepment-section")} - {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
