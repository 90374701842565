import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { BASE_URL } from "../../config/globalVariables";
import {
  getCampaign,
  insertCampaign,
  updateCampaign,
} from "../../redux/actions/act-bry-campaign/campaignActions";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import CampaignDealer from "./CampaignDealer";
import CampaignProductInsert from "./CampaignProductInsert";
import CampaignProducts from "./CampaignProducts";
import CampaignProps from "./CampaignProps";

class CampaignDetail extends Component {
  state = {
    selected: 0,
    visibleCampaignProduct: false,
  };

  componentDidMount() {
    this.props.getCampaign(this.props.match.params.id);
  }

  componentDidUpdate() {
    if (
      this.props.campaign.dataInserted === true &&
      this.props.match.params.id !==
        this.props.campaign._campaign.brY_KAMPANYA_TANIM_ID.toString()
    ) {
      this.props.history.push(
        BASE_URL +
          "campaign-detail/" +
          this.props.campaign._campaign.brY_KAMPANYA_TANIM_ID
      );
    }
  }

  onSelect = (e) => {
    this.setState({ selected: e.selected });
  };

  toggleDialogCampaignProduct = () => {
    this.setState({
      visibleCampaignProduct: !this.state.visibleCampaignProduct,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.props.match.params.id == 0
      ? this.props.insertCampaign(this.props.campaign)
      : this.props.updateCampaign(this.props.campaign);
    // this.props.match.params.id == 0
    //   ? console.log("ID => INSERT",this.props.match.params.id)
    //   : console.log("ID => UPDATE",this.props.campaign);
  };

  render() {
    return (
      <main style={{ flexGrow: "1", padding: "40px" }}>
        {this.props.campaign.fetching && LoadingPanel}
        {this.state.visibleCampaignProduct ? (
          <CampaignProductInsert
            toggleDialog={this.toggleDialogCampaignProduct}
            id={this.props.match.params.id}
            code={this.props.campaign._campaign.kampanyA_KODU}
          ></CampaignProductInsert>
        ) : (
          ""
        )}
        <h3>
          {this.props.match.params.id === "0"
            ? "Yeni Kampanya"
            : "Kampanya Detayı"}
        </h3>
        <hr></hr>

        {!this.props.campaign.fetching ? (
          <CampaignProps
            history={this.props.history}
            id={this.props.match.params.id}
            campaign={this.props.campaign}
            submitFunc={this.onSubmit}
          ></CampaignProps>
        ) : (
          ""
        )}
        {this.props.match.params.id === "0" ? (
          ""
        ) : (
          <TabStrip
            selected={this.state.selected}
            onSelect={this.onSelect}
            scrollable={true}
          >
            {!this.props.campaign.fetching ? (
              <TabStripTab title={"Kampanya Katılımcıları"}>
                <CampaignDealer
                  code={this.props.campaign._campaign.kampanyA_KODU}
                ></CampaignDealer>
              </TabStripTab>
            ) : (
              ""
            )}
            {!this.props.campaign.fetching ? (
              <TabStripTab title={"Kampanya Ürünleri"}>
                <CampaignProducts
                  toggleDialog={this.toggleDialogCampaignProduct}
                  code={this.props.campaign._campaign.kampanyA_KODU}
                ></CampaignProducts>
              </TabStripTab>
            ) : (
              ""
            )}
          </TabStrip>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getCampaign: bindActionCreators(getCampaign, dispatch),
    insertCampaign: bindActionCreators(insertCampaign, dispatch),
    updateCampaign: bindActionCreators(updateCampaign, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDetail);
