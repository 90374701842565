import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function dealerProductGroupListReducer(
  state = initialState.dealerProductGroupList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_DEALER_PRODUCT_GROUP:
      return {
        ...state,
        dealerProductGroups: action.payload,
      };
    case actionTypes.FETCH_DEALER_PRODUCT_GROUP_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_DEALER_PRODUCT_GROUP_FULFILLED:
      return {
        ...state,
        dealerProductGroups: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_DEALER_PRODUCT_GROUP_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.GET_DEALER_PRODUCT_GROUPS:
      return {
        ...state,
        dealerProductGroups: action.payload,
      };
    case actionTypes.GET_DEALER_PRODUCT_GROUPS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_DEALER_PRODUCT_GROUPS_FULFILLED:
      return {
        ...state,
        dealerProductGroups: action.payload,
        fetching: false,
      };
    case actionTypes.GET_DEALER_PRODUCT_GROUPS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
