import React, { Component } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";

import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getGridFilterProperties,
} from "../../helpers/utils";
import { getChannelsByFilter } from "../../redux/actions/act-bry-channel/channelActions";
import {
  changeCustomerProduct,
  deleteCustomerProduct,
  fetchCustomerProduct,
  fetchCustomerProductPayload,
  insertCustomerProduct,
  updateCustomerProduct,
} from "../../redux/actions/act-bry-discount/customerProductActions";
import { getSalesChannelsByFilter } from "../../redux/actions/act-bry-salesChannel/salesChannelActions";
import { getDealers } from "../../redux/actions/act-dealer/dealerActions";
import { fetchSalesDepartmant } from "../../redux/actions/act-mrc-salesDepartmant/salesDepartmantActions";
import { fetchProductByFilter } from "../../redux/actions/act-product/productActions";
import CustomAutoComplateForCell from "../sys-common/CustomAutoComplateForCell";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const primaryKey = "brY_IND_1_MUSTERI_URUN_ID";
const editField = "inEdit";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];
class CustomerProductList extends Component {
  isCustomerProductList = false;
  editField = "inEdit";
  isRejectedData = false;

  state = {
    visibleNewProduct: false,
    customerProductData: this.props.customerProductData,
    editData: null,
    removeData: null,
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchCustomerProduct(event.dataState, this.onComplatedCallBack);
  };
  componentDidMount() {
    this.props.fetchSalesDepartmant();
    this.props.getChannelsByFilter({ skip: 0, take: 1000 });
    this.getCustomerProductList();
  }

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  export = async () => {
    if (this._export !== null) {
      var data = await fetchCustomerProductPayload({
        ...this.props.customerProductList.dataState,
        skip: 0,
        take: 10000,
      });
      this._export.save(data.data.data, this._grid.columns);
    }
  };

  getCustomerProductList = (search) => {
    var take = this.props.customerProductList.dataState
      ? this.props.customerProductList.dataState.take
      : 6;
    var skip = this.props.customerProductList.dataState
      ? this.props.customerProductList.dataState.skip
      : 0;

    this.props.fetchCustomerProduct(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onComplatedCallBack
    );
  };

  itemChange = (event) => {
    const data = this.state.customerProductData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    this.setState({
      customerProductData: { ...this.state.customerProductData, data },
    });
  };
  addNewCustomerProduct = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    var isOpenEdit = this.state.customerProductData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        customerProductData: {
          ...this.state.customerProductData,
          data: [newDataItem, ...this.state.customerProductData.data],
        },
      });
    }
  };

  remove = (dataItem) => {
    this.props.deleteCustomerProduct(
      dataItem[primaryKey],
      this.props.customerProductList.dataState,
      this.onComplatedCallBack
    );
    this.setState({ removeData: dataItem });
  };

  checkValidate = (dataItem) => {
    console.log(dataItem);
    if (dataItem.indiriM_ORANI < 0 || dataItem.indiriM_ORANI > 100) {
      defaultAlertMessages.MessageDetailError =
        "İndirim oranı 0 ile 100 arasında olmalıdır.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      return false;
    }

    if (
      !dataItem.satiS_DEPARTMANI_KODU ||
      !dataItem.brY_KANAL ||
      !dataItem.bayI_KODU ||
      !dataItem.uruN_KODU ||
      typeof dataItem.indiriM_ORANI !== "number"
    ) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen gerekli alanları doldurunuz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      return false;
    }
    return true;
  };

  add = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.insertCustomerProduct(
      dataItem,
      this.props.customerProductList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = false;
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.customerProductData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.customerProductData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        customerProductData: { ...this.state.customerProductData, data },
      });
    }
  };

  update = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.updateCustomerProduct(
      dataItem,
      this.props.customerProductList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = undefined;
  };

  discard = () => {
    const data = [...this.state.customerProductData.data];
    data.splice(0, 1);
    this.setState({
      customerProductData: { ...this.state.customerProductData, data },
    });
  };

  cancel = (dataItem) => {
    console.log(this.props.customerProductList);
    const originalItem =
      this.props.customerProductList.customerProducts.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.customerProductData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      customerProductData: { ...this.state.customerProductData, data },
    });
  };
  setCustomerProductData = () => {
    this.setState({
      customerProductData: this.state.customerProductData.data.map((item) =>
        item[primaryKey] === this.state.editData[primaryKey]
          ? { ...item, inEdit: true }
          : item
      ),
    });
  };

  onComplatedCallBack = (data) => {
    this.setState({
      customerProductData: data,
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      add={this.add}
      discard={this.discard}
      update={this.update}
      remove={this.remove}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  DiscountNumericTextBox = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const onChange = (e) => {
      props.onChange(e);
      const data = this.state.customerProductData.data.map((item) =>
        !item.inEdit
          ? item
          : {
              ...item,
              [props.field]: e.value,
            }
      );
      this.setState({
        customerProductData: {
          ...this.state.customerProductData,
          data,
        },
      });
    };
    if (dataItem.inEdit) {
      return (
        <td>
          <NumericTextBox
            value={dataValue}
            onChange={onChange}
            format="n5"
          ></NumericTextBox>
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  ProductAutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const onChange = (e, k) => {
      props.onChange(e);
      const {
        brY_URUN_KATEGORI,
        brY_URUN_GRUBU,
        brY_MARKA_SEGMENT,
        uruN_KODU,
        uruN_ADI,
      } = k;
      const data = this.state.customerProductData.data.map((item) =>
        !item.inEdit
          ? item
          : {
              ...item,
              brY_URUN_KATEGORI,
              brY_URUN_GRUBU,
              brY_MARKA_SEGMENT,
              uruN_KODU,
              uruN_ADI,
            }
      );
      this.setState({
        customerProductData: {
          ...this.state.customerProductData,
          data,
        },
      });
    };
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomAutoComplateForCell
            cellProps={{ ...props, onChange }}
            action={fetchProductByFilter}
            listName="filteredProducts"
            reducerName="productFilterListReducer"
            secondFieldName="uruN_ADI"
          />
        </td>
      );
    } else {
      return (
        <td>
          {dataValue} ({dataItem.uruN_ADI})
        </td>
      );
    }
  };

  BayiAutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const onChange = (e, k) => {
      const { bayI_KODU, bayI_ADI } = k;
      props.onChange(e);
      const data = this.state.customerProductData.data.map((item) =>
        !item.inEdit
          ? item
          : {
              ...item,
              bayI_KODU,
              bayI_ADI,
            }
      );
      this.setState({
        customerProductData: {
          ...this.state.customerProductData,
          data,
        },
      });
    };
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomAutoComplateForCell
            cellProps={{ ...props, onChange }}
            action={getDealers}
            listName="dealers"
            reducerName="dealerListReducer"
            secondFieldName="bayI_ADI"
            onChange={onChange}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  SalesDepartmantDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.salesDepartmantList.salesDepartmants}
            required
            textField="satiS_DEPARTMANI_KODU"
            dataItemKey="mrC_SATIS_DEPARTMANI_TANIM_ID"
            loading={this.props.salesDepartmantList.fetching}
            onChange={(e) => this.salesDepartmantOnChange(e, props)}
            value={{ satiS_DEPARTMANI_KODU: dataValue }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  salesDepartmantOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value[field];
    this.props.getSalesChannelsByFilter(value);
    const data = this.state.customerProductData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [props.field]: value,
            brY_KANAL: undefined,
            brY_SATIS_KANALI_KODU: undefined,
          }
    );
    this.setState({
      customerProductData: {
        ...this.state.customerProductData,
        data,
      },
    });
  };

  ChannelAutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            cellProps={props}
            data={this.props.salesChannelList.salesChannels.data}
            required
            value={{ brY_KANAL: dataValue }}
            textField="brY_KANAL"
            dataItemKey="brY_SATIS_KANALI_TANIM_ID"
            loading={this.props.salesChannelList.fetching}
            onChange={(e) => {
              const value = e.value[field];
              var satisKanalKodu =
                this.props.salesChannelList.salesChannels.data.find(
                  (d) => d.brY_KANAL === value
                );
              const data = this.state.customerProductData.data.map((item) =>
                !item.inEdit
                  ? item
                  : {
                      ...item,
                      brY_SATIS_KANALI_KODU:
                        satisKanalKodu?.brY_SATIS_KANALI_KODU,
                      brY_KANAL: value,
                    }
              );
              this.setState({
                customerProductData: {
                  ...this.state.customerProductData,
                  data,
                },
              });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  onChangeSearch = (event) => {
    console.log(this._grid.columns);
    var searchFilter = Object.assign(
      {},
      this.props.customerProductList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );
    this.isLoadCustomerProductData = false;
    this.getCustomerProductList(searchFilter);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.customerProductList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.customerProductList.customerProducts?.data}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.customerProductData}
            onDataStateChange={this.dataStateChange}
            {...this.props.customerProductList.dataState}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            onItemChange={this.itemChange}
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
            filterable
            dataItemKey={primaryKey}
            editField={editField}
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={this.addNewCustomerProduct}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              {/* <Input
                    name="search"
                    label="Hızlı arama için enter'a basınız"
                    style={{ width: "40%", float: "right" }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        this.onChangeSearch(event);
                      }
                    }}
                  ></Input> */}
            </GridToolbar>
            <GridColumn
              cell={this.CommandCell}
              width="100px"
              filterable={false}
            />
            <GridColumn
              field={primaryKey}
              title="Id"
              width="100px"
              filter={"numeric"}
              editable={false}
            ></GridColumn>
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="Departman Kodu"
              width="120px"
              cell={this.SalesDepartmantDropDown}
            ></GridColumn>
            <GridColumn
              field="brY_KANAL"
              editable={false}
              title="Kanal"
              width="120px"
              cell={this.ChannelAutoComleteCell}
            ></GridColumn>
            <GridColumn
              field="brY_SATIS_KANALI_KODU"
              title="Satış Kanal Kodu"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="bayI_KODU"
              title="Customer SAP Code"
              width="120px"
              cell={this.BayiAutoComleteCell}
            ></GridColumn>
            <GridColumn
              field="bayI_ADI"
              title="Customer Name"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="uruN_KODU"
              title="Product Code"
              width="120px"
              cell={this.ProductAutoComleteCell}
            ></GridColumn>
            <GridColumn
              field="uruN_ADI"
              title="Product Name"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_KATEGORI"
              title="Product Category"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_GRUBU"
              title="Product Group"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="brY_MARKA_SEGMENT"
              title="Brand Seg"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="indiriM_ORANI"
              title="Discount %"
              width="140px"
              filter={"numeric"}
              cell={this.DiscountNumericTextBox}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    customerProductList: state.customerProductListReducer,
    salesDepartmantList: state.salesDepartmantListReducer,
    dealerList: state.dealerListReducer,
    auth: state.authReducer,
    channelList: state.channelsByFilterReducer,
    salesChannelList: state.salesChannelsReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchCustomerProduct: bindActionCreators(fetchCustomerProduct, dispatch),
    changeCustomerProduct: bindActionCreators(changeCustomerProduct, dispatch),
    insertCustomerProduct: bindActionCreators(insertCustomerProduct, dispatch),
    updateCustomerProduct: bindActionCreators(updateCustomerProduct, dispatch),
    deleteCustomerProduct: bindActionCreators(deleteCustomerProduct, dispatch),
    fetchSalesDepartmant: bindActionCreators(fetchSalesDepartmant, dispatch),
    getDealers: bindActionCreators(getDealers, dispatch),
    getChannelsByFilter: bindActionCreators(getChannelsByFilter, dispatch),
    getSalesChannelsByFilter: bindActionCreators(
      getSalesChannelsByFilter,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProductList);
