import { Step, Stepper } from "@progress/kendo-react-layout";
import * as React from "react";
import { List } from "reactstrap";
import {
  convertDateToStringLocaleFormat
} from "../../helpers/utils";

const customSteps = [
  {
    id: 1,
    label: "FABRİKA",
    icon: "fa fa-industry",
    data: [],
  },
  {
    id: 2,
    label: "TLS",
    icon: "fa fa-truck",
    data: [],
  },
  {
    id: 3,
    label: "DASU",
    icon: "fa fa-user-o",
    data: [],
  },
];
const CustomStep = (props) => {
  return (
    <Step {...props}>
      <List type="unstyled">
        <li>
          <div
            style={{
              marginBottom: "15px",
              border: "1px solid #93C1F2",
              width: "50px",
              height: "50px",
              backgroundColor: "#93C1F2",
            }}
            className="custom-step"
          >
            <i style={{ fontSize: "24px" }} className={props.icon} />
          </div>
        </li>
        <li>
          <span className="step-label">{props.label}</span>
        </li>
        <hr></hr>
        <li>
          <div>
            {props.data &&
              props.data.map((x) => (
                <p>
                  {x.recorD_TYPE +
                    " (" +
                    convertDateToStringLocaleFormat(x.isleM_TARIHI) +
                    ")"}
                </p>
              ))}
          </div>
        </li>
      </List>
    </Step>
  );
};
export const SerialNumberStepper = ({ data }) => {
  customSteps.map((x) => (x.data = []));
  data.map((d) =>
    d.recorD_OWNER == "DATABAR"
      ? customSteps.find((x) => x.id == 1).data.push(d)
      : d.recorD_OWNER == "DPM"
      ? customSteps.find((x) => x.id == 1).data.push(d)
      : d.recorD_OWNER == "TLS"
      ? customSteps.find((x) => x.id == 2).data.push(d)
      : d.recorD_OWNER == "DASU"
      ? customSteps.find((x) => x.id == 3).data.push(d)
      : null
  );
  return (
    <>
      <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        <Stepper
          className="custom-stepper-serial"
          items={customSteps}
          item={CustomStep}
        />
      </div>
    </>
  );
};
