import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchSalesApproval(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_APPROVAL,
      payload: fetchSalesApprovalPayload(dataState, callback),
    });
  };
}

export function fetchSalesApprovalPayload(dataState, callback) {
  return axios
    .get(`${API_GATEWAY + PR_DEX}/satisonay/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}