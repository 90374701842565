import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";
import { process } from "@progress/kendo-data-query";

export default function menuListReducer(
  state = initialState.menuList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_MENUS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_MENUS_FULFILLED:
    return {
         ...state,
        _menuList: action.payload,
        result: process(action.payload.slice(0), { take: 6, skip: 0 }),
        fetching: false,
      };
    case actionTypes.GET_MENUS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
