import { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import UserRoleService from "../../services/srv-user/UserRoleService";
import RoleListBox from "../sys-common/RoleListBox";
import { Button } from "reactstrap";

export function UserRole({ modalToggle, user, userName }) {
  const gridPrimaryKey = "kullanicI_ROLE_TANIM_ID";
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const [originalUserRoleData, setOriginalUserRoleData] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [userRoleDataState, setUserRoleDataState] = useState({
    sort,
    skip: 0,
    ...gridSearch,
    filter: {
      logic: "or",
      filters: [
        {
          field: "kullanicI_ID",
          operator: "eq",
          value: user,
        },
      ],
    },
  });
  const [userRoleDataLoading, setUserRoleDataLoading] = useState(false);

  useEffect(() => {
    getUsersByFilter();
  }, [userRoleDataState]);

  function getUsersByFilter() {
    setUserRoleDataLoading(true);
    UserRoleService.getByFilter(userRoleDataState, onCompletedGetData);
  }

  function onCompletedGetData(result) {
    if (result) {
      setOriginalUserRoleData(result);
      if (result.dataState) setUserRoleDataState(result.dataState);
    }
    setUserRoleDataLoading(false);
  }

  function onError() {
    setUserRoleDataLoading(false);
  }

  function onLoadingCompleted(result) {
    setUserRoleDataLoading(false);
    getUsersByFilter();
  }

  const sendData = (data) => {
    setSelectedData(data);
  };

  const btnClickHandler = () => {
    var _newData = { entities: selectedData, KullaniciID: user };
    UserRoleService.addRange(_newData, onLoadingCompleted, onError);
  };

  return (
    <Window
      title={`${userName} Rolleri`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <LocalizationProvider language="tr">
        <IntlProvider locale="tr">
          <RoleListBox
            selectedData={originalUserRoleData}
            onChange={sendData}
          />

          <div>
            <Button
              style={{ marginTop: "10px" }}
              color="primary"
              onClick={btnClickHandler}
            >
              Kaydet
            </Button>
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </Window>
  );
}
