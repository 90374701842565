import React from "react";
import FormatNumber from "./NumberFormat";

const GridFooterSumCell = (props, data, decimalScale) => {
  var value = 0;
  if (data.length > 0) {
    value = data.reduce((acc, current) => acc + current[props.field], 0);
  }
  return (
    <td colSpan={props.colSpan} style={props.style}>
      <b>
        Toplam : <br></br>
        {FormatNumber(value, decimalScale)}
      </b>
    </td>
  );
};

export default GridFooterSumCell;
