import * as React from "react";

import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import UpIcon from "@material-ui/icons/ArrowUpward";
import DownIcon from "@material-ui/icons/ArrowDownward";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Menu,
  ButtonGroup,
  Button,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default function ColumnsMenu(props) {
  const { columns, onChange, storageKey } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeColumnVisiblity = (field, hidden) => {
    const newColumns = columns.map((column) => {
      if (column.field === field) {
        column.hidden = hidden;
      }
      return column;
    });
    ColumnMenuHelper.setColumn(newColumns, storageKey);
    onChange(newColumns);
  };
  const changeColumnOrder = (field, type) => {
    const newColumns = [...columns];
    const targetColumn = newColumns.find((column) => column.field === field);
    const currentOrder = targetColumn.order;
    if (type === "increase") targetColumn.order += 1;
    else targetColumn.order -= 1;
    const equalColumn = newColumns.find(
      (column) => column.order === targetColumn.order && column.field !== field
    );
    equalColumn.order = currentOrder;
    ColumnMenuHelper.setColumn(newColumns, storageKey);
    onChange(newColumns);
  };
  return (
    <div style={{ display: "inline-block" }}>
      <IconButton
        color="primary"
        onClick={handleClick}
        aria-controls={open ? "column-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <ViewColumnIcon />
      </IconButton>

      <Menu
        id="column-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "column-button",
        }}
      >
        <FormGroup style={{ padding: "16px" }}>
          {columns
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((column, i) => (
              <Grid
                container
                key={column.field}
                alignItems="flex-center"
                justifyContent="space-between"
              >
                <Grid item>
                  <FormControlLabel
                    name={column.field}
                    onChange={(event) => {
                      changeColumnVisiblity(
                        event.target.name,
                        !event.target.checked
                      );
                    }}
                    control={<Switch checked={!column.hidden} />}
                    label={t(column.title)}
                  />
                </Grid>
                <Grid item>
                  <ButtonGroup size="small">
                    <Button
                      onClick={() =>
                        changeColumnOrder(column.field, "decrease")
                      }
                      disabled={i === 0}
                      startIcon={<UpIcon />}
                    ></Button>
                    <Button
                      onClick={() =>
                        changeColumnOrder(column.field, "increase")
                      }
                      disabled={i === columns.length - 1}
                      endIcon={<DownIcon />}
                    ></Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
            ))}
        </FormGroup>
      </Menu>
    </div>
  );
}

export const ColumnMenuHelper = {
  getColumn: (key) => JSON.parse(localStorage.getItem(key) || "{}"),
  setColumn: (columns, key) => {
    localStorage.setItem(key, JSON.stringify(columns));
  },
};
