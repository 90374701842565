import React from "react";
import { useRef } from "react";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import { formatDate } from "@telerik/kendo-intl";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";

const ExportExcel = (props) => {
  const { t, i18n } = useTranslation();
  const _export = useRef(null);
  async function exportExcel() {
    if (_export !== null) {
      props.loadingFunction(true);
      confirmAlert({
        title: "Uyarı",
        message:
          "Listenen veri sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
        buttons: [
          {
            label: "Evet",
            onClick: async () => {
              try {
                var data = await props.serviceMethod(props.dataState);

                var dateColumns = props.columns
                  .filter((a) => a.type === "date")
                  .map((a) => a.field);
                var dateTimeColumns = props.columns
                  .filter((a) => a.type === "datetime")
                  .map((a) => a.field);

                data.map((a) => {
                  dateColumns?.forEach((elem) => {
                    if (a[elem])
                      a[elem] = formatDate(new Date(a[elem]), "dd.MM.yyyy");
                  });
                  dateTimeColumns?.forEach((elem) => {
                    if (a[elem])
                      a[elem] = formatDate(
                        new Date(a[elem]),
                        "dd.MM.yyyy HH:mm:ss"
                      );
                  });
                  return a;
                });

                _export.current.save(data);
              } catch (err) {
                props.loadingFunction(false);
              }
            },
          },
          {
            label: "Hayır",
            onClick: () => {
              props.loadingFunction(false);
            },
          },
        ],
      });
    }
  }

  function onExportComplete() {
    props.loadingFunction(false);
  }

  return (
    <div>
      {" "}
      <ExcelExport
        data={props.data}
        ref={_export}
        onExportComplete={onExportComplete}
        fileName={`${props.fileName}_${formatDate(
          new Date(),
          "ddMMyyyyHHmmss"
        )}`}
      >
        {props.columns.map((column, index) => (
          <ExcelExportColumn
            key={"column_" + index.toString()}
            field={column.field}
            title={t(column.title) ? t(column.title) : column.title}
            width={200}
          />
        ))}
      </ExcelExport>
      <button
        title="Export Excel"
        className="btn btn-success btn-sm"
        onClick={exportExcel}
      >
        Excel
      </button>
    </div>
  );
};

export default ExportExcel;
