import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { PR_DEX } from '../../config/globalVariables';
import { ServiceBase, rawRequest } from '../srv-base/ServiceBase';

class DasuService extends ServiceBase {
  constructor() {
    super(PR_DEX, '/dasu');
  }

  getDasuForExcel(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      PR_DEX,
      '/dasu',
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new DasuService();
