import GridDateFormatCell from "../sys-common/GridDateFormatCell";

export const BiggSerialsCsvReportGridColumns = [
  {
    field: "dkN_BIGG_SERIALS_CSV_ID",
    title: "DKN BIGG SERIALS CSV ID",
    width: "128px",
    filter: "numeric",
    order: 0,
  },
  {
    field: "handlinG_UNIT",
    title: "HANDLING UNIT",
    width: "128px",
    order: 1,
  },
  {
    field: "seriaL_NUMBER",
    title: "SERİ NUMARASI",
    width: "256px",
    order: 2,
  },
  {
    field: "material",
    title: "MATERIAL",
    width: "256px",
    order: 3,
  },
  {
    field: "producT_NUMBER",
    title: "ÜRÜN KODU",
    width: "256px",
    order: 4,
  },
  {
    field: "materiaL_DESCRIPTION",
    title: "MATERIAL DESCRIPTION",
    width: "256px",
    order: 5,
  },
  {
    field: "storagE_LOCATION",
    title: "STORAGE LOCATION",
    width: "256px",
    order: 6,
  },
  {
    field: "saleS_ORDER",
    title: "SALES ORDER",
    width: "256px",
    order: 7,
  },
  {
    field: "delivery",
    title: "DELIVERY",
    width: "256px",
    order: 8,
  },
  {
    field: "customer",
    title: "CUSTOMER",
    width: "256px",
    order: 9,
  },
  {
    field: "customeR_NAME",
    title: "MÜŞTERİ ADI",
    width: "256px",
    order: 10,
  },
  {
    field: "customeR_CITY",
    title: "MÜŞTERİ ŞEHRİ",
    width: "256px",
    order: 11,
  },
  {
    field: "entrY_DATE",
    title: "GİRİŞ ZAMANI",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    order: 12,
  },
  {
    field: "shiP_TO",
    title: "GÖNDERİM DURUMU",
    width: "256px",
    order: 13,
  },
  {
    field: "shiP_TO_NAME",
    title: "GÖNDERİM ADI",
    width: "256px",
    order: 14,
  },
  {
    field: "shiP_TO_CITY",
    title: "GÖNDERİM ŞEHRİ",
    width: "256px",
    order: 15,
  },
  {
    field: "billinG_DOCUMENT",
    title: "BILLING_DOCUMENT",
    width: "256px",
    order: 16,
  },
  {
    field: "movemenT_TYPE",
    title: "MOVEMENT_TYPE",
    width: "256px",
    order: 17,
  },
  {
    field: "resulT_CODE",
    title: "SONUC",
    width: "256px",
    order: 18,
  },
  {
    field: "crE_DATE",
    title: "KAYIT TARİHİ",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    order: 19,
  },
  {
    field: "crE_DATE_2",
    title: "KAYIT TARİHİ",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    order: 20,
  },
];
