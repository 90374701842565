const filterLocalization = (e) => {
  const updatedFilters =
    e.dataState.filter &&
    e.dataState.filter?.filters?.map((item) => {
      if (typeof item.value === "string") {
        if (item.value.includes("ı")) {
          return {
            ...item,
            value: item.value.replace(/ı/g, "i"),
            //value: item.value.replaceAll("ı", "i"),
          };
        }
        if (item.value.includes("I")) {
          return {
            ...item,
            value: item.value.replace(/I/g, "İ"),
            //value: item.value.replaceAll("I", "İ"),
          };
        }
      }
      return item;
    });

  const { filter, group, skip, sort, take } = e.dataState;
  if (updatedFilters?.length > 0) {
    filter.filters = updatedFilters;
  }
  const newState = { filter, group, skip, sort, take };
  return newState;
};

export default filterLocalization;
