import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import CustomerService from "../../services/srv-customer/CustomerService";

const CustomerTempDropDownList = ({
  id,
  label,
  value,
  required,
  disabled,
  onChange,
}) => {
  var pendingRequest = undefined;
  const [filteredData, setFilteredData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (value && filteredData === false) {
      var filter = [];
      filter.push({
        field: "muS_KODU",
        operator: "eq",
        value: value,
      });
      getDealersByFilter(filter);
    }
  }, [value, filteredData]);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setFilteredData(true);
    setDataLoading(false);
  };

  const getDealersByFilter = (filters) => {
    setDataLoading(true);
    CustomerService.getCustomersWithTemp(
      {
        filter: { logic: "or", filters: filters },
      },
      onCompletedGetData
    );
  };

  const dealerFilter = (event) => {
    if (dataLoading) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        dealerFilter(event);
      }, 50);
      return;
    }

    var filters = [];
    filters.push({
      field: "muS_KODU",
      operator: "contains",
      value: event.filter.value,
    });
    filters.push(event.filter);
    getDealersByFilter(filters);
  };

  const onFilterDealer = (event) => {
    if (event.filter.value.length > 2) {
      dealerFilter(event);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.muS_KODU}){" "}
            {itemProps.dataItem.bayI_KODU &&
              `- (${itemProps.dataItem.bayI_KODU})`}
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="muS_ADI"
      dataItemKey="muS_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      disabled={disabled}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterDealer(event)}
      loading={dataLoading}
      value={value ? data.find((v) => v.muS_KODU === value) : ""}
    ></DropDownList>
  );
};

export default CustomerTempDropDownList;
