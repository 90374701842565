import { useEffect, useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import UserMenuAddonsService from "../../services/srv-user/UserMenuAddonsService";
import MenuListBox from "../sys-common/MenuListBox";
import { Button } from "reactstrap";

export function UserMenuAddon({ modalToggle, user, userName }) {
  const gridPrimaryKey = "kullanicI_MENU_EK_HAK_TANIM_ID";
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const [originalUserMenuData, setOriginalUserMenuData] = useState(null);
  const [selectedData, setSelectedData] = useState([]);
  const [userMenuDataState, setUserMenuDataState] = useState({
    sort,
    skip: 0,
    ...gridSearch,
    filter: {
      logic: "or",
      filters: [
        {
          field: "kullanicI_ID",
          operator: "eq",
          value: user,
        },
      ],
    },
  });
  const [userMenuDataLoading, setUserMenuDataLoading] = useState(false);

  useEffect(() => {
    getUsersByFilter();
  }, [userMenuDataState]);

  function getUsersByFilter() {
    setUserMenuDataLoading(true);
    UserMenuAddonsService.getByFilter(userMenuDataState, onCompletedGetData);
  }

  function onCompletedGetData(result) {
    if (result) {
      setOriginalUserMenuData(result);
      if (result.dataState) setUserMenuDataState(result.dataState);
    }
    setUserMenuDataLoading(false);
  }

  function onError() {
    setUserMenuDataLoading(false);
  }

  function onLoadingCompleted(result) {
    setUserMenuDataLoading(false);
    getUsersByFilter();
  }

  const sendData = (data) => {
    setSelectedData(data);
  };

  const btnClickHandler = () => {
    var _newData = { entities: selectedData, KullaniciID: user };
    UserMenuAddonsService.addRange(_newData, onLoadingCompleted, onError);
  };

  return (
    <Window
      title={`${userName} Ek Menü Hakları`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <LocalizationProvider language="tr">
        <IntlProvider locale="tr">
          {originalUserMenuData && (
            <MenuListBox
              selectedData={originalUserMenuData}
              onChange={sendData}
            />
          )}
          <div>
            <Button
              style={{ marginTop: "10px" }}
              color="primary"
              onClick={btnClickHandler}
            >
              Kaydet
            </Button>
          </div>
        </IntlProvider>
      </LocalizationProvider>
    </Window>
  );
}
