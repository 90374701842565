import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { API_GATEWAY, PR_FINANS } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";

class CoefficientService {
  getByCode(code, callback) {
    return axios
      .get(`${API_GATEWAY + PR_FINANS}/katsayi/get-by-kod/${code}`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
  update(data, callback) {
    return axios
      .post(`${API_GATEWAY + PR_FINANS}/katsayi/update`, data)
      .then((response) => {
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new CoefficientService();
