import React, { useState, useEffect } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

import ProductService from '../../services/srv-product/ProductService';

const ProductDropDownList = ({ 
  id, 
  label, 
  value,
  required,
  onChange
}) => {

  var pendingRequest = undefined;
  const [filteredData, setFilteredData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (value && filteredData === false) {
      var filter = [];
      filter.push({
        field: 'uruN_KODU',
        operator: 'eq',
        value: value
      });
      getProductsByFilter(filter);
    }
  }, [value, filteredData]);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setFilteredData(true);
    setDataLoading(false);
  };

  const getProductsByFilter = (filters) => {
    setDataLoading(true);
    ProductService.getProducts(
      {
        filter: { logic: 'or', filters: filters }
      },
      onCompletedGetData
    );
  };

  const productFilter = (event) => {
    if (dataLoading) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        productFilter(event);
      }, 50);
      return;
    }

    var productFilters = [];
    productFilters.push({
      field: 'uruN_KODU',
      operator: 'contains',
      value: event.filter.value
    });
    productFilters.push(event.filter);
    getProductsByFilter(productFilters);
  };

  const onFilterProduct = (event) => {
    if (event.filter.value.length > 2) {
      productFilter(event);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.uruN_KODU})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  
  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="uruN_ADI"
      dataItemKey="uruN_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterProduct(event)}
      loading={dataLoading}
      value={
        value
        ? data.find((v) => v.uruN_KODU === value)
        : ""
      }></DropDownList>
  )
}

export default ProductDropDownList;