import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function bankInstallmentReducer(
  state = initialState.bankInstallmentList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_BRY_BANK_INSTALLMENTS:
      return {
        ...state,
        bankInstallments: action.payload,
      };
    case actionTypes.GET_BRY_BANK_INSTALLMENTS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_BANK_INSTALLMENTS_FULFILLED:
      return {
        ...state,
        bankInstallments: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_BANK_INSTALLMENTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
