import React from "react";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { NumericTextBox } from "@progress/kendo-react-inputs";

const CommonNumericTextBox = (props) => {
  return (
    <LocalizationProvider language="tr">
      <IntlProvider locale="tr">
        <NumericTextBox
          label={props.label}
          name={props.id}
          id={props.id}
          style={{ width: "100%" }}
          onChange={props.onChange}
          value={props.value}
          format="n2"
          required={props.required}
          disabled={props.disabled}
          readOnly={props.readOnly}
        ></NumericTextBox>
      </IntlProvider>
    </LocalizationProvider>
  );
};

export default CommonNumericTextBox;
