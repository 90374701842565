import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./components/sys-root/App";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import "@progress/kendo-theme-material/dist/all.css";
import "alertifyjs/build/css/alertify.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./css/fontawesome-free/css/all.min.css";
import "./css/OverlayScrollbars.min.css";
import "./css/adminlte.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import "./css/index.css";

import { Provider } from "react-redux";
import configureStore from "./redux/reducers/sys-configure/configureStore";
import { BrowserRouter } from "react-router-dom";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/tr/numbers.json";
import currencies from "cldr-numbers-full/main/tr/currencies.json";
import caGregorian from "cldr-dates-full/main/tr/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/tr/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/tr/timeZoneNames.json";
import { load, loadMessages } from "@progress/kendo-react-intl";
import "./config/languageInit";
import translationTR from "../src/resources/tr/translation.json";
import translationEN from "../src/resources/en/translation.json";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import i18next from "i18next";

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames
);

loadMessages(translationTR, "tr-TR");
loadMessages(translationEN, "en-US");

const store = configureStore();
const locale = i18next.language === "tr" ? "tr-TR" : "en-US";
ReactDOM.render(
  <LocalizationProvider language={locale}>
    <IntlProvider locale={i18next.language}>
      <BrowserRouter>
        <Provider store={store}>
          <Suspense fallback="...">
            <App />
          </Suspense>
        </Provider>
      </BrowserRouter>
    </IntlProvider>
  </LocalizationProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
