import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderApprovalReducer(
  state = initialState.orderApproval,
  action
) {
  switch (action.type) {
    case actionTypes.GET_ORDER_APPROVE_LOG_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_ORDER_APPROVE_LOG_FULFILLED:
      return {
        ...state,
        approveLogs: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.GET_ORDER_APPROVE_LOG_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CALLSP_APPROVAL_HIERARCHY_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.CALLSP_APPROVAL_HIERARCHY_FULFILLED:
      return {
        ...state,
        fetching: false,
        dataUpdated: true,
      };
    case actionTypes.CALLSP_APPROVAL_HIERARCHY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        dataUpdated: false,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_QUERY:
      return {
        ...state,
        isApproval: "",
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_QUERY_PENDING:
      return {
        ...state,
        isApproval: "",
        fetching: true,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_QUERY_FULFILLED:
      return {
        ...state,
        fetching: false,
        isApproval: action.payload.data.p_onay_gerekli,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_QUERY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_CANCEL:
      return {
        ...state,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_CANCEL_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_CANCEL_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.EXECUTE_ORDER_APPROVAL_CANCEL_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
