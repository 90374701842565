import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { Component } from "react";
import { connect } from "react-redux";
import Col from "reactstrap/lib/Col";
import Row from "reactstrap/lib/Row";
import { bindActionCreators } from "redux";
import { fetchSalesDepartmant } from "../../redux/actions/act-mrc-salesDepartmant/salesDepartmantActions";
import { getChannels } from "../../redux/actions/act-bry-channel/channelActions";
import { fetchCustomerGroup } from "../../redux/actions/act-bry-customerGroup/customerGroupActions";
import { updateParticipants } from "../../redux/actions/act-bry-campaign/campaignParticipantsActions";
import { getCampaignChannels } from "../../redux/actions/act-bry-campaign/campaignChannelActions";
import { getCampaignDepartmants } from "../../redux/actions/act-bry-campaign/campaignDepartmantActions";
import { getCampaignCustomerGroup } from "../../redux/actions/act-bry-campaign/campaignCustomerGroupActions";
import {
  getCampaignDealerOptions,
  getCampaignDealers,
  updateCampaignDealers,
} from "../../redux/actions/act-bry-campaign/campaignDealerActions";
import { Button } from "reactstrap";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
} from "@progress/kendo-react-listbox";
import { Input } from "@progress/kendo-react-inputs";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class CampaignDealer extends Component {
  showDealerButton = false;
  isLoadCampaignParticipants = false;
  isLoadCampaignDealers = false;
  SELECTED_FIELD = "selected";

  state = {
    selectedDepartmants: [],
    selectedChannels: [],
    selectedCustomerGroups: [],
    filteredDealerList: [],
    selectedDealerList: [],
    salesDepartmantList: [],
    channelList: [],
    customerGroupList: [],
    filterDealer: "",
    allDealerList: [],
  };

  componentDidMount() {
    this.props.fetchSalesDepartmant();
    this.props.fetchCustomerGroup();
    this.props.getChannels();
    this.props.getCampaignChannels(this.props.code);
    this.props.getCampaignCustomerGroup(this.props.code);
    this.props.getCampaignDepartmants(this.props.code);
    this.props.getCampaignDealers(this.props.code);
    this.filterDealerList();
  }

  componentDidUpdate() {
    if (
      this.isLoadCampaignParticipants === false &&
      this.props.bryChannels.fetching === false &&
      this.props.salesDepartmantList.fetching === false &&
      this.props.customerGroupList.fetching === false &&
      this.props.campaignChannelList.fetching === false &&
      this.props.campaignDepartmantList.fetching === false &&
      this.props.campaignCustomerGroupList.fetching === false
    ) {
      this.isLoadCampaignParticipants = true;
      this.setState({
        selectedChannels: this.props.bryChannels.channels.filter((a) =>
          this.props.campaignChannelList.campaignChannels
            .map((s) => s.brY_KANAL)
            .includes(a.brY_KANAL)
        ),
        selectedDepartmants:
          this.props.salesDepartmantList.salesDepartmants.filter((a) =>
            this.props.campaignDepartmantList.campaignDepartmants
              .map((s) => s.satiS_DEPARTMANI_KODU)
              .includes(a.satiS_DEPARTMANI_KODU)
          ),
        selectedCustomerGroups:
          this.props.customerGroupList.customerGroups.filter((a) =>
            this.props.campaignCustomerGroupList.campaignCustomerGroups
              .map((s) => s.brY_MUSTERI_GRUBU)
              .includes(a.brY_MUSTERI_GRUBU)
          ),
        salesDepartmantList: this.props.salesDepartmantList.salesDepartmants,
        channelList: this.props.bryChannels.channels,
        customerGroupList: this.props.customerGroupList.customerGroups,
      });
    }

    if (
      this.props.campaignDealerList.fetching === false &&
      this.props.campaignDealerOptionList.fetching === false &&
      this.isLoadCampaignDealers === false
    ) {
      this.isLoadCampaignDealers = true;
      var dealerList =
        this.props.campaignDealerOptionList.campaignDealerOptions.filter(
          (a) =>
            !this.props.campaignDealerList.campaignDealers
              .map((s) => s.bayI_KODU)
              .includes(a.bayI_KODU)
        );
      this.setState({
        filteredDealerList: dealerList,
        allDealerList: dealerList,
        selectedDealerList:
          this.props.campaignDealerOptionList.campaignDealerOptions.filter(
            (a) =>
              this.props.campaignDealerList.campaignDealers
                .map((s) => s.bayI_KODU)
                .includes(a.bayI_KODU)
          ),
      });
    }

    if (
      this.props.campaignParticipants.dataUpdated === true &&
      this.showDealerButton === false
    ) {
      this.showDealerButton = true;
      this.isLoadCampaignDealers = false;
      this.filterDealerList();
    }
  }

  filterDealerList = () => {
    this.props.getCampaignDealerOptions(this.props.code);
  };

  onChangeDepartmant = (event) => {
    this.setState({ selectedDepartmants: event.target.value });
  };
  onChangeChannel = (event) => {
    this.setState({ selectedChannels: event.target.value });
  };
  onChangeCustomerGroup = (event) => {
    this.setState({ selectedCustomerGroups: event.target.value });
  };

  handleItemClick = (event, data, connectedData) => {
    var dataObj = {};
    dataObj[data] = this.state[data].map((item) => {
      if (item.bayI_KODU === event.dataItem.bayI_KODU) {
        item[this.SELECTED_FIELD] = !item[this.SELECTED_FIELD];
      } else if (!event.nativeEvent.ctrlKey) {
        item[this.SELECTED_FIELD] = false;
      }
      return item;
    });

    dataObj[connectedData] = this.state[connectedData].map((item) => {
      item[this.SELECTED_FIELD] = false;
      return item;
    });

    this.setState(dataObj);
  };

  handleToolBarClick = (e) => {
    var result = processListBoxData(
      this.state.filteredDealerList,
      this.state.selectedDealerList,
      e.toolName,
      this.SELECTED_FIELD
    );
    this.setState({
      filteredDealerList: result.listBoxOneData,
      selectedDealerList: result.listBoxTwoData,
    });
  };

  onSubmitParticipants = (event) => {
    var participants = {
      DepartmanList: this.state.selectedDepartmants.map(
        (a) => a.satiS_DEPARTMANI_KODU
      ),
      MusteriGrubuList: this.state.selectedCustomerGroups.map(
        (a) => a.brY_MUSTERI_GRUBU
      ),
      KanalList: this.state.selectedChannels.map((a) => a.brY_KANAL),
      kampanyaKodu: this.props.campaign._campaign.kampanyA_KODU,
    };
    this.props.updateParticipants(participants);
  };

  onSubmitDealers = (event) => {
    var dealersObj = {
      bayiKodlari: this.state.selectedDealerList.map((a) => a.bayI_KODU),
      kampanyaKodu: this.props.campaign._campaign.kampanyA_KODU,
    };
    this.props.updateCampaignDealers(dealersObj);
  };

  MyCustomItem = (props) => {
    let { dataItem, selected, ...others } = props;
    return (
      <li {...others}>
        <div>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {props.dataItem.bayI_ADI}
          </span>
          <br />
          <span> {props.dataItem.bayI_KODU}</span>
        </div>
      </li>
    );
  };

  onChangeSearch = (event) => {
    this.setState({ filterDealer: event.value });
    var dealerList = this.state.allDealerList.filter(
      (a) =>
        a.bayI_ADI.toLocaleLowerCase().includes(event.value) ||
        a.bayI_KODU.toLocaleLowerCase().includes(event.value)
    );
    this.setState({ filteredDealerList: dealerList });
  };

  render() {
    return (
      <div>
        {(this.props.campaignParticipants.fetching ||
          this.props.campaignDealerList.fetching) &&
          LoadingPanel}
        <Row>
          <Col lg="6" md="6" xs="12">
            <Row>
              <Col lg="11" md="11" xs="11">
                <div>
                  {" "}
                  {this.props.salesDepartmantList.fetching && LoadingPanel}
                  <MultiSelect
                    data={this.state.salesDepartmantList}
                    value={this.state.selectedDepartmants}
                    header={
                      <span>
                        <Button
                          type="button"
                          size="xs"
                          onClick={() => {
                            this.setState({
                              selectedDepartmants:
                                this.state.salesDepartmantList,
                            });
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          style={{ float: "right" }}
                        >
                          <i className="fas fa-check-circle"></i> Tümünü Seç
                        </Button>
                      </span>
                    }
                    onChange={this.onChangeDepartmant}
                    textField="satiS_DEPARTMANI_ADI"
                    placeholder="Lütfen Seçiniz"
                    dataItemKey="satiS_DEPARTMANI_KODU"
                    label="Katılımcı Departman"
                    autoClose={false}
                  />
                </div>
                <div>
                  {this.props.bryChannels.fetching && LoadingPanel}
                  <MultiSelect
                    data={this.state.channelList}
                    value={this.state.selectedChannels}
                    header={
                      <span>
                        <Button
                          type="button"
                          size="xs"
                          onClick={() => {
                            this.setState({
                              selectedChannels: this.state.channelList,
                            });
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          style={{ float: "right" }}
                        >
                          <i className="fas fa-check-circle"></i> Tümünü Seç
                        </Button>
                      </span>
                    }
                    onChange={this.onChangeChannel}
                    textField="brY_KANAL"
                    placeholder="Lütfen Seçiniz"
                    dataItemKey="brY_KANAL"
                    label="Katılımcı Kanal"
                    autoClose={false}
                    style={{ width: "100%" }}
                  />
                </div>
                <div>
                  {" "}
                  {this.props.customerGroupList.fetching && LoadingPanel}
                  <MultiSelect
                    data={this.state.customerGroupList}
                    value={this.state.selectedCustomerGroups}
                    header={
                      <span>
                        <Button
                          type="button"
                          size="xs"
                          onClick={() => {
                            this.setState({
                              selectedCustomerGroups:
                                this.state.customerGroupList,
                            });
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                          style={{ float: "right" }}
                        >
                          <i className="fas fa-check-circle"></i> Tümünü Seç
                        </Button>
                      </span>
                    }
                    onChange={this.onChangeCustomerGroup}
                    textField="brY_MUSTERI_GRUBU"
                    placeholder="Lütfen Seçiniz"
                    dataItemKey="brY_MUSTERI_GRUBU"
                    label="Katılımcı Müşteri Grubu"
                    autoClose={false}
                    style={{ width: "100%" }}
                  />
                </div>

                <Button
                  color="primary"
                  type="submit"
                  size="md"
                  onClick={this.onSubmitParticipants}
                >
                  Katılımcıları Kaydet
                </Button>
              </Col>
            </Row>
          </Col>
          <Col lg="6" md="6" xs="12">
            <div>
              <Row>
                <Col lg="6" md="6" xs="12">
                  <h6>Filtrelenmiş Bayi Listesi</h6>
                  <Input
                    label="Bayi arayın..."
                    name="searchDealer"
                    id="searchDealer"
                    style={{ width: "100%" }}
                    onChange={this.onChangeSearch}
                    value={this.state.filterDealer}
                  ></Input>
                  {this.props.campaignDealerOptionList.fetching && LoadingPanel}
                  <ListBox
                    style={{ height: 350, width: "100%" }}
                    data={this.state.filteredDealerList}
                    textField="bayI_ADI"
                    selectedField={this.SELECTED_FIELD}
                    item={this.MyCustomItem}
                    onItemClick={(e) =>
                      this.handleItemClick(
                        e,
                        "filteredDealerList",
                        "selectedDealerList"
                      )
                    }
                    toolbar={() => {
                      return (
                        <ListBoxToolbar
                          tools={[
                            "transferTo",
                            "transferFrom",
                            "transferAllTo",
                            "transferAllFrom",
                          ]}
                          data={this.state.filteredDealerList}
                          dataConnected={this.state.selectedDealerList}
                          onToolClick={this.handleToolBarClick}
                        />
                      );
                    }}
                  />
                </Col>
                <Col lg="6" md="6" xs="12">
                  <h6>Seçilen Bayiler</h6>
                  {this.props.campaignDealerList.fetching && LoadingPanel}
                  <ListBox
                    style={{ height: 350, width: "100%" }}
                    data={this.state.selectedDealerList}
                    textField="bayI_ADI"
                    selectedField={this.SELECTED_FIELD}
                    item={this.MyCustomItem}
                    onItemClick={(e) =>
                      this.handleItemClick(
                        e,
                        "selectedDealerList",
                        "filteredDealerList"
                      )
                    }
                  />
                  <Button
                    color="primary"
                    type="submit"
                    size="md"
                    onClick={this.onSubmitDealers}
                  >
                    Bayileri Kaydet
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    salesDepartmantList: state.salesDepartmantListReducer,
    customerGroupList: state.customerGroupListReducer,
    bryChannels: state.channelsReducer,
    campaign: state.campaignReducer,
    campaignParticipants: state.campaignParticipantsReducer,
    campaignChannelList: state.campaignChannelListReducer,
    campaignDealerList: state.campaignDealerListReducer,
    campaignDepartmantList: state.campaignDepartmantListReducer,
    campaignCustomerGroupList: state.campaignCustomerGroupListReducer,
    campaignDealerOptionList: state.campaignDealerOptionListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesDepartmant: bindActionCreators(fetchSalesDepartmant, dispatch),
    fetchCustomerGroup: bindActionCreators(fetchCustomerGroup, dispatch),
    getChannels: bindActionCreators(getChannels, dispatch),
    updateParticipants: bindActionCreators(updateParticipants, dispatch),
    getCampaignChannels: bindActionCreators(getCampaignChannels, dispatch),
    getCampaignDealers: bindActionCreators(getCampaignDealers, dispatch),
    updateCampaignDealers: bindActionCreators(updateCampaignDealers, dispatch),
    getCampaignDepartmants: bindActionCreators(
      getCampaignDepartmants,
      dispatch
    ),
    getCampaignCustomerGroup: bindActionCreators(
      getCampaignCustomerGroup,
      dispatch
    ),
    getCampaignDealerOptions: bindActionCreators(
      getCampaignDealerOptions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignDealer);
