import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useRef, useState } from "react";
import GeneralExcelTemplate from "../cmp-generalExcelUpload/GeneralExcelTemplate";
import { Col, Row } from "reactstrap";
import { Window } from "@progress/kendo-react-dialogs";
import ExcelService from "../../services/srv-excel/ExcelService";
import alertify from "alertifyjs";
import { FILE_STATUSES, IG_SENDER } from "../../config/globalVariables";
import { Upload } from "@progress/kendo-react-upload";

const ProductArtiGunExcelUpload = ({ modalToggle }) => {
  const primaryKey = "dkN_GENEL_EXCEL_UPLOAD_DATA_ID";

  const selectedSablon = "URUN_ARTI_FREE_STOCK_GUN_GUNCELLE";
  const [importedValues, setImportedValues] = useState([]);
  const [procId, setProcId] = useState(null);
  const [importedValuesDataState, setImportedValuesDataState] = useState({
    skip: 0,
    take: 10,
  });

  const _grid = useRef(null);

  function onCompletedExcelErrors(data) {
    setImportedValues(data);
  }

  function onBeforeUpload(event) {
    event.additionalData = {
      localpath: "DEX",
      excelTanimKodu: selectedSablon,
    };
  }

  function onStatusChange(event) {
    var respFile = event.affectedFiles[0];
    var status = FILE_STATUSES[respFile.status];
    var procId = null;

    if (status === "UploadFailed") {
      if (
        event.response &&
        event.response.response &&
        event.response.response.status === 400
      ) {
        procId = event.response.response.data.data.p_proc_id;
      } else {
        alertify.error("Sunucuya erişirken sorun yaşandı.");
      }
    }
    if (status === "Uploaded") {
      alertify.success("İşlem başarılı");
      procId = event.response.response.data.p_proc_id;
    }

    setProcId(procId);
    if (procId) {
      var newDataState = {
        ...importedValuesDataState,
        filter: {
          logic: "or",
          filters: [
            {
              field: "DKN_GENEL_EXCEL_UPLOAD_PROC_ID",
              operator: "eq",
              value: procId,
            },
          ],
        },
      };
      setImportedValuesDataState(newDataState);
      getExcelErrorsByFilter(newDataState);
    }
  }

  function getExcelErrorsByFilter(dataState) {
    ExcelService.getExcelErrorsByProcId(dataState, onCompletedExcelErrors);
  }

  function dataStateChange(event) {
    setImportedValuesDataState(event.dataState);
    getExcelErrorsByFilter(event.dataState);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  }

  return (
    <Window
      title={`Ürün Artı Gün Excel Yükleme`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <main style={{ flexGrow: "1" }}>
        <h2>Excel Dosya Yükleme</h2>
        <hr />
        <Row className="mb-3">
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>Parametreler</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="mt-2">
                  <Col md="3">
                    <b>Yüklenecek Dosya</b>
                  </Col>
                  <Col>
                    <Upload
                      batch={false}
                      multiple={false}
                      defaultFiles={[]}
                      withCredentials={false}
                      restrictions={{
                        maxFileSize: 50000000,
                      }}
                      saveHeaders={{
                        Authorization:
                          "Bearer " + localStorage.getItem("jwtToken"),
                      }}
                      // saveUrl={`${API_GATEWAY + MS_SENDER}/excel/v2/add`}
                      saveUrl={IG_SENDER + `/api/excel/v2/add`}
                      onBeforeUpload={onBeforeUpload}
                      onStatusChange={onStatusChange}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle>Örnek Şablon</CardTitle>
                <CardSubtitle>
                  * Excel başlıkları olmalıdır ve dosya uzantısı .xlsx
                  olmalıdır.
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <GeneralExcelTemplate sablon={selectedSablon} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div>
          {procId ? <h4>Proc Id : {procId} </h4> : ""}
          <Grid
            ref={_grid}
            data={importedValues}
            {...importedValuesDataState}
            dataItemKey={primaryKey}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [10, 20, 50, 100] }}
            resizable={true}
            sortable={true}
            filterable={false}
            onDataStateChange={dataStateChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
          >
            <GridColumn field="sonuc" title="Sonuç" width="100px"></GridColumn>
            <GridColumn
              field="proC_DATE"
              title="Aktarma Tarihi"
              width="150px"
            ></GridColumn>
            <GridColumn field="alaN1" title="ALAN 1" width="100px"></GridColumn>
            <GridColumn field="alaN2" title="ALAN 2" width="100px"></GridColumn>
            <GridColumn field="alaN3" title="ALAN 3" width="100px"></GridColumn>
            <GridColumn field="alaN4" title="ALAN 4" width="100px"></GridColumn>
            <GridColumn field="alaN5" title="ALAN 5" width="100px"></GridColumn>
            <GridColumn field="alaN6" title="ALAN 6" width="100px"></GridColumn>
            <GridColumn field="alaN7" title="ALAN 7" width="100px"></GridColumn>
            <GridColumn field="alaN8" title="ALAN 8" width="100px"></GridColumn>
            <GridColumn field="alaN9" title="ALAN 9" width="100px"></GridColumn>
          </Grid>
        </div>
      </main>
    </Window>
  );
};

export default ProductArtiGunExcelUpload;
