import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function getOrderResponsiblePersons(orderId, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_ORDER_RESPONSIBLE_PERSON,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparisyetkilikisibilgileri/get-list-by-order-id/${orderId}`
        )
        .then((result) => {
          if (callback) {
            callback(result.data);
          }
          return result.data;
        }),
    });
  };
}

export function upsertOrderResponsiblePerson(
  _orderResponsiblePerson,
  callback
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_ORDER_RESPONSIBLE_PERSON,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparisyetkilikisibilgileri/upsert-responsible-person`,
          {
            ..._orderResponsiblePerson,
          }
        )
        .then((result) => {
          if (callback) {
            callback();
          }
          return result.data;
        }),
    });
  };
}
export function deleteOrderResponsiblePerson(
  _orderResponsiblePerson,
  callback
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_ORDER_RESPONSIBLE_PERSON,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparisyetkilikisibilgileri/delete-responsible-person`,
          {
            ..._orderResponsiblePerson,
          }
        )
        .then((result) => {
          if (callback) {
            callback();
          }
          return result.data;
        }),
    });
  };
}

export function changeResponsiblePerson(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_ORDER_RESPONSIBLE_PERSON,
      payload: event,
    });
  };
}
