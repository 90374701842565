import axios from 'axios';

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';

import {
  API_GATEWAY,
  PR_DEX_BIREYSEL,
} from '../../../config/globalVariables';
import * as actionTypes from '../sys-configure/actionTypes';

/**
 * DKN_BRY_IND_1_MUSTERI_URUN listesini fetch eder
 *
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_1_MUSTERI_URUN listesi geçer
 */
export function fetchCustomerProduct(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_CUSTOMER_PRODUCT,
      payload: fetchCustomerProductPayload(dataState, callback),
    });
  };
}
export function changeCustomerProduct(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_CUSTOMER_PRODUCT,
      payload: event,
    });
  };
}

export function fetchCustomerProductPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_BIREYSEL
      }/indirimmusteriurun/get-all?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

/**
 * Yeni DKN_BRY_IND_1_MUSTERI_URUN
 *
 * @param {object} data DKN_BRY_IND_1_MUSTERI_URUN
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_1_MUSTERI_URUN listesi geçer
 */
export function insertCustomerProduct(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_CUSTOMER_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/indirimmusteriurun/add-customer-product`,
          data
        )
        .then((result) => {
          dispatch(fetchCustomerProduct(dataState, callback));
          return result.data;
        }),
    });
  };
}

/**
 * Güncelle DKN_BRY_IND_1_MUSTERI_URUN
 *
 * @param {object} data DKN_BRY_IND_1_MUSTERI_URUN
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_1_MUSTERI_URUN listesi geçer
 */
export function updateCustomerProduct(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CUSTOMER_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/indirimmusteriurun/update-customer-product`,
          data
        )
        .then((result) => {
          dispatch(fetchCustomerProduct(dataState, callback));
          return result.data.message;
        }),
    });
  };
}

/**
 * Sil DKN_BRY_IND_1_MUSTERI_URUN
 *
 * @param {number} id DKN_BRY_IND_1_MUSTERI_URUN primarkey'i {BRY_IND_1_MUSTERI_URUN_ID}
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_1_MUSTERI_URUN listesi geçer
 */
export function deleteCustomerProduct(id, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_REMOVE_CUSTOMER_PRODUCT,
      payload: axios
        .delete(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/indirimmusteriurun/delete-customer-product?id=${id}`
        )
        .then((result) => {
          dispatch(fetchCustomerProduct(dataState, callback));
          return result.data.message;
        }),
    });
  };
}
