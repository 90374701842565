import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

import axios from "axios";

export function getChannels() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CHANNELS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/kanal/get-all`)
        .then((result) => result.data),
    });
  };
}
export function getChannelsByFilter(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CHANNELS_BY_FILTER,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/kanal/get-all-by-filter?${toDataSourceRequestString(
          dataState
        )}`)
        .then((result) =>
        dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(result.data)
          : result.data),
    });
  };
}
