import { Window } from "@progress/kendo-react-dialogs";
import React, { useEffect } from "react";
import FreeStock from "../cmp-freeStock/FreeStock";

const OrderFreeStock = ({
  teklifSiparisId,
  teklifSiparisUrunId,
  toggleDialog,
}) => {
  useEffect(() => {}, []);

  return (
    <Window
      title={"Free Stock Güncelleme"}
      onClose={toggleDialog}
      initialHeight={"80%"}
      initialWidth={"80%"}
      initialLeft={(window.outerWidth - window.outerWidth * 0.8) / 2}
      initialTop={
        (window.outerHeight - window.outerHeight * 0.8) / 2 +
        document.documentElement.scrollTop
      }
      modal={true}
    >
      <FreeStock
        teklifSiparis="SIPARIS"
        teklifSiparisId={teklifSiparisId}
        teklifSiparisUrunId={teklifSiparisUrunId}
      ></FreeStock>
    </Window>
  );
};

export default OrderFreeStock;
