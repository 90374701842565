import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import BrandService from "../../services/srv-brand/BrandService";
import SectorService from "../../services/srv-brand/SectorService";
import VerticalSegmentationService from "../../services/srv-brand/VerticalSegmentationService";
import CommonInput from "../sys-common/CommonInput";
import SectorDropDownList from "../sys-common/SectorDropDownList";
import VerticalSegmentationDropDownList from "../sys-common/VerticalSegmentationDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { brandGridColumns } from "./BrandGridColumns";


const BrandList = () => {
  const gridPrimaryKey = "geneL_MARKA_TANIM_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [sectorData, setSectorData] = useState([]);
  const [originalData, setOriginalData] = useState(null);
  const [verticalSegmentationData, setVerticalSegmentationData] = useState([]);
  const [initialGridData, setInitialGridData] = useState([]);
  const [gridFilterData, setGridFilterData] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getInitialGridData();
  }, [gridFilterData]);

  function getInitialGridData() {
    setDataLoading(true);
    VerticalSegmentationService.getVerticalSegmentations(
      setVerticalSegmentationData
    );
    SectorService.getSectors(setSectorData);
    BrandService.getByFilter(gridFilterData, onCompletedGetData);
  }

  const onCompletedGetData = (data) => {
    if (data) {
      setInitialGridData(data);
      setOriginalData(data);
    }
    setDataLoading(false);
  };

  function onError() {
    setDataLoading(false);
    getInitialGridData();
  }

  function onEditCompleted() {
    setDataLoading(false);
    getInitialGridData();
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setGridFilterData(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function enterEditCommand(dataItem) {
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = initialGridData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setInitialGridData({ ...initialGridData, data });
    }
  }

  const addNewBrand = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setInitialGridData({
        ...initialGridData,
        data: [newDataItem, ...initialGridData.data],
      });
    }
  };

  const addCommand = (dataItem) => {
    setDataLoading(true);
    BrandService.create(dataItem, onEditCompleted, onError);
    dataItem.inEdit = false;
  };

  const discardCommand = () => {
    const data = [...initialGridData.data];
    data.splice(0, 1);
    setInitialGridData({ ...initialGridData, data });
  };

  const updateCommand = (dataItem) => {
    setDataLoading(true);
    BrandService.update(dataItem, onEditCompleted, onError);
    dataItem.inEdit = false;
  };

  function cancelCommand(dataItem) {
    const originalItem = originalData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = initialGridData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setInitialGridData({ ...initialGridData, data });
  }

  const CommandCell = (props) => (
    <MyCommandCellWithoutDelete
      {...props}
      edit={enterEditCommand}
      add={addCommand}
      discard={discardCommand}
      update={updateCommand}
      cancel={cancelCommand}
      editField={gridEditField}
      idField={gridPrimaryKey}
      nameField={gridPrimaryKey}
    />
  );

  function SectorCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SectorDropDownList
            id={field}
            name={field}
            required={true}
            value={dataValue}
            data={sectorData.data}
            onChange={(e) => {
              dataItem[field] = e.value.sektoR_KODU;
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }
  function BrandCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      if (dataItem.geneL_MARKA_TANIM_ID > 0) {
        return (
          <td>
            <CommonInput
              id="markA_KODU"
              name="markA_KODU"
              readOnly={true}
              value={dataValue}
            />
          </td>
        );
      } else {
        return (
          <td>
            <CommonInput
              id="markA_KODU"
              name="markA_KODU"
              readOnly={false}
              value={dataValue}
              onChange={(e) => {
                dataItem[field] = e.value;
              }}
            />
          </td>
        );
      }
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function VerticalSegmentationCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <VerticalSegmentationDropDownList
            id={field}
            name={field}
            required={true}
            value={dataValue}
            data={verticalSegmentationData.data}
            onChange={(e) => {
              dataItem[field] = e.value.pazaR_SEGMENTI_KODU;
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  const YesNoCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={field}
            name={field}
            required={true}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setInitialGridData({ ...initialGridData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  const onItemChange = (event) => {
    const data = initialGridData.data.map((item) =>
      item[gridPrimaryKey] === event.dataItem[gridPrimaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setInitialGridData({ ...initialGridData, data });
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Marka Tanım</CardTitle>
        </CardHeader>
        <CardBody>
          {dataLoading && LoadingPanel}
          <Grid
            sortable={true}
            resizable={true}
            filterable={true}
            data={initialGridData}
            {...gridFilterData}
            dataItemKey={gridPrimaryKey}
            pageable={gridPageable}
            ref={gridRef}
            editField={gridEditField}
            onItemChange={onItemChange}
            onDataStateChange={onDataStateChange}
            rowRender={(row, dataItem) => rowRender(row, dataItem)}
          >
            <GridToolbar>
              <button
                title="Yeni Kayıt"
                className="btn btn-primary btn-sm"
                onClick={addNewBrand}
              >
                Yeni Kayıt
              </button>
              <ExportExcel
                data={gridFilterData}
                columns={brandGridColumns}
                loadingFunction={setDataLoading}
                dataState={gridFilterData}
                serviceMethod={BrandService.getBrandsForExcel}
                fileName="MarkaTanim"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn cell={CommandCell} width="100px" filterable={false} />
            <GridColumn
              field="markA_KODU"
              title="MARKA KODU"
              width="256px"
              cell={BrandCell}
            />
            <GridColumn
              field="markA_ADI"
              title="MARKA ADI"
              width="256px"
              editor={"text"}
            />
            <GridColumn
              field="sektoR_KODU"
              title="SEKTÖR KODU"
              width="256px"
              cell={SectorCell}
            />
            <GridColumn
              field="pazaR_SEGMENTI_KODU"
              title="PAZAR SEGMENTİ KODU"
              width="256px"
              cell={VerticalSegmentationCell}
            />
            <GridColumn
              field="gecerli"
              title="GEÇERLİ"
              width="192px"
              cell={YesNoCell}
            />
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
};
export default BrandList;
