import React from "react";
import { Window } from "@progress/kendo-react-dialogs";
import CommonUploadFiles from "../sys-common/CommonFileUpload";

export function OrderFiles({ orderData, title, toggleDialog, fileType, auth }) {
  return (
    <Window
      title={title}
      onClose={toggleDialog}
      initialHeight={"50vh"}
      initialWidth={"40%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <CommonUploadFiles
        disabled={auth.id !== orderData.siciL_NO && auth.isAdmin === false}
        tableName={"DKN_BRY_SIPARIS"}
        tableId={orderData.brY_SIPARIS_ID}
        fileType={fileType}
      ></CommonUploadFiles>
    </Window>
  );
}
