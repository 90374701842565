import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchUserSalesRights(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USER_SALES_RIGHTS,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX
          }/KullaniciSatisHaklari/get-list-by-filter?${toDataSourceRequestString(
            dataState
          )}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function changeUserSalesRights(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_USER_SALES_RIGHTS,
      payload: event,
    });
  };
}

export function fetchUserSalesRightsAll(exports, columns) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USER_SALES_RIGHTS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/KullaniciSatisHaklari/get-all`)
        .then((result) => {
          exports.save(result.data, columns);
          dispatch(
            fetchUserSalesRights({
              take: 6,
              skip: 0,
            })
          );
        }),
    });
  };
}
