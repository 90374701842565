import React, { Component } from "react";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import filterLocalization from "../../helpers/filterLocalization";
import { getCitiesByCountryCode } from "../../redux/actions/act-city/cityActions";
import { fetchSalesDepartmant } from "../../redux/actions/act-mrc-salesDepartmant/salesDepartmantActions";
import {
  changeUserProjectResponsibility,
  deleteUserProjectResponsibility,
  fetchUserProjectResponsibility,
  fetchUserProjectResponsibilityPayload,
  insertUserProjectResponsibility,
  updateUserProjectResponsibility,
} from "../../redux/actions/act-mrc-userProjectResponsibility/userProjectResponsibilityActions";
import {
  fetchSatisTemsilcileri,
  fetchUser,
} from "../../redux/actions/act-user/userActions";

import { confirmAlert } from "react-confirm-alert";
import { Button } from "reactstrap";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import CityDropDownList from "../sys-common/CityDaikinDropDownList";
import ProvinceDropDownList from "../sys-common/ProvinceDaikinDropDownList";
import UserDropDownList from "../sys-common/UserDropDownList";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const primaryKey = "dkN_MRC_KULLANICI_PROJE_SORUMLUSU_ILCE_ID";
const editField = "inEdit";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];

class UserProjectResponsibilityList extends Component {
  isUserProjectResponsibilityList = false;
  editField = "inEdit";
  isRejectedData = false;

  state = {
    userProjectResponsibilityData: this.props.userProjectResponsibilityData,
    editData: null,
    removeData: null,
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchUserProjectResponsibility(
      event.dataState,
      this.onComplatedCallBack
    );
  };

  componentDidMount() {
    this.props.fetchSalesDepartment();
    this.props.fetchUser();
    this.getUserProjectResponsibilityList();
  }

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  export = async () => {
    if (this._export !== null) {
      var data = await fetchUserProjectResponsibilityPayload({
        ...this.props.userProjectResponsibilityList.dataState,
        skip: 0,
        take: 10000,
      });
      this._export.save(data.data.data, this._grid.columns);
    }
  };

  getUserProjectResponsibilityList = (search) => {
    var take = this.props.userProjectResponsibilityList.dataState
      ? this.props.userProjectResponsibilityList.dataState.take
      : 6;
    var skip = this.props.userProjectResponsibilityList.dataState
      ? this.props.userProjectResponsibilityList.dataState.skip
      : 0;

    this.props.fetchUserProjectResponsibility(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onComplatedCallBack
    );
  };

  itemChange = (event) => {
    const data = this.state.userProjectResponsibilityData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    this.setState({
      userProjectResponsibilityData: {
        ...this.state.userProjectResponsibilityData,
        data,
      },
    });
  };

  addNewUserProjectResponsibility = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = this.state.userProjectResponsibilityData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        userProjectResponsibilityData: {
          ...this.state.userProjectResponsibilityData,
          data: [newDataItem, ...this.state.userProjectResponsibilityData.data],
        },
      });
    }
  };

  remove = (dataItem) => {
    this.props.deleteUserProjectResponsibility(
      dataItem,
      this.props.userProjectResponsibilityList.dataState,
      this.onComplatedCallBack
    );
    this.setState({ removeData: dataItem });
  };

  checkValidate = (dataItem) => {
    return true;
  };

  add = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.insertUserProjectResponsibility(
      dataItem,
      this.props.userProjectResponsibilityList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = false;
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.userProjectResponsibilityData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.userProjectResponsibilityData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        userProjectResponsibilityData: {
          ...this.state.userProjectResponsibilityData,
          data,
        },
      });
    }
  };

  update = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.updateUserProjectResponsibility(
      dataItem,
      this.props.userProjectResponsibilityList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = undefined;
  };

  discard = () => {
    const data = [...this.state.userProjectResponsibilityData.data];
    data.splice(0, 1);
    this.setState({
      userProjectResponsibilityData: {
        ...this.state.userProjectResponsibilityData,
        data,
      },
    });
  };

  cancel = (dataItem) => {
    const originalItem =
      this.props.userProjectResponsibilityList.userProjectResponsibilities.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.userProjectResponsibilityData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      userProjectResponsibilityData: {
        ...this.state.userProjectResponsibilityData,
        data,
      },
    });
  };

  setUserProjectResponsibilityData = () => {
    this.setState({
      userProjectResponsibilityData:
        this.state.userProjectResponsibilityData.data.map((item) =>
          item[primaryKey] === this.state.editData[primaryKey]
            ? { ...item, inEdit: true }
            : item
        ),
    });
  };

  onComplatedCallBack = (data) => {
    this.setState({
      userProjectResponsibilityData: data,
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      add={this.add}
      discard={this.discard}
      update={this.update}
      remove={this.remove}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  SalesDepartmantDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.salesDepartmentList.salesDepartmants}
            required
            textField="satiS_DEPARTMANI_KODU"
            dataItemKey="mrC_SATIS_DEPARTMANI_TANIM_ID"
            loading={this.props.salesDepartmentList.fetching}
            onChange={(e) => this.salesDepartmantOnChange(e, props)}
            value={{ satiS_DEPARTMANI_KODU: dataValue }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.satiS_DEPARTMANI_KODU}</td>;
    }
  };
  salesDepartmantOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value[field];
    const data = this.state.userProjectResponsibilityData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [props.field]: value,
          }
    );
    this.setState({
      userProjectResponsibilityData: {
        ...this.state.userProjectResponsibilityData,
        data,
      },
    });
  };

  CityDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <CityDropDownList
            id="daikiN_IL_KODU"
            value={dataValue}
            filterField={"ulkE_KODU"}
            filterValue={"TR"}
            onChange={(e, props) => {
              dataItem[field] = e.value["daikiN_IL_KODU"];
              this.setState({
                userProjectResponsibilityData: {
                  ...this.state.userProjectResponsibilityData,
                },
              });
            }}
            required={true}
          />
        </td>
      );
    } else {
      return <td>{dataItem["daikiN_IL_ADI"]}</td>;
    }
  };

  ProvinceDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <ProvinceDropDownList
            id="geneL_DAIKIN_ILCE_TANIM_ID"
            value={dataValue}
            filterField={"daikiN_IL_KODU"}
            filterValue={dataItem["daikiN_IL_KODU"]}
            onChange={(e, props) => {
              dataItem[field] = e.value["geneL_DAIKIN_ILCE_TANIM_ID"];
              this.setState({
                userProjectResponsibilityData: {
                  ...this.state.userProjectResponsibilityData,
                },
              });
            }}
            required={true}
          />
        </td>
      );
    } else {
      return <td>{dataItem["daikiN_ILCE_ADI"]}</td>;
    }
  };

  UserDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <UserDropDownList
            id="kullanicI_ID"
            value={dataValue}
            onChange={(e, props) => {
              dataItem[field] = e.value["kullanicI_ID"];
              this.setState({
                userProjectResponsibilityData: {
                  ...this.state.userProjectResponsibilityData,
                },
              });
            }}
            required={true}
            role="satis-temsilcisi"
          />
        </td>
      );
    } else {
      return <td>{dataItem["kullanicI_AD_SOYAD"]}</td>;
    }
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Proje Sorumlusu İlçe Düzenleme</h2>
        {this.props.userProjectResponsibilityList.fetching && LoadingPanel}
        <ExcelExport
          data={
            this.props.userProjectResponsibilityList.userProjectResponsibilities
              ?.data
          }
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.userProjectResponsibilityData}
            onDataStateChange={this.dataStateChange}
            {...this.props.userProjectResponsibilityList.dataState}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            onItemChange={this.itemChange}
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
            filterable
            dataItemKey={primaryKey}
            editField={editField}
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={this.addNewUserProjectResponsibility}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
            </GridToolbar>
            <GridColumn
              cell={this.CommandCell}
              width="100px"
              filterable={false}
            />
            <GridColumn
              field={primaryKey}
              title="Id"
              width="100px"
              filter={"numeric"}
              editable={false}
            ></GridColumn>

            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              textField="satiS_DEPARTMANI_KODU"
              title="Departman Kodu"
              width="180px"
              cell={this.SalesDepartmantDropDown}
            ></GridColumn>
            <GridColumn
              field="daikiN_IL_KODU"
              title="Sehir"
              width="200px"
              cell={this.CityDropDown}
            ></GridColumn>
            <GridColumn
              field="geneL_DAIKIN_ILCE_TANIM_ID"
              title="Ilce"
              width="200px"
              cell={this.ProvinceDropDown}
            ></GridColumn>
            <GridColumn
              field="kullanicI_ID"
              textField="kullanicI_AD_SOYAD"
              title="Kullanici"
              width="250px"
              cell={this.UserDropDown}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    userProjectResponsibilityList: state.userProjectResponsibilityListReducer,
    salesDepartmentList: state.salesDepartmantListReducer,
    cityList: state.cityListReducer,
    userList: state.userListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserProjectResponsibility: bindActionCreators(
      fetchUserProjectResponsibility,
      dispatch
    ),
    changeUserProjectResponsibility: bindActionCreators(
      changeUserProjectResponsibility,
      dispatch
    ),
    insertUserProjectResponsibility: bindActionCreators(
      insertUserProjectResponsibility,
      dispatch
    ),
    updateUserProjectResponsibility: bindActionCreators(
      updateUserProjectResponsibility,
      dispatch
    ),
    deleteUserProjectResponsibility: bindActionCreators(
      deleteUserProjectResponsibility,
      dispatch
    ),
    fetchSalesDepartment: bindActionCreators(fetchSalesDepartmant, dispatch),
    getCitiesByCountryCode: bindActionCreators(
      getCitiesByCountryCode,
      dispatch
    ),
    fetchUser: bindActionCreators(fetchUser, dispatch),
    fetchSatisTemsilcileri: bindActionCreators(
      fetchSatisTemsilcileri,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProjectResponsibilityList);
