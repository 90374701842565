import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class DealerService {
  getDealers(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/bayi/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getDealerCodeList(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX}/bayi/get-all`).then((result) => {
      if (callback) {
        callback(result);
      }
      return result;
    });
  }
}

export default new DealerService();
