import * as actionTypes from "../../actions/sys-configure/actionTypes";

export function setLogin(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN,
      payload: event,
    });
  };
}

export function resetLogin(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_LOGIN,
      payload: event,
    });
  };
}
