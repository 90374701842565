import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function campaignDepartmantListReducer(
  state = initialState.bryCampaignDepartmantList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_BRY_CAMPAIGN_DEPARTMANTS:
      return {
        ...state,
        campaignDepartmants: action.payload,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEPARTMANTS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEPARTMANTS_FULFILLED:
      return {
        ...state,
        campaignDepartmants: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEPARTMANTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
