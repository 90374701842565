import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function getCampaignDealers(kampanyaKodu) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGN_DEALERS,
      payload: kampanyaKodu
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/kampanyakatilimcibayi/get-list-by-kampanya/${kampanyaKodu}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function getCampaignDealersByBayiKodu(bayiKodu) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGN_DEALERS,
      payload: bayiKodu
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/kampanyakatilimcibayi/get-list-by-bayi/${bayiKodu}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function getCampaignDealerOptions(kampanyaKodu) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGN_DEALER_OPTIONS,
      payload: kampanyaKodu
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/kampanyakatilimcibayisecim/get-list-by-kampanya/${kampanyaKodu}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function updateCampaignDealers(entity) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_CAMPAIGN_DEALERS,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/kampanyakatilimcibayi/update-dealers`,
          {
            ...entity,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}
