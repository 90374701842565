import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Fragment, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  DATAFIRMAKIMLIGI,
  DATAMUSFIRMATIPI,
  DATAMUSTIPI,
  DATAPRODURUMU,
  DATAPROTIPI,
  DATASERVISTIPI,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import CustomerSalesDepartmentService from "../../services/srv-customerSalesDepartment/CustomerSalesDepartmentService";
import CustomerDropDownList from "../sys-common/CustomerDropDownList";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import GridRowRenderDefault from "../sys-toolbox/GridRowRenderDefault";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export function CustomerSalesDepartment({ musKodu }) {
  const exportRef = useRef(null);
  const gridRef = useRef(null);

  const [customerSalesDepartmentsLoading, setCustomerSalesDepartmentsLoading] =
    useState(false);
  const [orginalCustomerSalesDepartments, setOriginalCustomerSalesDepartments] =
    useState([]);
  const [customerSalesDepartments, setCustomerSalesDepartments] = useState([]);
  const [
    customerSalesDepartmentsDataState,
    setCustomerSalesDepartmentsDataState,
  ] = useState({
    take: 100,
    skip: 0,
    filter: {
      logic: "or",
      filters: [
        {
          field: "muS_KODU",
          operator: "eq",
          value: musKodu,
        },
      ],
    },
  });

  useEffect(() => {
    getCustomerSalesDepartments();
  }, []);

  function onDataStateChange(event) {
    filterLocalization(event);
    setCustomerSalesDepartmentsDataState(event.dataState);
    getCustomerSalesDepartments();
  }

  function onGetCustomerSalesDepartmentsCompleted(data) {
    if (data) {
      setCustomerSalesDepartments(data);
      setOriginalCustomerSalesDepartments(data);
    }
    setCustomerSalesDepartmentsLoading(false);
  }

  function getCustomerSalesDepartments() {
    setCustomerSalesDepartmentsLoading(true);
    CustomerSalesDepartmentService.getCustomerSalesDepartments(
      customerSalesDepartmentsDataState,
      onGetCustomerSalesDepartmentsCompleted
    );
  }

  function onCommandCompleted() {
    setCustomerSalesDepartmentsLoading(false);
  }

  // Command Cell Functions
  function enterEditCommand(dataItem) {
    var isOpenEdit = customerSalesDepartments.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = customerSalesDepartments.data.map((item) =>
        item["muS_SATIS_DEPARTMANI_DET_ID"] ===
        dataItem["muS_SATIS_DEPARTMANI_DET_ID"]
          ? { ...item, inEdit: true }
          : item
      );
      setCustomerSalesDepartments({ ...customerSalesDepartments, data });
    }
  }
  function addCommand(dataItem) {
    setCustomerSalesDepartmentsLoading(true);
    CustomerSalesDepartmentService.insert(dataItem, onCommandCompleted);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...customerSalesDepartments.data];
    data.splice(0, 1);
    setCustomerSalesDepartments({ ...customerSalesDepartments, data });
  }
  function updateCommand(dataItem) {
    setCustomerSalesDepartmentsLoading(true);
    CustomerSalesDepartmentService.update(dataItem, onCommandCompleted);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = orginalCustomerSalesDepartments.data.find(
      (p) =>
        p["muS_SATIS_DEPARTMANI_DET_ID"] ===
        dataItem["muS_SATIS_DEPARTMANI_DET_ID"]
    );
    const data = customerSalesDepartments.data.map((item) =>
      item["muS_SATIS_DEPARTMANI_DET_ID"] ===
      originalItem["muS_SATIS_DEPARTMANI_DET_ID"]
        ? originalItem
        : item
    );

    setCustomerSalesDepartments({ ...customerSalesDepartments, data });
  }
  function CommandCell(props) {
    return (
      <MyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={"inEdit"}
        idField={"muS_SATIS_DEPARTMANI_DET_ID"}
        nameField={"muS_SATIS_DEPARTMANI_DET_ID"}
      />
    );
  }

  function FirmaTipiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATAMUSFIRMATIPI}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATAMUSFIRMATIPI.find((a) => a.id === dataValue)}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function MusTipiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var disabled =
      dataItem["satiS_DEPARTMANI_KODU"] === "VRV" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "REFRIGERATION" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "APP" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "EXPORT_ESG" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "EXPORT_UPG" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "SSHD";
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATAMUSTIPI}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATAMUSTIPI.find((a) => a.id === dataValue)}
            disabled={disabled}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function UstMusteriCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var disabled =
      dataItem["satiS_DEPARTMANI_KODU"] === "VRV" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "REFRIGERATION" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "APP" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "EXPORT_ESG" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "EXPORT_UPG" ||
      dataItem["satiS_DEPARTMANI_KODU"] === "SSHD";
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomerDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.muS_KODU;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            disabled={disabled}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function ServisTipiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATASERVISTIPI}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATASERVISTIPI.find((a) => a.id === dataValue)}
            disabled={dataItem["satiS_DEPARTMANI_KODU"] !== "SSHD"}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function ProTipiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATAPROTIPI}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATAPROTIPI.find((a) => a.id === dataValue)}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function FirmaKimligiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATAFIRMAKIMLIGI}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATAFIRMAKIMLIGI.find((a) => a.id === dataValue)}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function ProDurumuCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            name={field}
            id={field}
            data={DATAPRODURUMU}
            textField="text"
            dataItemKey="id"
            style={{ width: "100%" }}
            onChange={(e) => {
              dataItem[field] = e.value.id;
              setCustomerSalesDepartments({ ...customerSalesDepartments });
            }}
            value={DATAPRODURUMU.find((a) => a.id === dataValue)}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function exportExcel() {
    exportRef.current.save(customerSalesDepartments, gridRef.current.columns);
  }

  return (
    <Fragment>
      {customerSalesDepartmentsLoading && LoadingPanel}
      <ExcelExport data={customerSalesDepartments} ref={exportRef}>
        <Grid
          ref={gridRef}
          data={customerSalesDepartments}
          {...customerSalesDepartmentsDataState}
          onDataStateChange={onDataStateChange}
          sortable={true}
          resizable={true}
          filterable={false}
          style={{ fontSize: "11px", marginBottom: "32px" }}
          pageable={false}
          rowRender={(trElement, dataItem) =>
            GridRowRenderDefault(trElement, dataItem)
          }
        >
          <GridToolbar>
            <button
              title="Excel'e Kaydet"
              className="btn btn-success btn-sm"
              onClick={exportExcel}
            >
              Excel'e Kaydet
            </button>
          </GridToolbar>
          <GridColumn cell={CommandCell} width="100px" filterable={false} />
          <GridColumn
            field="satiS_DEPARTMANI_KODU"
            title="Satış Dep. Kodu"
            width="192px"
            filterable={false}
            editable={false}
          />
          <GridColumn
            field="firmA_TIPI"
            title="Firma Tipi"
            width="192px"
            filterable={false}
            cell={FirmaTipiCell}
          />
          <GridColumn
            field="usT_MUS_KODU"
            title="Üst Müşteri Kodu"
            width="320px"
            filterable={false}
            cell={UstMusteriCell}
          />
          <GridColumn
            field="muS_TIPI"
            title="Bayi Tipi"
            width="192px"
            filterable={false}
            cell={MusTipiCell}
          />
          <GridColumn
            field="serviS_TIPI"
            title="Servis Tipi"
            width="192px"
            filterable={false}
            cell={ServisTipiCell}
          />
          <GridColumn
            field="prO_TIPI"
            title="Pro Tipi"
            width="192px"
            filterable={false}
            cell={ProTipiCell}
          />
          <GridColumn
            field="firmA_KIMLIGI"
            title="Firma Kimliği"
            width="192px"
            filterable={false}
            cell={FirmaKimligiCell}
          />
          <GridColumn
            field="prO_DURUMU"
            title="Pro Durumu"
            width="192px"
            filterable={false}
            cell={ProDurumuCell}
          />
        </Grid>
      </ExcelExport>
    </Fragment>
  );
}
