import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function campaignDealerListReducer(
  state = initialState.bryCampaignDealerList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_BRY_CAMPAIGN_DEALERS:
      return {
        ...state,
        campaignDealers: action.payload,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEALERS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEALERS_FULFILLED:
      return {
        ...state,
        campaignDealers: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_CAMPAIGN_DEALERS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    case actionTypes.UPDATE_CAMPAIGN_DEALERS_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_CAMPAIGN_DEALERS_FULFILLED:
      return {
        ...state,
        fetching: false,
        dataUpdated: true,
      };
    case actionTypes.UPDATE_CAMPAIGN_DEALERS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        dataUpdated: false,
      };

    default:
      return state;
  }
}
