import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import {
  getConfirmErrorMessage,
  defaultAlertMessages,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import { API_GATEWAY, PR_MAHSUPLASMA } from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";

class OffsetService {
  getOffsets(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_MAHSUPLASMA
        }/mahsup/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) {
          response.data.dataState = dataState;
          callback(response.data);
        }
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  matchOffset(data, callback) {
    return axios
      .post(`${API_GATEWAY + PR_MAHSUPLASMA}/mahsup/match-offset`, data)
      .then((response) => {
        if (callback) callback(response);
        defaultAlertMessages.MessageDetailSuccess = response.data;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }

  clearOffset(data, callback) {
    return axios
      .post(`${API_GATEWAY + PR_MAHSUPLASMA}/mahsup/clear-offset`, data)
      .then((response) => {
        if (callback) callback(response);
        defaultAlertMessages.MessageDetailSuccess = response.data;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }

  getByOffsetCode(code, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_MAHSUPLASMA}/mahsup/get-list-by-mahsupKodu/${code}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getByOffsetCodeByDA(code, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_MAHSUPLASMA}/mahsup/get-da-by-mahsupKodu/${code}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getDetailByOffsetCode(code, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_MAHSUPLASMA
        }/mahsup/get-detail-by-mahsupKodu/${code}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getDetailByDealerAndTerm(bayiKodu, donemKodu, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_MAHSUPLASMA
        }/mahsup/get-detail-by-dealer-and-term/${bayiKodu}/${donemKodu}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new OffsetService();
