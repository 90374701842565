import { API_GATEWAY, MS_USERSDEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function getUserSalesRegions() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_USER_SALES_REGIONS,
      payload: axios
        .get(
          `${
            API_GATEWAY + MS_USERSDEX
          }/kullanicisatisbolge/get-list-by-kullanici`
        )
        .then((result) => result.data),
    });
  };
}
