import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { BASE_URL } from "../../../config/globalVariables";
import OfferDenvService from "../../../services/mercury/srv-offer-denv/OfferDenvService";
import filterLocalization from "../../../helpers/filterLocalization";
import ExportExcel from "../../sys-toolbox/ExcelExport";
import GridRowRenderDefault from "../../sys-toolbox/GridRowRenderDefault";
import LoadingPanel from "../../sys-toolbox/LoadingPanel";
import { offerDenvGridColumns } from "../../sys-toolbox/grid-columns/OfferDenvGridColumns";
import { DateFormatterLocale } from "../../sys-toolbox/intl-format/DateFormatterLocale";
import { DateTimeFormatterLocale } from "../../sys-toolbox/intl-format/DateTimeFormatterLocale";
import { NumberFormatterLocale } from "../../sys-toolbox/intl-format/NumberFormatterLocale";

function OfferDenvList() {
  const { t, i18n } = useTranslation();
  const gridRef = useRef(null);
  const [isMobile, setIsMobile] = useState(true);
  const [offersDenv, setOffersDenv] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offersDenvDataState, setOffersDenvDataState] = useState({
    take: 10,
    skip: 0,
  });

  useEffect(() => {
    let initilWidth = gridRef.current.element.clientWidth;
    if (initilWidth < 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    window.addEventListener("resize", (event) => {
      let gridWidth = gridRef.current.element.clientWidth;
      if (gridWidth < 700) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);

  useEffect(() => {
    getDataByFilter();
  }, [offersDenvDataState]);

  function getDataByFilter() {
    setIsLoading(true);
    OfferDenvService.getByFilter(offersDenvDataState, onCompletedOfferDenvList);
  }

  function onCompletedOfferDenvList(result) {
    if (result) {
      setOffersDenv(result);
      if (result.dataState) setOffersDenvDataState(result.dataState);
    }
    setIsLoading(false);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setOffersDenvDataState(event.dataState);
  }

  const GridRowRenderResponsive = (tr, props) => {
    if (isMobile) {
      let tdContent = props.children.map((td) => {
        return td;
      });
      return (
        <tr>
          <td colSpan={props.children.length}>{tdContent}</td>
        </tr>
      );
    }
    return GridRowRenderDefault(tr, props.dataItem);
  };

  const GridCellRenderResponsive = (td, props) => {
    let column = offerDenvGridColumns.find((a) => a.field === props.field);
    let value = cellRenderByType(column.cellType, td.props.children);
    if (isMobile) {
      return (
        <React.Fragment>
          <b>{t(column.title)}: </b> {value} <br />
        </React.Fragment>
      );
    }
    return <td title={value}>{value}</td>;
  };

  function cellRenderByType(type, value) {
    if (value === null || value === undefined || value === "") {
      return "";
    }
    if (type === "date") {
      return DateFormatterLocale(value);
    } else if (type === "datetime") {
      return DateTimeFormatterLocale(value);
    } else if (type === "numeric") {
      return NumberFormatterLocale(value);
    } else {
      return value;
    }
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>{t("offer.list")}</CardTitle>
        </CardHeader>
        <CardBody>
          {isLoading && LoadingPanel}
          <Grid
            ref={gridRef}
            sortable={true}
            resizable={true}
            filterable={true}
            data={offersDenv}
            {...offersDenvDataState}
            dataItemKey={"mrC_TEKLIF_DENV_ONAY_ID"}
            pageable={{ buttonCount: 3, pageSizes: [10, 20, 50, 100] }}
            onDataStateChange={onDataStateChange}
            rowRender={GridRowRenderResponsive}
            cellRender={GridCellRenderResponsive}
            className={isMobile ? "mobile-grid" : ""}
            style={{ minHeight: "500px" }}
          >
            <GridToolbar>
              <ExportExcel
                data={offersDenv}
                columns={offerDenvGridColumns}
                loadingFunction={setIsLoading}
                dataState={offersDenvDataState}
                serviceMethod={OfferDenvService.getOfferDenvListForExcel}
                fileName={t("offer.list_file_name")}
              ></ExportExcel>
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="120px"
              cell={(props) => (
                <td>
                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={() => {
                      window.location.href =
                        BASE_URL +
                        "offer-cis-approval/" +
                        props.dataItem.mrC_TEKLIF_DENV_ONAY_ID;
                    }}
                  >
                    <span class="k-icon k-i-info"></span> {t("detail")}
                  </Button>
                </td>
              )}
            />
            {offerDenvGridColumns.map((column) => {
              return (
                <GridColumn
                  field={column.field}
                  title={t(column.title)}
                  width={column.width}
                  cell={column.cell}
                />
              );
            })}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}

export default OfferDenvList;
