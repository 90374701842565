import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function channelsByFilterReducer(
  state = initialState.filteredChannelList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_BRY_CHANNELS_BY_FILTER:
      return {
        ...state,
        channels: action.payload,
      };
    case actionTypes.GET_BRY_CHANNELS_BY_FILTER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_CHANNELS_BY_FILTER_FULFILLED:
      return {
        ...state,
        channels: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_CHANNELS_BY_FILTER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
