import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function excelReducer(
  state = initialState.excelSettings,
  action
) {
  switch (action.type) {
    case actionTypes.CHANGE_EXCEL_VISIBILITY:
      return {
        ...state,
        errorVisibilty: action.payload.visibilty,
      };
    case actionTypes.CHANGE_EXCEL_VISIBILITY_DATA:
      return {
        ...state,
        errorVisibilty: action.payload.visibilty,
        errorProcId: action.payload.data.p_proc_id,
        errorColumns: action.payload.data.p_columns,
      };
    default:
      return state;
  }
}