import React, { Component } from "react";

import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";

import { URUN_SEGMENT } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getGridFilterProperties,
} from "../../helpers/utils";
import {
  fetchProductCategory,
  fetchProductGroup,
} from "../../redux/actions/act-bry-campaignProduct/campaignProductActions";
import { getChannelsByFilter } from "../../redux/actions/act-bry-channel/channelActions";
import { fetchCustomerGroup } from "../../redux/actions/act-bry-customerGroup/customerGroupActions";
import {
  chanceSalesChannelProductGroup,
  deleteSalesChannelProductGroup,
  fetchSalesChannelProductGroup,
  fetchSalesChannelProductGroupPayload,
  insertSalesChannelProductGroup,
  updateSalesChannelProductGroup,
} from "../../redux/actions/act-bry-discount/salesChannelProductGroupActions";
import { getSalesChannelsByFilter } from "../../redux/actions/act-bry-salesChannel/salesChannelActions";
import { getDealers } from "../../redux/actions/act-dealer/dealerActions";
import { fetchSalesDepartmant } from "../../redux/actions/act-mrc-salesDepartmant/salesDepartmantActions";
import { fetchProductByFilter } from "../../redux/actions/act-product/productActions";
import CustomAutoComplateForCell from "../sys-common/CustomAutoComplateForCell";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const primaryKey = "brY_IND_6_SATIS_KANALI_URUN_GRUBU_ID";
const editField = "inEdit";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];

class SalesChannelProductGroupList extends Component {
  editField = "inEdit";

  state = {
    salesChannelProductGroupData: this.props.salesChannelProductGroupData,
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchSalesChannelProductGroup(
      event.dataState,
      this.onComplatedCallBack
    );
  };
  componentDidMount() {
    this.props.fetchSalesDepartmant();
    this.props.getChannelsByFilter({ skip: 0, take: 1000 });
    this.getList();
    this.props.fetchProductCategory();
    this.props.fetchCustomerGroup();
  }

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  export = async () => {
    if (this._export !== null) {
      var data = await fetchSalesChannelProductGroupPayload({
        ...this.props.salesChannelProductGroupList.dataState,
        skip: 0,
        take: 10000,
      });
      this._export.save(data.data.data, this._grid.columns);
    }
  };

  getList = (search) => {
    var take = this.props.salesChannelProductGroupList.dataState
      ? this.props.salesChannelProductGroupList.dataState.take
      : 6;
    var skip = this.props.salesChannelProductGroupList.dataState
      ? this.props.salesChannelProductGroupList.dataState.skip
      : 0;

    this.props.fetchSalesChannelProductGroup(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onComplatedCallBack
    );
  };

  onComplatedCallBack = (data) => {
    this.setState({
      salesChannelProductGroupData: data,
    });
    console.log("callback çalışıyor");
  };

  itemChange = (event) => {
    const data = this.state.salesChannelProductGroupData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    this.setState({
      salesChannelProductGroupData: {
        ...this.state.salesChannelProductGroupData,
        data,
      },
    });
  };
  addNew = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    var isOpenEdit = this.state.salesChannelProductGroupData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        salesChannelProductGroupData: {
          ...this.state.salesChannelProductGroupData,
          data: [newDataItem, ...this.state.salesChannelProductGroupData.data],
        },
      });
    }
  };

  remove = (dataItem) => {
    this.props.deleteSalesChannelProductGroup(
      dataItem,
      this.props.salesChannelProductGroupList.dataState,
      this.onComplatedCallBack
    );
  };

  checkValidate = (dataItem) => {
    if (dataItem.indiriM_ORANI < 0 || dataItem.indiriM_ORANI > 100) {
      defaultAlertMessages.MessageDetailError =
        "İndirim oranı 0 ile 100 arasında olmalıdır.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      return false;
    }

    if (
      !dataItem.satiS_DEPARTMANI_KODU ||
      !dataItem.brY_KANAL ||
      typeof dataItem.indiriM_ORANI !== "number" ||
      !dataItem.brY_URUN_GRUBU ||
      !dataItem.brY_MARKA_SEGMENT ||
      !dataItem.brY_URUN_KATEGORI
    ) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen gerekli alanları doldurunuz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
      return false;
    }
    return true;
  };

  add = (dataItem) => {
    console.log(dataItem);
    if (!this.checkValidate(dataItem)) return;
    this.props.insertSalesChannelProductGroup(
      dataItem,
      this.props.salesChannelProductGroupList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = false;
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.salesChannelProductGroupData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.salesChannelProductGroupData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        salesChannelProductGroupData: {
          ...this.state.salesChannelProductGroupData,
          data,
        },
      });
    }
  };

  update = (dataItem) => {
    console.log(dataItem);
    if (!this.checkValidate(dataItem)) return;
    this.props.updateSalesChannelProductGroup(
      dataItem,
      this.props.salesChannelProductGroupList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = undefined;
    console.log(dataItem);
  };

  discard = () => {
    const data = [...this.state.salesChannelProductGroupData.data];
    data.splice(0, 1);
    this.setState({
      salesChannelProductGroupData: {
        ...this.state.salesChannelProductGroupData,
        data,
      },
    });
  };

  cancel = (dataItem) => {
    console.log(this.props.salesChannelProductGroupList);
    const originalItem =
      this.props.salesChannelProductGroupList.salesChannelProductGroups.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.salesChannelProductGroupData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      salesChannelProductGroupData: {
        ...this.state.salesChannelProductGroupData,
        data,
      },
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      add={this.add}
      discard={this.discard}
      update={this.update}
      remove={this.remove}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  productAutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const onChange = (e, k) => {
      props.onChange(e);
      const {
        brY_URUN_KATEGORI,
        brY_URUN_GRUBU,
        brY_MARKA_SEGMENT,
        uruN_KODU,
        uruN_ADI,
      } = k;
      const data = this.state.salesChannelProductGroupData.data.map((item) =>
        !item.inEdit
          ? item
          : {
              ...item,
              brY_URUN_KATEGORI,
              brY_URUN_GRUBU,
              brY_MARKA_SEGMENT,
              uruN_KODU,
              uruN_ADI,
            }
      );
      this.setState({
        salesChannelProductGroupData: {
          ...this.state.salesChannelProductGroupData,
          data,
        },
      });
    };
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomAutoComplateForCell
            cellProps={{ ...props, onChange }}
            action={fetchProductByFilter}
            listName="filteredProducts"
            reducerName="productFilterListReducer"
            secondFieldName="uruN_ADI"
          />
        </td>
      );
    } else {
      return (
        <td>
          {dataValue} ({dataItem.uruN_ADI})
        </td>
      );
    }
  };

  salesDepartmantDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const defaultItem = this.props.salesDepartmantList.salesDepartmants?.find(
      (d) => d.satiS_DEPARTMANI_KODU === dataValue
    );
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.salesDepartmantList.salesDepartmants}
            required
            textField="satiS_DEPARTMANI_KODU"
            dataItemKey="mrC_SATIS_DEPARTMANI_TANIM_ID"
            loading={this.props.salesDepartmantList.fetching}
            onChange={(e) => this.salesDepartmantOnChange(e, props)}
            def
            value={defaultItem}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };
  productCategoryDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const defaultItem = this.props.productCategoryList.productCategories.find(
      (d) => d.brY_URUN_KATEGORI === dataValue
    );
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.productCategoryList.productCategories}
            textField="brY_URUN_KATEGORI_ACIKLAMA"
            dataItemKey="brY_URUN_KATEGORI_TANIM_ID"
            required
            loading={this.props.productCategoryList.fetching}
            onChange={(e) => {
              this.props.fetchProductGroup(e.value.brY_URUN_KATEGORI);
              props.onChange({
                dataItem: props.dataItem,
                field,
                syntheticEvent: props.syntheticEvent,
                value: e.value[field],
              });
              const data = this.state.salesChannelProductGroupData.data.map(
                (item) =>
                  !item.inEdit
                    ? item
                    : {
                        ...item,
                        [field]: e.value[field],
                        brY_URUN_GRUBU: undefined,
                      }
              );
              this.setState({
                salesChannelProductGroupData: {
                  ...this.state.salesChannelProductGroupData,
                  data,
                },
              });
            }}
            value={defaultItem}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  productGroupDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.productGroupList.productGroups}
            required
            loading={this.props.productGroupList.productGroups.fetching}
            onChange={(e) => {
              props.onChange({
                dataItem: props.dataItem,
                field,
                syntheticEvent: props.syntheticEvent,
                value: e.value[field],
              });
            }}
            textField="brY_URUN_GRUBU_ACIKLAMA"
            dataItemKey="brY_URUN_GRUBU_TANIM_ID"
            value={{ brY_URUN_GRUBU_ACIKLAMA: dataValue }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  customerGroupDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.customerGroupList.customerGroups}
            required
            loading={this.props.customerGroupList.customerGroups.fetching}
            onChange={(e) => {
              props.onChange({
                dataItem: props.dataItem,
                field,
                syntheticEvent: props.syntheticEvent,
                value: e.value[field],
              });
            }}
            textField="brY_MUSTERI_GRUBU"
            dataItemKey="brY_MUSTERI_GRUBU_ID"
            value={{
              brY_MUSTERI_GRUBU: dataValue,
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  productSegmentDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={URUN_SEGMENT}
            required
            onChange={(e) => {
              props.onChange({
                dataItem: props.dataItem,
                field,
                syntheticEvent: props.syntheticEvent,
                value: e.value,
              });
            }}
            defaultValue={dataValue}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  salesDepartmantOnChange = (e, props) => {
    const { field } = props;
    const value = e.value[field];
    props.onChange({
      dataItem: props.dataItem,
      field,
      syntheticEvent: props.syntheticEvent,
      value,
    });
    this.props.getSalesChannelsByFilter(value);
    const data = this.state.salesChannelProductGroupData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [field]: value,
            brY_KANAL: undefined,
          }
    );
    this.setState({
      salesChannelProductGroupData: {
        ...this.state.salesChannelProductGroupData,
        data,
      },
    });
  };

  ProductAutoComleteCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    const onChange = (e, k) => {
      props.onChange(e);
      const {
        brY_URUN_KATEGORI,
        brY_URUN_GRUBU,
        brY_MARKA_SEGMENT,
        uruN_KODU,
        uruN_ADI,
      } = k;
      const data = this.state.salesChannelProductGroupData.data.map((item) =>
        !item.inEdit
          ? item
          : {
              ...item,
              brY_URUN_KATEGORI,
              brY_URUN_GRUBU,
              brY_MARKA_SEGMENT,
              uruN_KODU,
              uruN_ADI,
            }
      );
      this.setState({
        salesChannelProductGroupData: {
          ...this.state.salesChannelProductGroupData,
          data,
        },
      });
    };
    if (dataItem.inEdit) {
      return (
        <td>
          <CustomAutoComplateForCell
            cellProps={{ ...props, onChange }}
            action={fetchProductByFilter}
            listName="filteredProducts"
            reducerName="productFilterListReducer"
            secondFieldName="uruN_ADI"
          />
        </td>
      );
    } else {
      return (
        <td>
          {dataValue} ({dataItem.uruN_ADI})
        </td>
      );
    }
  };

  ChannelDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.props.salesChannelList.salesChannels.data}
            required
            textField="brY_KANAL"
            dataItemKey="brY_SATIS_KANALI_TANIM_ID"
            value={{
              brY_KANAL: dataValue,
            }}
            loading={this.props.salesChannelList.fetching}
            onChange={(e) => {
              var satisKanalKodu =
                this.props.salesChannelList.salesChannels.data.find(
                  (d) => d.brY_KANAL === e.value[field]
                );
              const data = this.state.salesChannelProductGroupData.data.map(
                (item) =>
                  !item.inEdit
                    ? item
                    : {
                        ...item,
                        brY_SATIS_KANALI_KODU:
                          satisKanalKodu?.brY_SATIS_KANALI_KODU,
                        brY_KANAL: e.value[field],
                      }
              );
              this.setState({
                salesChannelProductGroupData: {
                  ...this.state.salesChannelProductGroupData,
                  data,
                },
              });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  };

  onChangeSearch = (event) => {
    var searchFilter = Object.assign(
      {},
      this.props.salesChannelProductGroupList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );
    this.isLoadcustomerProductGroupData = false;
    this.getList(searchFilter);
  };
  render() {
    return (
      <React.Fragment>
        {this.props.salesChannelProductGroupList.fetching && LoadingPanel}
        <ExcelExport
          data={
            this.props.salesChannelProductGroupList.salesChannelProductsGroups
              ?.data
          }
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.salesChannelProductGroupData}
            onDataStateChange={this.dataStateChange}
            {...this.props.salesChannelProductGroupList.dataState}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            onItemChange={this.itemChange}
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
            filterable
            dataItemKey={primaryKey}
            editField={editField}
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={this.addNew}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              {/* <Input
                    name="search"
                    label="Hızlı arama için enter'a basınız"
                    style={{ width: "40%", float: "right" }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        this.onChangeSearch(event);
                      }
                    }}
                  ></Input> */}
            </GridToolbar>
            <GridColumn
              cell={this.CommandCell}
              width="100px"
              filterable={false}
            />
            <GridColumn
              field={primaryKey}
              title="Id"
              width="100px"
              filter={"numeric"}
              editable={false}
            ></GridColumn>
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="Departman Kodu"
              width="120px"
              cell={this.salesDepartmantDropDown}
            ></GridColumn>
            <GridColumn
              field="brY_KANAL"
              editable={false}
              title="Kanal"
              width="120px"
              cell={this.ChannelDropDown}
            ></GridColumn>
            <GridColumn
              field="brY_SATIS_KANALI_KODU"
              title="Satış Kanal Kodu"
              width="120px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_KATEGORI"
              title="Product Category"
              width="120px"
              editable={false}
              cell={this.productCategoryDropDown}
            ></GridColumn>
            <GridColumn
              field="brY_URUN_GRUBU"
              title="Product Group"
              width="120px"
              editable={false}
              cell={this.productGroupDropDown}
            ></GridColumn>
            <GridColumn
              field="brY_MARKA_SEGMENT"
              title="Brand Seg"
              width="120px"
              editable={false}
              cell={this.productSegmentDropDown}
            ></GridColumn>
            <GridColumn
              field="indiriM_ORANI"
              title="Discount %"
              width="100px"
              filter={"numeric"}
              editor={"numeric"}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    salesChannelProductGroupList: state.salesChannelProductGroupListReducer,
    salesDepartmantList: state.salesDepartmantListReducer,
    dealerList: state.dealerListReducer,
    auth: state.authReducer,
    channelList: state.channelsByFilterReducer,
    salesChannelList: state.salesChannelsReducer,
    productCategoryList: state.productCategoryListReducer,
    productGroupList: state.productGroupsListReducer,
    customerGroupList: state.customerGroupListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProductCategory: bindActionCreators(fetchProductCategory, dispatch),
    fetchSalesChannelProductGroup: bindActionCreators(
      fetchSalesChannelProductGroup,
      dispatch
    ),
    chanceSalesChannelProductGroup: bindActionCreators(
      chanceSalesChannelProductGroup,
      dispatch
    ),
    insertSalesChannelProductGroup: bindActionCreators(
      insertSalesChannelProductGroup,
      dispatch
    ),
    updateSalesChannelProductGroup: bindActionCreators(
      updateSalesChannelProductGroup,
      dispatch
    ),
    deleteSalesChannelProductGroup: bindActionCreators(
      deleteSalesChannelProductGroup,
      dispatch
    ),
    fetchSalesDepartmant: bindActionCreators(fetchSalesDepartmant, dispatch),
    getDealers: bindActionCreators(getDealers, dispatch),
    getChannelsByFilter: bindActionCreators(getChannelsByFilter, dispatch),
    getSalesChannelsByFilter: bindActionCreators(
      getSalesChannelsByFilter,
      dispatch
    ),
    fetchProductGroup: bindActionCreators(fetchProductGroup, dispatch),
    fetchCustomerGroup: bindActionCreators(fetchCustomerGroup, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesChannelProductGroupList);
