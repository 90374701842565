import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import DealerService from "../../services/srv-dealer/DealerService";

const DealerMultiSelect = ({ required, onChange, value }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    DealerService.getDealerCodeList(defaultDataHandler);
  }, []);

  const defaultDataHandler = ({ data }) => {
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.bayI_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>
            {itemProps.dataItem.bayI_ADI}
          </span>{" "}
          <Badge color="primary" pill>
            {itemProps.dataItem.bayI_KODU}
          </Badge>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <MultiSelect
      name="bayI_KODU"
      id="bayI_KODU"
      label="Bayiler"
      data={filteredData}
      textField="bayI_ADI"
      dataItemKey="bayI_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      placeholder="Bayileri seçiniz."
      value={
        data && value.length > 0
          ? data.filter((a) => value.includes(a.bayI_KODU))
          : []
      }
    ></MultiSelect>
  );
};

export default DealerMultiSelect;
