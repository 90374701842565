import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function fetchCampaignProduct(code) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_CAMPAIGNS_PRODUCT,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/kampanyaurun/get-list-by-code/${code}`
        )
        .then((result) => result.data),
    });
  };
}

export function changeCampaignProduct(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_BRY_CAMPAIGNS_PRODUCT,
      payload: event,
    });
  };
}

export function addCampaignProduct(_campaignProduct) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.INSERT_BRY_CAMPAIGN_PRODUCTS,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/kampanyaurun/add-campaign-product`,
          {
            ..._campaignProduct,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function deleteCampaignProduct(_campaignProduct, code) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_BRY_CAMPAIGNS_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/kampanyaurun/delete-campaign-product`,
          {
            ..._campaignProduct,
          }
        )
        .then((result) => {
          dispatch(fetchCampaignProduct(code));
          return result.data;
        }),
    });
  };
}

export function fetchProductCategory() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_CATEGORY,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/urun/get-product-category`)
        .then((result) => result.data),
    });
  };
}

export function fetchProductGroup(code) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_GROUP,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/urun/get-product-group/${code}`)
        .then((result) => result.data),
    });
  };
}

export function fetchProductType(productCategoryCode, productGroupCode) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_TYPE,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/urun/get-product-type/${productCategoryCode}/${productGroupCode}`
        )
        .then((result) => result.data),
    });
  };
}

export function fetchProductModel(
  productCategoryCode,
  productGroupCode,
  productTypeCode
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_MODEL,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/urun/get-product-model/${productCategoryCode}/${productGroupCode}/${productTypeCode}`
        )
        .then((result) => result.data),
    });
  };
}

export function fetchProductModelDetail(
  productCategoryCode,
  productGroupCode,
  productTypeCode,
  productModelCode
) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_MODEL_DETAIL,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/urun/get-product-model-detail/${productCategoryCode}/${productGroupCode}/${productTypeCode}/${productModelCode}`
        )
        .then((result) => result.data),
    });
  };
}

export function setCampaignsProduct(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_BRY_CAMPAIGNS_PRODUCT,
      payload: event,
    });
  };
}

export function fetchProductBrand() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_PRODUCT_BRAND,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/Marka/get-all`
        )
        .then((result) => result.data),
    });
  };
}