import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import UserSalesDepartmentService from "../../services/srv-user/UserSalesDepartmentService";
import SalesDepartmentDropDownList from "../sys-common/SalesDepartmentDropDownList";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export function UserSalesDepartment({ modalToggle, user, userName }) {
  const gridPrimaryKey = "mrC_KULLANICI_SATIS_DEPARTMANI_TANIM_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [originalUserSalesDepartmentData, setOriginalUserSalesDepartmentData] =
    useState(null);
  const [userSalesDepartmentData, setUserSalesDepartmentData] = useState(null);
  const [userSalesDepartmentDataState, setUserSalesDepartmentDataState] =
    useState({
      sort,
      take: 10,
      skip: 0,
      ...gridSearch,
      filter: {
        logic: "or",
        filters: [
          {
            field: "kullanicI_ID",
            operator: "eq",
            value: user,
          },
        ],
      },
    });
  const [userSalesDepartmentDataLoading, setUserSalesDepartmentDataLoading] =
    useState(false);

  useEffect(() => {
    getUserSalesDepartmentsByFilter();
  }, [userSalesDepartmentDataState]);

  function getUserSalesDepartmentsByFilter() {
    setUserSalesDepartmentDataLoading(true);
    UserSalesDepartmentService.getByFilter(
      userSalesDepartmentDataState,
      onCompletedGetData
    );
  }

  function onCompletedGetData(result) {
    if (result && result) {
      setUserSalesDepartmentData(result);
      setOriginalUserSalesDepartmentData(result);
      if (result.dataState) setUserSalesDepartmentDataState(result.dataState);
    }
    setUserSalesDepartmentDataLoading(false);
  }

  function onLoadingCompleted(result) {
    setUserSalesDepartmentDataLoading(false);
    getUserSalesDepartmentsByFilter();
  }

  function onError() {
    setUserSalesDepartmentDataLoading(false);
  }

  function onDataStateChange(event) {
    setUserSalesDepartmentDataState(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function enterEditCommand(dataItem) {
    var isOpenEdit = userSalesDepartmentData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = userSalesDepartmentData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setUserSalesDepartmentData({ ...userSalesDepartmentData, data });
    }
  }

  function addNewUserSalesDepartment() {
    const newDataItem = {
      kullanicI_ID: user,
      yetki: "TEKLIF_BASLIGI_GOREBILIR",
      inEdit: true,
    };
    var isOpenEdit = userSalesDepartmentData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setUserSalesDepartmentData({
        ...userSalesDepartmentData,
        data: [newDataItem, ...userSalesDepartmentData.data],
      });
    }
  }

  function addCommand(dataItem) {
    setUserSalesDepartmentDataLoading(true);
    UserSalesDepartmentService.create(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function removeCommand(dataItem) {
    setUserSalesDepartmentDataLoading(true);
    UserSalesDepartmentService.delete(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...userSalesDepartmentData.data];
    data.splice(0, 1);
    setUserSalesDepartmentData({ ...userSalesDepartmentData, data });
  }
  function updateCommand(dataItem) {
    setUserSalesDepartmentDataLoading(true);
    UserSalesDepartmentService.update(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = originalUserSalesDepartmentData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = userSalesDepartmentData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setUserSalesDepartmentData({ ...userSalesDepartmentData, data });
  }
  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={enterEditCommand}
      add={addCommand}
      remove={removeCommand}
      discard={discardCommand}
      update={updateCommand}
      cancel={cancelCommand}
      editField={gridEditField}
      idField={gridPrimaryKey}
      nameField={gridPrimaryKey}
    />
  );

  function SalesDepartmentCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SalesDepartmentDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.satiS_DEPARTMANI_KODU;
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  return (
    <Window
      title={`${userName} Satış Departmanları`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {userSalesDepartmentDataLoading && LoadingPanel}
      <Grid
        sortable={true}
        resizable={true}
        filterable={false}
        data={userSalesDepartmentData}
        {...userSalesDepartmentDataState}
        dataItemKey={gridPrimaryKey}
        pageable={gridPageable}
        ref={gridRef}
        onDataStateChange={onDataStateChange}
        rowRender={(row, dataItem) => rowRender(row, dataItem)}
      >
        <GridToolbar>
          <button
            title="Yeni Satış Departmanı Hak Tanım"
            className="btn btn-primary btn-sm"
            onClick={addNewUserSalesDepartment}
          >
            Yeni Kayıt
          </button>
        </GridToolbar>
        <GridColumn cell={CommandCell} width="100px" filterable={false} />
        <GridColumn
          field="satiS_DEPARTMANI_KODU"
          title="Satış Departmanı Adı"
          width="256px"
          filterable={false}
          cell={SalesDepartmentCell}
        />
        <GridColumn
          field="yetki"
          title="Yetki"
          width="256px"
          filterable={false}
        />
      </Grid>
    </Window>
  );
}
