import React, { Component } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { Row, Col, Button } from "reactstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { connect } from "react-redux";
import alertify from "alertifyjs";
import { bindActionCreators } from "redux";
import { fetchProduct } from "../../redux/actions/act-product/productActions";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";
import {
  fetchProductCategory,
  fetchProductGroup,
  fetchProductType,
  fetchProductModel,
  fetchProductModelDetail,
  fetchProductBrand,
  fetchCampaignProduct,
  setCampaignsProduct,
  addCampaignProduct,
} from "../../redux/actions/act-bry-campaignProduct/campaignProductActions";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { filterBy } from "@progress/kendo-data-query";

class CampaignProductInsert extends Component {
  isAddCampaignProduct = false;
  isLoadProducts = false;

  state = {
    products: [],
    brY_URUN_KATEGORI: null,
    brY_URUN_GRUBU: null,
    brY_URUN_TIPI: null,
    brY_URUN_MODELI: null,
    brY_URUN_MODELI_DETAY: null,
    uruN_KODU: null,
    brY_SATIS_MARKA: null,

    defaultItem: {
      brY_URUN_KATEGORI: {
        brY_URUN_KATEGORI: "Seciniz",
        brY_URUN_KATEGORI_ACIKLAMA: "Seçiniz...",
      },
      brY_URUN_GRUBU: {
        brY_URUN_GRUBU: "Seciniz",
        brY_URUN_GRUBU_ACIKLAMA: "Seçiniz...",
      },
      brY_URUN_TIPI: {
        brY_URUN_TIPI: "Seciniz",
        brY_URUN_TIPI_ACIKLAMA: "Seçiniz...",
      },
      brY_URUN_MODELI: {
        brY_URUN_MODELI: "Seciniz",
        brY_URUN_MODELI_ACIKLAMA: "Seçiniz...",
      },
      brY_URUN_MODELI_DETAY: {
        brY_URUN_MODELI_DETAY: "Seciniz",
        brY_URUN_MODELI_DETAY_ACIKLAMA: "Seçiniz...",
      },
      uruN_KODU: {
        uruN_KODU: "Seciniz",
        uruN_ADI: "Seçiniz...",
      },
      brY_SATIS_MARKA: {
        brY_SATIS_MARKA: "Seciniz",
        brY_SATIS_MARKA_ACIKLAMA: "Seçiniz...",
      },
    },
  };
  componentDidMount() {
    this.props.fetchProductCategory();
    this.props.fetchProductBrand();
  }

  componentDidUpdate() {
    if (this.isAddCampaignProduct && this.props.campaignProduct.dataUpdated) {
      this.isAddCampaignProduct = false;
      this.props.toggleDialog();
      this.props.fetchCampaignProduct(this.props.code);
    }

    if (
      this.isLoadProducts === false &&
      this.props.productList.fetching === false
    ) {
      this.isLoadProducts = true;
      this.setState({ products: this.props.productList.products });
    }
  }

  onChange = (event) => {
    this.props.setCampaignsProduct(event);
  };

  onChangeProductCategory = (event) => {
    this.onChange(event);
    this.props.fetchProductGroup(event.value.brY_URUN_KATEGORI);

    this.setState({
      brY_URUN_KATEGORI: event.value.brY_URUN_KATEGORI,
      brY_URUN_GRUBU: "",
      brY_URUN_TIPI: "",
      brY_URUN_MODELI: "",
      brY_URUN_MODELI_DETAY: "",
      uruN_KODU: "",
    });
  };

  onChangeProductGroup = (event) => {
    this.onChange(event);
    this.props.fetchProductType(
      event.value.brY_URUN_KATEGORI,
      event.value.brY_URUN_GRUBU
    );

    this.setState({
      brY_URUN_KATEGORI: this.state.brY_URUN_KATEGORI,
      brY_URUN_GRUBU: event.value.brY_URUN_GRUBU,
      brY_URUN_TIPI: "",
      brY_URUN_MODELI: "",
      brY_URUN_MODELI_DETAY: "",
      uruN_KODU: "",
    });
  };

  onChangeProductType = (event) => {
    this.onChange(event);
    this.props.fetchProductModel(
      event.value.brY_URUN_KATEGORI,
      event.value.brY_URUN_GRUBU,
      event.value.brY_URUN_TIPI
    );

    this.setState({
      brY_URUN_KATEGORI: this.state.brY_URUN_KATEGORI,
      brY_URUN_GRUBU: this.state.brY_URUN_GRUBU,
      brY_URUN_TIPI: event.value.brY_URUN_TIPI,
      brY_URUN_MODELI: "",
      brY_URUN_MODELI_DETAY: "",
      uruN_KODU: "",
    });
  };

  onChangeProductModel = (event) => {
    this.onChange(event);
    this.props.fetchProductModelDetail(
      event.value.brY_URUN_KATEGORI,
      event.value.brY_URUN_GRUBU,
      event.value.brY_URUN_TIPI,
      event.value.brY_URUN_MODELI
    );

    this.setState({
      brY_URUN_KATEGORI: this.state.brY_URUN_KATEGORI,
      brY_URUN_GRUBU: this.state.brY_URUN_GRUBU,
      brY_URUN_TIPI: this.state.brY_URUN_TIPI,
      brY_URUN_MODELI: event.value.brY_URUN_MODELI,
      brY_URUN_MODELI_DETAY: "",
      uruN_KODU: "",
    });
  };

  onChangeProductModelDetail = (event) => {
    this.onChange(event);
    this.isLoadProducts = false;
    this.props.fetchProduct(
      event.value.brY_URUN_KATEGORI,
      event.value.brY_URUN_GRUBU,
      event.value.brY_URUN_TIPI,
      event.value.brY_URUN_MODELI,
      event.value.brY_URUN_MODELI_DETAY
    );

    this.setState({
      brY_URUN_KATEGORI: this.state.brY_URUN_KATEGORI,
      brY_URUN_GRUBU: this.state.brY_URUN_GRUBU,
      brY_URUN_TIPI: this.state.brY_URUN_TIPI,
      brY_URUN_MODELI: this.state.brY_URUN_MODELI,
      brY_URUN_MODELI_DETAY: event.value.brY_URUN_MODELI_DETAY,
    });
  };

  addCampaignProduct = (event) => {
    if (
      this.props.campaign._campaign.kampanyA_TURU === "ADETE_BAGLI_PAKET" &&
      (!this.props.campaignProduct._campaignProduct.adet ||
        this.props.campaignProduct._campaignProduct.adet === "")
    ) {
      defaultAlertMessages.MessageDetailError = "Lütfen adet giriniz.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.isAddCampaignProduct = true;
      var data = this.props.campaignProduct._campaignProduct;
      data.kampanyA_KODU = this.props.code;
      this.props.addCampaignProduct(data);
    }
  };

  filterProductData = (event) => {
    if (event.filter.value === "") {
      this.setState({ products: this.props.productList.products });
    } else {
      var data = this.props.productList.products.slice();
      var filteredData = filterBy(data, event.filter);
      this.setState({ products: filteredData });
    }
  };

  render() {
    return (
      <Window
        title={"Yeni Kampanya Ürünleri"}
        onClose={this.props.toggleDialog}
        initialHeight={"85vh"}
        initialWidth={"35%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        {(this.props.productList.fetching ||
          this.props.productCategoryList.fetching ||
          this.props.productGroupList.fetching ||
          this.props.productTypeList.fetching ||
          this.props.productModelList.fetching ||
          this.props.productModelDetailList.fetching ||
          this.props.productBrandList.fetching) &&
          LoadingPanel}
        <Row>
          <Col lg="12" md="12" xs="12">
            <DropDownList
              name="brY_URUN_KATEGORI"
              label="Ürün Kategorisi"
              data={this.props.productCategoryList.productCategories}
              defaultItem={this.state.defaultItem.brY_URUN_KATEGORI}
              textField="brY_URUN_KATEGORI_ACIKLAMA"
              dataItemKey="brY_URUN_KATEGORI"
              style={{ width: "100%" }}
              onChange={this.onChangeProductCategory}
              value={
                this.props.campaignProduct._campaignProduct.brY_URUN_KATEGORI
                  ? this.props.campaignProduct._campaignProduct
                      .brY_URUN_KATEGORI ===
                    this.state.defaultItem.brY_URUN_KATEGORI.brY_URUN_KATEGORI
                    ? this.state.defaultItem.brY_URUN_KATEGORI
                    : this.props.productCategoryList.productCategories.find(
                        (a) =>
                          a.brY_URUN_KATEGORI ===
                          this.props.campaignProduct._campaignProduct
                            .brY_URUN_KATEGORI
                      )
                  : ""
              }
            />
            <DropDownList
              name="brY_URUN_GRUBU"
              label="Ürün Grubu"
              data={this.props.productGroupList.productGroups}
              defaultItem={this.state.defaultItem.brY_URUN_GRUBU}
              textField="brY_URUN_GRUBU_ACIKLAMA"
              dataItemKey="brY_URUN_GRUBU"
              style={{ width: "100%" }}
              onChange={this.onChangeProductGroup}
              disabled={
                !this.props.campaignProduct._campaignProduct
                  .brY_URUN_KATEGORI ||
                this.props.campaignProduct._campaignProduct
                  .brY_URUN_KATEGORI ===
                  this.state.defaultItem.brY_URUN_KATEGORI.brY_URUN_KATEGORI
              }
              value={
                this.props.campaignProduct._campaignProduct.brY_URUN_GRUBU
                  ? this.props.campaignProduct._campaignProduct
                      .brY_URUN_GRUBU ===
                    this.state.defaultItem.brY_URUN_GRUBU.brY_URUN_GRUBU
                    ? this.state.defaultItem.brY_URUN_GRUBU
                    : this.props.productGroupList.productGroups.find(
                        (a) =>
                          a.brY_URUN_GRUBU ===
                          this.props.campaignProduct._campaignProduct
                            .brY_URUN_GRUBU
                      )
                  : ""
              }
            />
            <DropDownList
              name="brY_URUN_TIPI"
              label="Ürün Tipi"
              data={this.props.productTypeList.productTypes}
              defaultItem={this.state.defaultItem.brY_URUN_TIPI}
              textField="brY_URUN_TIPI_ACIKLAMA"
              dataItemKey="brY_URUN_TIPI"
              style={{ width: "100%" }}
              onChange={this.onChangeProductType}
              disabled={
                !this.props.campaignProduct._campaignProduct.brY_URUN_GRUBU ||
                this.props.campaignProduct._campaignProduct.brY_URUN_GRUBU ===
                  this.state.defaultItem.brY_URUN_GRUBU.brY_URUN_GRUBU
              }
              value={
                this.props.campaignProduct._campaignProduct.brY_URUN_TIPI
                  ? this.props.campaignProduct._campaignProduct
                      .brY_URUN_TIPI ===
                    this.state.defaultItem.brY_URUN_TIPI.brY_URUN_TIPI
                    ? this.state.defaultItem.brY_URUN_TIPI
                    : this.props.productTypeList.productTypes.find(
                        (a) =>
                          a.brY_URUN_TIPI ===
                          this.props.campaignProduct._campaignProduct
                            .brY_URUN_TIPI
                      )
                  : ""
              }
            />
            <DropDownList
              name="brY_URUN_MODELI"
              label="Ürün Modeli"
              data={this.props.productModelList.productModels}
              defaultItem={this.state.defaultItem.brY_URUN_MODELI}
              textField="brY_URUN_MODELI_ACIKLAMA"
              dataItemKey="brY_URUN_MODELI"
              style={{ width: "100%" }}
              onChange={this.onChangeProductModel}
              disabled={
                !this.props.campaignProduct._campaignProduct.brY_URUN_TIPI ||
                this.props.campaignProduct._campaignProduct.brY_URUN_TIPI ===
                  this.state.defaultItem.brY_URUN_TIPI.brY_URUN_TIPI
              }
              value={
                this.props.campaignProduct._campaignProduct.brY_URUN_MODELI
                  ? this.props.campaignProduct._campaignProduct
                      .brY_URUN_MODELI ===
                    this.state.defaultItem.brY_URUN_MODELI.brY_URUN_MODELI
                    ? this.state.defaultItem.brY_URUN_MODELI
                    : this.props.productModelList.productModels.find(
                        (a) =>
                          a.brY_URUN_MODELI ===
                          this.props.campaignProduct._campaignProduct
                            .brY_URUN_MODELI
                      )
                  : ""
              }
            />
            <DropDownList
              name="brY_URUN_MODELI_DETAY"
              label="Ürün Modeli Detay"
              data={this.props.productModelDetailList.productModelDetails}
              defaultItem={this.state.defaultItem.brY_URUN_MODELI_DETAY}
              textField="brY_URUN_MODELI_DETAY_ACIKLAMA"
              dataItemKey="brY_URUN_MODELI_DETAY"
              style={{ width: "100%" }}
              onChange={this.onChangeProductModelDetail}
              disabled={
                !this.props.campaignProduct._campaignProduct.brY_URUN_MODELI ||
                this.props.campaignProduct._campaignProduct.brY_URUN_MODELI ===
                  this.state.defaultItem.brY_URUN_MODELI.brY_URUN_MODELI
              }
              value={
                this.props.campaignProduct._campaignProduct
                  .brY_URUN_MODELI_DETAY
                  ? this.props.campaignProduct._campaignProduct
                      .brY_URUN_MODELI_DETAY ===
                    this.state.defaultItem.brY_URUN_MODELI_DETAY
                      .brY_URUN_MODELI_DETAY
                    ? this.state.defaultItem.brY_URUN_MODELI_DETAY
                    : this.props.productModelDetailList.productModelDetails.find(
                        (a) =>
                          a.brY_URUN_MODELI_DETAY ===
                          this.props.campaignProduct._campaignProduct
                            .brY_URUN_MODELI_DETAY
                      )
                  : ""
              }
            />
            <DropDownList
              name="uruN_KODU"
              label="Ürün"
              data={this.state.products}
              defaultItem={this.state.defaultItem.uruN_KODU}
              textField="uruN_ADI"
              dataItemKey="uruN_KODU"
              style={{ width: "100%" }}
              onChange={this.onChange}
              disabled={
                !this.props.campaignProduct._campaignProduct
                  .brY_URUN_MODELI_DETAY ||
                this.props.campaignProduct._campaignProduct
                  .brY_URUN_MODELI_DETAY ===
                  this.state.defaultItem.brY_URUN_MODELI_DETAY
                    .brY_URUN_MODELI_DETAY
              }
              filterable={true}
              onFilterChange={(event) => this.filterProductData(event)}
              value={
                this.props.campaignProduct._campaignProduct.uruN_KODU
                  ? this.props.campaignProduct._campaignProduct.uruN_KODU ===
                    this.state.defaultItem.uruN_KODU.uruN_KODU
                    ? this.state.defaultItem.uruN_KODU
                    : this.state.products.find(
                        (a) =>
                          a.uruN_KODU ===
                          this.props.campaignProduct._campaignProduct.uruN_KODU
                      )
                  : ""
              }
            />
            
            <DropDownList
              name="brY_SATIS_MARKA"
              label="Satış Marka"
              data={this.props.productBrandList.productBrands}
              defaultItem={this.state.defaultItem.brY_SATIS_MARKA}
              textField="brY_SATIS_MARKA_ACIKLAMA"
              dataItemKey="brY_SATIS_MARKA"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={
                this.props.campaignProduct._campaignProduct
                  .brY_SATIS_MARKA
                  ? this.props.campaignProduct._campaignProduct
                      .brY_SATIS_MARKA ===
                    this.state.defaultItem.brY_SATIS_MARKA
                      .brY_SATIS_MARKA
                    ? this.state.defaultItem.brY_SATIS_MARKA
                    : this.props.productBrandList.productBrands.find(
                        (a) =>
                          a.brY_SATIS_MARKA ===
                          this.props.campaignProduct._campaignProduct
                            .brY_SATIS_MARKA
                      )
                  : ""
              }
            />

            {this.props.campaign._campaign.kampanyA_TURU ===
            "INDIRIM" ? undefined : (
              <NumericTextBox
                label="Adet"
                name="adet"
                id="adet"
                style={{ width: "100%" }}
                onChange={this.onChange}
                disabled={
                  !this.props.campaignProduct._campaignProduct
                    .brY_URUN_KATEGORI ||
                  this.props.campaignProduct._campaignProduct.uruN_KODU ===
                    this.state.defaultItem.uruN_KODU.uruN_KODU
                }
                value={this.props.campaignProduct._campaignProduct.adet}
              ></NumericTextBox>
            )}
          </Col>
        </Row>
        <br></br>
        <Button
          disabled={
            !this.props.campaignProduct._campaignProduct.brY_URUN_KATEGORI ||
            this.props.campaignProduct._campaignProduct.brY_URUN_KATEGORI === ""
          }
          color="success"
          type="submit"
          size="md"
          onClick={this.addCampaignProduct}
        >
          Ekle
        </Button>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign: state.campaignReducer,
    campaignProduct: state.campaignProductReducer,
    productCategoryList: state.productCategoryListReducer,
    productGroupList: state.productGroupsListReducer,
    productModelDetailList: state.productModelDetailListReducer,
    productModelList: state.productModelListReducer,
    productTypeList: state.productTypeListReducer,
    productBrandList: state.productBrandListReducer,
    productList: state.productListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProductCategory: bindActionCreators(fetchProductCategory, dispatch),
    fetchProductGroup: bindActionCreators(fetchProductGroup, dispatch),
    fetchProductType: bindActionCreators(fetchProductType, dispatch),
    fetchProductModel: bindActionCreators(fetchProductModel, dispatch),
    fetchCampaignProduct: bindActionCreators(fetchCampaignProduct, dispatch),
    fetchProductModelDetail: bindActionCreators(fetchProductModelDetail, dispatch),
    fetchProductBrand: bindActionCreators(fetchProductBrand, dispatch),
    fetchProduct: bindActionCreators(fetchProduct, dispatch),
    addCampaignProduct: bindActionCreators(addCampaignProduct, dispatch),
    setCampaignsProduct: bindActionCreators(setCampaignsProduct, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignProductInsert);
