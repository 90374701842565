import axios from "axios";
import { API_GATEWAY, PR_DEX_MERCURY } from "../../config/globalVariables";

class OfferOrderRegionService {
  getRegions(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX_MERCURY}/teklifsiparisbolge/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new OfferOrderRegionService();
