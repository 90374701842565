import { BiggSerialsCsvReport } from "./BiggSerialsCsvReport";
import { BiggSerialsModifiedReport } from "./BiggSerialsModifiedReport";

export default function BiggSerialsModifiedPage() {
  return (
    <>
      <BiggSerialsCsvReport />
      <BiggSerialsModifiedReport />
    </>
  );
}
