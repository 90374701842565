import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import ProductService from "../../services/srv-product/ProductService";
import ExportExcel from "../sys-toolbox/ExcelExport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { LockedMyCommandCellWithoutDelete } from "../sys-toolbox/LockedGridInlineEditWitouthDelete";
import ProductArtiGunExcelUpload from "./ProductArtiGunExcelUpload";
import { productArtiGunGridColums } from "./ProductArtiGunGridColumns";

const ProductArtiGunList = () => {
  const _grid = useRef(null);
  const primaryKey = "uruN_KODU";
  const editField = "inEdit";
  const search = "";
  const selectedField = "selected";

  const [selectedState, setSelectedState] = useState({});

  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    take: 10,
    skip: 0,
    ...search,
  });
  const [originalProducts, setOriginalProducts] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    setProductsLoading(true);
    ProductService.getProductsArtiGun(
      productsDataState,
      onCompletedGetProducts
    );
  }, [productsDataState]);

  const onCompletedGetProducts = (data) => {
    setProducts(data);
    setOriginalProducts(data);
    if (data.dataState) setProductsDataState(data.dataState);
    setProductsLoading(false);
  };

  const dataStateChange = (event) => {
    filterLocalization(event);
    setProductsDataState(event.dataState);
  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: "uruN_KODU",
      });

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const enterEditCommand = (dataItem) => {
    var isOpenEdit = products.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = products.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setProducts({ ...products, data });
    }
  };

  const addCommand = (dataItem) => {
    ProductService.insert(dataItem, onCompletedGetProducts);
    dataItem.inEdit = false;
    setProducts({ ...products });
  };

  const discardCommand = (dataItem) => {
    const data = [...products.data];
    data.splice(0, 1);
    setProducts({ ...products, data });
  };

  const updateCommand = (dataItem) => {
    ProductService.updateArtiGun(dataItem, undefined);
    dataItem.inEdit = false;
    setProducts({ ...products });
  };

  const cancelCommand = (dataItem) => {
    const originalItem = originalProducts.data.find(
      (p) => p[primaryKey] === dataItem[primaryKey]
    );
    const data = products.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    setProducts({ ...products, data });
  };

  const CommandCell = (props) => {
    return (
      <LockedMyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={editField}
        idField={primaryKey}
        nameField={primaryKey}
      />
    );
  };

  const onItemChange = (event) => {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  };

  const [visibleExcelUpload, setVisibleExcelUpload] = useState(false);
  const excelToggle = () => {
    setVisibleExcelUpload(!visibleExcelUpload);
  };
  return (
    <main style={{ flexGrow: "1" }}>
      {visibleExcelUpload ? (
        <ProductArtiGunExcelUpload
          modalToggle={excelToggle}
        ></ProductArtiGunExcelUpload>
      ) : (
        ""
      )}
      <Card>
        <CardHeader>
          <CardTitle>Ürün FreeStock Artı Gün</CardTitle>
        </CardHeader>
        <CardBody>
          {productsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={products}
            {...productsDataState}
            onDataStateChange={dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{
              buttonCount: 3,
              pageSizes: [6, 10, 20, 50, 100],
            }}
            resizable={true}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            onItemChange={onItemChange}
            onSelectionChange={onSelectionChange}
            filterable
            dataItemKey={primaryKey}
            editField={editField}
            selectable={{
              enabled: true,
              cell: false,
              mode: "single",
            }}
            selectedField={selectedField}
          >
            <GridToolbar>
              <ExportExcel
                data={products}
                columns={productArtiGunGridColums}
                loadingFunction={setProductsLoading}
                dataState={productsDataState}
                serviceMethod={ProductService.getArtiGunProductsForExcel}
                fileName="UrunListesi"
              ></ExportExcel>
              <button
                title="Excel ile Toplu Güncelle"
                className="btn btn-primary btn-sm"
                onClick={() => excelToggle()}
              >
                Excel ile Toplu Güncelle
              </button>
            </GridToolbar>
            <GridColumn
              width="100px"
              filterable={false}
              cell={CommandCell}
              locked={true}
            />
            <GridColumn
              field="uruN_KODU"
              title="Ürün Kodu"
              width="200px"
              locked={true}
            ></GridColumn>
            <GridColumn
              field="uruN_ADI"
              title="Ürün Adı"
              width="300px"
              editor={"text"}
              editable={false}
            ></GridColumn>
            <GridColumn
              field="artI_FREE_STOCK_GUN"
              title="Artı Free Stock Gün"
              width="192px"
              editor="numeric"
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
};

export default ProductArtiGunList;
