import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, MS_USERSDEX } from "../../config/globalVariables";

class RoleService {
  getRoles(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + MS_USERSDEX
        }/role/get-list-by-filter-grid?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getRoleCodeList(callback) {
    return axios
      .get(`${API_GATEWAY + MS_USERSDEX}/role/get-list-role-kodu`)
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
        return result.data;
      });
  }
}

export default new RoleService();
