import axios from "axios";
import { API_GATEWAY, MS_USERSDEX } from "../../../config/globalVariables";
import { authorizationToken } from "../../../helpers/authorizationToken";
import * as actionTypes from "../sys-configure/actionTypes";

export function login(username, password, mfa, projectName) {
  var md5 = require("md5");
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN,
      payload: axios
        .post(`${API_GATEWAY + MS_USERSDEX}/auth/mfa-log-in`, {
          email: username,
          password: md5(password),
          mfa: mfa,
          projectName: projectName,
        })
        .then((result) => result.data),
    });
  };
}

export function loginMfa(
  username,
  password,
  verificationCode,
  mfa,
  projectName
) {
  var md5 = require("md5");
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_MFA,
      payload: axios
        .post(`${API_GATEWAY + MS_USERSDEX}/auth/mfa-verify`, {
          email: username,
          password: md5(password),
          verifyCode: verificationCode,
          mfa: mfa,
          projectName: projectName,
        })
        .then((result) => result.data),
    });
  };
}

export function loginAad(email, token) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGIN_AAD,
      payload: axios.post(
        `${API_GATEWAY + MS_USERSDEX}/auth/log-in-aad`,
        JSON.stringify(email, token),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    });
  };
}

export function refreshJwtByAad(email, token) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REFRESH_JWT_BY_AAD,
      payload: axios.post(
        `${API_GATEWAY + MS_USERSDEX}/auth/log-in-aad`,
        JSON.stringify(email, token),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ),
    });
  };
}

export function logout() {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("aadToken");
  authorizationToken(false);
  window.location.href = "/";
  return (dispatch) => {
    dispatch({
      type: actionTypes.LOGOUT,
      payload: "Çıkış yapıldı...",
    });
  };
}

export function changeUser(token) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_USER,
      payload: token,
    });
  };
}

export function setShowForm() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_SHOW_FORM,
      payload: true,
    });
  };
}

export function changeMfa() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_MFA,
      payload: false,
    });
  };
}

export function forgotPassword(email) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FORGOT_PASSWORD,
      payload: axios
        .post(`${API_GATEWAY + MS_USERSDEX}/auth/forgot-password`, {
          email: email,
        })
        .then((result) => result.data),
    });
  };
}

export function resetPassword(uid, kid, password, passwordAgain) {
  var md5 = require("md5");
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_PASSWORD,
      payload: axios
        .post(`${API_GATEWAY + MS_USERSDEX}/auth/reset-password`, {
          uid: uid,
          kid: kid,
          password: md5(password),
          passwordAgain: md5(passwordAgain),
        })
        .then((result) => result.data),
    });
  };
}

export function updatePassword(mail, oldPassword, resetPassword) {
  var md5 = require("md5");
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_PASSWORD,
      payload: axios
        .post(`${API_GATEWAY + MS_USERSDEX}/auth/update-password`, {
          email: mail,
          oldPassword: md5(oldPassword),
          password: md5(resetPassword),
        })
        .then((result) => result.data),
    });
  };
}
