import React, { useState, useEffect } from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import RoleService from "../../services/srv-role/RoleService";

const RoleListBox = ({ onChange, selectedData }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    unselectedRoles: [],
    selectedRoles: [],
    draggedItem: {},
  });

  useEffect(() => {
    getDataByFilter();
  }, []);

  useEffect(() => {
    onChange(state.selectedRoles);
  }, [state]);

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        unselectedRoles: prevState.unselectedRoles.sort((a, b) =>
          a.rolE_KODU.localeCompare(b.rolE_KODU)
        ),
        selectedRoles: prevState.selectedRoles.sort((a, b) =>
          a.rolE_KODU.localeCompare(b.rolE_KODU)
        ),
      };
    });
  }, [state.selectedRoles, state.unselectedRoles]);

  useEffect(() => {
    if (selectedData) {
      const unselectedRoles = data.data?.filter(d => {
        const f = selectedData.data.find(x => x["rolE_KODU"] === d["rolE_KODU"]);
        return !f;
      }) || [];
  
      setState({
        ...state,
        unselectedRoles,
        selectedRoles: selectedData.data,
      });
    }
  }, [data, selectedData]);

  const onCompletedGetData = (data) => {
    setData({
      ...data,
      data: data.data.sort((a, b) => (a.rolE_KODU > b.rolE_KODU ? 1 : -1)),
    });
    setDataLoading(false);
  };

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    RoleService.getRoles(
      {
        filter: { logic: "and", filters: filters },
      },
      onCompletedGetData
    );
    console.log()
  };

  const SELECTED_FIELD = "selected";

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.rolE_KODU === event.dataItem.rolE_KODU) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.unselectedRoles,
      state.selectedRoles,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      unselectedRoles: result.listBoxOneData,
      selectedRoles: result.listBoxTwoData,
    });
  };
  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };
  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      state.unselectedRoles,
      state.selectedRoles,
      state.draggedItem,
      e.dataItem,
      "rolE_KODU"
    );
    setState({
      ...state,
      unselectedRoles: result.listBoxOneData,
      selectedRoles: result.listBoxTwoData,
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col k-pr-2">
          <h6>Roller</h6>
          {dataLoading && LoadingPanel}  
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            textField="rolE_KODU"
            data={state.unselectedRoles}
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, "unselectedRoles", "selectedRoles")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            toolbar={() => {
              return (
                <ListBoxToolbar
                  tools={["transferTo", "transferFrom", "transferAllFrom"]}
                  data={state.unselectedRoles}
                  dataConnected={state.selectedRoles}
                  onToolClick={handleToolBarClick}
                />
              );
            }}
          />
        </div>
        <div className="col k-pl-0">
          <h6>Seçilen Roller</h6>
          {dataLoading && LoadingPanel}  
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            data={state?.selectedRoles}
            textField="rolE_KODU"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, "selectedRoles", "unselectedRoles")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
      </div>
    </>
  );
};

export default RoleListBox;
