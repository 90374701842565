import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { useEffect, useRef, useState } from "react";
import { Button } from "reactstrap";
import { BASE_URL } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import CustomerService from "../../services/srv-customer/CustomerService";
import GridRowRenderDefault from "../sys-toolbox/GridRowRenderDefault";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import CustomerAddressUpdate from "./CustomerAddressUpdate";

export function CustomerAddressList({ musKodu, musId }) {
  const gridRef = useRef(null);
  const sort = [
    {
      field: "muS_TANIM_ID",
      dir: "desc",
    },
  ];
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [type, setType] = useState("");
  const [customerAddress, setCustomerAddress] = useState({});
  const [customersDataState, setCustomersDataState] = useState({
    take: 10,
    skip: 0,
    sort,
  });
  const [isVisibleCustomerUpdate, setIsVisibleCustomerUpdate] = useState(false);

  useEffect(() => {
    getCustomers();
  }, []);

  const onCompletedGetCustomers = (data) => {
    if (data) {
      setCustomers(data);
    }
    setCustomersLoading(false);
  };

  const getCustomers = () => {
    setCustomersLoading(true);
    CustomerService.getCustomerAddress(musId, onCompletedGetCustomers);
  };

  const dataStateChange = (event) => {
    filterLocalization(event);
    setCustomersDataState(event.dataState);
    getCustomers(event.dataState);
  };

  const toggleDialogAddress = (type, dataItem) => {
    setType(type);
    setIsVisibleCustomerUpdate(!isVisibleCustomerUpdate);
    dataItem && setCustomerAddress(dataItem.dataItem);
    getCustomers(customersDataState);
  };

  return (
    <>
      {isVisibleCustomerUpdate ? (
        <CustomerAddressUpdate
          musId={musId}
          musAdres={customerAddress}
          type={type}
          toggleDialog={toggleDialogAddress}
        ></CustomerAddressUpdate>
      ) : undefined}
      {customersLoading && LoadingPanel}
      <Grid
        data={customers}
        {...customersDataState}
        onDataStateChange={dataStateChange}
        sortable={true}
        style={{ minHeight: "400px", fontSize: "11px" }}
        pageable={{
          buttonCount: 3,
          pageSizes: [10, 20, 50, 100],
        }}
        resizable={true}
        ref={gridRef}
        rowRender={(trElement, dataItem) =>
          GridRowRenderDefault(trElement, dataItem)
        }
        filterable={false}
      >
        <GridToolbar>
          <button
            title="Yeni Müşteri"
            className="btn btn-primary btn-sm"
            onClick={() => toggleDialogAddress("insert")}
          >
            Yeni Adres
          </button>
        </GridToolbar>
        <GridColumn
          filterable={false}
          width="100px"
          cell={(props) => (
            <td>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => toggleDialogAddress("update", props)}
              >
                Detay
              </Button>
            </td>
          )}
        />
        <GridColumn
          field="adreS_BASLIGI"
          title="Adres Başlığı"
          width="110px"
        ></GridColumn>
        <GridColumn field="adreS_1" title="Adres 1" width="200px"></GridColumn>
        <GridColumn field="adreS_2" title="Adres 2" width="200px"></GridColumn>
        <GridColumn field="adreS_3" title="Adres 3" width="200px"></GridColumn>
        <GridColumn field="no" title="No" width="200px"></GridColumn>
        <GridColumn
          field="ulkE_ADI"
          title="Ülke Adı"
          width="200px"
        ></GridColumn>
        <GridColumn field="iL_ADI" title="İl Adı" width="200px"></GridColumn>
        <GridColumn
          field="ilcE_KODU"
          title="İlçe Kodu"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="tasimA_BOLGESI"
          title="Taşıma Bölgesi"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="timezone"
          title="Timezone"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="bolgE_KODU"
          title="Bölge Kodu"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="postA_KODU"
          title="Posta Kodu"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="sevK_SORUMLUSU"
          title="Sevk Sorumlusu"
          width="200px"
        ></GridColumn>
        <GridColumn
          field="sevK_SORUMLUSU_TELEFON"
          title="Sevk Sorumlusu Telefon"
          width="120px"
        ></GridColumn>
        <GridColumn field="dil" title="Dil" width="120px"></GridColumn>
        <GridColumn
          field="crE_DATE"
          title="Ekleme Tarihi"
          width="120px"
        ></GridColumn>
      </Grid>
    </>
  );
}
