import { cloneElement, useEffect, useState, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import DealerProductGroupService from "../../services/srv-bry-dealerProductGroup/DealerProductGroupService";
import BryDealerUserDropDownList from "../sys-common/BryDealerUserDropDownList";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import BryDealerProductGroupDropDownList from "../sys-common/BryDealerProductGroupDropDownList";
import BryDealerProductCategoryDropDownList from "../sys-common/BryDealerProductCategoryDropDownList";
import BryCanalDropDownList from "../sys-common/BryCanalDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import { DATASATISDEPARTMAN } from "../../config/globalVariables";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import { dealerProductGroupGridColumns } from "./DealerProductGroupGridColumns";
import filterLocalization from "../../helpers/filterLocalization";

export function DealerProductGroupList() {
  const gridPrimaryKey = "brY_BAYI_URUN_GRUBU_TANIM_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [productCategoryData, setProductCategoryData] = useState(null);
  const [originalUserRoleData, setOriginalUserRoleData] = useState(null);
  const [userRoleData, setUserRoleData] = useState(null);
  const [userRoleDataState, setUserRoleDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
  });
  const [userRoleDataLoading, setUserRoleDataLoading] = useState(false);

  useEffect(() => {
    getUsersByFilter();
  }, [userRoleDataState]);

  function getUsersByFilter() {
    setUserRoleDataLoading(true);
    DealerProductGroupService.getByFilter(
      userRoleDataState,
      onCompletedGetData
    );
  }

  function onCompletedGetData(result) {
    if (result && result) {
      setUserRoleData(result);
      setOriginalUserRoleData(result);
      if (result.dataState) setUserRoleDataState(result.dataState);
    }
    setUserRoleDataLoading(false);
  }

  function onLoadingCompleted(result) {
    setUserRoleDataLoading(false);
    getUsersByFilter();
  }

  function onError() {
    setUserRoleDataLoading(false);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setUserRoleDataState(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function addNewUserRole() {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = userRoleData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setUserRoleData({
        ...userRoleData,
        data: [newDataItem, ...userRoleData.data],
      });
    }
  }

  // Command Cell Functions
  function enterEditCommand(dataItem) {
    var isOpenEdit = userRoleData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = userRoleData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setUserRoleData({ ...userRoleData, data });
    }
  }
  function addCommand(dataItem) {
    setUserRoleDataLoading(true);
    DealerProductGroupService.create(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...userRoleData.data];
    data.splice(0, 1);
    setUserRoleData({ ...userRoleData, data });
  }
  function updateCommand(dataItem) {
    setUserRoleDataLoading(true);
    DealerProductGroupService.update(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = originalUserRoleData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = userRoleData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setUserRoleData({ ...userRoleData, data });
  }
  function CommandCell(props) {
    return (
      <MyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={gridEditField}
        idField={gridPrimaryKey}
        nameField={gridPrimaryKey}
      />
    );
  }

  // Cells
  function SatisTemsilcisiCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryDealerUserDropDownList
            id={field}
            name={field}
            value={dataItem["satiS_TEMSILCISI_adsoyad"]}
            label={dataItem["satiS_TEMSILCISI_adsoyad"]}
            onChange={(e) => {
              dataItem["satiS_TEMSILCISI_KULLANICI_ID"] = e.value.kullanicI_ID;
              dataItem["satiS_TEMSILCISI_adsoyad"] = e.value.adsoyad;
              setUserRoleData({ ...userRoleData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.satiS_TEMSILCISI_KULLANICI_ADI}</td>;
    }
  }

  function BolgeMuduruCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryDealerUserDropDownList
            id={field}
            name={field}
            value={dataItem["bolgE_MUDURU_adsoyad"]}
            label={dataItem["bolgE_MUDURU_adsoyad"]}
            onChange={(e) => {
              dataItem["bolgE_MUDURU_KULLANICI_ID"] = e.value.kullanicI_ID;
              dataItem["bolgE_MUDURU_adsoyad"] = e.value.adsoyad;
              setUserRoleData({ ...userRoleData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.bolgE_MUDURU_KULLANICI_ADI}</td>;
    }
  }

  function DealerCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DealerDropDownList
            id={field}
            name={field}
            label="En az 3 karakter giriniz."
            value={dataItem["bayI_KODU"]}
            onChange={(e) => {
              dataItem["bayI_KODU"] = e.value.bayI_KODU;
              setUserRoleData({ ...userRoleData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function SalesDepartmentCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <CommonDropDownList
            id={field}
            name={field}
            label={dataValue}
            data={DATASATISDEPARTMAN}
            onChange={(e) => {
              dataItem[field] = e.value.text;
              setUserRoleData({ ...userRoleData });
            }}
            value={dataValue}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function BryCanalCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryCanalDropDownList
            id={field}
            name={field}
            value={dataValue}
            label={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_KANAL;
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function BryProductGroupCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryDealerProductGroupDropDownList
            id={field}
            filterField={"brY_URUN_KATEGORI"}
            filterValue={productCategoryData}
            name={field}
            label={dataValue}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_GRUBU;
              setUserRoleData({ ...userRoleData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function BryProductCategoryCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <BryDealerProductCategoryDropDownList
            id={field}
            name={field}
            value={dataValue}
            label={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.brY_URUN_KATEGORI;
              setProductCategoryData(e.value.brY_URUN_KATEGORI);
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function YesNoCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={field}
            name={field}
            label={dataValue}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUserRoleData({ ...userRoleData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Bayi Ürün Grubu Tanım</CardTitle>
        </CardHeader>
        <CardBody>
          {userRoleDataLoading && LoadingPanel}
          <Grid
            sortable={true}
            resizable={true}
            filterable={true}
            data={userRoleData}
            {...userRoleDataState}
            dataItemKey={gridPrimaryKey}
            pageable={gridPageable}
            ref={gridRef}
            editField={gridEditField}
            onDataStateChange={onDataStateChange}
            rowRender={(row, dataItem) => rowRender(row, dataItem)}
          >
            <GridToolbar>
              <button
                title="Yeni Kayıt"
                className="btn btn-primary btn-sm"
                onClick={addNewUserRole}
              >
                Yeni Kayıt
              </button>
              <ExportExcel
                data={userRoleData}
                columns={dealerProductGroupGridColumns}
                loadingFunction={setUserRoleDataLoading}
                dataState={userRoleDataState}
                serviceMethod={
                  DealerProductGroupService.getDealerProductGroupsForExcel
                }
                fileName="BayiUrunGrubuTanim"
              ></ExportExcel>
            </GridToolbar>
            <GridColumn cell={CommandCell} width="100px" filterable={false} />
            <GridColumn
              field="bayI_ADI"
              title="Bayi"
              width="256px"
              sortable={true}
              cell={DealerCell}
            />
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="Satış Departmanı"
              width="192px"
              cell={SalesDepartmentCell}
            />
            <GridColumn
              field="brY_KANAL"
              title="Bry Kanal"
              width="192px"
              cell={BryCanalCell}
            />
            <GridColumn
              field="brY_URUN_KATEGORI"
              title="Bry Ürün Kategori"
              width="192px"
              cell={BryProductCategoryCell}
            />
            <GridColumn
              field="brY_URUN_GRUBU"
              title="Bry Ürün Grubu"
              width="192px"
              cell={BryProductGroupCell}
            />
            <GridColumn
              field="satiS_TEMSILCISI_KULLANICI_ADI"
              title="Satış Temsilcisi"
              width="192px"
              cell={SatisTemsilcisiCell}
            />
            <GridColumn
              field="bolgE_MUDURU_KULLANICI_ADI"
              title="Bölge Müdürü"
              width="192px"
              cell={BolgeMuduruCell}
            />
            <GridColumn
              field="gecerli"
              title="Geçerli"
              width="192px"
              cell={YesNoCell}
            />
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
