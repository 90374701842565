import { PR_DEX_BIREYSEL } from "../../config/globalVariables";
import { rawRequest, ServiceBase } from "../srv-base/ServiceBase";

class SalesService extends ServiceBase {
  constructor() {
    super(PR_DEX_BIREYSEL, "/pbsatis");
  }

  getListByYearAndMonth(year, months, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      PR_DEX_BIREYSEL,
      "/pbsatis",
      `/get-list-by-year-month`,
      { yil: year, aylar: months },
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new SalesService();
