import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import alertify from "alertifyjs";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Alert, Button } from "reactstrap";
import { DATAPARABIRIMI } from "../../config/globalVariables";
import TermService from "../../services/srv-fin-offset-term/TermService";
import OffsetService from "../../services/srv-fin-offset/OffsetService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import TermOffsetSummary from "./TermOffsetSummary";
import VirtualPostList from "./VirtualPostList";

const DBSList = (props) => {
  const SELECTED_FIELD = "selected";
  const initialFilter = {
    logic: "and",
    filters: [
      {
        field: "mahsuP_TIPI",
        operator: "eq",
        value: "DBS",
      },
      {
        field: "bayI_KODU",
        operator: "eq",
        value: props.auth.bayiKodu,
      },
    ],
  };
  const [filter, setFilter] = useState(initialFilter);
  const [isVisibleOffsetSummary, setVisibleOffsetSummary] = useState(false);
  const [offsetsLoading, setOffsetsLoading] = useState(false);
  const [mahsupKodu, setMahsupKodu] = useState(null);
  const [term, setTerm] = useState([]);
  const [offsets, setOffsets] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    TermService.getOpenedTerm(onCompletedGetTerm);
  }, []);

  const onCompletedGetTerm = (data) => {
    if (data) {
      setTerm(data);
      OffsetService.getDetailByDealerAndTerm(
        props.auth.bayiKodu,
        data.doneM_KODU,
        onCompletedGetOffsetDetail
      );
      getDBSData(data.doneM_KODU);
    }
  };

  const onCompletedGetOffsetDetail = (data) => {
    setMahsupKodu(data.mahsuP_KODU);
  };

  const getDBSData = (donemKodu) => {
    var filter = initialFilter;
    filter.filters.push({
      field: "doneM_KODU",
      operator: "eq",
      value: donemKodu,
    });
    getOffsetDataByFilter({ filter: filter });
  };

  const getOffsetDataByFilter = (dataState) => {
    setOffsetsLoading(true);
    OffsetService.getOffsets(dataState, onCompletedGetOffsets);
  };

  const onCompletedGetOffsets = (data) => {
    setOffsets(data.data);
    setOffsetsLoading(false);
    setData(data.data);
  };

  const [virtualPosVisible, setVirtualPosVisible] = useState(false);
  const [selectedDBSDataList, setSelectedDBSData] = useState([]);

  const toggleDialogVirtualPos = () => {
    if (virtualPosVisible === false) {
      var selectedData = offsets.filter((a) => a.selected === true);

      var distinctCurrencies = [
        ...new Set(selectedData.map((x) => x.parA_BIRIMI)),
      ];
      if (distinctCurrencies.length === 0) {
        alertify.error("Lütfen en az bir tane seçim yapınız.");
      } else if (distinctCurrencies.length > 1) {
        alertify.error("Lütfen tek bir para birimine ait DBS seçiniz.");
      } else {
        setSelectedDBSData(selectedData);
        setVirtualPosVisible(!virtualPosVisible);
      }
    } else {
      setVirtualPosVisible(!virtualPosVisible);
    }
  };

  const handleChangeCheckBox = (dataItem) => {
    var selectedId = dataItem.finM_MAHSUP_MST_ID;
    var newDBSData = [...offsets];
    var selectedData = newDBSData.find(
      (a) => a.finM_MAHSUP_MST_ID === selectedId
    );
    selectedData.selected = !selectedData.selected;
    setData(newDBSData);
  };

  const callbackAfterClearOffset = (event) => {
    getDBSData(term.doneM_KODU);
    setMahsupKodu(null);
  };

  const clearOffset = (event) => {
    var data = {
      bayI_KODU: props.auth.bayiKodu,
    };
    confirmAlert({
      title: "Uyarı!",
      message: "Mahsup işlemlerinizi sıfırlamak istediğinizden emin misiniz?.",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            OffsetService.clearOffset(data, callbackAfterClearOffset);
          },
        },
        {
          label: "Hayır",
        },
      ],
    });
  };

  const toggleDialogOffsetSummary = (e) => {
    setVisibleOffsetSummary(!isVisibleOffsetSummary);
  };

  const rowRenderCustom = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    if (dataItem.dataItem.selected) {
      trProps["style"] = {
        backgroundColor: "rgb(187 184 184)",
      };
    }

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const filterChange = (e) => {
    setData(filterBy(offsets, e.filter));
    setFilter(e.filter);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      {virtualPosVisible ? (
        <VirtualPostList
          toggleDialogVirtualPos={toggleDialogVirtualPos}
          dbsData={selectedDBSDataList}
          term={term}
        ></VirtualPostList>
      ) : undefined}
      {isVisibleOffsetSummary ? (
        <TermOffsetSummary
          mahsupKodu={mahsupKodu}
          toggleDialogOffsetSummary={toggleDialogOffsetSummary}
        ></TermOffsetSummary>
      ) : undefined}
      <Card>
        <CardHeader>
          <CardTitle>DBS Listesi</CardTitle>
        </CardHeader>
        <CardBody>
          {data.length === 0 ? (
            <Alert color="danger">
              <b>
                Dönem kapanmıştır. Güncel dönem verileri henüz sisteme
                yüklenmemiştir. Veriler yüklendikten sonra mahsup işleminizi
                gerçekleştirebilirsiniz.
              </b>
            </Alert>
          ) : undefined}
          {data.length > 0 && !mahsupKodu ? (
            <Alert color="warning">
              <b>
                Güncel dönem verileri sisteme yüklenmiştir. Mahsup işleminizi
                gerçekleştirebilirsiniz.
              </b>
            </Alert>
          ) : undefined}
          {offsetsLoading && LoadingPanel}
          <div className="row">
            <div className="col-12">
              <Grid
                data={data}
                sortable={false}
                style={{ height: "700px", fontSize: "11px" }}
                pageable={false}
                resizable={true}
                rowRender={rowRenderCustom}
                filterable
                filter={filter}
                onFilterChange={filterChange}
              >
                <GridToolbar>
                  {!mahsupKodu ? (
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      onClick={toggleDialogVirtualPos}
                    >
                      <span className="k-icon k-i-check-circle"></span> Mahsup
                      Et
                    </Button>
                  ) : (
                    <div>
                      <Button
                        color="danger"
                        type="button"
                        size="sm"
                        onClick={clearOffset}
                      >
                        <span className="k-icon k-i-check-circle"></span> Mahsup
                        İşlemini Temizle
                      </Button>{" "}
                      <Button
                        color="info"
                        type="button"
                        size="sm"
                        onClick={toggleDialogOffsetSummary}
                      >
                        MAHSUP DETAYI
                      </Button>
                    </div>
                  )}
                </GridToolbar>
                <GridColumn
                  field={SELECTED_FIELD}
                  width="50px"
                  cell={(props) => (
                    <td>
                      {props.dataItem.mahsuP_KODU ? undefined : (
                        <Checkbox
                          defaultChecked={false}
                          value={
                            offsets.find(
                              (s) =>
                                s.finM_MAHSUP_MST_ID ===
                                props.dataItem.finM_MAHSUP_MST_ID
                            ).selected
                          }
                          onChange={() => handleChangeCheckBox(props.dataItem)}
                        />
                      )}
                    </td>
                  )}
                />
                <GridColumn
                  field="doneM_YIL"
                  title="Yıl"
                  width="80px"
                  // filter="numeric"
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="doneM_AY"
                  title="Ay"
                  width="80px"
                  filterable={false}
                  // filterCell={(props) => (
                  //   <DropdownFilterCell
                  //     {...props}
                  //     data={DATAMONTH.map((a) => a.id)}
                  //     defaultValue={"Seçiniz"}
                  //   ></DropdownFilterCell>
                  // )}
                ></GridColumn>
                <GridColumn
                  field="doneM_KODU"
                  title="Dönem Kodu"
                  width="120px"
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="isleM_DURUMU"
                  title="İşlem Durumu"
                  width="120px"
                ></GridColumn>
                <GridColumn
                  field="kredI_KONTROL_ALANI"
                  title="Kredi Kontrol Alanı"
                  width="120px"
                ></GridColumn>
                <GridColumn
                  field="belgE_TURU"
                  title="Belge Türü"
                  width="120px"
                ></GridColumn>
                <GridColumn
                  field="belgE_NO"
                  title="Belge No"
                  width="120px"
                ></GridColumn>
                <GridColumn
                  field="ihtaR_ALANI"
                  title="İhtar Alanı"
                  width="100px"
                ></GridColumn>
                <GridColumn
                  field="bankA_ADI"
                  title="Banka Adı"
                  width="160px"
                ></GridColumn>
                <GridColumn
                  field="referanS_NO_2"
                  title="Referans No"
                  width="120px"
                ></GridColumn>
                <GridColumn
                  field="belgE_TARIHI"
                  title="Belge Tarihi"
                  width="120px"
                  cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="vadE_TARIHI"
                  title="Vade Tarihi"
                  width="120px"
                  cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="tutar"
                  title="Tutar"
                  width="80px"
                  cell={(props) => GridNumberFormatCell(props, 2)}
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="parA_BIRIMI"
                  title="Para Birimi"
                  width="120px"
                  filterCell={(props) => (
                    <DropdownFilterCell
                      {...props}
                      data={DATAPARABIRIMI.map((a) => a.text)}
                      defaultValue={"Seçiniz"}
                    ></DropdownFilterCell>
                  )}
                ></GridColumn>
                <GridColumn
                  field="tutaR_TRY"
                  title="Tutar (TRY)"
                  width="120px"
                  cell={(props) => GridNumberFormatCell(props, 2)}
                  filterable={false}
                ></GridColumn>
                <GridColumn
                  field="mahsuP_KODU"
                  title="Mahsup Kodu"
                  width="120px"
                ></GridColumn>
              </Grid>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card style={{ marginTop: "10px" }}>
        <CardBody>
          <Alert color="info">
            <b>
              Tüm mahsuplaşma işlemlerinizi 'Mahsup Et' butonu altından, açılan
              penceredeki yönergeleri izleyerek gerçekleştirebilirsiniz.
            </b>
          </Alert>
        </CardBody>
      </Card>
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(DBSList);
