import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";
import { process } from "@progress/kendo-data-query";

export default function campaignProductListReducer(
  state = initialState.campaignProductList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_BRY_CAMPAIGNS_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_BRY_CAMPAIGNS_PRODUCT_FULFILLED:
      return {
        ...state,
        campaignProducts: action.payload,
        result: process(action.payload.slice(0), { take: 6, skip: 0 }),
        fetching: false,
      };
    case actionTypes.FETCH_BRY_CAMPAIGNS_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_BRY_CAMPAIGNS_PRODUCT:
      return {
        ...state,
        dataState: action.payload,
        result: process(state.campaignProducts.slice(0), action.payload),
      };
    default:
      return state;
  }
}
