import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import { catchError } from "../srv-base/ServiceBase";

class CustomerService {
  getCustomers(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/musteri/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getCustomersWithTemp(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/musteri/get-list-with-temp?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getCustomerById(musId, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/musteri/get-by-id/${musId}`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getCustomerIdByBayiKodu(bayiKodu, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/musteri/get-mus-id-by-bayi-kodu/${bayiKodu}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getCustomerAddress(musId, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/musteri/get-address-list-by-mus-id/${musId}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
  getCustomerAddressByBayiKodu(bayiKodu, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/musteri/get-address-list-by-bayi-kodu/${bayiKodu}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
  getCustomerAddressByBayiKoduAdresBasligi(bayiKodu, adresBasligi, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/musteri/get-address-list-by-bayi-kodu-adres-basligi/${bayiKodu}/${adresBasligi}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
  upsertCustomerAddressInfo(addressInfo, callback, onErrorCallback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/musteri/upsert-customer-address`, {
        ...addressInfo,
      })
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      })
      .catch((error) =>
        catchError(error, onErrorCallback(error.response.data))
      );
  }

  insert(customer, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/musteri/add-customer`, customer)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  update(customer, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX}/musteri/update-customer`, customer)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  merge(customerMergeDto, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX}/musteri/merge-customer`, customerMergeDto)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new CustomerService();
