import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function complaintListReducer(
  state = initialState.complaintList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_COMPLAINT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_COMPLAINT_FULFILLED:
      return {
        ...state,
        complaints: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_COMPLAINT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_COMPLAINT:
      return {
        ...state,
        dataState: action.payload,
      };
    default:
      return state;
  }
}
