import axios from "axios";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_MERCURY } from "../../config/globalVariables";

class ManuelVadeEslemeService {
  getManuelVadeEslemeReports(dataState, callback) {
    return axios.get(`${API_GATEWAY + PR_DEX_MERCURY}/ManuelVadeEsleme/get-vade-esleme-report?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getManuelVadeEslemeReportsForExcel(dataState) {
    return axios
      .get(`${API_GATEWAY + PR_DEX_MERCURY}/ManuelVadeEsleme/get-list-for-excel?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        return response.data;
      });
  }
}

export default new ManuelVadeEslemeService();
