import axios from "axios";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_BIGG_LOYALTY } from "../../config/globalVariables";

class BiggSerialsModifiedService {
  getBiggSerialsModifiedReports(dataState, callback) {
    return axios.get(`${API_GATEWAY + PR_DEX_BIGG_LOYALTY}/Serials/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  } 
  getBiggSerialsModifiedReportsForExcel(dataState) {
    return axios
      .get(`${API_GATEWAY + PR_DEX_BIGG_LOYALTY}/Serials/get-list-for-excel?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        return response.data;
      });
  }
}

export default new BiggSerialsModifiedService();
