import { cloneElement, useState, useRef, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { Button } from "reactstrap";
import HizTestiService from "../../services/srv-hiz-testi/HizTestiService";
const initialDataState = {
  skip: 0,
  take: 10,
};
export function HizTesti() {
  const _grid = useRef(null);

  const [hizTestiLoading, setHizTestiLoading] = useState(false);
  const [hizTesti, setHizTesti] = useState([]);
  const [responseTime, setResponseTime] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [page, setPage] = useState(initialDataState);
  const [pageSizeValue, setPageSizeValue] = useState();
  const [results, setResults] = useState([]);
  const pageChange = (event) => {
    const take = event.page.take;
    if (take) {
      setPageSizeValue(take);
    }
    setPage({
      ...event.page,
      take,
    });
  };
  useEffect(() => {
    const result = {
      responseTime: responseTime ? formatResponseTime(responseTime) : "N/A",
      startTime: startTime && formatTime(startTime),
      endTime: endTime && formatTime(endTime),
    };
    responseTime && setResults((prevList) => [...prevList, result]);
  }, [responseTime]);

  const onGetDataCompleted = (data) => {
    setHizTesti(data);
    setHizTestiLoading(false);
  };

  const fetchData = async () => {
    setHizTestiLoading(true);
    const start = new Date();
    setStartTime(start);

    try {
      await HizTestiService.getHizTesti(onGetDataCompleted);
      const end = new Date();
      setEndTime(end);
      const duration = end - start;
      setResponseTime(duration);
    } catch (error) {
      console.error(error);
    }

    setHizTestiLoading(false);
  };

  const formatResponseTime = (duration) => {
    const minutes = Math.floor(duration / 60000);
    const seconds = ((duration % 60000) / 1000).toFixed(0);
    return `${minutes} dakika ${seconds} saniye`;
  };

  const formatTime = (time) => {
    return (
      time &&
      time.toLocaleString("tr-TR", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    );
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card
        style={{
          width: 300,
        }}
      ></Card>
      <div className="row justify-content-center">
        <div className="col-8">
          <Card className="card-wrapper" style={{ display: "flex" }}>
            <CardHeader>
              <CardTitle>Hız Testi</CardTitle>
            </CardHeader>
            <CardBody>
              <p>
                Aşağıdaki Butona tıkladığınızda bir istek atılacak. Bu istek ile
                100.000 datanın olduğu bir sonuç dönecektir. Süreyi "Sonuç"
                kısmında görebilirsiniz.
              </p>
              <p style={{ color: "red" }}>Max 10 sn. sürmesi beklenmektedir</p>
              <Button
                style={{ marginTop: "10px" }}
                color="primary"
                onClick={fetchData}
              >
                Verileri Getir
              </Button>
            </CardBody>
            <CardBody>
              {hizTestiLoading && LoadingPanel}
              <Grid
                ref={_grid}
                sortable={false}
                resizable={false}
                filterable={false}
                data={hizTesti.slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={hizTesti.length}
                style={{ minHeight: "400px", fontSize: "11px" }}
                rowRender={(trElement, dataItem) =>
                  rowRender(trElement, dataItem)
                }
                pageable={{
                  buttonCount: 4,
                  pageSizes: [5, 10, 15, 50],
                  pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}
              >
                <GridColumn field="id" title="ID" width="144px" />
                <GridColumn field="marka" title="DATA" width="144px" />
              </Grid>
            </CardBody>
          </Card>
        </div>
        <div className="col-4">
          <Card className="card-wrapper">
            <CardHeader>
              <CardTitle>Sonuçlar</CardTitle>
            </CardHeader>
            <CardBody>
              {hizTestiLoading && LoadingPanel}
              <Grid
                ref={_grid}
                sortable={false}
                resizable={false}
                filterable={false}
                data={results.slice(page.skip, page.take + page.skip)}
                skip={page.skip}
                take={page.take}
                total={results.length}
                style={{ minHeight: "400px", fontSize: "11px" }}
                rowRender={(trElement, dataItem) =>
                  rowRender(trElement, dataItem)
                }
                pageable={{
                  buttonCount: 4,
                  pageSizes: [5, 10, 15, 50],
                  pageSizeValue: pageSizeValue,
                }}
                onPageChange={pageChange}
              >
                <GridColumn
                  field="startTime"
                  title="BAŞLANGIÇ TARİHİ"
                  width="170px"
                />
                <GridColumn
                  field="endTime"
                  title="BİTİŞ TARİHİ"
                  width="144px"
                />
                <GridColumn field="responseTime" title="SÜRE" width="144px" />
              </Grid>
            </CardBody>
          </Card>
        </div>
      </div>
    </main>
  );
}
