import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function paymentExpiryListReducer(
  state = initialState.paymentExpiryList,
  action
) {
  switch (action.type) {
    case actionTypes.GET_PAYMENT_EXPIRY:
      return {
        ...state,
        paymentExpiry: action.payload,
      };
    case actionTypes.GET_PAYMENT_EXPIRY_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_PAYMENT_EXPIRY_FULFILLED:
      return {
        ...state,
        paymentExpiry: action.payload,
        fetching: false,
      };
    case actionTypes.GET_PAYMENT_EXPIRY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
