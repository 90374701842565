import { Upload } from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  API_GATEWAY,
  CDN_PROJECT_NAME,
  MS_SENDER,
} from "../../config/globalVariables";
import FileService from "../../services/srv-files/FileService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const CommonUploadFiles = ({
  batch,
  multiple,
  disabled,
  withCredentials,
  tableName,
  tableId,
  fileType,
}) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getFiles();
  }, []);

  const getFiles = () => {
    FileService.getFiles(tableName, tableId, fileType, callback);
  };

  const callback = (data) => {
    if (data) {
      setFiles(data);
    }

    setIsLoading(false);
  };

  const downloadFile = (cdn_file) => {
    setIsLoading(true);
    FileService.downloadFile(cdn_file, callback);
  };

  const onAdd = (event) => {
    setFiles(event.newState);
  };

  const onRemove = (fileId, tableName, fileType, tableId) => {
    setIsLoading(true);
    FileService.deleteFile(fileId, tableName, tableId, fileType, callback);
  };

  const onProgress = (event) => {
    setFiles(event.newState);
  };

  const onUploadSuccess = (uid, event) => {
    getFiles();
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
    getFiles();
  };

  const fileIconClass = (extension) => {
    switch (extension) {
      case ".csv":
      case ".xlsx":
      case ".xls":
        return "k-i-file-data";
      case ".png":
      case ".jpeg":
        return "k-i-file-image";
      case ".pdf":
        return "k-i-file-pdf";
      case ".txt":
      default:
        return "k-i-file-txt";
    }
  };

  const listItem = (props) => {
    return (
      <>
        {props.files?.map((file) => {
          return (
            <div className="k-file-single" key={file.name}>
              <span class="k-file-group-wrapper">
                <span
                  class={`k-file-group k-icon ${fileIconClass(file.extension)}`}
                ></span>
                <span class="k-file-state"></span>
              </span>
              <span class="k-file-name-size-wrapper">
                <span class="k-file-name" title={file.name}>
                  {file.name}
                </span>
                <span class="k-file-validation-message"></span>
              </span>
              <strong class="k-upload-status">
                <button
                  type="button"
                  class="k-button k-button-icon k-flat k-upload-action"
                  onClick={() => downloadFile(file.cdnFileId)}
                >
                  <span
                    aria-label="Download"
                    title="Download"
                    class="k-icon k-download k-i-download"
                  ></span>
                </button>
                <button
                  type="button"
                  class="k-button k-button-icon k-flat k-upload-action"
                  onClick={() =>
                    onRemove(file.fileId, tableName, fileType, tableId)
                  }
                >
                  <span
                    aria-label="Remove"
                    title="Remove"
                    class="k-icon k-delete k-i-x"
                  ></span>
                </button>
              </strong>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      {isLoading && LoadingPanel}
      <Upload
        batch={batch ? batch : false}
        multiple={multiple ? multiple : true}
        disabled={disabled}
        files={files}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onUploadSuccess={onUploadSuccess}
        onStatusChange={onStatusChange}
        listItemUI={listItem}
        withCredentials={withCredentials ? withCredentials : false}
        saveHeaders={{
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        }}
        removeHeaders={{
          Authorization: "Bearer " + localStorage.getItem("jwtToken"),
        }}
        saveUrl={`${
          API_GATEWAY + MS_SENDER
        }/file/add/${CDN_PROJECT_NAME}/${tableName}/${tableId}/${fileType}`}
        removeUrl={(event) => {
          var fileId = files.find((f) => f.uid === event[0].uid).fileId;
          return `${
            API_GATEWAY + MS_SENDER
          }/file/delete/${CDN_PROJECT_NAME}/${fileId}`;
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(CommonUploadFiles);
