import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Fragment, cloneElement, useEffect, useRef, useState } from "react";
import { BASE_URL } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import OrderService from "../../services/srv-order/OrderService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

import ExportExcel from "../sys-toolbox/ExcelExport";
import { MyOrderGridColumns } from "./MyOrderGridColumns";
import MyOrderUpdateBry from "../cmp-order-my/MyOrderUpdateBry";
import MyOrderUpdateMrc from "../cmp-order-my/MyOrderUpdateMrc";

export function MyOrderList() {
  const _grid = useRef(null);
  const sort = [
    {
      field: "tekliF_SIPARIS_ID",
      dir: "desc",
    },
  ];

  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState(null);
  const [myOrdersDataState, setMyOrdersDataState] = useState({
    take: 10,
    skip: 0,
    sort,
  });

  const [visibleMyOrderUpdateBry, setVisibleMyOrderUpdateBry] = useState(false);
  const [tableData, setTableData] = useState({});

  const [visibleMyOrderUpdateMrc, setVisibleMyOrderUpdateMrc] = useState(false);

  useEffect(() => {
    getListMyOrder();
  }, []);

  function toggleDialogMyOrderUpdateBry(dataItem) {
    setVisibleMyOrderUpdateBry(!visibleMyOrderUpdateBry);
    setTableData(dataItem);
  }

  function toggleDialogMyOrderUpdateMrc(dataItem) {
    setVisibleMyOrderUpdateMrc(!visibleMyOrderUpdateMrc);
    setTableData(dataItem);
  }

  function onGetMyOrdersCompleted(data) {
    setMyOrders(data);
    setLoading(false);
  }

  function getListMyOrder() {
    setLoading(true);
    OrderService.getListMyOrder(myOrdersDataState, onGetMyOrdersCompleted);
  }

  function onDataStateChange(event) {
    filterLocalization(event);
    setLoading(true);
    setMyOrdersDataState(event.dataState);
    OrderService.getListMyOrder(event.dataState, onGetMyOrdersCompleted);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  function goOrderDetail(dataItem) {
    if (dataItem.tekliF_SIPARIS === "TEKLIF") {
      window.open(
        BASE_URL +
          "Dex/Mercury/TeklifGuncelle?teklifId=" +
          dataItem.tekliF_SIPARIS_ID
      );
    }
    if (dataItem.tekliF_SIPARIS === "SIPARIS") {
      window.open(BASE_URL + "order-update/" + dataItem.tekliF_SIPARIS_ID);
    }
  }

  function onCallbackGeriGonder() {
    getListMyOrder();
  }

  return (
    <Fragment>
      {visibleMyOrderUpdateBry ? (
        <MyOrderUpdateBry
          toggleDialog={toggleDialogMyOrderUpdateBry}
          title="Sipariş Güncelle"
          data={tableData}
          callBack={onCallbackGeriGonder}
        ></MyOrderUpdateBry>
      ) : (
        ""
      )}

      {visibleMyOrderUpdateMrc ? (
        <MyOrderUpdateMrc
          toggleDialog={toggleDialogMyOrderUpdateMrc}
          title="Sipariş Güncelle"
          data={tableData}
          callBack={onCallbackGeriGonder}
        ></MyOrderUpdateMrc>
      ) : (
        ""
      )}
      <Card>
        <CardHeader>
          <CardTitle>Siparişlerim</CardTitle>
        </CardHeader>
        <CardBody>
          {loading && LoadingPanel}
          <Grid
            ref={_grid}
            data={myOrders}
            dataItemKey={"tekliF_SIPARIS_ID"}
            {...myOrdersDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ExportExcel
                data={myOrders}
                columns={MyOrderGridColumns}
                loadingFunction={setLoading}
                dataState={myOrdersDataState}
                serviceMethod={OrderService.getListMyOrderForExcel}
                fileName="Siparislerim"
              ></ExportExcel>
            </GridToolbar>

            <GridColumn
              filterable={false}
              width="200px"
              cell={(props) => (
                <td>
                  <button
                    title="Düzenle"
                    className="btn btn-info btn-sm mr-1"
                    onClick={() =>
                      props.dataItem.tekliF_SIPARIS === "SIPARIS"
                        ? toggleDialogMyOrderUpdateBry(props.dataItem)
                        : toggleDialogMyOrderUpdateMrc(props.dataItem)
                    }
                  >
                    <i class="fas fa-edit"></i> Güncelle
                  </button>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => goOrderDetail(props.dataItem)}
                  >
                    <i className="fas fa-info-circle"></i> Detay
                  </button>
                </td>
              )}
            />
            {MyOrderGridColumns.map((column) => {
              return (
                <GridColumn
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  filter={column.filter}
                  filterable={column.filterable ? column.filterable : true}
                  cell={column.cell}
                  filterCell={column.filterCell}
                  resizable={column.resizable}
                />
              );
            })}
          </Grid>
        </CardBody>
      </Card>
    </Fragment>
  );
}
