import axios from 'axios';

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';

import {
  API_GATEWAY,
  PR_DEX_BIREYSEL,
} from '../../../config/globalVariables';
import * as actionTypes from '../sys-configure/actionTypes';

/**
 * DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU listesini fetch eder
 *
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU listesi geçer
 */
export function fetchSalesChannelProductGroup(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_CHANNEL_PRODUCT_GROUP,
      payload: fetchSalesChannelProductGroupPayload(dataState, callback),
    });
  };
}

export function fetchSalesChannelProductGroupPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_BIREYSEL
      }/IndirimSatisKanaliGrubu/get-all?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

export function chanceSalesChannelProductGroup(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_SALES_CHANNEL_PRODUCT_GROUP,
      payload: event,
    });
  };
}

/**
 * Yeni DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 *
 * @param {object} data DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU listesi geçer
 */
export function insertSalesChannelProductGroup(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_SALES_CHANNEL_PRODUCT_GROUP,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliGrubu/add-sales-chanell-product-group`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProductGroup(dataState, callback));
          return result.data;
        }),
    });
  };
}

/**
 * Güncelle DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 *
 * @param {object} data DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU listesi geçer
 */
export function updateSalesChannelProductGroup(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SALES_CHANNEL_PRODUCT_GROUP,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliGrubu/update-sales-chanell-product-group`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProductGroup(dataState, callback));
          return result.data.message;
        }),
    });
  };
}

/**
 * Sil DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 *
 * @param {object} data DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_6_SATIS_KANALI_URUN_GRUBU listesi geçer
 */
export function deleteSalesChannelProductGroup(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_REMOVE_SALES_CHANNEL_PRODUCT_GROUP,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliGrubu/delete-sales-chanell-product-group`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProductGroup(dataState, callback));
          return result.data.message;
        }),
    });
  };
}
