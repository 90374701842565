import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function getCampaignChannels(kampanyaKodu) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_CAMPAIGN_CHANNELS,
      payload: kampanyaKodu
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/kampanyakatilimcikanal/get-list-by-kampanya/${kampanyaKodu}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}
