import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import alertify from "alertifyjs";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import {
  deleteOrderResponsiblePerson,
  getOrderResponsiblePersons,
  upsertOrderResponsiblePerson,
} from "../../redux/actions/act-order/orderResponsiblePersonActions";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";

const primaryKey = "brY_SIPARIS_YETKILI_KISI_BILGILERI_ID";
const editField = "inEdit";

class OrderReponsiblePerson extends Component {
  state = {
    personList: [],
  };

  componentDidMount() {
    this.getPersonList();
  }

  getPersonList = () => {
    this.props.getOrderResponsiblePersons(
      this.props.orderId,
      this.callbackAfterGetList
    );
  };

  callbackAfterGetList = (data) => {
    this.setState({ personList: data });
  };

  callbackAfterUpdate = () => {
    this.getPersonList();
  };

  dataStateChange = (event) => {
    this.props.fetchSalesDepartment(event.dataState, this.callbackAfterUpdate);
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      remove={this.remove}
      add={this.add}
      discard={this.discard}
      update={this.update}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField="aD_SOYAD"
    />
  );

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.personList.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.personList.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        personList: { ...this.state.personList, data },
      });
    }
  };

  remove = (dataItem) => {
    this.props.deleteOrderResponsiblePerson(dataItem, this.callbackAfterUpdate);
  };

  add = (dataItem) => {
    if (dataItem.aD_SOYAD && dataItem.telefon && dataItem.mail) {
      dataItem.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID = 0;
      dataItem.brY_SIPARIS_ID = parseInt(this.props.orderId);
      this.props.upsertOrderResponsiblePerson(
        dataItem,
        this.callbackAfterUpdate
      );
      dataItem.inEdit = undefined;
    } else {
      alertify.error("Lütfen zorunlu alanları doldurunuz.");
    }
  };

  update = (dataItem) => {
    if (dataItem.aD_SOYAD && dataItem.telefon && dataItem.mail) {
      this.props.upsertOrderResponsiblePerson(
        dataItem,
        this.callbackAfterUpdate
      );
      dataItem.inEdit = undefined;
    } else {
      alertify.error("Lütfen zorunlu alanları doldurunuz.");
    }
  };

  discard = () => {
    const data = [...this.state.personList];
    data.splice(0, 1);
    this.setState({ personList: data });
  };

  cancel = (dataItem) => {
    const originalItem =
      this.props.orderResponsiblePersonList.responsiblePersons.find(
        (p) =>
          p.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID ===
          dataItem.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID
      );
    const data = this.state.personList.map((item) =>
      item.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID ===
      originalItem.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID
        ? originalItem
        : item
    );

    this.setState({ personList: data });
  };

  addNew = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = this.state.personList.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        personList: [newDataItem, ...this.state.personList],
      });
    }
  };

  itemChange = (event) => {
    const data = this.state.personList.map((item) =>
      item.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID ===
      event.dataItem.brY_SIPARIS_YETKILI_KISI_BILGILERI_ID
        ? { ...item, [event.field]: event.value }
        : item
    );

    this.setState({ personList: data });
  };

  rowRender = (trElement) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  render() {
    return (
      <Window
        title={this.props.title}
        onClose={this.props.toggleDialog}
        initialHeight={"70vh"}
        initialWidth={"70%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <Grid
          ref={(grid) => {
            this._grid = grid;
          }}
          data={this.state.personList}
          {...this.props.orderResponsiblePersonList.dataState}
          onDataStateChange={this.dataStateChange}
          sortable={true}
          style={{ height: "350px" }}
          onItemChange={this.itemChange}
          editField={editField}
          resizable
          filterable
          rowRender={(trElement, dataItem) =>
            this.rowRender(trElement, dataItem)
          }
        >
          <GridToolbar>
            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={() => this.addNew()}
            >
              {" "}
              <span className="k-icon k-i-plus-circle"></span> Yetkili Kişi Ekle
            </Button>
          </GridToolbar>
          <GridColumn
            cell={this.CommandCell}
            width="100px"
            filterable={false}
          />
          <GridColumn
            field="aD_SOYAD"
            title="Ad Soyad"
            width="300px"
            editor={"text"}
          ></GridColumn>
          <GridColumn
            field="telefon"
            title="Telefon"
            width="200px"
            editor={"text"}
          ></GridColumn>
          <GridColumn
            field="mail"
            title="Mail"
            width="300px"
            editor={"text"}
          ></GridColumn>
        </Grid>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderResponsiblePersonList: state.orderResponsiblePersonListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderResponsiblePersons: bindActionCreators(
      getOrderResponsiblePersons,
      dispatch
    ),
    upsertOrderResponsiblePerson: bindActionCreators(
      upsertOrderResponsiblePerson,
      dispatch
    ),
    deleteOrderResponsiblePerson: bindActionCreators(
      deleteOrderResponsiblePerson,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderReponsiblePerson);
