import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Window } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Button, Col, Row } from "reactstrap";
import { TIMEZONE } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmSuccessMessage,
  getLocalDate,
} from "../../helpers/utils";
import AnnouncementService from "../../services/srv-announcement/AnnouncementService";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import CommonInput from "../sys-common/CommonInput";
import DealerMultiSelect from "../sys-common/DealerMultiSelect";
import RoleMultiSelect from "../sys-common/RoleMultiSelect";
import UserMultiSelect from "../sys-common/UserMultiSelect";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import AnnouncementEditor from "./AnnouncementEditor";
import { AnnouncementFileUpload } from "./AnnouncementFileUpload.js";
import { DAGITIM_TIPI, DUYURU_TIPI } from "./AnnouncementVariables";

const Announcement = (props) => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [duyuruId, setDuyuruId] = useState(props.data?.duyurU_ID);
  const [announcementDealers, setAnnouncementDealers] = useState([]);
  const [announcementUsers, setAnnouncementUsers] = useState([]);
  const [announcementRoles, setAnnouncementRoles] = useState([]);

  useEffect(() => {
    if (duyuruId > 0) {
      getAnnouncement();
    }
  }, [duyuruId]);

  // useEffect(() => {
  //   if (data?.dagitim == "ROLE") {
  //     setAnnouncementDealers([]);
  //     setAnnouncementUsers([]);
  //   }
  //   vsvsvs
  // }, [data?.dagitim]);

  function getAnnouncement() {
    setIsLoading(true);
    AnnouncementService.getAnnouncementById(duyuruId, onCompletedGetData);
  }

  function onCompletedGetData({ data }) {
    if (data) {
      data.dkN_DUYURU_TANIM && setData(data.dkN_DUYURU_TANIM);
      data.bayiler && setAnnouncementDealers(data.bayiler);
      data.roller && setAnnouncementRoles(data.roller);
      data.kullanicilar && setAnnouncementUsers(data.kullanicilar);
    }
    setIsLoading(false);
  }

  function onCompletedUpsert(axiosResult) {
    setIsLoading(false);
    setDuyuruId(axiosResult.data.data);
    defaultAlertMessages.MessageDetailSuccess = axiosResult.data.message;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
    return;
  }

  const saveClickHandler = () => {
    setIsLoading(true);
    var announcementDto = {
      dkN_DUYURU_TANIM: data,
      bayiler: announcementDealers,
      roller: announcementRoles,
      kullanicilar: announcementUsers,
    };
    data.duyurU_ID > 0
      ? AnnouncementService.update(announcementDto, onCompletedUpsert)
      : AnnouncementService.insert(announcementDto, onCompletedUpsert);
  };

  return (
    <Window
      title={duyuruId > 0 ? "Duyuru Düzenle" : "Duyuru Ekle"}
      onClose={props.modalToggle}
      initialHeight={"85vh"}
      initialWidth={"70%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {isLoading && LoadingPanel}
      <Row>
        <Col>
          <CommonDropDownList
            id="duyurU_TIPI"
            label="Duyuru Tipi"
            data={DUYURU_TIPI}
            onChange={(e) =>
              setData({ ...data, duyurU_TIP: e.target.value.id })
            }
            required={true}
            value={data?.duyurU_TIP}
          />
        </Col>
        <Col>
          <CommonDropDownList
            id="dagitim"
            label="Dağıtım Tipi"
            data={DAGITIM_TIPI}
            onChange={(e) => {
              setData({ ...data, dagitim: e.target.value.id });
              if (
                e.target.value.id == "TUM_BAYI_KULLANICILARI" ||
                e.target.value.id == "TUM_DAIKIN_KULLANICILARI"
              ) {
                setAnnouncementDealers([]);
                setAnnouncementRoles([]);
                setAnnouncementUsers([]);
              }
            }}
            required={true}
            value={data?.dagitim}
          />
        </Col>
        <Col>
          <CommonInput
            id="sirA_NO"
            name="sirA_NO"
            label="Sıra"
            value={data?.sirA_NO ? data?.sirA_NO : ""}
            onChange={(e) => setData({ ...data, sirA_NO: e.target.value })}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateTimePicker
            id="baslangiC_TARIHI"
            label="Başlangıç Tarihi"
            onChange={(e) => {
              setData({
                ...data,
                baslangiC_TARIHI: getLocalDate(e.value, TIMEZONE),
                baS_TARIHI: getLocalDate(e.value, TIMEZONE),
              });
            }}
            value={
              data?.baslangiC_TARIHI ? new Date(data?.baslangiC_TARIHI) : null
            }
            required={true}
          />
        </Col>
        <Col>
          <DateTimePicker
            id="bitiS_TARIHI"
            label="Bitiş Tarihi"
            onChange={(e) => {
              setData({
                ...data,
                bitiS_TARIHI: getLocalDate(e.value, TIMEZONE),
                biT_TARIHI: getLocalDate(e.value, TIMEZONE),
              });
            }}
            value={data?.bitiS_TARIHI ? new Date(data?.bitiS_TARIHI) : null}
            required={true}
          />
        </Col>
        <Col>
          <YesNoDropDownList
            id="gecerli"
            name="gecerli"
            label="Geçerli"
            required={true}
            value={data?.gecerli}
            onChange={(e) => {
              setData({ ...data, gecerli: e.value });
            }}
          />
        </Col>
      </Row>
      {data?.dagitim && (
        <Row>
          <Col>
            {data.dagitim == "ROLE" ? (
              <RoleMultiSelect
                value={announcementRoles}
                required={data.dagitim == "ROLE"}
                onChange={(e) => {
                  setAnnouncementRoles(e.target.value.map((x) => x.rolE_KODU));
                }}
              />
            ) : null}
            {data.dagitim == "BAYI" ? (
              <DealerMultiSelect
                value={announcementDealers}
                required={data.dagitim == "BAYI"}
                onChange={(e) => {
                  setAnnouncementDealers(
                    e.target.value.map((x) => x.bayI_KODU)
                  );
                  setAnnouncementRoles([]);
                  setAnnouncementUsers([]);
                }}
              />
            ) : null}
            {data.dagitim == "KULLANICI" ? (
              <UserMultiSelect
                value={announcementUsers}
                required={data.dagitim == "KULLANICI"}
                onChange={(e) => {
                  setAnnouncementUsers(
                    e.target.value.map((x) => x.kullanicI_ID)
                  );
                  setAnnouncementDealers([]);
                  setAnnouncementRoles([]);
                }}
              />
            ) : null}
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <CommonInput
            id="duyurU_LINK"
            name="duyurU_LINK"
            label="Link"
            //placeholder="https://www.youtube-nocookie.com/embed/..."
            value={data?.duyurU_LINK ? data?.duyurU_LINK : ""}
            onChange={(e) => setData({ ...data, duyurU_LINK: e.target.value })}
          />
        </Col>
      </Row>
      {duyuruId > 0 && (
        <Row style={{ marginTop: "2%" }}>
          <Col>
            <AnnouncementFileUpload
              multiple={true}
              disabled={!duyuruId}
              title="Duyuru Tanım Dosyaları"
              tableId={duyuruId}
              fileType="DUYURU"
              tableName="DKN_DUYURU_TANIM"
            />
          </Col>
          <Col>
            <AnnouncementFileUpload
              disabled={!duyuruId}
              title="Duyuru Icon Dosyası"
              tableId={duyuruId}
              fileType="DUYURU_ICON"
              tableName="DKN_DUYURU_TANIM"
            />
          </Col>
        </Row>
      )}
      <Row style={{ marginTop: "2%" }}>
        <Col>
          <AnnouncementEditor
            value={data?.icerik}
            onChange={(e) => {
              setData({
                ...data,
                icerik: e.html,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            style={{ marginTop: "10px" }}
            color="primary"
            onClick={saveClickHandler}
          >
            Kaydet
          </Button>
        </Col>
      </Row>
    </Window>
  );
};

export default Announcement;
