import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import promiseMiddleware from "redux-promise-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import { PROJECT_CODE } from "../../../config/globalVariables";

export default function configureStore() {
  var middleware = composeWithDevTools(
    applyMiddleware(promiseMiddleware, thunk, logger)
  );
  if (PROJECT_CODE === "DEX_PROD") {
    middleware = applyMiddleware(promiseMiddleware, thunk);
  }

  return createStore(rootReducer, middleware);
}
