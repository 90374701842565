import React from 'react'

function Unauthorized() {
    return (
        <div>
            Sayfayı görüntüleme yetkiniz yoktur!
        </div>
    )
}

export default Unauthorized
