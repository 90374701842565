import { Window } from "@progress/kendo-react-dialogs";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { formatDate } from "@telerik/kendo-intl";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  changeProductPriceLog,
  fetchProductPriceLog,
} from "../../redux/actions/act-productPriceLog/productPriceLogActions";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class ProductPriceListLog extends Component {
  componentDidMount() {
    this.props.fetchProductPriceLog(this.props.code);
  }

  _export;
  export = () => {
    this._export.save();
  };

  dataStateChange = (event) => {
    this.props.changeProductPriceLog(event.dataState);
  };

  dateByFormat = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return (
      <td>
        {" "}
        {dataValue
          ? formatDate(new Date(dataValue), "dd.MM.yyyy HH:mm")
          : ""}{" "}
      </td>
    );
  };

  render() {
    return (
      <Window
        title={"Ürün Fiyat Hareket Listesi"}
        onClose={this.props.toggleDialog}
        initialHeight={"85vh"}
        initialWidth={"80vw"}
      >
        <Row>
          <Col lg="12" md="12" xs="12">
            <div>
              {this.props.productPriceLogList.fetching && LoadingPanel}
              <ExcelExport
                data={this.props.productPriceLogList.productPriceLogs}
                ref={(exporter) => (this._export = exporter)}
              >
                <Grid
                  data={this.props.productPriceLogList.result}
                  {...this.props.productPriceLogList.dataState}
                  onDataStateChange={this.dataStateChange}
                  sortable={true}
                  pageable={true}
                  resizable={true}
                  pageSize={6}
                  style={{ height: "510px" }}
                >
                  <GridToolbar>
                    <button
                      title="Export Excel"
                      className="btn btn-info"
                      onClick={this.export}
                    >
                      Excel
                    </button>
                  </GridToolbar>
                  <GridColumn
                    field="uruN_KODU"
                    title="Ürün Kodu"
                    width="120px"
                    editable={false}
                  ></GridColumn>
                  <GridColumn
                    field="kullanicI_AD_SOYAD"
                    title="Yapan Kişi"
                    width="180px"
                    editable={false}
                  ></GridColumn>
                  <GridColumn
                    field="crE_DATE"
                    title="Tarih"
                    cell={this.dateByFormat}
                    width="120px"
                    filterable={false}
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_DOM_TRY"
                    title="Seçim Fiyatı SAP DOM TRY"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_DOM_USD"
                    title="Seçim Fiyatı SAP DOM USD"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_DOM_EUR"
                    title="Seçim Fiyatı SAP DOM EUR"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_EXP_TRY"
                    title="Seçim Fiyatı SAP EXP TRY"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_EXP_USD"
                    title="Seçim Fiyatı SAP EXP USD"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="seciM_FIYATI_SAP_EXP_EUR"
                    title="Seçim Fiyatı SAP EXP EUR"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_DOM_TRY"
                    title="Liste Fiyatı SAP DOM TRY"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_DOM_USD"
                    title="Liste Fiyatı SAP DOM USD"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_DOM_EUR"
                    title="Liste Fiyatı SAP DOM EUR"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_EXP_TRY"
                    title="Liste Fiyatı SAP EXP TRY"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_EXP_USD"
                    title="Liste Fiyatı SAP EXP USD"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_FIYATI_SAP_EXP_EUR"
                    title="Liste Fiyatı SAP EXP EUR"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                  <GridColumn
                    field="kdv"
                    title="KDV"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="orjinaL_KUR"
                    title="Orjinal Kur"
                    width="120px"
                    editor="numeric"
                    format="{0:N2}"
                  ></GridColumn>
                </Grid>
              </ExcelExport>
              <br></br>
            </div>
          </Col>
        </Row>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productPriceLogList: state.productPriceLogListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchProductPriceLog: bindActionCreators(fetchProductPriceLog, dispatch),
    changeProductPriceLog: bindActionCreators(changeProductPriceLog, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ProductPriceListLog));
