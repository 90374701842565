import { toDataSourceRequestString } from "@progress/kendo-data-query";
import { PR_DEX_MERCURY } from "../../../config/globalVariables";
import {
  serviceMessageError,
  serviceMessageSuccess,
} from "../../../helpers/utils";
import { rawRequest, ServiceBase } from "../../srv-base/ServiceBase";

class OfferDenvService extends ServiceBase {
  constructor() {
    super(PR_DEX_MERCURY, "/teklifdenvonay");
  }

  getById(id, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/teklifdenvonay",
      `/get-by-id/${id}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  getOfferDenvListForExcel(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/teklifdenvonay",
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  approveReject(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      PR_DEX_MERCURY,
      "/teklifdenvonay",
      `/execute-denv-onay-islemleri`,
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new OfferDenvService();
