import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Upload } from "@progress/kendo-react-upload";
import { useEffect, useState } from "react";
import {
  API_GATEWAY,
  CDN_PROJECT_NAME,
  MS_SENDER,
} from "../../config/globalVariables";
import FileService from "../../services/srv-files/FileService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export const AnnouncementFileUpload = ({
  disabled,
  fileType,
  tableId,
  title,
  batch,
  multiple,
  withCredentials,
  tableName,
}) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!disabled) {
      setIsLoading(true);
      getFiles();
    }
  }, [disabled]);

  const getFiles = () => {
    FileService.getFiles(tableName, tableId, fileType, callback);
  };

  const callback = (data) => {
    if (data) {
      setFiles(data);
    }
    setIsLoading(false);
  };

  const downloadFile = (cdn_file) => {
    setIsLoading(true);
    FileService.downloadFile(cdn_file, callback);
  };

  const onAdd = (event) => {
    fileType == "DUYURU_ICON" &&
      files.length > 0 &&
      files.map(
        (file) =>
          !file.validationErrors &&
          onRemoveWithoutCallback(
            file.fileId,
            tableName,
            "DUYURU_ICON",
            tableId
          )
      );
    setFiles(event.newState);
  };

  const onRemove = (fileId, tableName, fileType, tableId) => {
    setIsLoading(true);
    FileService.deleteFile(fileId, tableName, tableId, fileType, callback);
  };

  const onRemoveWithoutCallback = (fileId, tableName, fileType, tableId) => {
    FileService.deleteFile(fileId, tableName, tableId, fileType);
  };

  const onProgress = (event) => {
    setFiles(event.newState);
  };

  const onStatusChange = (event) => {
    setFiles(event.newState);
    getFiles();
  };

  const fileIconClass = (extension) => {
    switch (extension) {
      case ".csv":
      case ".xlsx":
      case ".xls":
        return "k-i-file-data";
      case ".png":
      case ".jpeg":
        return "k-i-file-image";
      case ".pdf":
        return "k-i-file-pdf";
      case ".txt":
      default:
        return "k-i-file-txt";
    }
  };

  const listItem = (props) => {
    return (
      <>
        {props.files?.map((file) => {
          return (
            <div className="k-file-single" key={file.name}>
              <span class="k-file-group-wrapper">
                <span
                  class={`k-file-group k-icon ${fileIconClass(file.extension)}`}
                ></span>
                <span class="k-file-state"></span>
              </span>
              <span class="k-file-name-size-wrapper">
                <span class="k-file-name" title={file.name}>
                  {file.name}
                </span>
                {file.validationErrors?.includes("invalidFileExtension") ? (
                  <span class="k-upload k-file-error k-file-validation-message">
                    Lütfen .jpg - .jpeg - .png - .ico uzantılı dosya seçiniz.
                  </span>
                ) : null}
              </span>
              {file.validationErrors?.includes(
                "invalidFileExtension"
              ) ? null : (
                <strong class="k-upload-status">
                  <button
                    type="button"
                    class="k-button k-button-icon k-flat k-upload-action"
                    onClick={() => downloadFile(file.cdnFileId)}
                  >
                    <span
                      aria-label="Download"
                      title="Download"
                      class="k-icon k-download k-i-download"
                    ></span>
                  </button>
                  <button
                    type="button"
                    class="k-button k-button-icon k-flat k-upload-action"
                    onClick={() =>
                      onRemove(file.fileId, tableName, fileType, tableId)
                    }
                  >
                    <span
                      aria-label="Remove"
                      title="Remove"
                      class="k-icon k-delete k-i-x"
                    ></span>
                  </button>
                </strong>
              )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <CardBody>
        {isLoading && LoadingPanel}
        <Upload
          batch={batch ? batch : false}
          multiple={multiple ? multiple : false}
          restrictions={
            fileType == "DUYURU_ICON" && {
              allowedExtensions: ["jpg", "png", "jpeg", "ico"],
            }
          }
          disabled={disabled}
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          onProgress={onProgress}
          onStatusChange={onStatusChange}
          listItemUI={listItem}
          withCredentials={withCredentials ? withCredentials : false}
          saveHeaders={{
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          }}
          removeHeaders={{
            Authorization: "Bearer " + localStorage.getItem("jwtToken"),
          }}
          saveUrl={`${
            API_GATEWAY + MS_SENDER
          }/file/add/${CDN_PROJECT_NAME}/${tableName}/${tableId}/${fileType}`}
          removeUrl={(event) => {
            var fileId = files.find((f) => f.uid === event[0].uid).fileId;
            return `${
              API_GATEWAY + MS_SENDER
            }/file/delete/${CDN_PROJECT_NAME}/${fileId}`;
          }}
        />
      </CardBody>
    </Card>
  );
};
