import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

import axios from "axios";

export function getSalesChannelsByFilter(code) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_SALES_CHANNELS_BY_FILTER,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/satiskanal/get-all-by-filter/${code}`
        )
        .then((result) => result.data),
    });
  };
}
