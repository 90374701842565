import { DATAPARABIRIMI } from "../../config/globalVariables";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";

function KurFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={DATAPARABIRIMI.map(a => a.text)}
      defaultValue={"Seçiniz"}
    />
  );
};

function ResmiOzelFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={["RESMI", "OZEL"]}
      defaultValue={"Seçiniz"}
    />
  );
};

export const upgReportManagerGridColumns = [
  {
    field: "mrC_ANA_PROJE_ID",
    title: "ANA PROJE ID",
    width: "128px",
    filter: "numeric"
  },
  {
    field: "crE_DATE",
    title: "ANA PROJE OLUŞTURULMA TARİHİ",
    width: "256px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "mrC_PROJE_ID",
    title: "ALT PROJE ID",
    width: "128px",
  },
  {
    field: "mrC_TEKLIF_ID",
    title: "TEKLİF ID",
    width: "128px",
  },
  {
    field: "ilK_TEKLIF_TARIHI",
    title: "İLK TEKLİF TARİHİ",
    width: "256px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "anA_PROJE_ADI",
    title: "ANA PROJE ADI",
    width: "256px",
  },
  {
    field: "projE_ADI",
    title: "ALT PROJE ADI",
    width: "256px",
  },
  {
    field: "muS_ADI",
    title: "MÜŞTERİ ADI",
    width: "256px",
  },
  {
    field: "satiS_TEMSILCISI_KULLANICI_ADI",
    title: "SATIŞ TEMSİLCİSİ",
    width: "256px",
  },
  {
    field: "satiS_DEPARTMANI_KODU",
    title: "SATIŞ DEPARTMANI",
    width: "256px",
    filterable: false
  },
  {
    field: "uruN_GRUBU",
    title: "ÜRÜN GRUBU",
    width: "256px",
  },
  {
    field: "isteneN_PARA_BIRIMI",
    title: "İSTENEN PARA BİRİMİ",
    width: "128px",
    filterCell: () => KurFilterCell
  },
  {
    field: "listE_FIYATI",
    title: "LİSTE FİYATI",
    width: "192px",
    cell: (props) => GridNumberFormatCell(props, 2),
  },
  {
    field: "indiriM_ORANI",
    title: "İNDİRİM ORANI",
    width: "128px",
  },
  {
    field: "gm",
    title: "GM (%)",
    width: "128px",
    cell: (props) => GridNumberFormatCell(props, 2),
  },
  {
    field: "adet",
    title: "ADET",
    width: "128px",
  },
  {
    field: "durum",
    title: "DURUM",
    width: "128px",
  },
  {
    field: "baglanmA_ZAMANI",
    title: "BAĞLANMA ZAMANI",
    width: "256px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "faturA_TARIHI",
    title: "FATURA TARİHİ",
    width: "256px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "kaybedilmE_TARIHI",
    title: "KAYBEDİLME TARİHİ",
    width: "256px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "kaybedileN_MARKA",
    title: "KAYBEDİLEN MARKA",
    width: "256px",
  },
  {
    field: "kaybedilmE_NEDENI",
    title: "KAYBEDİLME NEDENİ",
    width: "256px",
  },
  {
    field: "temsilcI_NOTU",
    title: "TEMSİLCİ NOTU",
    width: "256px",
  },
  {
    field: "resmI_OZEL",
    title: "RESMİ-ÖZEL",
    width: "128px",
    filterCell: () => ResmiOzelFilterCell
  },
  {
    field: "santiyE_KULLANIM_AMACI",
    title: "ŞANTİYE KULLANIM AMACI",
    width: "192px",
  }
];
