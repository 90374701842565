import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function getDbsBanks() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DBS_BANKS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/banka/get-all`)
        .then((result) => result.data),
    });
  };
}
