import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";

class UrunTanimFiyatExcelLogService {
  getByProcId(procId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/UrunTanimFiyatExcelLog/get-all-by-proc-id/${procId}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        console.log('-----------------------------')
        console.log(response)
        return response.data;
      })
      .catch((err) => {
        if (callback) callback(null, true);
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new UrunTanimFiyatExcelLogService();
