import React, { useState, useEffect } from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import MenuService from "../../services/srv-menu/MenuService";

const MenuListBox = ({ onChange, selectedData }) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    unselectedMenus: [],
    selectedMenus: [],
    draggedItem: {},
  });

  useEffect(() => {
    getDataByFilter();
  }, []);

  useEffect(() => {
    onChange(state.selectedMenus);
  }, [state]);

  useEffect(() => {
    setState((prevState) => {
      return {
        ...prevState,
        unselectedMenus: prevState.unselectedMenus.sort((a, b) =>
          a.menU_CODE.localeCompare(b.menU_CODE)
        ),
        selectedMenus: prevState.selectedMenus.sort((a, b) =>
          a.menU_CODE.localeCompare(b.menU_CODE)
        ),
      };
    });
  }, [state.selectedMenus, state.unselectedMenus]);

  useEffect(() => {
    if (selectedData) {
      const unselectedMenus =
        data.data?.filter((d) => {
          const f = selectedData.data.find(
            (x) => x["menU_CODE"] === d["menU_CODE"]
          );
          return !f;
        }) || [];

      setState({
        ...state,
        unselectedMenus,
        selectedMenus: selectedData.data,
      });
    }
  }, [data, selectedData]);

  const onCompletedGetData = (data) => {
    setData({
      ...data,
      data: data.data.sort((a, b) => (a.menU_CODE > b.menU_CODE ? 1 : -1)),
    });
    setDataLoading(false);
  };

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    MenuService.getMenus(
      {
        filter: { logic: "and", filters: filters },
      },
      onCompletedGetData
    );
  };

  const SELECTED_FIELD = "selected";

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.menU_CODE === event.dataItem.menU_CODE) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.unselectedMenus,
      state.selectedMenus,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      unselectedMenus: result.listBoxOneData,
      selectedMenus: result.listBoxTwoData,
    });
  };
  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };
  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      state.unselectedMenus,
      state.selectedMenus,
      state.draggedItem,
      e.dataItem,
      "menU_CODE"
    );
    setState({
      ...state,
      unselectedMenus: result.listBoxOneData,
      selectedMenus: result.listBoxTwoData,
    });
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col k-pr-2">
          <h6>Menüler</h6>
          {dataLoading && LoadingPanel}
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            textField="menU_CODE"
            data={state.unselectedMenus}
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, "unselectedMenus", "selectedMenus")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            toolbar={() => {
              return (
                <ListBoxToolbar
                  tools={["transferTo", "transferFrom", "transferAllFrom"]}
                  data={state.unselectedMenus}
                  dataConnected={state.selectedMenus}
                  onToolClick={handleToolBarClick}
                />
              );
            }}
          />
        </div>
        <div className="col k-pl-0">
          <h6>Seçilen Menüler</h6>
          {dataLoading && LoadingPanel}
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            data={state?.selectedMenus}
            textField="menU_CODE"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, "selectedMenus", "unselectedMenus")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
      </div>
    </>
  );
};

export default MenuListBox;
