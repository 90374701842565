import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import ProjectReportService from "../../services/srv-projectReport/ProjectReportService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import filterLocalization from "../../helpers/filterLocalization";
import ExportExcel from "../sys-toolbox/ExcelExport";
import { projectReportGridColumns } from "./ProjectReportGridColumns";

export function ProjectReport() {
  const _grid = useRef(null);

  const [projectReportsLoading, setProjectReportsLoading] = useState(false);
  const [projectReports, setProjectReports] = useState(null);
  const [projectReportsDataState, setProjectReportsDataState] = useState({
    take: 10,
    skip: 0,
  });

  useEffect(() => {
    setProjectReportsLoading(true);
    var newDataState = {
      take: projectReportsDataState ? projectReportsDataState.take : 10,
      skip: projectReportsDataState ? projectReportsDataState.skip : 0,
    };
    ProjectReportService.getProjectReports(
      newDataState,
      onCompletedGetProjectReports
    );
  }, []);

  const onCompletedGetProjectReports = (data) => {
    setProjectReports(data);
    setProjectReportsLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setProjectReportsLoading(true);
    setProjectReportsDataState(event.dataState);
    ProjectReportService.getProjectReports(
      event.dataState,
      onCompletedGetProjectReports
    );
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Proje Rapor</CardTitle>
        </CardHeader>
        <CardBody>
          {projectReportsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={projectReports}
            dataItemKey={"mrC_TEKLIF_ID"}
            {...projectReportsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ExportExcel
                data={projectReports}
                columns={projectReportGridColumns}
                loadingFunction={setProjectReportsLoading}
                dataState={projectReportsDataState}
                serviceMethod={ProjectReportService.getProjectReportsForExcel}
                fileName="MrcProjeRapor"
              ></ExportExcel>
            </GridToolbar>
            {projectReportGridColumns.map((column, index) => (
              <GridColumn
                field={column.field}
                title={column.title}
                width={column.width}
                filter={column.filter}
                filterable={column.filterable ? column.filterable : true}
                cell={column.cell}
                filterCell={column.filterCell}
              />
            ))}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
