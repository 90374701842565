import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function complaintReducer(
  state = initialState.complaint,
  action
) {
  switch (action.type) {
    case actionTypes.GET_COMPLAINT:
      return {
        ...state,
        _complaint: action.payload,
      };
    case actionTypes.GET_COMPLAINT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_COMPLAINT_FULFILLED:
      return {
        ...state,
        _complaint: action.payload,
        fetching: false,
      };
    case actionTypes.GET_COMPLAINT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.SET_COMPLAINT:
      var newComplaint = {
        [action.payload.target.name]: action.payload.target.value.id
          ? action.payload.target.value.id
          : action.payload.target.value,
      };
      return {
        ...state,
        _complaint: { ...state._complaint, ...newComplaint },
      };
    case actionTypes.UPDATE_COMPLAINT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_COMPLAINT_FULFILLED:
      return {
        ...state,
        fetching: false,
        dataUpdated: true,
      };
    case actionTypes.UPDATE_COMPLAINT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
        dataUpdated: false,
      };
    default:
      return state;
  }
}
