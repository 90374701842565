import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";
import { process } from "@progress/kendo-data-query";

export default function excelListReducer(state = initialState.excel, action) {
  switch (action.type) {
    case actionTypes.FETCH_EXCEL_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_EXCEL_FULFILLED:
      return {
        ...state,
        excels: action.payload,
        result: process(action.payload.slice(0), { take: 6, skip: 0 }),
        fetching: false,
      };
    case actionTypes.FETCH_EXCEL_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_EXCEL:
      return {
        ...state,
        result: process(state.excels.slice(0), action.payload),
        dataState: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
