import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import DiscountApproveMatrixService from "../../services/srv-discountApproveMatrix/DiscountApproveMatrixService";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export default function DiscountApproveMatrixList() {
  const _grid = useRef(null);
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };

  const editField = "inEdit";
  const primaryKey = "tablE_ID";
  const search = "";
  const sort = [
    {
      field: primaryKey,
      dir: "desc",
    },
  ];

  // Data Related Functions Begin
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...search,
  });

  function onCompletedGetProducts(result) {
    setProducts(result);
    if (result.dataState) {
      setProductsDataState(result.dataState);
    }
    setProductsLoading(false);
  }

  useEffect(() => {
    getProductsByFilter();
  }, [productsDataState]);

  function getProductsByFilter() {
    setProductsLoading(true);
    DiscountApproveMatrixService.getByFilter(
      productsDataState,
      onCompletedGetProducts
    );
  }
  // Data Related Functions End

  // Grid Related Functions Begin
  function onDataStateChange(event) {
    setProductsDataState(event.dataState);
  }

  function onItemChange(event) {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }
  // Grid Related Functions End

  function TypeFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={["BIREYSEL", "DAIKIN"]}
        defaultValue={"Seçiniz"}
      />
    );
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Discount Onay Matrisleri</CardTitle>
        </CardHeader>
        <CardBody>
          {productsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={products}
            {...productsDataState}
            pageable={gridPageable}
            dataItemKey={primaryKey}
            editField={editField}
            onDataStateChange={onDataStateChange}
            onItemChange={onItemChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px" }}
          >
            <GridColumn field="tablE_ID" title="ID" width="256px" />
            <GridColumn
              field="tip"
              title="TİP"
              width="100px"
              filterCell={TypeFilterCell}
            />
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              title="SATIS DEPARTMANI"
              width="256px"
            />
            <GridColumn
              field="discounT_URUN_GRUBU"
              title="DISCOUNT URUN GRUBU"
              width="256px"
            />
            <GridColumn field="unvan" title="UNVAN" width="256px" />
            <GridColumn
              field="indiriM_ORANI"
              title="INDIRIM ORANI"
              width="256px"
            />
            <GridColumn field="sira" title="SIRA" width="256px" />
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
