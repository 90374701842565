import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import { connect } from "react-redux";
import { Window } from "@progress/kendo-react-dialogs";
import { REQUIRED, KAPATMA_TIPLERI } from "../../config/globalVariables";
import { Input } from "@progress/kendo-react-inputs";
import {
  getComplaint,
  setComplaint,
  updateComplaint,
} from "../../redux/actions/act-complaintManagement/complaintActions";
import { bindActionCreators } from "redux";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ComplaintHistory from "./ComplaintHistory";
import { fetchComplaintHistory } from "../../redux/actions/act-complaintManagement/complaintHistoryActions";

class ComplaintUpdate extends Component {
  isUpdated = false;

  componentDidMount() {
    this.props.getComplaint(this.props.complaintId);
    this.props.fetchComplaintHistory(this.props.complaintId);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isUpdated && this.props.complaint.dataUpdated) {
      this.isUpdated = false;
      this.props.toggleDialog();
    }
  }

  onChange = (event) => {
    this.props.setComplaint(event);
  };

  onSubmit = (event, status) => {
    this.isUpdated = true;
    event.preventDefault();
    var roleType = this.props.isCMY
      ? "CMY"
      : this.props.isDepartmanSorumlusu
      ? "DEPARTMAN"
      : "BAYI";

    var take = this.props.complaintGrid.props.take
      ? this.props.complaintGrid.props.take
      : 6;
    var skip = this.props.complaintGrid.props.skip
      ? this.props.complaintGrid.props.skip
      : 0;
    this.props.updateComplaint(this.props.complaint, status, roleType, {
      take: take,
      skip: skip,
    });
  };

  approveComplaint = (event) => {
    this.onSubmit(event, "ONAY");
  };

  rejectComplaint = (event) => {
    this.onSubmit(event, "RED");
  };

  render() {
    return (
      <div>
        {this.props.complaint.fetching && LoadingPanel}
        <Window
          title={"Bayi Şikayet - İşlem Yap"}
          onClose={this.props.toggleDialog}
          initialHeight={"70vh"}
          initialWidth={"70%"}
          style={{
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            position: "absolute",
          }}
        >
          <form onSubmit={this.onSubmit} className="k-form">
            <Row>
              <Col lg="6" md="6" xs="12">
                <Input
                  name="bayI_KODU"
                  label={"Bayi Kodu"}
                  style={{ width: "100%" }}
                  required={true}
                  validationMessage={REQUIRED}
                  value={this.props.complaint._complaint.bayI_KODU}
                  onChange={this.onChange}
                  disabled={true}
                ></Input>
                <Input
                  name="departmaN_KODU"
                  label={"Departman Kodu"}
                  style={{ width: "100%" }}
                  required={true}
                  validationMessage={REQUIRED}
                  value={this.props.complaint._complaint.departmaN_KODU}
                  onChange={this.onChange}
                  disabled={true}
                ></Input>
                <Input
                  name="sikayeT_ACIKLAMASI"
                  label={"Şikayet Açıklaması"}
                  style={{ width: "100%" }}
                  required={true}
                  validationMessage={REQUIRED}
                  value={this.props.complaint._complaint.sikayeT_ACIKLAMASI}
                  onChange={this.onChange}
                  disabled={true}
                ></Input>
                <Input
                  name="durum"
                  label={"DURUM"}
                  style={{ width: "100%" }}
                  required={true}
                  validationMessage={REQUIRED}
                  value={this.props.complaint._complaint.durum}
                  onChange={this.onChange}
                  disabled={true}
                ></Input>
                <Input
                  name="sikayeT_CEVABI_1"
                  label={"Şikayet Cevabı 1"}
                  style={{ width: "100%" }}
                  required={true}
                  validationMessage={REQUIRED}
                  value={this.props.complaint._complaint.sikayeT_CEVABI_1}
                  onChange={this.onChange}
                  disabled={
                    !(
                      this.props.complaint._complaint.durum === "YENI" &&
                      (this.props.auth.roles.includes("BAYI_CALISANI") ||
                        this.props.isDepartmanSorumlusu)
                    )
                  }
                ></Input>
                {this.props.complaint._complaint.durum === "ONAYDA_1" ||
                this.props.complaint._complaint.cmY_YORUM_1 !== null ? (
                  <Input
                    name="cmY_YORUM_1"
                    label={"Çğ. Mrk. Yön. Açıklaması"}
                    style={{ width: "100%" }}
                    required={true}
                    validationMessage={REQUIRED}
                    value={this.props.complaint._complaint.cmY_YORUM_1}
                    onChange={this.onChange}
                    disabled={
                      !(
                        this.props.complaint._complaint.durum === "ONAYDA_1" &&
                        this.props.auth.roles.includes(
                          "DAIKIN_CAGRI_MERKEZI_YONETICISI"
                        )
                      )
                    }
                  ></Input>
                ) : undefined}
                {this.props.complaint._complaint.durum === "CMY_RED" ||
                this.props.complaint._complaint.sikayeT_CEVABI_2 !== null ? (
                  <Input
                    name="sikayeT_CEVABI_2"
                    label={"Şikayet Cevabı 2"}
                    style={{ width: "100%" }}
                    required={true}
                    validationMessage={REQUIRED}
                    value={this.props.complaint._complaint.sikayeT_CEVABI_2}
                    onChange={this.onChange}
                    disabled={
                      !(
                        this.props.complaint._complaint.durum === "CMY_RED" &&
                        (this.props.auth.roles.includes("BAYI_CALISANI") ||
                          this.props.isDepartmanSorumlusu)
                      )
                    }
                  ></Input>
                ) : undefined}
                {this.props.complaint._complaint.durum === "ONAYDA_2" ||
                this.props.complaint._complaint.cmY_YORUM_2 !== null ? (
                  <Input
                    name="cmY_YORUM_2"
                    label={"Çğ. Mrk. Yön. Açıklaması 2"}
                    style={{ width: "100%" }}
                    required={true}
                    validationMessage={REQUIRED}
                    value={this.props.complaint._complaint.cmY_YORUM_2}
                    onChange={this.onChange}
                    disabled={
                      !(
                        this.props.complaint._complaint.durum === "ONAYDA_2" &&
                        this.props.auth.roles.includes(
                          "DAIKIN_CAGRI_MERKEZI_YONETICISI"
                        )
                      )
                    }
                  ></Input>
                ) : undefined}
                {this.props.complaint._complaint.durum === "ONAYDA_2" ||
                this.props.complaint._complaint.durum === "KAPALI" ? (
                  <DropDownList
                    name="kapatmA_TIPI"
                    label="Kapatma Tipi"
                    data={KAPATMA_TIPLERI}
                    value={
                      this.props.complaint._complaint.kapatmA_TIPI
                        ? KAPATMA_TIPLERI.find(
                            (a) =>
                              a.id ===
                              this.props.complaint._complaint.kapatmA_TIPI
                          )
                        : ""
                    }
                    textField="text"
                    dataItemKey="id"
                    onChange={this.onChange}
                    disabled={
                      !(
                        this.props.complaint._complaint.durum === "ONAYDA_2" &&
                        this.props.auth.roles.includes(
                          "DAIKIN_CAGRI_MERKEZI_YONETICISI"
                        )
                      )
                    }
                  />
                ) : undefined}
              </Col>
              <Col lg="6" md="6" xs="12" style={{ textAlign: "center" }}>
                <h5>GEÇMİŞ</h5>
                <hr></hr>
                <ComplaintHistory
                  sikayetId={this.props.complaintId}
                ></ComplaintHistory>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col lg="6" md="6" xs="12">
                {this.props.complaint._complaint.durum === "ONAYDA_1" &&
                this.props.complaint._complaint.cmY_YORUM_1_TARIHI === null &&
                this.props.auth.roles.includes(
                  "DAIKIN_CAGRI_MERKEZI_YONETICISI"
                ) ? (
                  <div style={{ textAlign: "center" }}>
                    <Button
                      type="button"
                      size="sm"
                      color="success"
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={this.approveComplaint}
                    >
                      <i className="k-icon k-i-check-circle"></i> ONAYLA
                    </Button>
                    <Button
                      type="button"
                      size="sm"
                      color="danger"
                      style={{
                        marginRight: "5px",
                      }}
                      onClick={this.rejectComplaint}
                    >
                      <i className="k-icon k-i-undo"></i> REDDET
                    </Button>
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="btn btn-primary form-control"
                    disabled={
                      !(
                        (this.props.auth.roles.includes(
                          "DAIKIN_CAGRI_MERKEZI_YONETICISI"
                        ) &&
                          (this.props.complaint._complaint.durum ===
                            "ONAYDA_1" ||
                            this.props.complaint._complaint.durum ===
                              "ONAYDA_2")) ||
                        ((this.props.auth.roles.includes("BAYI_CALISANI") ||
                          this.props.isDepartmanSorumlusu) &&
                          (this.props.complaint._complaint.durum === "YENI" ||
                            this.props.complaint._complaint.durum ===
                              "CMY_RED"))
                      )
                    }
                  >
                    Güncelle
                  </Button>
                )}
              </Col>
            </Row>
          </form>
        </Window>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    complaint: state.complaintReducer,
    complaintHistory: state.complaintHistoryReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getComplaint: bindActionCreators(getComplaint, dispatch),
    setComplaint: bindActionCreators(setComplaint, dispatch),
    updateComplaint: bindActionCreators(updateComplaint, dispatch),
    fetchComplaintHistory: bindActionCreators(fetchComplaintHistory, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintUpdate);
