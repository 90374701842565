import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function bankListReducer(state = initialState.bankList, action) {
  switch (action.type) {
    case actionTypes.GET_DBS_BANKS:
      return {
        ...state,
        banks: action.payload,
      };
    case actionTypes.GET_DBS_BANKS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_DBS_BANKS_FULFILLED:
      return {
        ...state,
        banks: action.payload,
        fetching: false,
      };
    case actionTypes.GET_DBS_BANKS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
