import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function getBankInstallment() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BRY_BANK_INSTALLMENTS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/bankataksit/get-all`)
        .then((result) => result.data),
    });
  };
}
