import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import UserSalesZoneService from "../../services/srv-user/UserSalesZoneService";
import SalesAuthDropDownList from "../sys-common/SalesAuthDropDownList";
import SalesDepartmentMultiSelect from "../sys-common/SalesDepartmentMultiSelect";
import SalesZoneDropDownList from "../sys-common/SalesZoneDropDownList";
import SalesZoneMultiSelect from "../sys-common/SalesZoneMultiSelect";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export function UserSalesZone({ modalToggle, user, userName }) {
  const gridPrimaryKey = "mrC_KULLANICI_BOLGE_TANIM_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [originalUserSalesZoneData, setOriginalUserSalesZoneData] =
    useState(null);
  const [userSalesZoneData, setUserSalesZoneData] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [zones, setZones] = useState([]);
  const [userSalesZoneDataState, setUserSalesZoneDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
    filter: {
      logic: "or",
      filters: [
        {
          field: "kullanicI_ID",
          operator: "eq",
          value: user,
        },
      ],
    },
  });
  const [userSalesZoneDataLoading, setUserSalesZoneDataLoading] =
    useState(false);

  useEffect(() => {
    getUserSalesZonesByFilter();
  }, [userSalesZoneDataState]);

  useEffect(() => {
    getUserSalesZonesByFilter();
  }, []);

  function getUserSalesZonesByFilter() {
    setUserSalesZoneDataLoading(true);
    UserSalesZoneService.getByFilter(
      userSalesZoneDataState,
      onCompletedGetData
    );
  }

  function onCompletedGetData(result) {
    if (result && result) {
      setUserSalesZoneData(result);
      setOriginalUserSalesZoneData(result);
      if (result.dataState) setUserSalesZoneDataState(result.dataState);
    }
    setUserSalesZoneDataLoading(false);
  }

  function onLoadingCompleted(result) {
    setUserSalesZoneDataLoading(false);
    getUserSalesZonesByFilter();
  }

  function onError() {
    setUserSalesZoneDataLoading(false);
  }

  function onDataStateChange(event) {
    setUserSalesZoneDataState(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function enterEditCommand(dataItem) {
    var isOpenEdit = userSalesZoneData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = userSalesZoneData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setUserSalesZoneData({ ...userSalesZoneData, data });
    }
  }

  function addNewUserSalesZone() {
    const newDataItem = {
      kullanicI_ID: user,
      inEdit: true,
    };
    var isOpenEdit = userSalesZoneData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setUserSalesZoneData({
        ...userSalesZoneData,
        data: [newDataItem, ...userSalesZoneData.data],
      });
    }
  }

  function addCommand(dataItem) {
    setUserSalesZoneDataLoading(true);
    UserSalesZoneService.create(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function removeCommand(dataItem) {
    setUserSalesZoneDataLoading(true);
    UserSalesZoneService.delete(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...userSalesZoneData.data];
    data.splice(0, 1);
    setUserSalesZoneData({ ...userSalesZoneData, data });
  }
  function updateCommand(dataItem) {
    setUserSalesZoneDataLoading(true);
    UserSalesZoneService.update(dataItem, onLoadingCompleted, onError);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = originalUserSalesZoneData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = userSalesZoneData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setUserSalesZoneData({ ...userSalesZoneData, data });
  }
  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={enterEditCommand}
      add={addCommand}
      remove={removeCommand}
      discard={discardCommand}
      update={updateCommand}
      cancel={cancelCommand}
      editField={gridEditField}
      idField={gridPrimaryKey}
      nameField={gridPrimaryKey}
    />
  );

  function YesNoCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUserSalesZoneData({ ...userSalesZoneData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function SalesZoneCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SalesZoneDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.satiS_BOLGE_KODU;
              setUserSalesZoneData({ ...userSalesZoneData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function SalesAuthCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SalesAuthDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUserSalesZoneData({ ...userSalesZoneData });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function addRangeUserSaleZone() {
    setUserSalesZoneDataLoading(true);
    var userDto = {
      kullanicI_ID: user,
      zones: zones,
    };
    UserSalesZoneService.addRange(userDto, onLoadingCompleted, onError);
  }

  return (
    <Window
      title={`${userName} Satış Bölgeleri`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {userSalesZoneDataLoading && LoadingPanel}
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            width: "30%",
          }}
          className="k-card-list"
        >
          <Card
            style={{
              width: "100%",
            }}
          >
            <CardBody>
              <SalesDepartmentMultiSelect
                id="satiS_DEPARTMANI_KODU"
                name="satiS_DEPARTMANI_KODU"
                label="Departmanlar"
                value={departments}
                onChange={(e) => {
                  setDepartments(
                    e.target.value.map((x) => x.satiS_DEPARTMANI_KODU)
                  );
                }}
              ></SalesDepartmentMultiSelect>
            </CardBody>
          </Card>
        </div>

        <div
          style={{
            width: "30%",
          }}
          className="k-card-list"
        >
          <Card
            style={{
              width: "100%",
            }}
          >
            <CardBody>
              <SalesZoneMultiSelect
                id="satiS_BOLGE_KODU"
                name="satiS_BOLGE_KODU"
                label="Satış Bölgeleri"
                selectedDepartments={departments}
                value={zones}
                setZones={setZones}
                onChange={(e) => {
                  setZones(e.target.value.map((x) => x.satiS_BOLGE_KODU));
                }}
              ></SalesZoneMultiSelect>
            </CardBody>
          </Card>
        </div>
        <div
          style={{
            width: "15%",
          }}
          className="k-card-list"
        >
          <button
            style={{ margin: "auto" }}
            className="btn btn-primary btn-m"
            onClick={() => addRangeUserSaleZone()}
          >
            Seçilen Bölgeleri Ekle
          </button>
        </div>
      </div>
      <Grid
        sortable={true}
        resizable={true}
        filterable={false}
        data={userSalesZoneData}
        {...userSalesZoneDataState}
        dataItemKey={gridPrimaryKey}
        pageable={gridPageable}
        ref={gridRef}
        onDataStateChange={onDataStateChange}
        rowRender={(row, dataItem) => rowRender(row, dataItem)}
      >
        <GridToolbar>
          <button
            title="Yeni Satış Bölgesi Hak Tanım"
            className="btn btn-primary btn-sm"
            onClick={addNewUserSalesZone}
          >
            Yeni Kayıt
          </button>
        </GridToolbar>
        <GridColumn cell={CommandCell} width="100px" filterable={false} />
        <GridColumn
          field="anA_BOLGE"
          title="Ana Bölge"
          width="256px"
          filterable={false}
          cell={YesNoCell}
        />
        <GridColumn
          field="satiS_BOLGE_KODU"
          title="Satış Bölge Kodu"
          width="256px"
          filterable={false}
          cell={SalesZoneCell}
        />
        <GridColumn
          field="yetki"
          title="Yetki"
          width="256px"
          filterable={false}
          cell={SalesAuthCell}
        />
      </Grid>
    </Window>
  );
}
