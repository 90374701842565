import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class FreeStockDetService {
  getByTeklifSiparisUrunId(teklifSiparis, teklifSiparisUrunId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestockdet/get-by-teklif-siparis-urun-id/${teklifSiparis}/${teklifSiparisUrunId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
}

export default new FreeStockDetService();
