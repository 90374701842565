import axios from 'axios';
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class ProductGroupService {
  getProductGroups(dataState, callback) {
    return axios.get(`${API_GATEWAY + PR_DEX}/urungrubu/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getAllProductGroups(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX}/urungrubu/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getAllByBayi(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX}/urungrubu/get-all-by-bayi`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new ProductGroupService();