import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";

class OrderDiscountProductGroupService {
  getOrderDiscountProductGroups(orderId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/SiparisDiscountUrunGrubu/get-by-orderId/${orderId}`
      )
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
        return result.data;
      });
  }
}

export default new OrderDiscountProductGroupService();
