import GridDateFormatCell from "../sys-common/GridDateFormatCell";

export const serialNumberGridColumns = [
  // {
  //   field: "alL_SERIAL_NUMBER_ID",
  //   title: "All Serial Number ID",
  //   width: "128px",
  //   filter: "numeric",
  // },
  {
    field: "seriaL_NUMBER",
    title: "Seri No",
    width: "256px",
  },
  {
    field: "recorD_TYPE",
    title: "İşlem Tipi",
    width: "256px",
  },
  {
    field: "isleM_TARIHI",
    title: "İşlem Tarihi",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "recorD_OWNER",
    title: "Veri Merkezi",
    width: "128px",
    filter: "numeric",
  },
  {
    field: "uruN_KODU",
    title: "Ürün Kodu",
    width: "256px",
  },
  {
    field: "bayI_KODU",
    title: "Bayi Kodu",
    width: "256px",
  },
  {
    field: "bayI_ADI",
    title: "Bayi Adı",
    width: "256px",
  },
  {
    field: "sipariS_NO",
    title: "Sipariş No",
    width: "256px",
  },
  {
    field: "irsaliyE_NO",
    title: "İrsaliye No",
    width: "256px",
  },
  {
    field: "adres",
    title: "Adres",
    width: "256px",
  },
  {
    field: "sevK_YONTEMI",
    title: "Sevk Yöntemi",
    width: "256px",
  },
  {
    field: "nakliyE_FIRMASI",
    title: "Nakliye Firması",
    width: "256px",
  },
  {
    field: "movE_TYPE",
    title: "Hareket Tipi",
    width: "256px",
  },
  {
    field: "deliverY_TYPE",
    title: "Delivery Type",
    width: "256px",
  },
  {
    field: "kargoyA_TESLIM_EDILDI",
    title: "Kargo Teslim",
    width: "256px",
  },
  {
    field: "kargO_TAKIP_NO",
    title: "Kargo Takip No",
    width: "256px",
  },
  {
    field: "araC_PLAKA",
    title: "Araç Plaka",
    width: "256px",
  },
  {
    field: "sofoR_ADI",
    title: "Şoför Adı",
    width: "256px",
  },
  {
    field: "sofoR_TEL",
    title: "Şoför Tel",
    width: "256px",
  },
  {
    field: "haT_KODU",
    title: "Hat Kodu",
    width: "256px",
  },
  {
    field: "vardiyA_KODU",
    title: "Vardiya",
    width: "256px",
  },
  {
    field: "serviS_KODU",
    title: "Servis Kodu",
    width: "256px",
  },
  {
    field: "serviS_ADI",
    title: "Servis Adı",
    width: "256px",
  },
  {
    field: "sehiR_ADI",
    title: "Şehir Adı",
    width: "256px",
  },
  {
    field: "ilcE_KODU",
    title: "İlçe Kodu",
    width: "256px",
  },
  {
    field: "ilcE_ADI",
    title: "İlçe Adı",
    width: "256px",
  },
  {
    field: "hakediS_NO",
    title: "Hakediş No",
    width: "256px",
  },
  {
    field: "faturA_NO",
    title: "Fatura No",
    width: "256px",
  },
  {
    field: "faturA_TARIHI",
    title: "Fatura Tarihi",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "garanti",
    title: "Garanti",
    width: "256px",
  },
  {
    field: "serviS_TUTAR",
    title: "Servis Tutar",
    width: "256px",
  },
  {
    field: "depO_KODU",
    title: "Depo Kodu",
    width: "256px",
  },
  {
    field: "depO_ID",
    title: "Depo ID",
    width: "256px",
  },
  {
    field: "depO_ACIKLAMA",
    title: "Depo Açıklama",
    width: "256px",
  },
  {
    field: "maM_KOD",
    title: "MAM Kod",
    width: "256px",
  },
  {
    field: "anagrp",
    title: "ANAGRP",
    width: "256px",
  },
  {
    field: "crE_DATE",
    title: "CRE DATE",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "inbounD_NO",
    title: "Inbound No",
    width: "256px",
  },
  {
    field: "returN_NO",
    title: "Return No",
    width: "256px",
  },
  {
    field: "saP_SERI_NO",
    title: "SAP Seri No",
    width: "256px",
  },
  {
    field: "serI_PROFILI",
    title: "Seri Profili",
    width: "256px",
  },

  /*
  VIEWIN TAMAMI EKLENDİ. 
  GEREKLİ ALANLAR AŞAĞIDAN AÇILABİLİR.

  {
    field: "uruN_KODU_DASU",
    title: "CHECKSUM VAR",
    width: "256px",
  },
  {
    field: "uruN_ADI",
    title: "MARKA",
    width: "256px",
  },

  {
    field: "marka",
    title: "ÜRÜN GRUBU",
    width: "256px",
  },
  {
    field: "paleT_NO",
    title: "MÜŞTERİ KODU",
    width: "256px",
  },
  {
    field: "sevkiyaT_NO",
    title: "MÜŞTERİ ADI",
    width: "256px",
  },
  {
    field: "musterI_NO",
    title: "MÜŞTERİ KODU",
    width: "256px",
  },
  {
    field: "musterI_ADI",
    title: "MÜŞTERİ ADI",
    width: "256px",
  },
  {
    field: "adreS_NO",
    title: "MÜŞTERİ KODU",
    width: "256px",
  },
  {
    field: "isleM_KULLANICISI",
    title: "GÖNDERİM DURUMU",
    width: "256px",
  },
  {
    field: "aciklama",
    title: "GÖNDERİM AÇIKLAMA",
    width: "256px",
  },
  {
    field: "uretiM_YILI",
    title: "GÖNDERİM AÇIKLAMA",
    width: "256px",
  },
  {
    field: "uretiM_AYI",
    title: "GÖNDERİM DURUMU",
    width: "256px",
  },
  {
    field: "dasU_ILETILDI",
    title: "GÖNDERİM AÇIKLAMA",
    width: "256px",
  },
  {
    field: "sehiR_KODU",
    title: "GÖNDERİM DURUMU",
    width: "256px",
  },
  {
    field: "dasU_ISLEM_ACIKLAMA",
    title: "GÖNDERİM AÇIKLAMA",
    width: "256px",
  },

  */
];
