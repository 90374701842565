export const customerVisitGridColumns = [
  {
    field: "muS_ZIYARET_GOREV_ID",
    title: "ID",
    width: "256px",
  },
  {
    field: "muS_KODU",
    title: "Müşteri Kodu",
    width: "256px",
  },
  {
    field: "muS_ADI",
    title: "Müşteri Adı",
    width: "256px",
  },
  {
    field: "kullanicI_ID_ATANAN_ADI",
    title: "Atanan Kişi",
    width: "256px",
  },
  {
    field: "yil",
    title: "Yıl",
    width: "256px",
  },
  {
    field: "hafta",
    title: "Hafta",
    width: "256px",
  },
  {
    field: "tarih",
    title: "Tarih",
    width: "256px",
  },
  {
    field: "gorev",
    title: "Görev Açıklaması",
    width: "256px",
  },
  {
    field: "kullanicI_ID_ATAYAN_ADI",
    title: "Atayan Kişi",
    width: "256px",
  },
];
