import * as React from "react";

import PropTypes from "prop-types";

import { Button } from "@progress/kendo-react-buttons";

export const MyCommandCell = (props) => {
  const { dataItem } = props;
  const inEdit = dataItem[props.editField];
  const isNewItem = dataItem[props.idField] === undefined;

  return inEdit ? (
    <td className="k-command-cell">
      <Button
        icon="check"
        look="outline"
        className="k-button k-grid-save-command"
        onClick={() =>
          isNewItem ? props.add(dataItem) : props.update(dataItem)
        }
      />
      <Button
        icon="times"
        look="outline"
        className="k-button k-grid-cancel-command"
        onClick={() =>
          isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
        }
      />
    </td>
  ) : (
    <td className="k-command-cell">
      <Button
        icon="edit"
        look="outline"
        className="k-button k-grid-edit-command"
        onClick={() => props.edit(dataItem)}
      />
      <Button
        icon="trash"
        look="outline"
        className="k-button k-grid-remove-command"
        onClick={() =>
          window.confirm("Silme Onayı: " + dataItem[props.nameField]) &&
          props.remove(dataItem)
        }
      />
    </td>
  );
};
MyCommandCell.propTypes = {
  add: PropTypes.func,
  discard: PropTypes.func,
  remove: PropTypes.func,
  edit: PropTypes.func,
  update: PropTypes.func,
  cancel: PropTypes.func,
  nameField: PropTypes.string,
  editField: PropTypes.string.isRequired,
  idField: PropTypes.string.isRequired,
};
