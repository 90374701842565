import {
  Grid,
  GridColumn,
  GridToolbar,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { cloneElement, useCallback, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { PROJECT_CODE } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import UserService from "../../services/srv-user/UserService";
import DaikinUserTypeDropDownList from "../sys-common/DaikinUserTypeDropDownList";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import UserTypeDropDownList from "../sys-common/UserTypeDropDownList";
import YesNoDropDownList from "../sys-common/YesNoDropDownList";
import ExportExcel from "../sys-toolbox/ExcelExport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { LockedMyCommandCellWithoutDelete } from "../sys-toolbox/LockedGridInlineEditWitouthDelete";
import { UserDealer } from "./UserDealer";
import { UserFinoRole } from "./UserFinoRole";
import { UserFinoSubUser } from "./UserFinoSubUser";
import { UserGenericModal } from "./UserGenericModal";
import { userGridColumns } from "./UserGridColumns";
import { UserMenuAddon } from "./UserMenuAddon";
import { UserProjectResponsibility } from "./UserProjectResponsibility";
import { UserRole } from "./UserRole";
import { UserSalesDepartment } from "./UserSalesDepartment";
import { UserSalesZone } from "./UserSalesZone";

export function UserList() {
  const gridPrimaryKey = "kullanicI_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";
  const gridSelectedField = "selected";

  const gridRef = useRef(null);

  const [selectedState, setSelectedState] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState("");

  const [users, setUsers] = useState();
  const [originalUsers, setOriginalUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersDataState, setUsersDataState] = useState({
    sort,
    take: 10,
    skip: 0,
  });

  const data = {
    projectName: PROJECT_CODE,
    email: "",
  };

  useEffect(() => {
    getUsersByFilter();
  }, [usersDataState]);

  function getUsersByFilter() {
    setUsersLoading(true);
    UserService.getByFilter(usersDataState, onCompletedUserGetByFilter);
  }

  function onCompletedUserGetByFilter(result) {
    if (result) {
      setUsers(result);
      setOriginalUsers(result);
      if (result.dataState) setUsersDataState(result.dataState);
    }
    setUsersLoading(false);
  }

  function onLoadingCompleted(result) {
    setUsersLoading(false);
    getUsersByFilter();
  }

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setUsersDataState(event.dataState);
  };

  function onItemChange(event) {
    const data = users.data.map((item) =>
      item[gridPrimaryKey] === event.dataItem[gridPrimaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setUsers({ ...users, data });
  }
  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: gridPrimaryKey,
      });
      setSelectedState(newSelectedState);
      setSelectedUser(Number(Object.keys(newSelectedState)[0]));
    },
    [selectedState]
  );
  function onRowClick(event) {
    const { dataItem } = event;
    setSelectedUserName(dataItem.adsoyad);
  }
  function rowRender(row, dataItem) {
    var rowColor = "white";
    const trProps = {
      ...row.props,
      style: { backgrounColor: rowColor },
    };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function addNewUser() {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = users.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setUsers({
        ...users,
        data: [newDataItem, ...users.data],
      });
    }
  }

  // Command Cell Functions
  function enterEditCommand(dataItem) {
    setSelectedUser(null);
    var isOpenEdit = users.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = users.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setUsers({ ...users, data });
    }
  }
  function addCommand(dataItem) {
    setUsersLoading(true);
    UserService.create(dataItem, onLoadingCompleted);
    dataItem.inEdit = false;
    setUsersLoading(false);
  }
  function discardCommand() {
    const data = [...users.data];
    data.splice(0, 1);
    setUsers({ ...users, data });
  }
  function updateCommand(dataItem) {
    setUsersLoading(true);
    UserService.update(dataItem, onLoadingCompleted);
    dataItem.inEdit = false;
    setUsersLoading(false);
  }
  function cancelCommand(dataItem) {
    const originalItem = originalUsers.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = users.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setUsers({ ...users, data });
  }

  function CommandCell(props) {
    return (
      <LockedMyCommandCellWithoutDelete
        {...props}
        edit={enterEditCommand}
        add={addCommand}
        discard={discardCommand}
        update={updateCommand}
        cancel={cancelCommand}
        editField={gridEditField}
        idField={gridPrimaryKey}
        nameField={gridPrimaryKey}
      />
    );
  }

  // Modals
  const userRolesPrimaryKey = "kullanicI_ROLE_TANIM_ID";
  const userRolesColumnData = [
    { field: "adsoyad", title: "Ad Soyad", width: "192px" },
    { field: "rolE_KODU", title: "Rol Kodu", width: "192px" },
  ];
  const [visibleUserRoles, setVisibleUserRoles] = useState(false);
  const userRolesToggle = () => {
    setVisibleUserRoles(!visibleUserRoles);
  };
  const [visibleUserRole, setVisibleUserRole] = useState(false);
  const userRoleToggle = () => {
    setVisibleUserRole(!visibleUserRole);
  };

  const userMenuAddonsPrimaryKey = "kullanicI_MENU_EK_HAK_TANIM_ID";
  const userMenuAddonsColumnData = [
    { field: "adsoyad", title: "Ad Soyad", width: "192px" },
    { field: "menU_ADI", title: "Menü Adı", width: "192px" },
  ];
  const [visibleUserMenuAddons, setVisibleUserMenuAddons] = useState(false);
  const userMenuAddonsToggle = () => {
    setVisibleUserMenuAddons(!visibleUserMenuAddons);
  };
  const [visibleUserMenuAddon, setVisibleUserMenuAddon] = useState(false);
  const userMenuAddonToggle = () => {
    setVisibleUserMenuAddon(!visibleUserMenuAddon);
  };

  const userDealersPrimaryKey = "dkN_BAYI_KULLANICI_TANIM_ID";
  const userDealersColumnData = [
    { field: "adsoyad", title: "Ad Soyad", width: "192px" },
    { field: "bayI_ADI", title: "Bayi Adı", width: "256px" },
  ];
  const [visibleUserDealers, setVisibleUserDealers] = useState(false);
  const userDealersToggle = () => {
    setVisibleUserDealers(!visibleUserDealers);
  };
  const [visibleUserDealer, setVisibleUserDealer] = useState(false);
  const userDealerToggle = () => {
    setVisibleUserDealer(!visibleUserDealer);
  };

  const userSalesDepartmentsPrimaryKey =
    "mrC_KULLANICI_SATIS_DEPARTMANI_TANIM_ID";
  const userSalesDepartmentsColumnData = [
    { field: "kullanicI_ADI", title: "Ad Soyad", width: "192px" },
    {
      field: "satiS_DEPARTMANI_KODU",
      title: "Satış Departmanı Adı",
      width: "256px",
    },
    // { field: "yetki", title: "Yetki", width: "256px" },
  ];
  const [visibleUserSalesDepartments, setVisibleUserSalesDepartments] =
    useState(false);
  const userSalesDepartmentsToggle = () => {
    setVisibleUserSalesDepartments(!visibleUserSalesDepartments);
  };
  const [visibleUserSalesDepartment, setVisibleUserSalesDepartment] =
    useState(false);
  const userSalesDepartmentToggle = () => {
    setVisibleUserSalesDepartment(!visibleUserSalesDepartment);
  };

  const userSalesZonesPrimaryKey = "mrC_KULLANICI_BOLGE_TANIM_ID";
  const userSalesZonesColumnData = [
    { field: "kullanicI_ADI", title: "Ad Soyad", width: "192px" },
    { field: "anA_BOLGE", title: "Ana Bölge", width: "100px" },
    { field: "satiS_BOLGE_KODU", title: "Satış Bölge Kodu", width: "200px" },
    { field: "yetki", title: "Yetki", width: "250px" },
  ];
  const [visibleUserSalesZones, setVisibleUserSalesZones] = useState(false);
  const userSalesZonesToggle = () => {
    setVisibleUserSalesZones(!visibleUserSalesZones);
  };
  const [visibleUserSalesZone, setVisibleUserSalesZone] = useState(false);
  const userSalesZoneToggle = () => {
    setVisibleUserSalesZone(!visibleUserSalesZone);
  };

  const userProjectResponsibilitiesPrimaryKey =
    "dkN_MRC_KULLANICI_PROJE_SORUMLUSU_ILCE_ID";
  const userProjectResponsibilitiesColumnData = [
    { field: "kullanicI_AD_SOYAD", title: "Ad Soyad", width: "192px" },
    {
      field: "satiS_DEPARTMANI_KODU",
      title: "Satış Departmanı",
      width: "256px",
    },
    { field: "daikiN_IL_ADI", title: "İl Adı", width: "256px" },
    { field: "daikiN_ILCE_ADI", title: "İlçe Adı", width: "256px" },
  ];
  const [
    visibleUserProjectResponsibilities,
    setVisibleUserProjectResponsibilities,
  ] = useState(false);
  const userProjectResponsibilitiesToggle = () => {
    setVisibleUserProjectResponsibilities(!visibleUserProjectResponsibilities);
  };
  const [
    visibleUserProjectResponsibility,
    setVisibleUserProjectResponsibility,
  ] = useState(false);
  const userProjectResponsibilityToggle = () => {
    setVisibleUserProjectResponsibility(!visibleUserProjectResponsibility);
  };

  const userFinoRolesPrimaryKey = "finO_KULLANICI_ROLE_ID";
  const userFinoRolesColumnData = [
    { field: "kullanicI_AD_SOYAD", title: "Ad Soyad", width: "192px" },
    { field: "yoneticI_AD_SOYAD", title: "Yönetici Ad Soyad", width: "192px" },
    { field: "finanS_HAKKI", title: "Finans Hakkı", width: "120px" },
    { field: "gmY_AD_SOYAD", title: "GMY", width: "175px" },
    {
      field: "executivE_COMMITTEE",
      title: "Executive Committee",
      width: "170px",
    },
    { field: "irtibaT_KISISI", title: "İrtibat Kişisi", width: "120px" },
  ];
  const [visibleUserFinoRoles, setVisibleUserFinoRoles] = useState(false);
  const userFinoRolesToggle = () => {
    setVisibleUserFinoRoles(!visibleUserFinoRoles);
  };
  const [visibleUserFinoRole, setVisibleUserFinoRole] = useState(false);
  const userFinoRoleToggle = () => {
    setVisibleUserFinoRole(!visibleUserFinoRole);
  };

  const userFinoSubUsersPrimaryKey = "finO_ODEME_TIPI_ALT_KULLANICI_ID";
  const userFinoSubUsersColumnData = [
    { field: "kullanicI_AD_SOYAD", title: "Ad Soyad", width: "192px" },
    { field: "odemE_TIPI_ANA_KODU", title: "Ana Ödeme Tipi", width: "256px" },
    { field: "odemE_TIPI_ALT_KODU", title: "Alt Ödeme Tipi", width: "256px" },
  ];
  const [visibleUserFinoSubUsers, setVisibleUserFinoSubUsers] = useState(false);
  const userFinoSubUsersToggle = () => {
    setVisibleUserFinoSubUsers(!visibleUserFinoSubUsers);
  };
  const [visibleUserFinoSubUser, setVisibleUserFinoSubUser] = useState(false);
  const userFinoSubUserToggle = () => {
    setVisibleUserFinoSubUser(!visibleUserFinoSubUser);
  };

  const onCompletedUserResetPassword = () => {
    setUsersLoading(false);
  };
  const userPasswordChangeToggle = () => {
    if (selectedUser) {
      setUsersLoading(true);
      UserService.resetPassword(
        users.data.find((a) => a.kullanicI_ID === selectedUser),
        onCompletedUserResetPassword,
        onCompletedUserResetPassword
      );
    }
  };

  const onCompletedUserResetAuth = () => {
    setUsersLoading(false);
  };
  const userAuthChangeToggle = () => {
    confirmAlert({
      title: "Uyarı",
      message: "Auth kaydını silmek üzeresiniz. Devam etmek istiyor musunuz?",
      buttons: [
        {
          label: "Evet",
          onClick: async () => {
            if (selectedUser) {
              setUsersLoading(true);
              data.email = users.data.find(
                (a) => a.kullanicI_ID === selectedUser
              ).email;
              UserService.resetAuthenticator(
                data,
                onCompletedUserResetAuth,
                onCompletedUserResetAuth
              );
            }
          },
        },
        {
          label: "Hayır",
          onClick: () => {},
        },
      ],
    });
  };

  // Cells
  function DealerCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem["bayI_KODU"] === null ? "" : dataItem["bayI_KODU"];
    if (dataItem.inEdit) {
      return (
        <td>
          <DealerDropDownList
            id={"bayI_KODU"}
            name={"bayI_KODU"}
            value={dataValue}
            onChange={(e) => {
              dataItem["bayI_KODU"] = e.value.bayI_KODU;
              setUsers({ ...users });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.bayI_ADI}</td>;
    }
  }
  function YesNoCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <YesNoDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUsers({ ...users });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }
  function UserTypeCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <UserTypeDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUsers({ ...users });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }
  function DaikinUserTypeCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DaikinUserTypeDropDownList
            id={field}
            name={field}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value;
              setUsers({ ...users });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  return (
    <main style={{ flexGrow: "1" }}>
      {visibleUserRoles ? (
        <UserGenericModal
          modalTitle="Tüm Rol Tanımları"
          modalToggle={userRolesToggle}
          columnData={userRolesColumnData}
          gridFor="userRoles"
          gridPrimaryKey={userRolesPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserRole ? (
        <UserRole
          modalToggle={userRoleToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserRole>
      ) : (
        ""
      )}

      {visibleUserMenuAddons ? (
        <UserGenericModal
          modalTitle="Tüm Menu Ek Hak Tanımları"
          modalToggle={userMenuAddonsToggle}
          columnData={userMenuAddonsColumnData}
          gridFor="userMenuAddons"
          gridPrimaryKey={userMenuAddonsPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserMenuAddon ? (
        <UserMenuAddon
          modalToggle={userMenuAddonToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserMenuAddon>
      ) : (
        ""
      )}

      {visibleUserDealers ? (
        <UserGenericModal
          modalTitle="Tüm Bayi Hak Tanımları"
          modalToggle={userDealersToggle}
          columnData={userDealersColumnData}
          gridFor="userDealers"
          gridPrimaryKey={userDealersPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserDealer ? (
        <UserDealer
          modalToggle={userDealerToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserDealer>
      ) : (
        ""
      )}

      {visibleUserSalesDepartments ? (
        <UserGenericModal
          modalTitle="Tüm Satış Departmanı Tanımları"
          modalToggle={userSalesDepartmentsToggle}
          columnData={userSalesDepartmentsColumnData}
          gridFor="userSalesDepartments"
          gridPrimaryKey={userSalesDepartmentsPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserSalesDepartment ? (
        <UserSalesDepartment
          modalToggle={userSalesDepartmentToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserSalesDepartment>
      ) : (
        ""
      )}

      {visibleUserSalesZones ? (
        <UserGenericModal
          modalTitle="Tüm Satış Bölge Tanımları"
          modalToggle={userSalesZonesToggle}
          columnData={userSalesZonesColumnData}
          gridFor="userSalesZones"
          gridPrimaryKey={userSalesZonesPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserSalesZone ? (
        <UserSalesZone
          modalToggle={userSalesZoneToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserSalesZone>
      ) : (
        ""
      )}

      {visibleUserProjectResponsibilities ? (
        <UserGenericModal
          modalTitle="Tüm Proje Sorumlusu Tanımları"
          modalToggle={userProjectResponsibilitiesToggle}
          columnData={userProjectResponsibilitiesColumnData}
          gridFor="userProjectResponsibilities"
          gridPrimaryKey={userProjectResponsibilitiesPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserProjectResponsibility ? (
        <UserProjectResponsibility
          modalToggle={userProjectResponsibilityToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserProjectResponsibility>
      ) : (
        ""
      )}

      {visibleUserFinoRoles ? (
        <UserGenericModal
          modalTitle="Tüm Fin. Öd. Portalı Hakları"
          modalToggle={userFinoRolesToggle}
          columnData={userFinoRolesColumnData}
          gridFor="userFinoRoles"
          gridPrimaryKey={userFinoRolesPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserFinoRole ? (
        <UserFinoRole
          modalToggle={userFinoRoleToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserFinoRole>
      ) : (
        ""
      )}

      {visibleUserFinoSubUsers ? (
        <UserGenericModal
          modalTitle="Tüm Fin. Öd. Portalı Ödeme Tipleri"
          modalToggle={userFinoSubUsersToggle}
          columnData={userFinoSubUsersColumnData}
          gridFor="userFinoSubUsers"
          gridPrimaryKey={userFinoSubUsersPrimaryKey}
        ></UserGenericModal>
      ) : (
        ""
      )}
      {visibleUserFinoSubUser ? (
        <UserFinoSubUser
          modalToggle={userFinoSubUserToggle}
          user={selectedUser}
          userName={selectedUserName}
        ></UserFinoSubUser>
      ) : (
        ""
      )}

      <h2>Kullanıcı Listesi</h2>
      {usersLoading && LoadingPanel}
      <Grid
        sortable={true}
        resizable={true}
        filterable={true}
        selectable={{
          enabled: true,
          cell: false,
          mode: "single",
        }}
        selectedField={gridSelectedField}
        data={users}
        {...usersDataState}
        dataItemKey={gridPrimaryKey}
        pageable={gridPageable}
        editField={gridEditField}
        ref={gridRef}
        onDataStateChange={onDataStateChange}
        onItemChange={onItemChange}
        onSelectionChange={onSelectionChange}
        onRowClick={onRowClick}
        rowRender={(row, dataItem) => rowRender(row, dataItem)}
      >
        <GridToolbar>
          <button
            title="Yeni Kullanici"
            className="btn btn-primary btn-sm"
            onClick={addNewUser}
          >
            Yeni Kayıt
          </button>
          <ExportExcel
            data={users}
            columns={userGridColumns}
            loadingFunction={setUsersLoading}
            dataState={usersDataState}
            serviceMethod={UserService.getUsersForExcel}
            fileName="KullaniciListesi"
          ></ExportExcel>
          {selectedUser && (
            <>
              <button
                title="Seçili Kullanıcı"
                className="btn btn-default btn-sm"
                disabled
              >
                {selectedUserName ? selectedUserName : ""}
              </button>
              <button
                title="Rol Tanım"
                className="btn btn-primary btn-sm"
                onClick={() => userRoleToggle()}
              >
                Roller
              </button>
              <button
                title="Menü Ek Hak Tanım"
                className="btn btn-secondary btn-sm"
                onClick={() => userMenuAddonToggle()}
              >
                Ek Menüler
              </button>
              <button
                title="Bayi Hak Tanım"
                className="btn btn-primary btn-sm"
                onClick={() => userDealerToggle()}
              >
                Bayi
              </button>
              <button
                title="Satış Departmanı Hak Tanım"
                className="btn btn-primary btn-sm"
                onClick={() => userSalesDepartmentToggle()}
              >
                Satış Departmanı
              </button>
              <button
                title="Satış Bölge Tanım"
                className="btn btn-primary btn-sm"
                onClick={() => userSalesZoneToggle()}
              >
                Satış Bölgesi
              </button>
              <button
                title="Proje Sorumlusu İlçe"
                className="btn btn-info btn-sm"
                onClick={() => userProjectResponsibilityToggle()}
              >
                Proje Sorumlusu İlçe
              </button>
              <button
                title="Finans Portalı Hak Tanım"
                className="btn btn-success btn-sm"
                onClick={() => userFinoRoleToggle()}
              >
                Fin. Öd. Portalı
              </button>
              <button
                title="Finans Ödeme Talimatı Alt Kullanıcı Hak Tanım"
                className="btn btn-success btn-sm"
                onClick={() => userFinoSubUserToggle()}
              >
                Fin. Öd. Portalı Ödeme Tipleri
              </button>
              <button
                title="Şifre Resetle"
                className="btn btn-warning btn-sm"
                onClick={() => userPasswordChangeToggle()}
              >
                Şifre Resetle
              </button>
              <button
                title="Auth Resetle"
                className="btn btn-danger btn-sm"
                onClick={() => userAuthChangeToggle()}
              >
                Auth Resetle
              </button>
            </>
          )}
        </GridToolbar>
        <GridColumn
          cell={CommandCell}
          width="100px"
          filterable={false}
          locked={true}
        />
        <GridColumn field="ad" title="AD" width="144px" locked={true} />
        <GridColumn field="soyad" title="SOYAD" width="144px" locked={true} />
        <GridColumn field="dkN_SICIL_NO" title="SİCİL NO" width="144px" />
        <GridColumn field="unvan" title="ÜNVAN" width="192px" />
        <GridColumn field="email" title="E-POSTA" width="144px" />
        <GridColumn
          field="kullanicI_TIPI"
          title="KULLANICI TİPİ"
          width="144px"
          cell={UserTypeCell}
        />
        <GridColumn
          field="bayI_ADI"
          title="BAYİ"
          width="256px"
          cell={DealerCell}
        />
        <GridColumn
          field="gecerli"
          title="GEÇERLİ"
          width="144px"
          cell={YesNoCell}
        />
        <GridColumn field="telefon" title="TELEFON" width="144px" />
        <GridColumn
          field="daikiN_CALISANI_MI"
          title="DAIKIN ÇALIŞANI MI"
          width="144px"
          cell={DaikinUserTypeCell}
        />
        <GridColumn
          field="deX_HAKKI"
          title="DEX HAKKI"
          width="144px"
          cell={YesNoCell}
        />
        <GridColumn
          field="mercurY_HAKKI"
          title="MERCURY HAKKI"
          width="144px"
          cell={YesNoCell}
        />
        <GridColumn
          field="smartY_HAKKI"
          title="SMARTY HAKKI"
          width="144px"
          cell={YesNoCell}
        />
        <GridColumn
          field="finanS_HAKKI"
          title="FİNANS HAKKI"
          width="144px"
          cell={YesNoCell}
        />
        <GridColumn field="tickeT_ID" title="TICKET ID" width="144px" />
        <GridColumn field="kullanicI_ID" title="KULLANICI ID" width="144px" />
      </Grid>
      <div className="mt-3">
        <button
          title="Tüm Rol Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userRolesToggle()}
        >
          Rol Tanımları
        </button>
        <button
          title="Menü Ek Hak Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userMenuAddonsToggle()}
        >
          Menü Ek Hak Tanımları
        </button>
        <button
          title="Tüm Bayi Hak Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userDealersToggle()}
        >
          Bayi Hak Tanımları
        </button>
        <button
          title="Satış Departmanı Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userSalesDepartmentsToggle()}
        >
          Satış Departmanı Tanımları
        </button>
        <button
          title="Satış Bölge Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userSalesZonesToggle()}
        >
          Satış Bölge Tanımları
        </button>
        <button
          title="Proje Sorumlusu Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userProjectResponsibilitiesToggle()}
        >
          Proje Sorumlusu Tanımları
        </button>
        <button
          title="Finans Portalı Rol Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userFinoRolesToggle()}
        >
          Fin. Öd. Portalı Rol Tanımları
        </button>
        <button
          title="Finans Portalı Alt Kullanıcı Tanımları"
          className="btn btn-primary btn-sm ml-1"
          onClick={() => userFinoSubUsersToggle()}
        >
          Fin. Öd. Portalı Ödeme Tipi Tanımları
        </button>
      </div>
    </main>
  );
}
