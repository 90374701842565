import axios from "axios";
import * as actionTypes from "../../actions/sys-configure/actionTypes";
import { API_GATEWAY, MS_USERSDEX } from "../../../config/globalVariables";

export function fetchUser(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USERS,
      payload: axios
        .get(`${API_GATEWAY + MS_USERSDEX}/kullanici/get-all`)
        .then((result) => {
          if (callback) callback();
          return result.data;
        }),
    });
  };
}

export function fetchSatisTemsilcileri(callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SATIS_TEMSILCILERI_USERS,
      payload: axios
        .get(`${API_GATEWAY + MS_USERSDEX}/kullanici/get-satis-temsilcileri`)
        .then((result) => {
          if (callback) callback();
          return result.data;
        }),
    });
  };
}
