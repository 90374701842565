import axios from "axios";
import * as actionTypes from "../sys-configure/actionTypes";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";

export function fetchDealerProductGroup() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEALER_PRODUCT_GROUP,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/bayiurungrubu/get-all`)
        .then((result) => result.data),
    });
  };
}

export function getDealerProductGroupByBayiKodu(bayiKodu) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DEALER_PRODUCT_GROUPS,
      payload: bayiKodu
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/bayiurungrubu/get-departmants-by-bayiKodu/${bayiKodu}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}
