import { DateInput } from "@progress/kendo-react-dateinputs";
import { Window } from "@progress/kendo-react-dialogs";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { DATAPLANT, DATASAPDURUM } from "../../config/globalVariables";
import OrderService from "../../services/srv-bry-order/OrderService";
import CommonDatePicker from "../sys-common/CommonDatePicker";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import CommonInput from "../sys-common/CommonInput";
import FinPaymentConditionDropDownList from "../sys-common/FinPaymentConditionDropDownList";

const OrderSAPStatus = (props) => {
  const [order, setOrder] = useState(props.data);
  function updateSAPStatus() {
    var sapDto = {
      BRY_SIPARIS_ID: order.brY_SIPARIS_ID,
      SAP_DURUM: order.saP_DURUM,
      SAP_SIPARIS_NO: order.saP_SIPARIS_NO,
      ODEME_KOSULU: order.odemE_KOSULU,
      TAHMINI_FATURA_TARIHI: order.tahminI_FATURA_TARIHI,
      SATIN_ALMA_SIPARIS_NO: order.satiN_ALMA_SIPARIS_NO,
      PLANT: order.plant,
      COMMENT: order.comment,
    };

    OrderService.executeSAPStatus(sapDto, props.onCallback);
  }
  return (
    <Window
      title={props.title}
      onClose={props.toggleDialog}
      initialHeight={"60vh"}
      initialWidth={"30%"}
      style={{
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {order?.odemE_VADESI_TIPI === "FATURA_VADESI" ? (
        <CommonDatePicker
          label="Yaklaşık Fatura Tarihi"
          id="tahminI_FATURA_TARIHI"
          min={new Date()}
          value={order.tahminI_FATURA_TARIHI}
          onChange={(e) =>
            setOrder({ ...order, tahminI_FATURA_TARIHI: e.target.value })
          }
        />
      ) : undefined}
      <FinPaymentConditionDropDownList
        id="kosuL_KODU"
        label="Ödeme Koşulu"
        value={order.odemE_KOSULU}
        onChange={(e) => {
          setOrder({ ...order, odemE_KOSULU: e.target.value.kosuL_KODU });
        }}
      />
      <CommonInput
        id="satiN_ALMA_SIPARIS_NO"
        label="Purchase Order No"
        onChange={(e) =>
          setOrder({ ...order, satiN_ALMA_SIPARIS_NO: e.target.value })
        }
        value={order?.saP_SIPARIS_NO}
      ></CommonInput>
      <CommonDropDownList
        id="saP_DURUM"
        label="SAP Durum"
        data={DATASAPDURUM}
        onChange={(e) => setOrder({ ...order, saP_DURUM: e.target.value.id })}
        required={true}
        value={order?.saP_DURUM}
      />
      <CommonInput
        id="saP_SIPARIS_NO"
        label="SAP Sipariş No"
        onChange={(e) => setOrder({ ...order, saP_SIPARIS_NO: e.target.value })}
        value={order?.saP_SIPARIS_NO}
      ></CommonInput>
      {order.saP_ISLEM_KULLANICI_ADI ? (
        <CommonInput
          id="saP_ISLEM_KULLANICI_ADI"
          label="İşlem Yapan Kişi"
          onChange={(e) =>
            setOrder({ ...order, saP_ISLEM_KULLANICI_ADI: e.target.value })
          }
          value={order?.saP_ISLEM_KULLANICI_ADI}
        ></CommonInput>
      ) : undefined}
      <CommonDropDownList
        id="plant"
        label="Plant"
        data={DATAPLANT}
        onChange={(e) => setOrder({ ...order, plant: e.target.value.id })}
        required={true}
        value={order?.plant}
      />
      <CommonInput
        id="firmA_YETKILI_KISI_TELEFON"
        name="firmA_YETKILI_KISI_TELEFON"
        label="Firma Yetkili Kişi Telefon"
        readOnly={true}
        value={order?.firmA_YETKILI_KISI_TELEFON}
      />
      {props.data.saP_GIRIS_TARIHI ? (
        <DateInput
          label="SAP Giriş Tarihi"
          format="dd.MM.yyyy HH:mm"
          formatPlaceholder="formatPattern"
          value={new Date(props.data.saP_GIRIS_TARIHI)}
          style={{ width: "100%" }}
          disabled={true}
        />
      ) : undefined}
      <CommonInput
        id="comment"
        label="Comment"
        onChange={(e) => setOrder({ ...order, comment: e.target.value })}
        value={order?.comment}
      ></CommonInput>
      <Row>
        <Col lg="12" md="12" xs="12" style={{ marginTop: "40px" }}>
          <Button
            color="secondary"
            type="button"
            size="sm"
            onClick={props.toggleDialog}
          >
            Vazgeç
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            style={{ width: "150px", float: "right" }}
            onClick={updateSAPStatus}
          >
            {" "}
            Kaydet
          </Button>
        </Col>
      </Row>
    </Window>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OrderSAPStatus);
