import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import { BASE_URL } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import { getGridFilterProperties } from "../../helpers/utils";
import {
  changeCampaign,
  getCampaignsByFilter,
} from "../../redux/actions/act-bry-campaign/campaignActions";
import GridDateCell from "../sys-common/GridDateCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class CampaignList extends Component {
  componentDidMount() {
    var take = this.props.campaignList.dataState
      ? this.props.campaignList.dataState.take
      : 6;
    var skip = this.props.campaignList.dataState
      ? this.props.campaignList.dataState.skip
      : 0;
    this.props.getCampaignsByFilter({ take: take, skip: skip });
  }

  goDetail = (dataItem) => {
    this.props.history.push(
      BASE_URL + "campaign-detail/" + dataItem.brY_KAMPANYA_TANIM_ID
    );
  };

  goNewCampaign = () => {
    this.props.history.push(BASE_URL + "campaign-detail/0");
  };

  export = () => {
    this._export.save();
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.getCampaignsByFilter(event.dataState);
    this.props.changeCampaign(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onChangeSearch = (event) => {
    var searchFilter = Object.assign(
      {},
      this.props.campaignList.dataState,
      getGridFilterProperties(
        this._grid.columns.filter(
          (a) => a.field && a.filter !== "numeric" && a.filter !== "date"
        ),
        event.target.value
      )
    );

    this.props.getCampaignsByFilter(searchFilter);
    this.props.changeCampaign(searchFilter);
  };

  render() {
    return (
      <div>
        {this.props.campaignList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.campaignList.campaigns}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.campaignList.result}
            {...this.props.campaignList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => this.goNewCampaign()}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni Kampanya
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              <Input
                name="search"
                label="Hızlı arama için enter'a basınız"
                style={{ width: "40%", float: "right" }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    this.onChangeSearch(event);
                  }
                }}
              ></Input>
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="100px"
              cell={(props) => (
                <td>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => this.goDetail(props.dataItem)}
                  >
                    Detay
                  </button>
                </td>
              )}
            />
            <GridColumn
              field="kampanyA_KODU"
              title="Kampanya Kodu"
              width="150px"
            ></GridColumn>
            <GridColumn
              field="kampanyA_TURU"
              title="Kampanya Türü"
              width="150px"
            ></GridColumn>
            <GridColumn
              field="kampanyA_ADI"
              title="Kampanya Adı"
              width="200px"
            ></GridColumn>
            <GridColumn
              field="kampanyA_HEDEFI"
              title="Kampanya Hedefi"
              width="150px"
            ></GridColumn>
            <GridColumn
              field="kampanyA_KOSULU"
              title="Kampanya Koşulu"
              width="150px"
            ></GridColumn>
            <GridColumn
              field="iskonto"
              title="İskonto"
              filter="numeric"
            ></GridColumn>
            <GridColumn
              field="baS_TAR"
              title="Başlangıç Tarihi"
              cell={GridDateCell}
              filter="date"
            ></GridColumn>
            <GridColumn
              field="biT_TAR"
              title="Bitiş Tarihi"
              cell={GridDateCell}
              filter="date"
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaignList: state.campaignListReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getCampaignsByFilter: bindActionCreators(getCampaignsByFilter, dispatch),
    changeCampaign: bindActionCreators(changeCampaign, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
