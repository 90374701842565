import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SalesDepartmentService from "../../services/srv-salesDepartment/SalesDepartmentService";

const SalesDepartmentDropDownList = ({
  id,
  label,
  required,
  onChange,
  defaultValue,
  value
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    SalesDepartmentService.getSalesDepartments(
      {
        filter: { logic: "or", filters: [] },
      },
      defaultDataHandler
    );
  }, []);

  const defaultDataHandler = ({data}) => {
    data.sort((a, b) => (a.satiS_DEPARTMANI_KODU > b.satiS_DEPARTMANI_KODU ? 1 : -1))
    setData(data);
    setFilteredData(data);
    setDataLoading(false);

  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.satiS_DEPARTMANI_KODU
          .toLowerCase()
          .includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>        
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const onChangeTest=(e)=>{
    onChange(e)
  }

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="satiS_DEPARTMANI_ADI"
      dataItemKey="satiS_DEPARTMANI_KODU"
      style={{ width: "100%" }}
      onChange={onChangeTest}
      defaultValue={defaultValue}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={value}

    ></DropDownList>
  );
};

export default SalesDepartmentDropDownList;
