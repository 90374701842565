import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import {
  API_GATEWAY,
  PR_DEX_BAYI_SIKAYET,
} from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchComplaint(type, dataState) {
  var method =
    type === "CMY"
      ? "get-all"
      : type === "BAYI"
      ? "get-by-bayi"
      : "get-by-departman";

  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_COMPLAINT,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BAYI_SIKAYET
          }/bayisikayet/${method}?${toDataSourceRequestString(dataState)}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function fetchComplaintForExcel(exports, columns, dataState, type) {
  var dataFilterState = {};
  dataFilterState.filter = dataState.filter;
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_COMPLAINT,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BAYI_SIKAYET
          }/bayisikayet/get-all?${toDataSourceRequestString(dataFilterState)}`
        )
        .then((result) => {
          exports.save(result.data, columns);
          dispatch(fetchComplaint(type, dataState));
        }),
    });
  };
}

export function changeComplaint(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_COMPLAINT,
      payload: event,
    });
  };
}

export function getComplaint(complaintId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_COMPLAINT,
      payload: complaintId
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BAYI_SIKAYET
              }/bayisikayet/get-by-id/${complaintId}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function setComplaint(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_COMPLAINT,
      payload: event,
    });
  };
}

export function updateComplaint({ _complaint }, onayred, roleType, dataState) {
  onayred = onayred === null ? "test" : onayred;
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_COMPLAINT,
      payload: axios
        .put(
          `${
            API_GATEWAY + PR_DEX_BAYI_SIKAYET
          }/bayisikayet/update-complaint/${onayred}`,
          {
            ..._complaint,
          }
        )
        .then((result) => {
          dispatch(fetchComplaint(roleType, dataState));
          return result.data;
        }),
    });
  };
}
