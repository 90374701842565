import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function loginReducer(state = initialState.login, action) {
  switch (action.type) {
    case actionTypes.RESET_LOGIN:
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("aadToken");
      var resetLogin = {
        email: "",
        password: "",
        mfa: "Authenticator",
        verificationCode: "",
      };
      return {
        ...state,
        _login: { ...state._login, ...resetLogin },
      };
    case actionTypes.SET_LOGIN:
      var newLogin = {
        [action.payload.target.name]: action.payload.target.value,
      };
      return {
        ...state,
        _login: { ...state._login, ...newLogin },
      };
    default:
      return state;
  }
}
