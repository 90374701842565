import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../config/globalVariables";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";

class OrderService {
  getOrders(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX_BIREYSEL
        }/siparis/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  executeSAPStatus(data, callback) {
    return axios
      .post(
        `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-sap-giris-islemleri`,
        data
      )
      .then((response) => {
        if (callback) callback(response);
        defaultAlertMessages.MessageDetailSuccess = response.data;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }

  executeOnayHiyerarsi(data, callback) {
    return axios
      .post(
        `${API_GATEWAY + PR_DEX_BIREYSEL}/siparis/execute-onay-hiyerarsi`,
        data
      )
      .then((response) => {
        if (callback) callback(response);
        return response;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new OrderService();
