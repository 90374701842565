import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function getOrderApproveLog(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ORDER_APPROVE_LOG,
      payload: orderId
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/siparissatisonay/get-by-orderId/${orderId}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}
