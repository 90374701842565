import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import { getOrder } from "./orderActions";
import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

export function getOrderProducts(orderId) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCTS,
      payload: orderId
        ? axios
            .get(
              `${
                API_GATEWAY + PR_DEX_BIREYSEL
              }/siparisUrun/get-by-orderId/${orderId}`
            )
            .then((result) => result.data)
        : [],
    });
  };
}

export function changeOrderProduct(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_ORDER_PRODUCTS,
      payload: event,
    });
  };
}

export function insertOrderProduct(orderProduct) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.ADD_ORDER_PRODUCT,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparisUrun/add-order-product`,
          {
            ...orderProduct,
          }
        )
        .then((result) => {
          dispatch(getOrder(orderProduct.brY_SIPARIS_ID));
          dispatch(getOrderProducts(orderProduct.brY_SIPARIS_ID));
          return result.data;
        }),
    });
  };
}

export function updateOrderProduct(orderProduct) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.EDIT_ORDER_PRODUCT,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparisUrun/update-order-product`,
          {
            ...orderProduct,
          }
        )
        .then((result) => {
          dispatch(getOrder(orderProduct.brY_SIPARIS_ID));
          dispatch(getOrderProducts(orderProduct.brY_SIPARIS_ID));
          return result.data;
        }),
    });
  };
}

export function deleteOrderProduct(orderProduct) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.REMOVE_ORDER_PRODUCT,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_BIREYSEL}/siparisUrun/delete-order-product`,
          {
            ...orderProduct,
          }
        )
        .then((result) => {
          dispatch(getOrder(orderProduct.brY_SIPARIS_ID));
          dispatch(getOrderProducts(orderProduct.brY_SIPARIS_ID));
          return result.data;
        }),
    });
  };
}

export function getProductsByFilter(dataState, siparisTuru, siparisId) {
  var method =
    siparisTuru === "KAMPANYA"
      ? "get-list-by-kampanya-filter"
      : "get-list-by-standart-filter";
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_ORDER_PRODUCTS_OPTIONS,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/siparisurunsecim/${method}/${siparisId}?${toDataSourceRequestString(
            dataState
          )}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}
