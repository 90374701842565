import React, { useState, useEffect } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

import MrcProductGroupService from '../../services/srv-product/MrcProductGroupService';

const MrcProductGroupDropDownList = ({ 
  id,
  name,
  label,
  value,
  required,
  onChange
}) => {

  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    MrcProductGroupService.getProductGroups(onCompletedGetData);
  }, []);

  const onCompletedGetData = (data) => {
    setData(data);
    setDataLoading(false);
  };
  
  return (
    <DropDownList
      name={name}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="mrC_URUN_GRUBU_ACIKLAMA"
      dataItemKey="mrC_URUN_GRUBU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      loading={dataLoading}
      value={
        value
        ? data.find((v) => v.mrC_URUN_GRUBU === value)
        : ""
      }></DropDownList>
  )
}

export default MrcProductGroupDropDownList;