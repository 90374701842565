import axios from 'axios';

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from '@progress/kendo-data-query';

import {
  API_GATEWAY,
  PR_DEX_BIREYSEL,
} from '../../../config/globalVariables';
import * as actionTypes from '../sys-configure/actionTypes';

/**
 * DKN_BRY_IND_5_SATIS_KANALI_URUN listesini fetch eder
 *
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_5_SATIS_KANALI_URUN listesi geçer
 */
export function fetchSalesChannelProduct(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_SALES_CHANNEL_PRODUCT,
      payload: fetchSalesChannelProductPayload(dataState, callback),
    });
  };
}
export function fetchSalesChannelProductPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_BIREYSEL
      }/IndirimSatisKanaliUrun/get-all?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

export function chanceSalesChannelProduct(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_SALES_CHANNEL_PRODUCT,
      payload: event,
    });
  };
}

/**
 * Yeni DKN_BRY_IND_5_SATIS_KANALI_URUN
 *
 * @param {object} data DKN_BRY_IND_5_SATIS_KANALI_URUN
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_5_SATIS_KANALI_URUN listesi geçer
 */
export function insertSalesChannelProduct(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_SALES_CHANNEL_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliUrun/add-sales-chanell-product`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProduct(dataState, callback));
          return result.data;
        }),
    });
  };
}

/**
 * Güncelle DKN_BRY_IND_5_SATIS_KANALI_URUN
 *
 * @param {object} data DKN_BRY_IND_5_SATIS_KANALI_URUN
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_5_SATIS_KANALI_URUN listesi geçer
 */
export function updateSalesChannelProduct(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_SALES_CHANNEL_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliUrun/update-sales-chanell-product`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProduct(dataState, callback));
          return result.data.message;
        }),
    });
  };
}

/**
 * Sil DKN_BRY_IND_5_SATIS_KANALI_URUN
 *
 * @param {object} data DKN_BRY_IND_5_SATIS_KANALI_URUN
 * @param {object} dataState Grid componentine uygulanan veri işlemlerinin durumu
 * @param {function?} callback Fetch işlemi sonrası çalışan fonksiyon, fonksiyon içerisine parametre olarak DKN_BRY_IND_5_SATIS_KANALI_URUN listesi geçer
 */
export function deleteSalesChannelProduct(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CALLSP_REMOVE_SALES_CHANNEL_PRODUCT,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/IndirimSatisKanaliUrun/delete-sales-chanell-product`,
          data
        )
        .then((result) => {
          dispatch(fetchSalesChannelProduct(dataState, callback));
          return result.data.message;
        }),
    });
  };
}
