import React, { useState, useEffect } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import SalesZoneService from "../../services/srv-salesZone/SalesZoneService";
import { Badge } from "reactstrap";

const SalesZoneMultiSelect = ({
  id,
  label,
  required,
  onChange,
  value,
  selectedDepartments,
  setZones,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    setDataLoading(true);

    SalesZoneService.getSalesZones(
      {
        filter: { logic: "or", filters: [] },
      },
      defaultDataHandler
    );
  }, []);

  useEffect(() => {
    setInitialValues(
      data.filter((a) => selectedDepartments.includes(a.satiS_DEPARTMANI_KODU))
    );
    setZones &&
      setZones(
        data
          .filter((a) => selectedDepartments.includes(a.satiS_DEPARTMANI_KODU))
          .map((x) => x.satiS_BOLGE_KODU)
      );
  }, [selectedDepartments]);

  useEffect(() => {
    setInitialValues(
      value.map((value) => data.find((a) => a.satiS_BOLGE_KODU === value))
    );
  }, [data, value]);

  const defaultDataHandler = ({ data }) => {
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.satiS_BOLGE_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>{" "}
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <MultiSelect
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="satiS_BOLGE_KODU"
      dataItemKey="satiS_BOLGE_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      placeholder="Satış Bölgesi seçiniz"
      value={initialValues}
    ></MultiSelect>
  );
};

export default SalesZoneMultiSelect;
