import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import filterLocalization from "../../helpers/filterLocalization";
import ManuelVadeEslemeService from "../../services/srv-fin-manuel-vade-esleme/ManuelVadeEslemeService";
import ExportExcel from "../sys-toolbox/ExcelExport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { manuelVadeEslemeReportManagerGridColumns } from "./ManuelVadeEslemeReportManagerGridColumns";

export function ExpiryReport() {
  const _grid = useRef(null);
  const sort = [
    {
      field: "dkN_FIN_MANUEL_VADE_ESLEME_ID",
      dir: "desc",
    },
  ];

  const [manuelVadeEslemeReportsLoading, setManuelVadeEslemeReportsLoading] =
    useState(false);
  const [manuelVadeEslemeReports, setManuelVadeEslemeReports] = useState(null);
  const [
    manuelVadeEslemeReportsDataState,
    setManuelVadeEslemeReportsDataState,
  ] = useState({
    sort,
    take: 10,
    skip: 0,
  });

  useEffect(() => {
    setManuelVadeEslemeReportsLoading(true);
    ManuelVadeEslemeService.getManuelVadeEslemeReports(
      manuelVadeEslemeReportsDataState,
      onGetDataCompleted
    );
  }, [manuelVadeEslemeReportsDataState]);

  const onGetDataCompleted = (data) => {
    setManuelVadeEslemeReports(data);
    setManuelVadeEslemeReportsLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setManuelVadeEslemeReportsLoading(true);
    setManuelVadeEslemeReportsDataState(event.dataState);
    ManuelVadeEslemeService.getManuelVadeEslemeReports(
      event.dataState,
      onGetDataCompleted
    );
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Manuel Vade Eşleme Rapor</CardTitle>
        </CardHeader>
        <CardBody>
          {manuelVadeEslemeReportsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={manuelVadeEslemeReports}
            dataItemKey={"dkN_FIN_MANUEL_VADE_ESLEME_ID"}
            {...manuelVadeEslemeReportsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ExportExcel
                data={manuelVadeEslemeReports}
                columns={manuelVadeEslemeReportManagerGridColumns}
                loadingFunction={setManuelVadeEslemeReportsLoading}
                dataState={manuelVadeEslemeReportsDataState}
                serviceMethod={
                  ManuelVadeEslemeService.getManuelVadeEslemeReportsForExcel
                }
                fileName="ManuelVadeEslemeRapor"
              ></ExportExcel>
            </GridToolbar>
            {manuelVadeEslemeReportManagerGridColumns.map((column) => (
              <GridColumn
                field={column.field}
                title={column.title}
                width={column.width}
                filter={column.filter}
                filterable={column.filterable}
                cell={column.cell}
                filterCell={column.filterCell}
              />
            ))}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
