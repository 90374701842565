import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Button } from "reactstrap";
import { BASE_URL } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import CustomerService from "../../services/srv-customer/CustomerService";
import GridRowRenderDefault from "../sys-toolbox/GridRowRenderDefault";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { CustomerMerge } from "./CustomerMerge";

export function CustomerList(props) {
  const exportRef = useRef(null);
  const gridRef = useRef(null);
  const sort = [
    {
      field: "muS_TANIM_ID",
      dir: "desc",
    },
  ];
  const [customersLoading, setCustomersLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customersDataState, setCustomersDataState] = useState({
    take: 10,
    skip: 0,
    sort,
  });
  const [isVisibleCustomerMerge, setIsVisibleCustomerMerge] = useState(false);

  useEffect(() => {
    getCustomers(customersDataState);
  }, []);

  const onCompletedGetCustomers = (data) => {
    if (data) {
      setCustomers(data);
    }
    setCustomersLoading(false);
  };

  const getCustomers = (dataState) => {
    setCustomersLoading(true);
    CustomerService.getCustomers(dataState, onCompletedGetCustomers);
  };

  const dataStateChange = (event) => {
    filterLocalization(event);
    setCustomersDataState(event.dataState);
    getCustomers(event.dataState);
  };

  const toggleDialogCustomerMerge = (dataItem) => {
    setIsVisibleCustomerMerge(!isVisibleCustomerMerge);
    getCustomers(customersDataState);
  };

  function goCustomerDetail(dataItem) {
    window.open(BASE_URL + "customer-update/" + dataItem.muS_TANIM_ID);
  }

  async function exportExcel() {
    if (exportRef !== null) {
      confirmAlert({
        title: "Uyarı",
        message:
          "Listenen satır sayısına göre işlem süresi uzayabilir. Devam etmek istiyor musunuz?",
        buttons: [
          {
            label: "Evet",
            onClick: async () => {
              setCustomersLoading(true);
              var data = await CustomerService.getCustomers({
                ...customersDataState,
                skip: null,
                take: null,
              });
              exportRef.current.save(data.data.data, gridRef.current.columns);
              setCustomersLoading(false);
            },
          },
          {
            label: "Hayır",
            onClick: () => {},
          },
        ],
      });
    }
  }

  function addNewCustomer() {
    window.open(BASE_URL + "customer-update/0");
  }

  return (
    <main style={{ flexGrow: "1" }}>
      {isVisibleCustomerMerge ? (
        <CustomerMerge toggleDialog={toggleDialogCustomerMerge}></CustomerMerge>
      ) : undefined}
      <Card>
        <CardHeader>
          <CardTitle>Müşteri Listesi</CardTitle>
        </CardHeader>
        <CardBody>
          {customersLoading && LoadingPanel}
          <div className="row">
            <div className="col-12">
              <ExcelExport data={customers} ref={exportRef}>
                <Grid
                  data={customers}
                  {...customersDataState}
                  onDataStateChange={dataStateChange}
                  sortable={true}
                  style={{ minHeight: "400px", fontSize: "11px" }}
                  pageable={{
                    buttonCount: 3,
                    pageSizes: [10, 20, 50, 100],
                  }}
                  resizable={true}
                  ref={gridRef}
                  rowRender={(trElement, dataItem) =>
                    GridRowRenderDefault(trElement, dataItem)
                  }
                  filterable
                >
                  <GridToolbar>
                    <button
                      title="Yeni Müşteri"
                      className="btn btn-primary btn-sm"
                      onClick={addNewCustomer}
                    >
                      Yeni Kayıt
                    </button>
                    <button
                      title="Müşteri Birleştir"
                      className="btn btn-warning btn-sm"
                      onClick={() => toggleDialogCustomerMerge()}
                    >
                      Müşteri Birleştir
                    </button>
                    <button
                      title="Excel'e Kaydet"
                      className="btn btn-success btn-sm"
                      onClick={exportExcel}
                    >
                      Excel'e Kaydet
                    </button>
                  </GridToolbar>
                  <GridColumn
                    filterable={false}
                    width="100px"
                    cell={(props) => (
                      <td>
                        <Button
                          color="primary"
                          type="button"
                          size="sm"
                          onClick={() => goCustomerDetail(props.dataItem)}
                        >
                          Detay
                        </Button>
                      </td>
                    )}
                  />
                  <GridColumn
                    field="muS_KODU"
                    title="Müş. Kodu"
                    width="110px"
                  ></GridColumn>
                  <GridColumn
                    field="muS_ADI"
                    title="Müş. Adı"
                    width="250px"
                  ></GridColumn>
                  <GridColumn
                    field="muS_EMAIL"
                    title="Müş. Email"
                    width="200px"
                  ></GridColumn>
                  <GridColumn
                    field="taM_UNVAN"
                    title="Tam Ünvan (DEX)"
                    width="250px"
                  ></GridColumn>
                  <GridColumn
                    field="bayI_KODU"
                    title="Bayi Kodu"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="firmA_TIPI"
                    title="Firma Tipi"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="muS_TELEFON"
                    title="Müş. Telefon"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="ulkE_KODU"
                    title="Ülke"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="daikiN_IL_KODU"
                    title="Şehir"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="cografI_BOLGE_KODU"
                    title="Coğrafi Bölge"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="kep"
                    title="KEP"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="vergI_DAIRESI"
                    title="Vergi Dairesi"
                    width="120px"
                  ></GridColumn>
                  <GridColumn
                    field="vergI_NO"
                    title="Vergi No"
                    width="120px"
                  ></GridColumn>
                </Grid>
              </ExcelExport>
            </div>
          </div>
        </CardBody>
      </Card>
    </main>
  );
}
