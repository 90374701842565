import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderFilesReducer(
  state = initialState.gaolDetailFiles,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_FILES:
      return {
        ...state,
        files: action.payload,
      };
    case actionTypes.FETCH_ORDER_FILES_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_ORDER_FILES_FULFILLED:
      return {
        ...state,
        files: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_ORDER_FILES_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.DELETE_ORDER_FILES:
      return {
        ...state,
        files: state.files.filter((x) => x.fileid !== action.payload),
        fetching: false,
      };
    default:
      return state;
  }
}
