import axios from "axios";
import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function updateParticipants(participants) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_BRY_CAMPAIGN_PARTICIPANTS,
      payload: axios
        .post(
          `${
            API_GATEWAY + PR_DEX_BIREYSEL
          }/kampanyakatilimcibayisecim/update-campaign-participants`,
          {
            ...participants,
          }
        )
        .then((result) => {
          return result.data;
        }),
    });
  };
}

