import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function customerGroupProductListReducer(
  state = initialState.customerGroupProductList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_CUSTOMER_GROUP_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_CUSTOMER_GROUP_PRODUCT_FULFILLED:
      return {
        ...state,
        customerGroupProducts: action.payload.data,
        fetching: false,
        dataState: action.payload.dataState,
      };
    case actionTypes.FETCH_CUSTOMER_GROUP_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_CUSTOMER_GROUP_PRODUCT:
      return {
        ...state,
        dataState: action.payload,
        fetching: true,
      };
    case actionTypes.CALLSP_CUSTOMER_GROUP_PRODUCT:
      return {
        ...state,
        customerGroupProducts: action.payload.data,
      };
    case actionTypes.CALLSP_CUSTOMER_GROUP_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
        dataInserted: false,
      };
    case actionTypes.CALLSP_CUSTOMER_GROUP_PRODUCT_FULFILLED:
      return {
        ...state,
        customerGroupProducts: action.payload.data,
        dataInserted: true,
        fetching: false,
      };
    case actionTypes.CALLSP_CUSTOMER_GROUP_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.UPDATE_CUSTOMER_GROUP_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_CUSTOMER_GROUP_PRODUCT_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.UPDATE_CUSTOMER_GROUP_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
      case actionTypes.CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT:
        return {
          ...state,
          fetching: true,
          dataRemoved: false,
        };
      case actionTypes.CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_PENDING:
        return {
          ...state,
          fetching: true,
          dataRemoved: false,
        };
      case actionTypes.CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_FULFILLED:
        return {
          ...state,
          dataRemoved: true,
          fetching: false,
        };
      case actionTypes.CALLSP_REMOVE_CUSTOMER_GROUP_PRODUCT_REJECTED:
        return {
          ...state,
          error: action.payload,
          fetching: false,
        };
    default:
      return state;
  }
}
