import React from "react";

const GridRowRenderDefault = (trElement, dataItem) => {
  const trProps = {
    ...trElement.props,
    className: "ellipsis",
  };

  return React.cloneElement(
    trElement,
    { ...trProps },
    trElement.props.children
  );
};

export default GridRowRenderDefault;
