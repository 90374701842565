import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const CommonDropDownList = (props) => {
  return (
    <DropDownList
      name={props.id}
      id={props.id}
      label={props.label}
      data={props.data}
      textField="text"
      dataItemKey="id"
      style={{ width: "100%" }}
      onChange={props.onChange}
      required={props.required}
      value={
        props.value && props.data
          ? props.data.find((a) => a.id === props.value)
          : ""
      }
    />
  );
};

export default CommonDropDownList;
