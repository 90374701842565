import { useTranslation } from "react-i18next";
const GetTranslateData = (value) => {
  const { t, i18n } = useTranslation();
  return <td>{t("data.denv_approve_status." + value)}</td>;
};

export const offerDenvGridColumns = [
  {
    field: "mrC_TEKLIF_ID",
    title: "offer.id",
    width: "90px",
    filter: "numeric",
  },
  {
    field: "onaY_DURUM",
    title: "offer.approve_status",
    width: "130px",
    cell: (props) => {
      const value = props.dataItem[props.field];
      return GetTranslateData(value);
    },
  },
  {
    field: "crE_DATE",
    title: "offer.send_to_approval_date",
    width: "180px",
    filterable: false,
    cellType: "date",
  },
  {
    field: "onaY_TARIHI",
    title: "offer.approval_date",
    width: "130px",
    filterable: false,
    cellType: "date",
  },
  {
    field: "bayI_KODU",
    title: "offer.dealer_code",
    width: "130px",
  },
  {
    field: "muS_ADI",
    title: "offer.customer_name",
    width: "300px",
  },
  {
    field: "muS_KODU",
    title: "offer.customer_code",
    width: "130px",
  },
  {
    field: "satiS_TEMSILCISI_AD_SOYAD",
    title: "user.sales_responsible.name",
    width: "200px",
  },
  {
    field: "gmY_AD_SOYAD",
    title: "offer.dmy",
    width: "200px",
  },
  {
    field: "onayA_GONDEREN_AD_SOYAD",
    title: "offer.send_to_approval_user",
    width: "200px",
  },
];
