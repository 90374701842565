import React, { useState, useEffect } from "react";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import BrandService from "../../services/srv-brand/BrandService";
import { Badge } from "reactstrap";

const BrandMultiSelect = ({
  id,
  label,
  required,
  onChange,
  defaultValue,
  value,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialValues, setInitialValues] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    BrandService.getBrands(defaultDataHandler);
  }, []);

  useEffect(() => {
    setInitialValues(
      value.map((value) => data.find((a) => a.markA_KODU === value))
    );
  }, [data, value]);

  const defaultDataHandler = ({ data }) => {
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.markA_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>{" "}
          <Badge color="primary" pill>
            {itemProps.dataItem.sektoR_KODU}
          </Badge>{" "}
          <Badge color="success" pill>
            {itemProps.dataItem.pazaR_SEGMENTI_KODU}
          </Badge>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <MultiSelect
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="markA_KODU"
      dataItemKey="markA_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      placeholder="Marka seçiniz"
      value={initialValues}
    ></MultiSelect>
  );
};

export default BrandMultiSelect;
