import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function userListReducer(
  state = initialState.userList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_USERS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_USERS_FULFILLED:
      return {
        ...state,
        users: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_USERS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_SATIS_TEMSILCILERI_USERS_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_SATIS_TEMSILCILERI_USERS_FULFILLED:
      return {
        ...state,
        users: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_SATIS_TEMSILCILERI_USERS_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
