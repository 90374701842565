import { process } from "@progress/kendo-data-query";
import {
  setExpandedState,
  setGroupIds,
} from "@progress/kendo-react-data-tools";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox, RadioGroup } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Splitter,
} from "@progress/kendo-react-layout";
import { Tooltip } from "@progress/kendo-react-tooltip";
import React, { useCallback, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { DATASALESMONTHS, DATASALESYEARS } from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import SalesService from "../../services/srv-bry-sales/SalesService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const initialGroups = [
  {
    field: "producT_GROUP",
  },
];

const initialAggregates = [
  {
    field: "quantitY_TARGET",
    aggregate: "sum",
  },
  {
    field: "quantitY_RESULT",
    aggregate: "sum",
  },
  {
    field: "neT_SALES_TARGET",
    aggregate: "sum",
  },
  {
    field: "neT_SALES_RESULT",
    aggregate: "sum",
  },
];

const initialDataState = {
  group: initialGroups,
};

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;
  if (groups) {
    groups.map((group) => (group.aggregates = initialAggregates));
  }
  dataState.group = groups;

  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });

  return newDataState;
};

export const Sales = () => {
  const _grid = useRef(null);

  const [sales, setSales] = useState();
  const [aggSales, setAggSales] = useState();
  const [salesLoading, setSalesLoading] = useState(false);
  const [salesDataState, setSalesDataState] = useState(initialDataState);
  const [collapsedState, setCollapsedState] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonthTexts, setSelectedMonthTexts] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const [dataSelectedYear, setDataSelectedYear] = useState("");
  const [dataSelectedMonths, setDataSelectedMonths] = useState([]);

  const getSales = () => {
    setSalesLoading(true);
    setDataSelectedYear(selectedYear);
    setDataSelectedMonths(selectedMonthTexts);
    SalesService.getListByYearAndMonth(
      selectedYear,
      selectedMonths,
      onSuccess,
      onError
    );
  };

  const onSuccess = (result) => {
    var uniqueObjs = [];
    result.map((item) => {
      var a = uniqueObjs.find(
        (x) =>
          x.producT_GROUP == item.producT_GROUP &&
          x.producT_MODEL == item.producT_MODEL
      );
      if (a) {
        uniqueObjs = uniqueObjs.map((x) => {
          if (
            x.producT_GROUP == item.producT_GROUP &&
            x.producT_MODEL == item.producT_MODEL
          ) {
            return {
              ...x,
              neT_SALES_RESULT: x.neT_SALES_RESULT + item.neT_SALES_RESULT,
              neT_SALES_TARGET: x.neT_SALES_TARGET + item.neT_SALES_TARGET,
              quantitY_RESULT: x.quantitY_RESULT + item.quantitY_RESULT,
              quantitY_TARGET: x.quantitY_TARGET + item.quantitY_TARGET,
            };
          }
          return x;
        });
      } else {
        uniqueObjs.push(item);
      }
    });
    setAggSales(result);
    setSales(processWithGroups(uniqueObjs, initialDataState));
    setSalesLoading(false);
  };

  const onError = (error) => {
    setSalesLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event)
    const newDataState = processWithGroups(sales.data, event.dataState);
    setSalesDataState(newDataState);
    getSales(newDataState);
  };

  const onExpandChange = useCallback(
    (event) => {
      const item = event.dataItem;
      if (item.groupId) {
        const newCollapsedIds = !event.value
          ? [...collapsedState, item.groupId]
          : collapsedState.filter((groupId) => groupId !== item.groupId);

        setCollapsedState(newCollapsedIds);
      }
    },
    [collapsedState]
  );

  const newData = setExpandedState({
    data: sales ? sales.data : [],
    collapsedIds: collapsedState,
  });

  const onMonthCheckboxChecked = (event, month) => {
    if (event.target.value === false) {
      setSelectedMonthTexts(
        selectedMonthTexts.filter((sm) => sm !== month.label)
      );
      setSelectedMonths(selectedMonths.filter((sm) => sm != month.value));
    } else if (event.target.value === true) {
      setSelectedMonthTexts([...selectedMonthTexts, month.label]);
      setSelectedMonths([...selectedMonths, parseInt(month.value)]);
    }
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = { ...trElement.props };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const NumberFormatCell = (value, dataItem) => {
    return (
      <NumberFormat
        thousandSeparator={"."}
        value={value}
        displayType={"text"}
        decimalSeparator={","}
        decimalScale="2"
        style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
      ></NumberFormat>
    );
  };

  const NetSalesCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return hiddenData ? (
      <td>
        <span style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          ***
        </span>
      </td>
    ) : (
      <td>{NumberFormatCell(dataValue)}</td>
    );
  };

  const RightAlignedColumn = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    return hiddenData ? (
      <td>
        <span style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          ***
        </span>
      </td>
    ) : (
      <td>{NumberFormatCell(dataValue)}</td>
    );
  };

  const AggregateFooter = (props) => {
    var { field } = props;

    const total = aggSales?.reduce(
      (acc, current) => acc + current[field] || 0,
      0
    );
    return hiddenData ? (
      <td>
        <span style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
          ***
        </span>
      </td>
    ) : (
      <td>
        Toplam:
        {
          <NumberFormat
            thousandSeparator={"."}
            value={total}
            displayType={"text"}
            decimalSeparator={","}
            decimalScale="2"
          ></NumberFormat>
        }
      </td>
    );
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      tdElement &&
      tdElement.props.children &&
      cellProps.rowType === "groupHeader"
    ) {
      let children = (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ display: "flex", flex: 1 }}>
            {tdElement.props.children.props.children}
          </span>
          {!salesView ? (
            <>
              <span
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {hiddenData
                  ? "***"
                  : NumberFormatCell(
                      cellProps.dataItem.aggregates.quantitY_TARGET.sum
                    )}
              </span>
              <span
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {hiddenData
                  ? "***"
                  : NumberFormatCell(
                      cellProps.dataItem.aggregates.quantitY_RESULT.sum
                    )}
              </span>
            </>
          ) : (
            <>
              <span
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {hiddenData
                  ? "***"
                  : NumberFormatCell(
                      cellProps.dataItem.aggregates.neT_SALES_TARGET.sum
                    )}
              </span>
              <span
                style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
              >
                {hiddenData
                  ? "***"
                  : NumberFormatCell(
                      cellProps.dataItem.aggregates.neT_SALES_RESULT.sum
                    )}
              </span>
            </>
          )}
        </div>
      );
      return React.cloneElement(tdElement, tdElement.props, children);
    }

    return tdElement;
  };

  const [hiddenData, setHiddenData] = useState(true);
  const [salesView, setSalesView] = useState(false);

  const [panes, setPanes] = useState([
    {
      size: "10%",
      min: "100px",
      collapsible: true,
    },
    {},
  ]);

  const [sidebarPanes, setSidebarPanes] = useState([
    {
      size: "30%",
      min: "30px",
      collapsible: false,
    },
    {},
  ]);

  const onPaneSizeChange = (event) => {
    setPanes(event.newState);
  };

  const onSidebarPaneSizeChange = (event) => {
    setSidebarPanes(event.newState);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>DAIKIN SCORECARD</CardTitle>
        </CardHeader>
        <CardBody>
          {salesLoading && LoadingPanel}
          <Splitter
            style={{ minHeight: "650px" }}
            orientation="horizontal"
            panes={panes}
            onChange={onPaneSizeChange}
          >
            <Splitter
              style={{
                minHeight: "650px",
              }}
              orientation="vertical"
              panes={sidebarPanes}
              onChange={onSidebarPaneSizeChange}
            >
              <div className="pane-content">
                <RadioGroup
                  data={DATASALESYEARS}
                  onChange={(event) => setSelectedYear(event.value)}
                  style={{ margin: "8px" }}
                />
              </div>
              <div className="pane-content">
                {DATASALESMONTHS.map((month) => {
                  return (
                    <div style={{ margin: "8px" }}>
                      <Checkbox
                        label={month.label}
                        onChange={(event) =>
                          onMonthCheckboxChecked(event, month)
                        }
                      />
                    </div>
                  );
                })}
              </div>
            </Splitter>
            <div className="pane-content">
              {dataSelectedYear && dataSelectedMonths && (
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    marginLeft: "300px",
                    position: "absolute",
                  }}
                >
                  <p>
                    <b>{dataSelectedYear}</b> yılının
                    <b>
                      {dataSelectedMonths &&
                        dataSelectedMonths.map((sm) => <span> {sm},</span>)}
                    </b>
                    aylarına ait verileri gösterilmektedir.
                  </p>
                </div>
              )}

              <div>
                <Tooltip anchorElement="target" position="top">
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      top: "445px",
                      left: "505px",
                      opacity: 0,
                    }}
                    className="btn btn-lg btn-primary"
                    onClick={() => getSales()}
                    title="Verileri getir"
                  >
                    Verileri Getir
                  </button>
                </Tooltip>
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "377px",
                    left: "735px",
                    backgroundColor: "cyan",
                    width: "30px",
                    height: "10px",
                    borderRadius: "10px",
                    opacity: hiddenData ? 0 : 1,
                  }}
                ></div>
                <Tooltip anchorElement="target" position="top">
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      top: "390px",
                      left: "720px",
                      opacity: 0,
                    }}
                    className="btn btn-lg btn-primary"
                    onClick={() => setHiddenData(!hiddenData)}
                    title="Verileri Gizle/Göster"
                  >
                    Gizle
                  </button>
                </Tooltip>
                <Tooltip anchorElement="target" position="top">
                  <button
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      top: "375px",
                      left: "355px",
                      opacity: 0,
                    }}
                    className="btn btn-lg btn-primary"
                    onClick={() => setSalesView(!salesView)}
                    title="Satış Görünümü Aç/Kapa"
                  >
                    Ciro Gör.
                  </button>
                </Tooltip>
                <img
                  style={{
                    marginLeft: "250px",
                    width: "650px",
                    height: "650px",
                  }}
                  src={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    "/SalesScoreCard.png"
                  }
                  alt="Daikin Scorecard"
                />
                <div
                  id="scoreCardGridWrapper"
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "90px",
                    left: "325px",
                    width: "520px",
                  }}
                >
                  <Grid
                    ref={_grid}
                    data={newData}
                    {...salesDataState}
                    onDataStateChange={onDataStateChange}
                    sortable
                    resizable
                    filterable={false}
                    rowRender={(trElement, dataItem) =>
                      rowRender(trElement, dataItem)
                    }
                    style={{
                      height: "255px",
                    }}
                    groupable={{
                      footer: "visible",
                    }}
                    onExpandChange={onExpandChange}
                    expandField="expanded"
                    cellRender={cellRender}
                  >
                    <GridColumn
                      field="producT_MODEL"
                      title="ÜRÜN"
                      width="150px"
                    ></GridColumn>
                    {!salesView ? (
                      <GridColumn
                        field="quantitY_TARGET"
                        title="ADET HEDEF"
                        width="150px"
                        cell={RightAlignedColumn}
                        footerCell={AggregateFooter}
                      ></GridColumn>
                    ) : (
                      <GridColumn
                        field="neT_SALES_TARGET"
                        title="SATIŞ HEDEF"
                        width="150px"
                        cell={NetSalesCell}
                        show={false}
                        footerCell={AggregateFooter}
                      ></GridColumn>
                    )}
                    {!salesView ? (
                      <GridColumn
                        field="quantitY_RESULT"
                        title="GERÇEKLEŞEN"
                        width="150px"
                        cell={RightAlignedColumn}
                        footerCell={AggregateFooter}
                      ></GridColumn>
                    ) : (
                      <GridColumn
                        field="neT_SALES_RESULT"
                        title="GERÇEKLEŞEN"
                        width="150px"
                        cell={NetSalesCell}
                        footerCell={AggregateFooter}
                      ></GridColumn>
                    )}
                  </Grid>
                </div>
              </div>
            </div>
          </Splitter>
        </CardBody>
      </Card>
    </main>
  );
};
