import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  BASE_URL,
  DATABRYSATISDEPARTMAN,
  DATALISTEVADESI,
  DATAPARABIRIMI,
  DATASAPDURUM,
  DATASIPARISTURU,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import { getChannels } from "../../redux/actions/act-bry-channel/channelActions";
import {
  cancelOrder,
  changeOrder,
  fetchOrder,
  fetchOrdersForExcel,
} from "../../redux/actions/act-order/orderActions";
import { getUserSalesRegions } from "../../redux/actions/act-userSalesRegion/userSalesRegionActions";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class OrderList extends Component {
  state = {
    inMyOrders: false,
  };

  componentDidMount() {
    var url = new URL(window.location.href);
    var filterMyOrder = url.searchParams.get("filtermyorder");
    if (filterMyOrder) {
      this.filterMyOrders();
    } else {
      this.getOrderList();
    }

    this.props.getChannels();
    this.props.getUserSalesRegions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.search.includes("filtermyorder") &&
      !window.location.search.includes("filtermyorder")
    ) {
      this.getOrderList();
    }
  }

  getOrderList = () => {
    var take = this.props.orderList.dataState
      ? this.props.orderList.dataState.take
      : 6;
    var skip = this.props.orderList.dataState
      ? this.props.orderList.dataState.skip
      : 0;
    var sort = [
      {
        field: "brY_SIPARIS_ID",
        dir: "desc",
      },
    ];

    if (this.props.auth.bayiKodu) {
      var bayiFilter = [];
      bayiFilter.push({
        field: "bayI_KODU",
        operator: "eq",
        value: this.props.auth.bayiKodu,
      });
      this.props.fetchOrder({
        filter: { logic: "or", filters: bayiFilter },
        take: take,
        skip: skip,
        sort: sort,
      });
    } else {
      this.props.fetchOrder({ take: take, skip: skip, sort: sort });
    }
  };

  goDetail = (dataItem) => {
    var userSalesRegions = this.props.userSalesRegionList.userSalesRegions;
    if (userSalesRegions.length > 0) {
      var userSalesRegionControl = userSalesRegions.find(
        (a) => a.satiS_BOLGE_KODU === dataItem.bayI_SATIS_BOLGE_KODU
      );
      if (
        userSalesRegionControl &&
        userSalesRegionControl.yetki === "TEKLIF_DETAYI_GOREBILIR"
      ) {
        window.open(BASE_URL + "order-update/" + dataItem.brY_SIPARIS_ID);
        return;
      }
    }

    defaultAlertMessages.MessageDetailError =
      "Bu bayinin siparişlerini görmek için yetkiniz bulunmamaktadır. Lütfen DEX Admin ile iletişime geçiniz.";
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmErrorMessage(onClose, defaultAlertMessages);
      },
    });
  };

  cancelOrder = (dataItem) => {
    this.props.cancelOrder(dataItem, this.props.orderList.dataState);
  };

  goNewOrder = () => {
    this.props.history.push(BASE_URL + "order-update/0");
  };

  filterMyOrders = () => {
    if (this.state.inMyOrders === false) {
      var filter = [];
      filter.push({
        field: "SATIS_ONAYCI_KULLANICI_ID",
        operator: "eq",
        value: this.props.auth.id,
      });
      filter.push({
        field: "SATIS_ONAY_DURUM",
        operator: "neq",
        value: "IPTAL",
      });
      this.props.fetchOrder({
        filter: { logic: "and", filters: filter },
        take: 6,
        skip: 0,
      });
    } else {
      this.getOrderList();
    }
    this.setState({
      inMyOrders: !this.state.inMyOrders,
    });
  };

  export = () => {
    this.props.fetchOrdersForExcel(
      this._export,
      this._grid.columns,
      this.props.orderList.dataState
    );
  };

  dataStateChange = (event) => {
    var sort = [
      {
        field: "brY_SIPARIS_ID",
        dir: "desc",
      },
    ];
    event.dataState.sort = event.dataState.sort ? event.dataState.sort : sort;
    filterLocalization(event);
    if (this.state.inMyOrders === true) {
      var filter = [];
      filter.push({
        field: "SATIS_ONAYCI_KULLANICI_ID",
        operator: "eq",
        value: this.props.auth.id,
      });
      filter.push({
        field: "SATIS_ONAY_DURUM",
        operator: "neq",
        value: "IPTAL",
      });
      this.props.fetchOrder({
        ...event.dataState,
        filter: { logic: "and", filters: filter },
      });
    } else {
      this.props.fetchOrder(event.dataState);
    }
    this.props.changeOrder(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  SapStatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATASAPDURUM.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  SalesDepartmantFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATABRYSATISDEPARTMAN.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  OrderTypeFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATASIPARISTURU.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  CurrencyFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATAPARABIRIMI.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  SalesStatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={["YENI", "ONAYDA", "ONAYLI", "IPTAL", "RED", "TEKLIF"]}
      defaultValue={"Seçiniz"}
    />
  );

  ChannelFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={this.props.bryChannels.channels.map((a) => a.brY_KANAL)}
      defaultValue={"Seçiniz"}
    />
  );

  VadeGunCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var data = DATALISTEVADESI.find((a) => a.id === dataValue.toString());
    return <td> {data ? data.text : dataValue}</td>;
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Sipariş Listesi</h2>
        {this.props.orderList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.orderList.orders}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.orderList.result}
            {...this.props.orderList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => this.goNewOrder()}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni Sipariş
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
              {!this.props.auth.bayiKodu ? (
                <Button
                  color="link"
                  type="button"
                  size="sm"
                  style={{ marginLeft: "130px" }}
                  onClick={() => this.filterMyOrders()}
                >
                  {" "}
                  <span className="k-icon k-i-sort-asc"></span>{" "}
                  {this.state.inMyOrders === true
                    ? "Tüm Siparişleri Göster"
                    : "Satış Onayımdaki Siparişleri Göster"}
                </Button>
              ) : undefined}
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="180px"
              cell={(props) => (
                <td>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => this.goDetail(props.dataItem)}
                  >
                    <i className="fas fa-info-circle"></i> Detay
                  </button>
                  {props.dataItem.satiS_ONAY_DURUM !== "IPTAL" ? (
                    <button
                      title="Sil"
                      style={{ marginLeft: "5px" }}
                      className="btn btn-danger btn-sm"
                      onClick={() => this.cancelOrder(props.dataItem)}
                    >
                      <i className="fas fa-times-circle"></i> İptal
                    </button>
                  ) : undefined}
                </td>
              )}
            />
            <GridColumn
              field="brY_SIPARIS_ID"
              title="Sipariş No"
              width="90px"
              filter={"numeric"}
            ></GridColumn>
            <GridColumn
              field="crE_DATE"
              title="Sipariş Giriş Tarihi"
              width="100px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
            ></GridColumn>
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="bayI_KODU"
                title="Bayi Kodu"
                width="120px"
              ></GridColumn>
            )}
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="bayI_ADI"
                title="Bayi Adı"
                width="250px"
              ></GridColumn>
            )}
            <GridColumn
              field="satiS_DEPARTMANI_ADI"
              title="Satış Departmanı"
              width="120px"
              filterCell={this.SalesDepartmantFilterCell}
            ></GridColumn>
            <GridColumn
              field="brY_KANAL"
              title="Kanal"
              width="120px"
              filterCell={this.ChannelFilterCell}
            ></GridColumn>
            <GridColumn
              field="sipariS_TURU"
              title="Sipariş Türü"
              width="120px"
              filterCell={this.OrderTypeFilterCell}
            ></GridColumn>
            <GridColumn
              field="kampanyA_KODU"
              title="Kampanya Kodu"
              width="120px"
            ></GridColumn>
            <GridColumn
              field="parA_BIRIMI"
              title="Para Birimi"
              width="100px"
              filterCell={this.CurrencyFilterCell}
            ></GridColumn>
            <GridColumn
              field="satiS_ONAY_DURUM"
              title="Satış Onay Durum"
              width="120px"
              filterCell={this.SalesStatusFilterCell}
            ></GridColumn>
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="satiS_ONAYCI_KULLANICI_ADI"
                title="Onay Hiyerarşisi"
                width="150px"
              ></GridColumn>
            )}
            <GridColumn
              field="satiS_TEMSILCISI"
              width="150px"
              title="Satış Temsilcisi"
            ></GridColumn>
            <GridColumn
              field="saP_DURUM"
              title="SAP Sipariş Durumu"
              width="120px"
              filterCell={this.SapStatusFilterCell}
            ></GridColumn>
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="saP_ISLEM_KULLANICI_ADI"
                title="SAP İşlem Yapan Kullanıcı"
                width="150px"
              ></GridColumn>
            )}
            <GridColumn
              field="saP_SIPARIS_NO"
              title="SAP Sipariş No"
              width="150px"
            ></GridColumn>
            {this.props.auth.bayiKodu ? undefined : (
              <GridColumn
                field="saP_GIRIS_TARIHI"
                title="SAP Giriş Tarihi"
                width="120px"
                filterable={false}
                filter="date"
                cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy HH:mm")}
              ></GridColumn>
            )}
            <GridColumn
              field="satiN_ALMA_SIPARIS_NO"
              title="Purchase Order Number"
              width="192px"
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bryChannels: state.channelsReducer,
    userSalesRegionList: state.userSalesRegionsReducer,
    orderList: state.orderListReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchOrder: bindActionCreators(fetchOrder, dispatch),
    changeOrder: bindActionCreators(changeOrder, dispatch),
    cancelOrder: bindActionCreators(cancelOrder, dispatch),
    getChannels: bindActionCreators(getChannels, dispatch),
    getUserSalesRegions: bindActionCreators(getUserSalesRegions, dispatch),
    fetchOrdersForExcel: bindActionCreators(fetchOrdersForExcel, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
