import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchProductPriceLog(code) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PRODUCT_PRICE_LOG,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/urunfiyatlog/get-list-by-code/${code}`)
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function changeProductPriceLog(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_PRODUCT_PRICE_LOG,
      payload: event,
    });
  };
}
