import GridDateFormatCell from "../sys-common/GridDateFormatCell";

export const biggSerialsModifiedReportGridColumns = [
  {
    field: "dkN_BIGG_SERIALS_MODIFIED_ID",
    title: "DKN BIGG SERIALS MODIFIED ID",
    width: "128px",
    filter: "numeric",
  },
  {
    field: "dkN_BIGG_SERIALS_HAM_ID",
    title: "DKN BIGG SERIALS HAM ID",
    width: "128px",
    filter: "numeric",
  },
  {
    field: "seriaL_NUMBER_MODIFIED",
    title: "SERİ NUMARASI MODİFİYE",
    width: "256px",
  },
  {
    field: "seriaL_NUMBER_ORJ",
    title: "SERİ NUMARASI ORJİNAL",
    width: "256px",
  },
  {
    field: "uruN_KODU",
    title: "ÜRÜN KODU",
    width: "256px",
  },
  {
    field: "checksuM_VAR",
    title: "CHECKSUM VAR",
    width: "256px",
  },
  {
    field: "marka",
    title: "MARKA",
    width: "256px",
  },
  {
    field: "model",
    title: "MODEL",
    width: "256px",
  },
  {
    field: "kapasite",
    title: "KAPASİTE",
    width: "256px",
  },
  {
    field: "brY_URUN_GRUBU",
    title: "ÜRÜN GRUBU",
    width: "256px",
  },
  {
    field: "customeR_CODE",
    title: "MÜŞTERİ KODU",
    width: "256px",
  },
  {
    field: "customeR_NAME",
    title: "MÜŞTERİ ADI",
    width: "256px",
  },
  {
    field: "entrY_DATE",
    title: "GİRİŞ ZAMANI",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "bigG_SEND",
    title: "GÖNDERİM DURUMU (BIGG)",
    width: "256px",
  },
  {
    field: "bigG_SEND_DATE",
    title: "GÖNDERİM ZAMANI (BIGG)",
    width: "256px",
    filter: "date",
    filterable: true,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "bigG_SEND_HATA_ACIKLAMA",
    title: "GÖNDERİM AÇIKLAMA (BIGG)",
    width: "256px",
  },
  {
    field: "senD_360",
    title: "GÖNDERİM DURUMU (360)",
    width: "256px",
  },
  {
    field: "senD_DATE_360",
    title: "GÖNDERİM ZAMANI (360)",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "hatA_ACIKLAMA_360",
    title: "GÖNDERİM AÇIKLAMA (360)",
    width: "256px",
  },
  {
    field: "senD_TYPE_360",
    title: "GÖNDERİM TİPİ (360)",
    width: "256px",
  },
];
