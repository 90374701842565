export const productArtiGunGridColums = [
  {
    field: "uruN_KODU",
    title: "Ürün Kodu",
    width: "256px",
  },
  {
    field: "uruN_ADI",
    title: "Ürün Adı",
    width: "256px",
  },
  {
    field: "artI_FREE_STOCK_GUN",
    title: "Artı Free Stock Gün",
    width: "256px",
    editor: "numeric",
  },
];
