import { Editor, EditorTools } from "@progress/kendo-react-editor";
import React from "react";
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
} = EditorTools;
const constraints = {
  maxHeight: 520,
  minWidth: 655,
  minHeight: 250,
};
const resize = {
  //resize: 'both'
};
const AnnouncementEditor = ({ ref, value, onChange }) => {
  return (
    <>
      <Editor
        ref={ref}
        id="icerik"
        resizable={true}
        style={{
          height: 360,
          ...constraints,
          ...resize,
        }}
        name="icerik"
        tools={[
          [Bold, Italic, Underline, Strikethrough],
          [Subscript, Superscript],
          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
          [Indent, Outdent],
          [OrderedList, UnorderedList],
          FontSize,
          FontName,
          FormatBlock,
          [Undo, Redo],
          [Link, Unlink, InsertImage, ViewHtml],
          [InsertTable],
          [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
          [DeleteRow, DeleteColumn, DeleteTable],
          [MergeCells, SplitCell],
        ]}
        onChange={onChange}
        value={value}
      />
    </>
  );
};

export default AnnouncementEditor;
