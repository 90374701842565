import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderListReducer(
  state = initialState.orderList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_ORDER_FULFILLED:
      return {
        ...state,
        orders: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_ORDER:
      return {
        ...state,
        dataState: action.payload,
      };
    case actionTypes.CALLSP_REMOVE_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
        dataRemoved: false,
      };
    case actionTypes.CALLSP_REMOVE_ORDER_FULFILLED:
      return {
        ...state,
        dataRemoved: true,
        fetching: false,
      };
    case actionTypes.CALLSP_REMOVE_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
