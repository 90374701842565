import { MS_USERSDEX } from "../../config/globalVariables";
import { rawRequest, ServiceBase } from "../srv-base/ServiceBase";

class UserFinoRoleService extends ServiceBase {
  constructor() {
    super(MS_USERSDEX, "/kullanicifinorole");
  }

  getByKullaniciId(kullaniciId, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      MS_USERSDEX,
      "/kullanicifinorole",
      `/get-by-id/${kullaniciId}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new UserFinoRoleService();
