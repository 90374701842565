import { filterBy } from "@progress/kendo-data-query";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import {
  fetchSalesDepartment,
  updateSalesDepartment,
} from "../../redux/actions/act-mrc-salesDepartmant/salesDepartmantActions";
import { fetchUser } from "../../redux/actions/act-user/userActions";
import { MyCommandCellWithoutDelete } from "../sys-toolbox/GridInlineEditWitouthDelete";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const primaryKey = "mrC_SATIS_DEPARTMANI_TANIM_ID";
const editField = "inEdit";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];

class SalesDepartmentList extends Component {
  isSalesDepartmentList = false;
  editField = "inEdit";
  isRejectedData = false;

  state = {
    salesDepartmentData: this.props.salesDepartmentData,
    editData: null,
    users: [],
    loadingUsers: false,
  };

  componentDidMount() {
    this.getSalesDepartmentList();
    this.props.fetchUser(this.onCompletedFetchUser);
  }

  dataStateChange = (event) => {
    this.props.fetchSalesDepartment(event.dataState, this.onCompletedCallBack);
  };

  getSalesDepartmentList = (search) => {
    var take = this.props.salesDepartmentList.dataState
      ? this.props.salesDepartmentList.dataState.take
      : 6;
    var skip = this.props.salesDepartmentList.dataState
      ? this.props.salesDepartmentList.dataState.skip
      : 0;

    this.props.fetchSalesDepartment(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onCompletedCallBack
    );
  };

  onCompletedCallBack = (data) => {
    this.setState({
      salesDepartmentData: data,
    });
  };

  onCompletedFetchUser = () => {
    this.setState({
      users: this.props.userList.users,
    });
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.salesDepartmentData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.salesDepartmentData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        salesDepartmentData: { ...this.state.salesDepartmentData, data },
      });
    }
  };

  update = (dataItem) => {
    this.props.updateSalesDepartment(
      dataItem,
      this.props.salesDepartmentList.dataState,
      this.onCompletedCallBack
    );
    dataItem.inEdit = undefined;
  };

  cancel = (dataItem) => {
    const originalItem =
      this.props.salesDepartmentList.salesDepartments.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.salesDepartmentData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      salesDepartmentData: { ...this.state.salesDepartmentData, data },
    });
  };

  CommandCell = (props) => (
    <MyCommandCellWithoutDelete
      {...props}
      edit={this.enterEdit}
      discard={this.discard}
      update={this.update}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  filterChangeUsers = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        users: filterBy(this.props.userList.users, event.filter),
        loadingUsers: false,
      });
    }, 500);

    this.setState({
      loadingUsers: true,
    });
  };
  UserDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.state.users}
            required
            textField="adsoyad"
            dataItemKey="kullanicI_ID"
            filterable={true}
            onFilterChange={this.filterChangeUsers}
            loading={this.state.loadingUsers}
            onChange={(e) => this.userOnChange(e, props)}
            value={{
              adsoyad: this.props.userList.users?.find(
                (u) => u.kullanicI_ID === dataValue
              )?.adsoyad,
              kullanicI_ID: dataValue,
            }}
          />
        </td>
      );
    } else {
      return (
        <td>
          {
            this.props.userList.users?.find((u) => u.kullanicI_ID === dataValue)
              ?.adsoyad
          }
        </td>
      );
    }
  };
  userOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value["kullanicI_ID"];
    const data = this.state.salesDepartmentData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [props.field]: value,
          }
    );
    this.setState({
      salesDepartmentData: {
        ...this.state.salesDepartmentData,
        data,
      },
    });
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>SATIS ONAY VEKALET</h2>
        {this.props.salesDepartmentList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.salesDepartmentList.salesDepartments}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.state.salesDepartmentData}
            onDataStateChange={this.dataStateChange}
            {...this.props.salesDepartmentList.dataState}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            onItemChange={this.itemChange}
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
            dataItemKey={primaryKey}
            editField={editField}
          >
            <GridColumn
              cell={this.CommandCell}
              width="100px"
              filterable={false}
            />
            <GridColumn
              field={primaryKey}
              title="Id"
              width="100px"
              editable={false}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="satiS_DEPARTMANI_KODU"
              textField="satiS_DEPARTMANI_KODU"
              title="Departman"
              width="180px"
              editable={false}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="sD_SALES_DM_KULLANICI_ID"
              textField="sD_SALES_DM_KULLANICI_ID"
              title="DM"
              width="200px"
              cell={this.UserDropDown}
            ></GridColumn>
            <GridColumn
              field="sD_SALES_DGM_KULLANICI_ID"
              textField="sD_SALES_DGM_KULLANICI_ID"
              editable={false}
              title="DGM"
              width="200px"
              cell={this.UserDropDown}
            ></GridColumn>
            <GridColumn
              field="sD_MARKETING_DGM_KULLANICI_ID"
              textField="sD_MARKETING_DGM_KULLANICI_ID"
              editable={false}
              title="MDGM"
              width="200px"
              cell={this.UserDropDown}
            ></GridColumn>
            <GridColumn
              field="sD_CPL_KULLANICI_ID"
              textField="sD_CPL_KULLANICI_ID"
              editable={false}
              title="CPL"
              width="200px"
              cell={this.UserDropDown}
            ></GridColumn>
            <GridColumn
              field="sD_CEO_KULLANICI_ID"
              textField="sD_CEO_KULLANICI_ID"
              editable={false}
              title="CEO"
              width="200px"
              cell={this.UserDropDown}
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    salesDepartmentList: state.salesDepartmantListReducer,
    userList: state.userListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSalesDepartment: bindActionCreators(fetchSalesDepartment, dispatch),
    updateSalesDepartment: bindActionCreators(updateSalesDepartment, dispatch),
    fetchUser: bindActionCreators(fetchUser, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesDepartmentList);
