import { PR_FINANS } from "../../config/globalVariables";
import { ServiceBase } from "../srv-base/ServiceBase";

class PaymentConditionService extends ServiceBase {
  constructor() {
    super(PR_FINANS, "/odemekosulu");
  }
}

export default new PaymentConditionService();
