import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import SalesZoneService from "../../services/srv-salesZone/SalesZoneService";

const SalesZoneDropDownList = ({ id, label, required, onChange, value }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setDataLoading(true);
    SalesZoneService.getSalesZones(
      {
        filter: { logic: "or", filters: [] },
      },
      defaultDataHandler
    );
  }, []);

  const defaultDataHandler = ({ data }) => {
    data.sort((a, b) => (a.satiS_BOLGE_KODU > b.satiS_BOLGE_KODU ? 1 : -1));
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.satiS_BOLGE_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="satiS_BOLGE_ADI"
      dataItemKey="satiS_BOLGE_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={
        value ? filteredData.find((v) => v.satiS_BOLGE_KODU === value) : ""
      }
    ></DropDownList>
  );
};

export default SalesZoneDropDownList;
