import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { Window } from "@progress/kendo-react-dialogs";
import { bindActionCreators } from "redux";
import DealerDropDownList from "../sys-common/DealerDropDownList";
import { copyOrder } from "../../redux/actions/act-order/orderActions";
import { BASE_URL } from "../../config/globalVariables";
class OrderCopy extends Component {
  state = {
    bayI_KODU: this.props.order._order.bayI_KODU,
    newOrderId: null,
  };

  copyOrder = () => {
    var data = {
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
      BAYI_KODU: this.state.bayI_KODU,
    };
    this.props.copyOrder(data, this.callbackOnAfterCopy);
  };

  callbackOnAfterCopy = (response) => {
    if (response.success) {
      this.setState({
        newOrderId: response.data.brY_YENI_SIPARIS_ID,
      });
    }
  };

  goNewOrder = () => {
    window.open(BASE_URL + "order-update/" + this.state.newOrderId);
  };

  render() {
    return (
      <Window
        title="Sipariş Kopyala"
        onClose={this.props.toggleDialog}
        initialHeight={"40vh"}
        initialWidth={"35%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <DealerDropDownList
          id="bayI_KODU"
          label="Bayi Seçimi (Arama yapmak için en az 3 harf giriniz.)"
          value={this.state.bayI_KODU}
          onChange={(e, props) => {
            this.setState({ bayI_KODU: e.value.bayI_KODU });
          }}
          required={true}
        />
        <Row style={{ marginTop: "20px" }}>
          {this.state.newOrderId ? (
            <Col lg="12" md="12" xs="12" style={{ textAlign: "center" }}>
              <Button
                color="success"
                type="button"
                size="md"
                onClick={this.goNewOrder}
              >
                <span class="fas fa-arrow-right"></span>
                Kopyalanan Siparişe Git
              </Button>
            </Col>
          ) : (
            <Col lg="12" md="12" xs="12" style={{ textAlign: "center" }}>
              <Button
                color="primary"
                type="button"
                size="md"
                onClick={this.copyOrder}
              >
                <span class="fas fa-check-circle"></span>
                Kopyala
              </Button>
            </Col>
          )}
        </Row>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orderReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    copyOrder: bindActionCreators(copyOrder, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderCopy);
