import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class AnnouncementService {
  getAnnouncements(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/Duyuru/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getAnnouncementById(id, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/Duyuru/get-by-id/${id}`)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  insert(announcement, callback) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/Duyuru/add-announcement`, announcement)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }

  update(announcement, callback) {
    return axios
      .put(`${API_GATEWAY + PR_DEX}/Duyuru/update-announcement`, announcement)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new AnnouncementService();
