import React, { Component } from "react";
import { logout } from "../../redux/actions/act-root/authActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { BASE_URL } from "../../config/globalVariables";

class Logout extends Component {
  componentWillMount() {
    this.props.logout();
  }

  componentDidMount() {
    window.location.href = BASE_URL;
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: bindActionCreators(logout, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
