import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { cloneElement, useEffect, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";
import UserProjectResponsibilityService from "../../services/srv-user/UserProjectResponsibilityService";
import CityDaikinDropDownList from "../sys-common/CityDaikinDropDownList";
import ProvinceDaikinDropDownList from "../sys-common/ProvinceDaikinDropDownList";
import SalesDepartmentDropDownList from "../sys-common/SalesDepartmentDropDownList";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export function UserProjectResponsibility({ modalToggle, user, userName }) {
  const gridPrimaryKey = "dkN_MRC_KULLANICI_PROJE_SORUMLUSU_ILCE_ID";
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };
  const sort = [
    {
      field: gridPrimaryKey,
      dir: "desc",
    },
  ];
  const gridSearch = "";
  const gridEditField = "inEdit";

  const gridRef = useRef(null);

  const [ilData, setIlData] = useState(null);
  const [initialGridData, setInitialGridData] = useState([]);
  const [gridFilterData, setGridFilterData] = useState({
    sort,
    take: 10,
    skip: 0,
    ...gridSearch,
    filter: {
      logic: "or",
      filters: [
        {
          field: "kullanicI_ID",
          operator: "eq",
          value: user,
        },
      ],
    },
  });
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getInitialGridData();
  }, [gridFilterData]);

  function getInitialGridData() {
    setDataLoading(true);
    UserProjectResponsibilityService.getByFilter(
      gridFilterData,
      onCompletedGetData
    );
  }

  function onCompletedGetData(result) {
    if (result) {
      setInitialGridData(result);
    }
    setDataLoading(false);
  }

  function onEditCompleted() {
    setDataLoading(false);
    getInitialGridData();
  }

  function onDataStateChange(event) {
    setGridFilterData(event.dataState);
  }

  function rowRender(row, dataItem) {
    const trProps = { ...row.props, className: "ellipsis" };
    return cloneElement(row, { ...trProps }, row.props.children);
  }

  function enterEditCommand(dataItem) {
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = initialGridData.data.map((item) =>
        item[gridPrimaryKey] === dataItem[gridPrimaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      setInitialGridData({ ...initialGridData, data });
    }
  }

  function addNewUserProjectResponsibility() {
    const newDataItem = {
      kullanicI_ID: user,
      inEdit: true,
    };
    var isOpenEdit = initialGridData.data.find((a) => a.inEdit === true);
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      setInitialGridData({
        ...initialGridData,
        data: [newDataItem, ...initialGridData.data],
      });
    }
  }

  function addCommand(dataItem) {
    setDataLoading(true);
    UserProjectResponsibilityService.create(dataItem, onEditCompleted);
    dataItem.inEdit = false;
  }
  function removeCommand(dataItem) {
    setDataLoading(true);
    UserProjectResponsibilityService.delete(dataItem, onEditCompleted);
    dataItem.inEdit = false;
  }
  function discardCommand() {
    const data = [...initialGridData.data];
    data.splice(0, 1);
    setInitialGridData({ ...initialGridData, data });
  }
  function updateCommand(dataItem) {
    setDataLoading(true);
    UserProjectResponsibilityService.update(dataItem, onEditCompleted);
    dataItem.inEdit = false;
  }
  function cancelCommand(dataItem) {
    const originalItem = initialGridData.data.find(
      (p) => p[gridPrimaryKey] === dataItem[gridPrimaryKey]
    );
    const data = initialGridData.data.map((item) =>
      item[gridPrimaryKey] === originalItem[gridPrimaryKey]
        ? originalItem
        : item
    );

    setInitialGridData({ ...initialGridData, data });
  }
  const CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={enterEditCommand}
      add={addCommand}
      remove={removeCommand}
      discard={discardCommand}
      update={updateCommand}
      cancel={cancelCommand}
      editField={gridEditField}
      idField={gridPrimaryKey}
      nameField={gridPrimaryKey}
    />
  );

  function SalesDepartmentCell(props) {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <SalesDepartmentDropDownList
            id={field}
            name={field}
            label={dataValue}
            value={dataValue}
            onChange={(e) => {
              dataItem[field] = e.value.satiS_DEPARTMANI_KODU;
            }}
          />
        </td>
      );
    } else {
      return <td>{dataValue}</td>;
    }
  }

  function CityCell(props) {
    var { dataItem, field } = props;
    var dataValue =
      dataItem["daikiN_IL_KODU"] === null ? "" : dataItem["daikiN_IL_KODU"];
    if (dataItem.inEdit) {
      return (
        <td>
          <CityDaikinDropDownList
            id="iL_KODU"
            value={dataValue}
            filterField={"ulkE_KODU"}
            filterValue={"TR"}
            onChange={(e) => {
              dataItem["daikiN_IL_KODU"] = e.value.daikiN_IL_KODU;
              setIlData({ ...ilData, iL_KODU: e.value.daikiN_IL_KODU });
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem["daikiN_IL_ADI"]}</td>;
    }
  }

  function ProvinceCell(props) {
    var { dataItem, field } = props;
    var dataValue =
      dataItem["geneL_DAIKIN_ILCE_TANIM_ID"] === null
        ? ""
        : dataItem["geneL_DAIKIN_ILCE_TANIM_ID"];
    if (dataItem.inEdit) {
      return (
        <td>
          <ProvinceDaikinDropDownList
            id={"geneL_DAIKIN_ILCE_TANIM_ID"}
            value={dataItem["daikiN_ILCE_ADI"]}
            filterValue={ilData?.iL_KODU}
            onChange={(e) => {
              console.log("dataItem", dataItem);
              dataItem["daikiN_ILCE_ADI"] = e.value.daikiN_ILCE_ADI;
              dataItem["geneL_DAIKIN_ILCE_TANIM_ID"] =
                e.value.geneL_DAIKIN_ILCE_TANIM_ID;
              console.log("dataItem2", dataItem);
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem["daikiN_ILCE_ADI"]}</td>;
    }
  }

  return (
    <Window
      title={`${userName} Proje İlçe Sorumlulukları`}
      onClose={modalToggle}
      initialHeight={"60vh"}
      initialWidth={"50%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {dataLoading && LoadingPanel}
      <Grid
        sortable={true}
        resizable={true}
        filterable={false}
        data={initialGridData}
        {...gridFilterData}
        dataItemKey={gridPrimaryKey}
        pageable={gridPageable}
        ref={gridRef}
        onDataStateChange={onDataStateChange}
        rowRender={(row, dataItem) => rowRender(row, dataItem)}
      >
        <GridToolbar>
          <button
            title="Yeni Proje Sorumlusu"
            className="btn btn-primary btn-sm"
            onClick={addNewUserProjectResponsibility}
          >
            Yeni Kayıt
          </button>
        </GridToolbar>
        <GridColumn cell={CommandCell} width="100px" filterable={false} />
        <GridColumn
          field="satiS_DEPARTMANI_KODU"
          title="Satış Departmanı"
          width="256px"
          cell={SalesDepartmentCell}
          filterable={false}
        />
        <GridColumn
          field="daikiN_IL_ADI"
          title="İl Adı"
          width="256px"
          cell={CityCell}
          filterable={false}
        />
        <GridColumn
          field="daikiN_ILCE_ADI"
          title="İlçe Adı"
          width="256px"
          cell={ProvinceCell}
          filterable={false}
        />
      </Grid>
    </Window>
  );
}
