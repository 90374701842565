import React, { Component, Fragment } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { filterBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  changeDealerProposer,
  deleteDealerProposer,
  fetchDealerProposer,
  fetchDealerProposerPayload,
  insertDealerProposer,
  updateDealerProposer,
} from "../../redux/actions/act-mrc-dealerProposer/dealerProposerActions";

import { fetchUser } from "../../redux/actions/act-user/userActions";

import {
  fetchDealers,
  getDealers,
} from "../../redux/actions/act-dealer/dealerActions";

import { Button } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getGridFilterProperties,
} from "../../helpers/utils";
import { MyCommandCell } from "../sys-toolbox/GridInlineEdit";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

const primaryKey = "dkN_MRC_BAYI_TEKLIFCI_ID";
const editField = "inEdit";
const sort = [
  {
    field: primaryKey,
    dir: "desc",
  },
];

class DealerProposerList extends Component {
  isDealerProposerList = false;
  editField = "inEdit";
  isRejectedData = false;

  state = {
    dealerProposerData: this.props.dealerProposerData,
    editData: null,
    removeData: null,
    users: [],
    loadingUsers: false,
  };

  dataStateChange = (event) => {
    this.props.fetchDealerProposer(event.dataState, this.onComplatedCallBack);
  };

  componentDidMount() {
    this.getDealerProposerList();
    this.props.fetchDealers();
    this.props.fetchUser(this.onCompletedFetchUser);
  }

  onCompletedFetchUser = () => {
    this.setState({ users: this.props.userList.users });
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  export = async () => {
    if (this._export !== null) {
      var data = await fetchDealerProposerPayload({
        ...this.props.dealerProposerList.dataState,
        skip: 0,
        take: 10000,
      });
      this._export.save(data.data.data, this._grid.columns);
    }
  };

  getDealerProposerList = (search) => {
    var take = this.props.dealerProposerList.dataState
      ? this.props.dealerProposerList.dataState.take
      : 6;
    var skip = this.props.dealerProposerList.dataState
      ? this.props.dealerProposerList.dataState.skip
      : 0;

    this.props.fetchDealerProposer(
      {
        sort,
        take: take,
        skip: skip,
        ...search,
      },
      this.onComplatedCallBack
    );
  };

  itemChange = (event) => {
    const data = this.state.dealerProposerData.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    this.setState({
      dealerProposerData: { ...this.state.dealerProposerData, data },
    });
  };

  addNewDealerProposer = () => {
    const newDataItem = {
      inEdit: true,
    };
    var isOpenEdit = this.state.dealerProposerData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      this.setState({
        dealerProposerData: {
          ...this.state.dealerProposerData,
          data: [newDataItem, ...this.state.dealerProposerData.data],
        },
      });
    }
  };

  remove = (dataItem) => {
    this.props.deleteDealerProposer(
      dataItem[primaryKey],
      this.props.dealerProposerList.dataState,
      this.onComplatedCallBack
    );
    this.setState({ removeData: dataItem });
  };

  checkValidate = (dataItem) => {
    console.log(dataItem);
    return true;
  };

  add = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.insertDealerProposer(
      dataItem,
      this.props.dealerProposerList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = false;
  };

  enterEdit = (dataItem) => {
    var isOpenEdit = this.state.dealerProposerData.data.find(
      (a) => a.inEdit === true
    );
    if (isOpenEdit) {
      defaultAlertMessages.MessageDetailError =
        "Lütfen önce düzenlenen satırı tamamlayınız.";
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      const data = this.state.dealerProposerData.data.map((item) =>
        item[primaryKey] === dataItem[primaryKey]
          ? { ...item, inEdit: true }
          : item
      );
      this.setState({
        dealerProposerData: { ...this.state.dealerProposerData, data },
      });
    }
  };

  update = (dataItem) => {
    if (!this.checkValidate(dataItem)) return;
    this.props.updateDealerProposer(
      dataItem,
      this.props.dealerProposerList.dataState,
      this.onComplatedCallBack
    );
    dataItem.inEdit = undefined;
  };

  discard = () => {
    const data = [...this.state.dealerProposerData.data];
    data.splice(0, 1);
    this.setState({
      dealerProposerData: { ...this.state.dealerProposerData, data },
    });
  };

  cancel = (dataItem) => {
    const originalItem =
      this.props.dealerProposerList.dealerProposers.data.find(
        (p) => p[primaryKey] === dataItem[primaryKey]
      );
    const data = this.state.dealerProposerData.data.map((item) =>
      item[primaryKey] === originalItem[primaryKey] ? originalItem : item
    );

    this.setState({
      dealerProposerData: { ...this.state.dealerProposerData, data },
    });
  };

  setDealerProposerData = () => {
    this.setState({
      dealerProposerData: this.state.dealerProposerData.data.map((item) =>
        item[primaryKey] === this.state.editData[primaryKey]
          ? { ...item, inEdit: true }
          : item
      ),
    });
  };

  onComplatedCallBack = (data) => {
    this.setState({
      dealerProposerData: data,
    });
  };

  CommandCell = (props) => (
    <MyCommandCell
      {...props}
      edit={this.enterEdit}
      add={this.add}
      discard={this.discard}
      update={this.update}
      remove={this.remove}
      cancel={this.cancel}
      editField={editField}
      idField={primaryKey}
      nameField={primaryKey}
    />
  );

  DealersDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var dataSource = this.props.dealerList.dealers;
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={dataSource}
            required
            textField="bayI_ADI"
            dataItemKey="bayI_KODU"
            loading={this.props.dealerList.fetching}
            onChange={(e) => this.dealersOnChange(e, props)}
            value={{
              bayI_ADI: dataSource?.find((u) => u.bayI_KODU === dataValue)
                ?.bayI_ADI,
              bayI_KODU: dataValue,
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.bayI_ADI}</td>;
    }
  };
  dealersOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value[field];
    const data = this.state.dealerProposerData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [props.field]: value,
          }
    );
    this.setState({
      dealerProposerData: {
        ...this.state.dealerProposerData,
        data,
      },
    });
  };

  filterChangeUsers = (event) => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.setState({
        users: filterBy(this.props.userList.users, event.filter),
        loadingUsers: false,
      });
    }, 500);

    this.setState({
      loadingUsers: true,
    });
  };

  UserDropDown = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    if (dataItem.inEdit) {
      return (
        <td>
          <DropDownList
            data={this.state.users}
            required
            textField="adsoyad"
            dataItemKey="kullanicI_ID"
            filterable={true}
            onFilterChange={this.filterChangeUsers}
            loading={this.state.loadingUsers}
            onChange={(e) => this.userOnChange(e, props)}
            value={{
              adsoyad: this.props.userList.users?.find(
                (u) => u.kullanicI_ID === dataValue
              )?.adsoyad,
              kullanicI_ID: dataValue,
            }}
          />
        </td>
      );
    } else {
      return <td>{dataItem.kullanicI_AD_SOYAD}</td>;
    }
  };
  userOnChange = (e, props) => {
    const { field } = props;
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: props.syntheticEvent,
      value: e.value,
    });
    const value = e.value[field];
    const data = this.state.dealerProposerData.data.map((item) =>
      !item.inEdit
        ? item
        : {
            ...item,
            [props.field]: value,
          }
    );
    this.setState({
      dealerProposerData: {
        ...this.state.dealerProposerData,
        data,
      },
    });
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Bayi - Onay Kullanıcısı</h2>
        {this.props.dealerProposerList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.dealerProposerList.dealerProposers?.data}
          ref={(exporter) => (this._export = exporter)}
        >
          <LocalizationProvider language="tr">
            <IntlProvider locale="tr">
              <Grid
                ref={(grid) => {
                  this._grid = grid;
                }}
                data={this.state.dealerProposerData}
                onDataStateChange={this.dataStateChange}
                {...this.props.dealerProposerList.dataState}
                sortable={true}
                style={{ minHeight: "400px" }}
                pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
                resizable={true}
                onItemChange={this.itemChange}
                rowRender={(trElement, dataItem) =>
                  this.rowRender(trElement, dataItem)
                }
                filterable
                sort={sort}
                dataItemKey={primaryKey}
                editField={editField}
              >
                <GridToolbar>
                  <Button
                    color="primary"
                    type="button"
                    size="sm"
                    onClick={this.addNewDealerProposer}
                  >
                    {" "}
                    <span className="k-icon k-i-plus-circle"></span> Yeni
                  </Button>
                  <button
                    title="Export Excel"
                    className="btn btn-info btn-sm"
                    onClick={this.export}
                  >
                    Excel
                  </button>
                </GridToolbar>

                <GridColumn
                  cell={this.CommandCell}
                  width="100px"
                  filterable={false}
                />

                <GridColumn
                  field={primaryKey}
                  title="Id"
                  width="100px"
                  filter={"numeric"}
                  editable={false}
                ></GridColumn>

                <GridColumn
                  field="bayI_KODU"
                  textField="bayI_ADI"
                  title="Bayi"
                  width="180px"
                  cell={this.DealersDropDown}
                ></GridColumn>

                <GridColumn
                  field="kullanicI_ID"
                  textField="kullanicI_ID"
                  title="Kullanıcı"
                  width="200px"
                  cell={this.UserDropDown}
                ></GridColumn>
              </Grid>
            </IntlProvider>
          </LocalizationProvider>
        </ExcelExport>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    dealerProposerList: state.dealerProposerListReducer,
    dealerList: state.dealerListReducer,
    userList: state.userListReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDealerProposer: bindActionCreators(fetchDealerProposer, dispatch),
    changeDealerProposer: bindActionCreators(changeDealerProposer, dispatch),
    insertDealerProposer: bindActionCreators(insertDealerProposer, dispatch),
    updateDealerProposer: bindActionCreators(updateDealerProposer, dispatch),
    deleteDealerProposer: bindActionCreators(deleteDealerProposer, dispatch),
    fetchDealers: bindActionCreators(fetchDealers, dispatch),
    fetchUser: bindActionCreators(fetchUser, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerProposerList);
