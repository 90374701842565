import { PR_DEX_MERCURY } from "../../../config/globalVariables";
import { rawRequest } from "../../srv-base/ServiceBase";

class OfferDenvLogService {
  getByOfferDenvId(id, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      PR_DEX_MERCURY,
      "/teklifdenvonaylog",
      `/get-by-teklif-denv-id/${id}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new OfferDenvLogService();
