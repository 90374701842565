import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { PR_DEX_MERCURY } from '../../config/globalVariables';
import { rawRequest, ServiceBase } from '../srv-base/ServiceBase';

class ProductBomService extends ServiceBase {
  constructor() {
    super(PR_DEX_MERCURY, '/urunbom');
  }

  getProductBomsForExcel(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      PR_DEX_MERCURY,
      '/urunbom',
      `/get-list-for-excel?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new ProductBomService();
