import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

export function fetchDealers() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_DEALERS,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX}/bayi/get-all`)
        .then((result) => {
          return result.data;
        }),
    });
  };
}

export function getDealers(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_DEALERS,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX
          }/bayi/get-list-by-filter?${toDataSourceRequestString(dataState)}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}
