import { confirmAlert } from "react-confirm-alert";
import { combineReducers } from "redux";

// import { SMARTY360_URL } from "../../../config/globalVariables";
import { authorizationToken } from "../../../helpers/authorizationToken";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
  getResetPasswordMessage,
} from "../../../helpers/utils";
import bankListReducer from "../rdc-bank/bankListReducer";
import bankInstallmentReducer from "../rdc-bry-bankInstallement/bankInstallmentReducer";
import campaignDiscountCalculateReducer from "../rdc-bry-campaign/campaignDiscountCalculateReducer";
import campaignListReducer from "../rdc-bry-campaign/campaignListReducer";
import campaignParticipantsReducer from "../rdc-bry-campaign/campaignParticipantsReducer";
import campaignReducer from "../rdc-bry-campaign/campaignReducer";
import campaignsReducer from "../rdc-bry-campaign/campaignsReducer";
import campaignChannelListReducer from "../rdc-bry-campaignParticipants/campaignChannelListReducer";
import campaignCustomerGroupListReducer from "../rdc-bry-campaignParticipants/campaignCustomerGroupListReducer";
import campaignDealerListReducer from "../rdc-bry-campaignParticipants/campaignDealerListReducer";
import campaignDealerOptionListReducer from "../rdc-bry-campaignParticipants/campaignDealerOptionListReducer";
import campaignDepartmantListReducer from "../rdc-bry-campaignParticipants/campaignDepartmantListReducer";
import campaignProductListReducer from "../rdc-bry-campaignProduct/campaignProductListReducer";
import campaignProductReducer from "../rdc-bry-campaignProduct/campaignProductReducer";
import productCategoryListReducer from "../rdc-bry-campaignProduct/productCategoryListReducer";
import productGroupsListReducer from "../rdc-bry-campaignProduct/productGroupListReducer";
import productModelDetailListReducer from "../rdc-bry-campaignProduct/productModelDetailListReducer";
import productModelListReducer from "../rdc-bry-campaignProduct/productModelListReducer";
import productTypeListReducer from "../rdc-bry-campaignProduct/productTypeListReducer";
import channelsByFilterReducer from "../rdc-bry-channel/channelsByFilterReducer";
import channelsReducer from "../rdc-bry-channel/channelsReducer";
import customerGroupListReducer from "../rdc-bry-customerGroup/customerGroupListReducer";
import dealerProductGroupListReducer from "../rdc-bry-dealerProductGroup/dealerProductGroupListReducer";
import customerGroupProductGroupListReducer from "../rdc-bry-discount/customerGroupProductGroupListReducer";
import customerGroupProductListReducer from "../rdc-bry-discount/customerGroupProductListReducer";
import customerProductGroupListReducer from "../rdc-bry-discount/customerProductGroupListReducer";
import customerProductListReducer from "../rdc-bry-discount/customerProductListReducer";
import productBrandListReducer from "../rdc-bry-campaignProduct/productBrandListReducer";
import salesChannelProductGroupListReducer from "../rdc-bry-discount/salesChannelProductGroupListReducer";
import salesChannelProductListReducer from "../rdc-bry-discount/salesChannelProductListReducer";
import salesChannelsReducer from "../rdc-bry-salesChannel/salesChannelsReducer";
import complaintHistoryReducer from "../rdc-complaintManagement/complaintHistoryReducer";
import complaintListReducer from "../rdc-complaintManagement/complaintListReducer";
import complaintReducer from "../rdc-complaintManagement/complaintReducer";
import dealerListReducer from "../rdc-dealer/dealerListReducer";
import salesDepartmantListReducer from "../rdc-mrc-salesDepartmant/salesDepartmantListReducer";
import orderApprovalReducer from "../rdc-order/orderApprovalReducer";
import orderFilesReducer from "../rdc-order/orderFilesReducer";
import orderListReducer from "../rdc-order/orderListReducer";
import orderProductListReducer from "../rdc-order/orderProductListReducer";
import orderProductOptionReducer from "../rdc-order/orderProductOptionReducer";
import orderProductReducer from "../rdc-order/orderProductReducer";
import orderReducer from "../rdc-order/orderReducer";
import productFilterListReducer from "../rdc-product/productFilterListReducer";
import productListReducer from "../rdc-product/productListReducer";
import productGroupListReducer from "../rdc-productGroup/productGroupListReducer";
import productPriceListReducer from "../rdc-productPrice/productPriceListReducer";
import productPriceReducer from "../rdc-productPrice/productPriceReducer";
import productPriceLogListReducer from "../rdc-productPriceLog/productPriceLogListReducer";
import authReducer from "../rdc-root/authReducer";
import loginReducer from "../rdc-root/loginReducer";
import menuListReducer from "../rdc-root/menuListReducer";
import smarty360Reducer from "../rdc-smarty360/smarty360Reducer";
import stockListReducer from "../rdc-stock/stockListReducer";
import userRightsListReducer from "../rdc-userRights/userRightsListReducer";
import orderListDealerReducer from "../rdc-order/orderListDealerReducer";
import userSalesRightsListReducer from "../rdc-userRights/userSalesRightsListReducer";
import userProjectResponsibilityListReducer from "../rdc-mrc-userProjectResponsibility/userProjectResponsibilityListReducer";
import dealerProposerListReducer from "../rdc-mrc-dealerProposer/dealerProposerListReducer";
import salesRepresentativeManagerListReducer from "../rdc-mrc-salesRepresentativeManager/salesRepresentativeManagerListReducer";
import cityListReducer from "../rdc-city/cityListReducer";
import userListReducer from "../rdc-user/userListReducer";
import countryListReducer from "../rdc-country/countryListReducer";
import paymentTermListReducer from "../rdc-paymentTerm/paymentTermListReducer";
import paymentExpiryListReducer from "../rdc-paymentExpiry/paymentExpiryListReducer";
import coefficientListReducer from "../rdc-mrc-coefficient/coefficientListReducer";
import salesApprovalListReducer from "../rdc-salesApproval/salesApprovalReducer";
import productBomListReducer from "../rdc-mrc-productBom/productBomReducer";
import excelReducer from "../rdc-root/excelReducer";
import excelListReducer from "../rdc-root/excelListReducer";
import orderAddressInfoReducer from "../rdc-bry-orderAddressInfo/orderAddressInfoReducer";
import orderResponsiblePersonListReducer from "../rdc-bry-orderResponsiblePerson/orderResponsiblePersonListReducer";
import userSalesRegionsReducer from "../rdc-userSalesRegion/userSalesRegionsReducer";

const appReducer = combineReducers({
  authReducer,
  loginReducer,
  menuListReducer,
  productGroupListReducer,
  stockListReducer,
  complaintListReducer,
  complaintReducer,
  complaintHistoryReducer,
  orderListReducer,
  orderListDealerReducer,
  orderReducer,
  dealerListReducer,
  orderProductListReducer,
  campaignReducer,
  channelsReducer,
  dealerProductGroupListReducer,
  productListReducer,
  smarty360Reducer,
  orderProductReducer,
  campaignProductListReducer,
  productCategoryListReducer,
  productGroupsListReducer,
  productModelDetailListReducer,
  productModelListReducer,
  productTypeListReducer,
  productBrandListReducer,
  campaignProductReducer,
  salesDepartmantListReducer,
  customerGroupListReducer,
  campaignChannelListReducer,
  campaignCustomerGroupListReducer,
  campaignDealerListReducer,
  campaignDealerOptionListReducer,
  campaignDepartmantListReducer,
  orderFilesReducer,
  campaignListReducer,
  campaignsReducer,
  campaignParticipantsReducer,
  orderProductOptionReducer,
  productPriceListReducer,
  productPriceReducer,
  productPriceLogListReducer,
  campaignDiscountCalculateReducer,
  orderApprovalReducer,
  bankListReducer,
  bankInstallmentReducer,
  cityListReducer,
  userListReducer,
  userProjectResponsibilityListReducer,
  salesRepresentativeManagerListReducer,
  dealerProposerListReducer,
  userRightsListReducer,
  userSalesRightsListReducer,
  customerProductListReducer,
  productFilterListReducer,
  channelsByFilterReducer,
  salesChannelsReducer,
  customerProductGroupListReducer,
  customerGroupProductListReducer,
  customerGroupProductGroupListReducer,
  salesChannelProductListReducer,
  salesChannelProductGroupListReducer,
  countryListReducer,
  paymentTermListReducer,
  paymentExpiryListReducer,
  coefficientListReducer,
  salesApprovalListReducer,
  productBomListReducer,
  orderAddressInfoReducer,
  orderResponsiblePersonListReducer,
  excelReducer,
  excelListReducer,
  userSalesRegionsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    localStorage.removeItem("jwtToken");
    localStorage.removeItem("aadToken");
    authorizationToken(false);
    state = undefined;
  } else if (action.type.includes("REJECTED")) {
    if (
      action.payload.response &&
      action.payload.response.status &&
      action.payload.response.status === 401
    ) {
      localStorage.removeItem("jwtToken");
      localStorage.removeItem("aadToken");
      authorizationToken(false);
      state = undefined;
      window.location.replace("/homepage");
    } else if (
      action.payload.response &&
      action.payload.response.status &&
      action.payload.response.status === 403
    ) {
      defaultAlertMessages.MessageDetailError = JSON.stringify(
        "Bu işlem için yetkiniz bulunmamaktadır."
      );
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else if (action.payload.response && action.payload.response.data) {
      defaultAlertMessages.MessageDetailError = JSON.stringify(
        action.payload.response.data
      );
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    } else {
      defaultAlertMessages.MessageDetailError = action.payload.message;
      confirmAlert({
        customUI: ({ onClose }) => {
          return getConfirmErrorMessage(onClose, defaultAlertMessages);
        },
      });
    }
  } else if (
    action.type.includes("FULFILLED") &&
    (action.type.includes("CALLSP") || action.type.includes("RETURN"))
  ) {
    defaultAlertMessages.MessageDetailSuccess = action.payload.message;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
  } else if (
    action.type.includes("FULFILLED") &&
    (action.type.includes("UPDATE") ||
      action.type.includes("INSERT") ||
      action.type.includes("DELETE") ||
      action.type.includes("CREATE"))
  ) {
    defaultAlertMessages.MessageDetailSuccess = action.payload;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getConfirmSuccessMessage(onClose, defaultAlertMessages);
      },
    });
  } else if (action.type.includes("RESET_PASSWORD_FULFILLED")) {
    defaultAlertMessages.MessageDetailSuccess = action.payload;
    confirmAlert({
      customUI: ({ onClose }) => {
        return getResetPasswordMessage(defaultAlertMessages);
      },
    });
  } else if (action.type.includes("FETCH_SMARTY_TOKEN_FULFILLED")) {
    // window.open(SMARTY360_URL + action.payload);
  }

  return appReducer(state, action);
};

export default rootReducer;
