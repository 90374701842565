import React, { useState, useEffect } from "react";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { Input } from "@progress/kendo-react-inputs";

const DealerListBox = ({ onChange, unselectedData, selectedData }) => {
  const [state, setState] = useState({
    unselectedDealers: [],
    selectedDealers: [],
    draggedItem: {},
  });
  const [userDealerDataLoading, setUserDealerDataLoading] = useState(false);

  useEffect(() => {
    selectedData &&
      unselectedData &&
      setState({
        ...state,
        selectedDealers: [...selectedData],
        unselectedDealers: [...unselectedData],
      });
  }, [unselectedData, selectedData]);

  useEffect(() => {
    onChange(state.selectedDealers);
  }, [state]);

  useEffect(() => {
    setUserDealerDataLoading(true);
    setState((prevState) => {
      return {
        ...prevState,
        unselectedDealers: prevState.unselectedDealers.sort((a, b) =>
          a.bayI_ADI.localeCompare(b.bayI_ADI)
        ),
        selectedDealers: prevState.selectedDealers.sort((a, b) =>
          a.bayI_ADI.localeCompare(b.bayI_ADI)
        ),
      };
    });      
    setUserDealerDataLoading(false);
  }, [state.selectedDealers, state.unselectedDealers]);

  const SELECTED_FIELD = "selected";

  const handleItemClick = (event, data, connectedData) => {
    setState({
      ...state,
      [data]: state[data].map((item) => {
        if (item.bayI_ID === event.dataItem.bayI_ID) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: state[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };

  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      state.unselectedDealers,
      state.selectedDealers,
      toolName,
      SELECTED_FIELD
    );
    setState({
      ...state,
      unselectedDealers: result.listBoxOneData,
      selectedDealers: result.listBoxTwoData,
    });
  };
  const handleDragStart = (e) => {
    setState({
      ...state,
      draggedItem: e.dataItem,
    });
  };
  const handleDrop = (e) => {
    let result = processListBoxDragAndDrop(
      state.unselectedDealers,
      state.selectedDealers,
      state.draggedItem,
      e.dataItem,
      "bayI_KODU"
    );
    setState({
      ...state,
      unselectedDealers: result.listBoxOneData,
      selectedDealers: result.listBoxTwoData,
    });
  };

  const MyCustomItem = (props) => {
    let { dataItem, selected, ...others } = props;
    return (
      <li {...others}>
        <div>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {props.dataItem.bayI_ADI}
          </span>
          <br />
          <span> {props.dataItem.bayI_KODU}</span>
        </div>
      </li>
    );
  };
  const onChangeSearch = (event) => {
    setState({
      ...state,
      unselectedDealers: [
        ...unselectedData.filter(
          (a) =>
            a.bayI_ADI.toLocaleLowerCase().includes(event.target.value) ||
            a.bayI_KODU.toLocaleLowerCase().includes(event.target.value)
        ),
      ].filter((x) => !state.selectedDealers.includes(x)),
    });
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col k-pr-2">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h6>Bayiler</h6>
            </div>
            <div>
              <Input
                label="Bayi arayın..."
                name="searchDealer"
                id="searchDealer"
                style={{ width: "100%" }}
                onChange={onChangeSearch}
              ></Input>
            </div>
          </div>

          <ListBox
            style={{
              height: 500,
              width: "100%",
              maxHeight: 500,
            }}
            textField="bayI_KODU"
            data={state.unselectedDealers}
            selectedField={SELECTED_FIELD}
            onItemClick={(e) =>
              handleItemClick(e, "unselectedDealers", "selectedDealers")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            item={MyCustomItem}
            toolbar={() => {
              return (
                <ListBoxToolbar
                  tools={["transferTo", "transferFrom", "transferAllFrom"]}
                  data={state.unselectedDealers}
                  dataConnected={state.selectedDealers}
                  onToolClick={handleToolBarClick}
                />
              );
            }}
          />
        </div>
        <div className="col k-pl-0">
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom:"25px"
            }}
          >
            <div>
              <h6>Seçilen Bayiler</h6>
            </div>
            <div>
            </div>
          </div>
          {userDealerDataLoading && LoadingPanel}
          <ListBox
            style={{
              height: 500,
              width: "100%",
              maxHeight: 500,
            }}
            data={state?.selectedDealers}
            textField="bayI_KODU"
            selectedField={SELECTED_FIELD}
            item={MyCustomItem}
            onItemClick={(e) =>
              handleItemClick(e, "selectedDealers", "unselectedDealers")
            }
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
      </div>
    </div>
  );
};

export default DealerListBox;
