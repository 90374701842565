import axios from "axios";

import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

import { API_GATEWAY, PR_DEX_MERCURY } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchUserProjectResponsibility(dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_USER_PROJECT_RESPONSIBILITY,
      payload: fetchUserProjectResponsibilityPayload(dataState, callback),
    });
  };
}
export function changeUserProjectResponsibility(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_USER_PROJECT_RESPONSIBILITY,
      payload: event,
    });
  };
}

export function fetchUserProjectResponsibilityPayload(dataState, callback) {
  return axios
    .get(
      `${
        API_GATEWAY + PR_DEX_MERCURY
      }/kullaniciprojesorumlusu/get-all?${toDataSourceRequestString(dataState)}`
    )
    .then((result) => {
      if (callback) callback(result.data);
      return dataState.group && dataState.group.length
        ? translateDataSourceResultGroups(result.data)
        : { data: result.data, dataState };
    });
}

export function insertUserProjectResponsibility(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.INSERT_USER_PROJECT_RESPONSIBILITY,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_MERCURY}/kullaniciprojesorumlusu/create`,
          data
        )
        .then((result) => {
          dispatch(fetchUserProjectResponsibility(dataState, callback));
          return result.data;
        }),
    });
  };
}

export function updateUserProjectResponsibility(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_USER_PROJECT_RESPONSIBILITY,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_MERCURY}/kullaniciprojesorumlusu/update`,
          data
        )
        .then((result) => {
          dispatch(fetchUserProjectResponsibility(dataState, callback));
          return result.data.message;
        }),
    });
  };
}

export function deleteUserProjectResponsibility(data, dataState, callback) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_USER_PROJECT_RESPONSIBILITY,
      payload: axios
        .post(
          `${API_GATEWAY + PR_DEX_MERCURY}/kullaniciprojesorumlusu/delete`,
          data
        )
        .then((result) => {
          dispatch(fetchUserProjectResponsibility(dataState, callback));
          return result.data.message;
        }),
    });
  };
}
