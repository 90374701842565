import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

class ProvinceService {
  getProvinces(dataState, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/ilce/get-all?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getProvincesGenel(dataState, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/ilce/get-all-genel?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getNeighbourhoods(dataState, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/mahalle/get-all?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
}

export default new ProvinceService();
