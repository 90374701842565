import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY } from "../../config/globalVariables";
import {
  serviceMessageError,
  serviceMessageSuccess,
} from "../../helpers/utils";
import errorNormalize from "../../helpers/errorNormalize";

export function catchError(error, onErrorCallback) {
  if (onErrorCallback) {
    onErrorCallback();
  }
  if (error.response?.status === 405 || error.response?.status === 500) {
    serviceMessageError(
      "Veriler yüklenemedi." +
        (error.response?.message
          ? `Hata Mesajı: ${error.response.message}`
          : "")
    );
  }
  if (error.response?.status === 400) {
    serviceMessageError(
      error.response?.data.message
        ? `Hata Mesajı: ${error.response.data.message}`
        : ""
    );
  } else {
    serviceMessageError(
      error.response?.data.message ||
        errorNormalize(error.response?.data.Message)
    );
  }
}

export function rawRequest(
  type,
  serviceUri,
  path,
  endpoint,
  data,
  then,
  onSuccessCallback,
  onErrorCallback
) {
  if (type === "get") {
    return axios
      .get(`${API_GATEWAY}${serviceUri}${path}${endpoint}`)
      .then((response) => then(response, onSuccessCallback))
      .catch((error) => catchError(error, onErrorCallback));
  } else if (type === "post") {
    return axios
      .post(`${API_GATEWAY}${serviceUri}${path}${endpoint}`, data)
      .then((response) => then(response, onSuccessCallback))
      .catch((error) => catchError(error, onErrorCallback));
  } else if (type === "put") {
    return axios
      .put(`${API_GATEWAY}${serviceUri}${path}${endpoint}`, data)
      .then((response) => then(response, onSuccessCallback))
      .catch((error) => catchError(error, onErrorCallback));
  } else if (type === "delete") {
    return axios
      .delete(`${API_GATEWAY}${serviceUri}${path}${endpoint}`, data)
      .then((response) => then(response, onSuccessCallback))
      .catch((error) => catchError(error, onErrorCallback));
  } else {
    return undefined;
  }
}

export class ServiceBase {
  options = {
    serviceUri: "",
    path: "",
  };

  constructor(serviceUri, path) {
    this.options = {
      serviceUri: serviceUri,
      path: path,
    };
  }

  getByFilter(dataState, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "get",
      this.options.serviceUri,
      this.options.path,
      `/get-list-by-filter?${toDataSourceRequestString(dataState)}`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (dataState.group && dataState.group.length) {
          return translateDataSourceResultGroups(response.data);
        }

        return { data: response.data, dataState };
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  create(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      this.options.serviceUri,
      this.options.path,
      `/create`,
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  addRange(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      this.options.serviceUri,
      this.options.path,
      `/add-range`,
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  update(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "put",
      this.options.serviceUri,
      this.options.path,
      `/update`,
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }

  delete(data, onSuccessCallback, onErrorCallback) {
    return rawRequest(
      "post",
      this.options.serviceUri,
      this.options.path,
      `/delete`,
      data,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        if (response && response.data.success && response.data.message) {
          serviceMessageSuccess(response.data.message);
        }

        if (response && !response.data.success && response.data.message) {
          serviceMessageError(response.data.message);
        }

        return response;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}
