import React, { Component } from "react";
import { connect } from "react-redux";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import RepeatIcon from "@material-ui/icons/Repeat";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { formatDate } from "@telerik/kendo-intl";

class ComplaintHistory extends Component {
  render() {
    return (
      <div>
        {this.props.complaintHistory.fetching && LoadingPanel}
        <Timeline align="alternate">
          {this.props.complaintHistory.historyList.map((p) => (
            <TimelineItem key={p.bayI_SIKAYET_LOG_ID}>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary">
                  <i className="k-icon k-i-clock"></i>
                  {formatDate(new Date(p.crE_DATE), "dd.MM.yyyy HH:mm")}
                  <br></br>
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot>
                  <RepeatIcon></RepeatIcon>
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper
                  elevation={3}
                  style={{ color: "green" }}
                  className="timelinePaper"
                >
                  {p.eskI_DURUM} <ArrowForwardIcon /> {p.yenI_DURUM}
                </Paper>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    complaintHistory: state.complaintHistoryReducer,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintHistory);
