import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class HizTestiService {
  getHizTesti(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX
    }/HizTesti/get-list-by-filter`)
      .then((response) => {
        callback(response.data);       
      });
  }
  
}

export default new HizTestiService();
