import axios from "axios";
import { toDataSourceRequestString, translateDataSourceResultGroups } from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX_MERCURY } from "../../config/globalVariables";

class ProjectReportService {
  getProjectReports(dataState, callback) {
    return axios.get(`${API_GATEWAY + PR_DEX_MERCURY}/projerapor/get-list-by-filter?${toDataSourceRequestString(dataState)}`)
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
  getProjectReportsForExcel(dataState) {
    return axios.get(`${API_GATEWAY + PR_DEX_MERCURY}/projerapor/get-list-for-excel?${toDataSourceRequestString(dataState)}`)
      .then(response => {
        return response.data;
      })
      .catch(e => console.log(e));
  }
}

export default new ProjectReportService();
