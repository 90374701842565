import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import { cloneElement, useEffect, useRef, useState } from "react";
import { Badge } from "reactstrap";
import filterLocalization from "../../helpers/filterLocalization";
import ProductGroupService from "../../services/srv-product/ProductGroupService";
import StockInquiryService from "../../services/srv-stockInquiry/StockInquiryService";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

export default function StockInquiryDealer() {
  const _grid = useRef(null);
  const gridPageable = { buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] };

  const primaryKey = "uruN_KODU";
  const search = "";
  const sort = [
    {
      field: primaryKey,
      dir: "desc",
    },
  ];

  // Data Related Functions Begin
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    sort,
    take: 10,
    skip: 0,
    ...search,
  });
  const [productGroups, setProductGroups] = useState([]);

  function onCompletedGetProducts(result) {
    setProducts(result);
    if (result.dataState) {
      setProductsDataState(result.dataState);
    }
    setProductsLoading(false);
  }

  function onCompletedGetProductGroups(result) {
    setProductGroups(result);
  }

  useEffect(() => {
    setProductsLoading(true);
    StockInquiryService.getStocksDealer(
      productsDataState,
      onCompletedGetProducts
    );
    ProductGroupService.getAllByBayi(onCompletedGetProductGroups);
  }, [productsDataState]);
  
  function onDataStateChange(event) {
    filterLocalization(event);
    setProductsDataState(event.dataState);
  }

  function onItemChange(event) {
    const data = products.data.map((item) =>
      item[primaryKey] === event.dataItem[primaryKey]
        ? { ...item, [event.field]: event.value }
        : item
    );
    setProducts({ ...products, data });
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  }

  function StockStatusCell(props) {
    const { dataItem } = props;
    let stockText = "";
    let stockBadge = "";

    if (dataItem.stoK_MIKTARI === 0) {
      stockText = "Stokta Yok";
      stockBadge = "danger";
    } else if (
      dataItem.esiK_STOK &&
      dataItem.stoK_MIKTARI <= dataItem.esiK_STOK
    ) {
      stockText = "Kritik Stok!";
      stockBadge = "warning";
    } else {
      stockText = "Stokta Var";
      stockBadge = "success";
    }

    return (
      <td>
        <Badge color={stockBadge}>{stockText}</Badge>
      </td>
    );
  }

  function ProductGroupFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={productGroups?.map((pg) => pg.aciklama)}
        defaultValue={"Seçiniz"}
      />
    );
  }

  function StockStatusFilterCell(props) {
    return (
      <DropdownFilterCell
        {...props}
        data={["Stokta Yok", "Kritik Stok!", "Stokta Var"].map((pg) => pg)}
        defaultValue={"Seçiniz"}
      />
    );
  }
  // Grid Related Functions End

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Stok Sorgulama Ekranı</CardTitle>
        </CardHeader>
        <CardBody>
          {productsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={products}
            {...productsDataState}
            pageable={gridPageable}
            onDataStateChange={onDataStateChange}
            onItemChange={onItemChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px" }}
          >
            <GridColumn
              field="uruN_KODU"
              title="Ürün Kodu"
              width="256px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="uruN_ADI"
              title="Ürün Adı"
              width="256px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="uruN_GRUBU_ACIKLAMA"
              title="Ürün Grubu"
              width="256px"
              editable={false}
              filterCell={ProductGroupFilterCell}
            ></GridColumn>
            <GridColumn
              field="marka"
              title="Marka"
              width="256px"
              editable={false}
            ></GridColumn>
            <GridColumn
              field="stock"
              title="Stok Durumu"
              width="256px"
              filterCell={StockStatusFilterCell}
              cell={StockStatusCell}
            ></GridColumn>
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
