import * as actionTypes from "../../actions/sys-configure/actionTypes";
import { API_GATEWAY, MS_SENDER } from "../../../config/globalVariables";
import axios from "axios";

export function changeExcelVisibility(visibilty) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_EXCEL_VISIBILITY,
      payload: { visibilty },
    });
  };
}

export function changeExcel(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_EXCEL,
      payload: event,
    });
  };
}

export function changeExcelVisibilityData(visibilty, data) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_EXCEL_VISIBILITY_DATA,
      payload: { visibilty, data },
    });
  };
}

export function fetchErrorData(procid) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_EXCEL,
      payload: axios
        .get(`${API_GATEWAY + MS_SENDER}/excel/get-list-by-procid/${procid}`)
        .then((result) => result.data),
    });
  };
}
