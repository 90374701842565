import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  BASE_URL,
  DATABRYCAMPAIGNTYPE,
  DATAFATURAVADESI,
  DATALISTEVADESI,
} from "../../config/globalVariables";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, NumericTextBox } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { setCampaigns } from "../../redux/actions/act-bry-campaign/campaignActions";

class CampaignProps extends Component {
  componentDidMount() {}

  onChange = (event) => {
    this.props.setCampaigns(event);
  };

  render() {
    return (
      <form onSubmit={this.props.submitFunc} className="k-form">
        <Row>
          <Col lg="4" md="4" xs="12">
            <DropDownList
              name="kampanyA_TURU"
              label="Kampanya Türü"
              data={DATABRYCAMPAIGNTYPE}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              required={true}
              value={
                this.props.campaign._campaign.kampanyA_TURU
                  ? DATABRYCAMPAIGNTYPE.find(
                      (a) =>
                        a.id === this.props.campaign._campaign.kampanyA_TURU
                    )
                  : ""
              }
            />
            <Input
              label="Kampanya Kodu"
              name="kampanyA_KODU"
              id="kampanyA_KODU"
              style={{ width: "100%" }}
              disabled={this.props.id !== "0"}
              onChange={this.onChange}
              required={true}
              value={this.props.campaign._campaign.kampanyA_KODU}
            ></Input>
            <Input
              label="Kampanya Adı"
              name="kampanyA_ADI"
              id="kampanyA_ADI"
              style={{ width: "100%" }}
              onChange={this.onChange}
              required={true}
              value={this.props.campaign._campaign.kampanyA_ADI}
            ></Input>
            <Input
              label="Kampanya Özel Koşul"
              name="ozeL_KOSUL_KODU"
              id="ozeL_KOSUL_KODU"
              style={{ width: "100%" }}
              value={this.props.campaign._campaign.ozeL_KOSUL_KODU}
            ></Input>{" "}
          </Col>
          <Col lg="4" md="4" xs="12">
            <Input
              label="Kampanya Bilgisi"
              name="kampanyA_HEDEFI"
              id="kampanyA_HEDEFI"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={this.props.campaign._campaign.kampanyA_HEDEFI}
            ></Input>
            <Input
              label="Kampanya Koşulu"
              name="kampanyA_KOSULU"
              id="kampanyA_KOSULU"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={this.props.campaign._campaign.kampanyA_KOSULU}
            ></Input>
            <NumericTextBox
              label="İskonto"
              name="iskonto"
              id="iskonto"
              format="n4"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={this.props.campaign._campaign.iskonto}
            ></NumericTextBox>
            <DropDownList
              name="listE_FIYATI_VADESI"
              label="Liste Fiyatı Vadesi"
              data={DATALISTEVADESI}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={
                this.props.campaign._campaign.listE_FIYATI_VADESI ||
                this.props.campaign._campaign.listE_FIYATI_VADESI === 0
                  ? DATALISTEVADESI.find(
                      (a) =>
                        a.id ===
                        this.props.campaign._campaign.listE_FIYATI_VADESI.toString()
                    )
                  : ""
              }
            />
            <DropDownList
              name="faturA_VADESI"
              label="Ödeme Vadesi"
              data={DATAFATURAVADESI}
              textField="text"
              dataItemKey="id"
              style={{ width: "100%" }}
              onChange={this.onChange}
              value={
                this.props.campaign._campaign.faturA_VADESI ||
                this.props.campaign._campaign.faturA_VADESI === 0
                  ? DATAFATURAVADESI.find(
                      (a) =>
                        a.id ===
                        this.props.campaign._campaign.faturA_VADESI.toString()
                    )
                  : ""
              }
            />
          </Col>
          <Col lg="4" md="4" xs="12">
            <DatePicker
              label="Başlangıç Tarihi"
              name="baS_TAR"
              format="dd.MM.yyyy"
              formatPlaceholder="formatPattern"
              max={
                this.props.campaign._campaign.biT_TAR != null
                  ? new Date(this.props.campaign._campaign.biT_TAR)
                  : undefined
              }
              value={
                this.props.campaign._campaign.baS_TAR != null || undefined
                  ? this.props.campaign._campaign.baS_TAR === ""
                    ? null
                    : new Date(this.props.campaign._campaign.baS_TAR)
                  : null
              }
              onChange={this.onChange}
              style={{ width: "100%" }}
            />
            <DatePicker
              label="Bitiş Tarihi"
              name="biT_TAR"
              format="dd.MM.yyyy"
              formatPlaceholder="formatPattern"
              min={
                this.props.campaign._campaign.baS_TAR != null
                  ? new Date(this.props.campaign._campaign.baS_TAR)
                  : undefined
              }
              value={
                this.props.campaign._campaign.biT_TAR != null || undefined
                  ? this.props.campaign._campaign.biT_TAR === ""
                    ? null
                    : new Date(this.props.campaign._campaign.biT_TAR)
                  : null
              }
              onChange={this.onChange}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <br></br>
        <Button color="success" type="submit" size="md">
          <span class="fas fa-check-circle"></span> Kaydet
        </Button>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {
    setCampaigns: bindActionCreators(setCampaigns, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignProps);
