import * as actionTypes from "../../actions/sys-configure/actionTypes";
import axios from "axios";
import {
  API_GATEWAY,
  PR_DEX_BAYI_SIKAYET,
} from "../../../config/globalVariables";

export function fetchComplaintHistory(bayisikayetid) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_COMPLAINT_HISTORY,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX_BAYI_SIKAYET
          }/bayisikayetlog/get-by-sikayetid/${bayisikayetid}`
        )
        .then((result) => result.data),
    });
  };
}
