import { Row, Col, List, Button, Alert } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Stepper,
  Step,
} from "@progress/kendo-react-layout";
import { useState } from "react";
import OfferDenvService from "../../../services/mercury/srv-offer-denv/OfferDenvService";
import { useEffect } from "react";
import LoadingPanel from "../../sys-toolbox/LoadingPanel";
import { OfferDenvProductList } from "./OfferDenvProductList";
import { DateFormatterLocale } from "../../sys-toolbox/intl-format/DateFormatterLocale";
import OfferDenvApproveReject from "./OfferDenvApproveReject";
import { DateTimeFormatterLocale } from "../../sys-toolbox/intl-format/DateTimeFormatterLocale";
import { Upload } from "@progress/kendo-react-upload";
import OfferFilesService from "../../../services/mercury/srv-offer-files/OfferFilesService";
import FileService from "../../../services/srv-files/FileService";
import { connect } from "react-redux";
import OfferDenvLogService from "../../../services/mercury/srv-offer-denv/OfferDenvLogService";

const OfferDenvDetail = (props) => {
  const offerid = props.match.params.offerid;
  const { t, i18n } = useTranslation();
  const [offer, setOffer] = useState();
  const [offerFiles, setOfferFiles] = useState([]);
  const [offerLog, setOfferLog] = useState([]);
  const [action, setAction] = useState("");
  const [actionDialogVisible, setActionDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  useEffect(() => {
    getOfferDenv();
  }, []);

  function getOfferDenv() {
    setIsLoading(true);
    setIsLoadingFiles(true);
    OfferDenvService.getById(offerid, onCompletedGetOffer);
    OfferDenvLogService.getByOfferDenvId(offerid, onCompletedGetOfferLog);
  }

  function onCompletedGetOffer(data) {
    if (data) {
      setOffer(data);
      OfferFilesService.getByTeklifId(data.mrC_TEKLIF_ID, onCompletedGetFiles);
    }

    setIsLoading(false);
  }

  function onCompletedGetOfferLog(data) {
    if (data) {
      setOfferLog(data);
    }
  }

  function onCompletedGetFiles(data) {
    if (data) {
      setOfferFiles(data);
    }

    setIsLoadingFiles(false);
  }

  const toggleDialogApproveReject = (action) => {
    if (!actionDialogVisible) {
      setAction(action);
    }
    setActionDialogVisible(!actionDialogVisible);
  };

  const downloadFile = (cdn_file) => {
    setIsLoadingFiles(true);
    FileService.downloadFile(cdn_file, onCompletedDownloadFile);
  };

  function onCompletedDownloadFile(data) {
    setIsLoadingFiles(false);
  }

  const customSteps = [
    {
      label: t("sent_for_approval"),
      icon: "k-icon k-i-track-changes-enable",
      dateTime: offer?.crE_DATE,
    },
    {
      label: t("dgm_approval"),
      icon: "k-icon k-i-clock",
      dateTime: offer?.gmY_ONAY_TARIHI,
    },
    {
      label: t("denv_approval"),
      icon: "k-icon k-i-checkmark-outline",
      dateTime: offer?.denV_ONAY_TARIHI,
    },
  ];

  const CustomStep = (props) => {
    return (
      <Step {...props}>
        <div className="custom-step">
          <span className={props.icon} />
        </div>
        <span className="step-label">{props.label}</span>
        <span className="step-label">
          {DateTimeFormatterLocale(props.dateTime)}
        </span>
      </Step>
    );
  };

  return (
    <main style={{ flexGrow: "1" }}>
      {actionDialogVisible ? (
        <OfferDenvApproveReject
          toggleDialog={toggleDialogApproveReject}
          offer={offer}
          action={action}
          refreshFunc={getOfferDenv}
        ></OfferDenvApproveReject>
      ) : undefined}

      <h3>{t("offer.detail")} </h3>
      {isLoading && LoadingPanel}

      <Row>
        <Col lg="8" md="12" xs="12">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <Card>
              <CardHeader>
                <CardTitle>
                  <i class="fa fa-building-o"></i> {t("customer.name")} :{" "}
                  <b>{offer?.muS_ADI}</b>
                  <span style={{ float: "right" }}>
                    {t("offer.id")} : <b>{offer?.mrC_TEKLIF_ID}</b>
                  </span>
                </CardTitle>
              </CardHeader>
            </Card>
            <Row>
              <Col lg="6" md="12" xs="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("customer.detail")}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <List type="unstyled">
                        <li>
                          <i class="fa fa-building"></i>{" "}
                          {t("customer.selltocustomername")} :{" "}
                          <b>{offer?.satilacaK_FIRMA}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-address-card"></i>{" "}
                          {t("customer.selltocustomeraddress")} :{" "}
                          <b>{offer?.satilacaK_FIRMA_ADRESI}</b>
                        </li>
                      </List>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("End_user_and_End_Use")}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <List type="unstyled">
                        <li>
                          <i class="fa fa-briefcase"></i> {t("project.name")} :{" "}
                          <b>{offer?.projE_ADI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-globe"></i>{" "}
                          {t("project.country_name")} :{" "}
                          <b>{offer?.projE_ULKE_ADI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-map-marker"></i>{" "}
                          {t("project.city_name")} :{" "}
                          <b>{offer?.projE_IL_ADI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-map-marker"></i>{" "}
                          {t("project.town_name")} :{" "}
                          <b>{offer?.projE_ILCE_ADI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-building"></i>{" "}
                          {t("application_end_use")} :{" "}
                          <b>
                            {t(
                              "data.construction_purpose." +
                                offer?.santiyE_KULLANIM_AMACI
                            )}
                          </b>
                        </li>
                      </List>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col lg="6" md="12" xs="12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "20px",
                  }}
                >
                  <Card>
                    <CardHeader>
                      <CardTitle>
                        <i class="fa fa-money"></i> {t("currency")} :{" "}
                        <b>{offer?.parA_BIRIMI}</b>
                      </CardTitle>
                    </CardHeader>
                  </Card>
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("project.define")}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <List type="unstyled">
                        <li>
                          <i class="fa fa-building"></i> {t("investor.name")} :{" "}
                          <b>{offer?.yatirimcI_FIRMA}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-building"></i>{" "}
                          {t("construction.name")} :{" "}
                          <b>{offer?.insaatcI_FIRMA}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-building"></i> {t("mechanical.name")}{" "}
                          : <b>{offer?.mekanikcI_FIRMA}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-building"></i> {t("project.company")}{" "}
                          : <b>{offer?.projecI_FIRMA_MUS_ADI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-thumb-tack"></i>{" "}
                          {t("device_delivery_location")} :{" "}
                          <b>{offer?.cihaZ_TESLIM_YERI}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-calendar"></i>{" "}
                          {t("expected_delivery_date")} :{" "}
                          <b>{DateFormatterLocale(offer?.tesliM_TARIHI)}</b>
                        </li>
                        <hr></hr>
                        <li>
                          <i class="fa fa-calendar-check-o"></i>{" "}
                          {t("deployment_date")} :{" "}
                          <b>
                            {DateFormatterLocale(offer?.devreyE_ALMA_ZAMANI)}
                          </b>
                        </li>
                      </List>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
            {offer ? (
              <OfferDenvProductList offer={offer}></OfferDenvProductList>
            ) : undefined}
          </div>
        </Col>
        <Col lg="4" md="12" xs="12">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              rowGap: "20px",
            }}
          >
            <Card>
              <CardBody>
                <Stepper
                  className="custom-stepper"
                  value={
                    offer?.onaY_DURUM === "GMY_ONAYINDA"
                      ? 1
                      : offer?.onaY_DURUM === "DENV_ONAYINDA"
                      ? 2
                      : offer?.onaY_DURUM === "ONAYLI"
                      ? 3
                      : 0
                  }
                  // onChange={handleChange}
                  items={customSteps}
                  item={CustomStep}
                />
              </CardBody>
            </Card>
            {(offer?.onaY_DURUM === "GMY_ONAYINDA" &&
              props.auth.roles.includes("DENV_TEKLIF_CIS_GMY")) ||
            (offer?.onaY_DURUM === "DENV_ONAYINDA" &&
              props.auth.roles.includes("DENV_TEKLIF_CIS_DENV")) ? (
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <Button
                        style={{ width: "100%" }}
                        color="success"
                        type="button"
                        size="md"
                        onClick={() => {
                          if (
                            offer?.onaY_DURUM === "GMY_ONAYINDA" &&
                            props.auth.roles.includes("DENV_TEKLIF_CIS_GMY")
                          ) {
                            var data = {
                              MRC_TEKLIF_DENV_ONAY_ID:
                                offer?.mrC_TEKLIF_DENV_ONAY_ID,
                              MRC_TEKLIF_ID: offer?.mrC_TEKLIF_ID,
                              ISLEM: "ONAY",
                            };
                            OfferDenvService.approveReject(data, () =>
                              getOfferDenv()
                            );
                          } else if (
                            offer?.onaY_DURUM === "DENV_ONAYINDA" &&
                            props.auth.roles.includes("DENV_TEKLIF_CIS_DENV")
                          ) {
                            toggleDialogApproveReject("approve");
                          }
                        }}
                      >
                        <i className="k-icon k-i-check-circle"></i>
                        {t("approve")}
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        style={{ width: "100%" }}
                        color="danger"
                        type="button"
                        size="md"
                        onClick={() => {
                          if (
                            offer?.onaY_DURUM === "GMY_ONAYINDA" &&
                            props.auth.roles.includes("DENV_TEKLIF_CIS_GMY")
                          ) {
                            var data = {
                              MRC_TEKLIF_DENV_ONAY_ID:
                                offer?.mrC_TEKLIF_DENV_ONAY_ID,
                              MRC_TEKLIF_ID: offer?.mrC_TEKLIF_ID,
                              ISLEM: "RED",
                            };
                            OfferDenvService.approveReject(data, () =>
                              getOfferDenv()
                            );
                          } else if (
                            offer?.onaY_DURUM === "DENV_ONAYINDA" &&
                            props.auth.roles.includes("DENV_TEKLIF_CIS_DENV")
                          ) {
                            toggleDialogApproveReject("reject");
                          }
                        }}
                      >
                        <i className="k-icon k-i-times-circle"></i>
                        {t("reject")}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : undefined}
            <Card>
              <CardHeader>
                <CardTitle>{t("for_the_declarent")}</CardTitle>
              </CardHeader>
              <CardBody>
                <List type="unstyled">
                  <li>
                    <i class="fa fa-user-circle-o"></i> {t("user.name_surname")}{" "}
                    : <b>{offer?.musterI_YETKILI_KISI_AD_SOYAD}</b>
                  </li>
                  <hr></hr>
                  <li>
                    <i class="fa fa-id-card-o"></i> {t("user.title")} :{" "}
                    <b>{offer?.musterI_YETKILI_KISI_UNVAN}</b>
                  </li>
                </List>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>{t("denv_responsible_sales_department")}</CardTitle>
              </CardHeader>
              <CardBody>
                <List type="unstyled">
                  <li>
                    <i class="fa fa-user-circle-o"></i>{" "}
                    {t("user.sales_responsible.name")} :{" "}
                    <b>{offer?.satiS_TEMSILCISI_AD_SOYAD}</b>
                  </li>
                  <hr></hr>
                  <li>
                    <i class="fa fa-id-card-o"></i>{" "}
                    {t("user.sales_responsible.title")} :{" "}
                    <b>{offer?.satiS_TEMSILCISI_UNVANI}</b>
                  </li>
                  <hr></hr>
                  <li>
                    <i class="fa fa-comment"></i>{" "}
                    {t("user.sales_responsible.comment")} :{" "}
                    <b>
                      {offerLog.length > 0
                        ? offerLog
                            .filter((a) => a.crE_ROLE === "SATIS_TEMSILCISI")
                            .sort(
                              (a, b) =>
                                b.mrC_TEKLIF_DENV_ONAY_LOG_ID -
                                a.mrC_TEKLIF_DENV_ONAY_LOG_ID
                            )[0]?.aciklama
                        : ""}
                    </b>
                  </li>
                  <hr></hr>
                  <li>
                    <i class="fa fa-user-circle-o"></i> {t("user.dgm.name")} :{" "}
                    <b>{offer?.gmY_AD_SOYAD}</b>
                  </li>
                </List>
              </CardBody>
            </Card>{" "}
            <Card>
              <CardHeader>
                <CardTitle>{t("offer.files")}</CardTitle>
              </CardHeader>
              <CardBody>
                <div>
                  <Upload
                    batch={false}
                    multiple={true}
                    defaultFiles={[]}
                    withCredentials={false}
                    disabled={true}
                    className="k-upload-hide"
                  />
                  {isLoadingFiles && LoadingPanel}
                  {offerFiles.length > 0 ? (
                    <div className="k-widget k-upload k-header">
                      <ul className="k-upload-files k-reset">
                        {offerFiles.map((p) => (
                          <li
                            className="k-file k-file-success"
                            data-uid="432f75fe-235a-4136-8687-813b5880c2f3"
                            tabIndex="-1"
                          >
                            <div className="k-file-single">
                              <span
                                className="k-progress"
                                style={{
                                  width: "100%",
                                  transition: "opacity 0.5s ease-in-out 0s",
                                }}
                              ></span>
                              <span className="k-file-extension-wrapper">
                                <span class="k-file-extension">
                                  {p.extention}
                                </span>
                                <span className="k-file-state"></span>
                              </span>
                              <span className="k-file-name-size-wrapper">
                                <span
                                  className="k-file-name"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => downloadFile(p.cdn_file)}
                                  title={p.name}
                                >
                                  {p.name}
                                </span>
                                <span className="k-file-size">{p.size} KB</span>
                              </span>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <Alert color="secondary" style={{ textAlign: "center" }}>
                      <i className=""></i> {t("no_files")}
                    </Alert>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col lg="12" md="12" xs="12">
          {offer ? (
            <OfferDenvProductList offer={offer}></OfferDenvProductList>
          ) : undefined}
        </Col>
      </Row> */}
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(OfferDenvDetail);
