import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardHeader } from "@progress/kendo-react-layout";
import { formatDate } from "@telerik/kendo-intl";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { DATAPARABIRIMI } from "../../config/globalVariables";
import OffsetService from "../../services/srv-fin-offset/OffsetService";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import FormatNumber from "../sys-toolbox/NumberFormat";
import OffsetSummary from "./OffsetSummary";

const VirtualPostList = (props) => {
  const SELECTED_FIELD = "selected";
  const initialFilter = {
    logic: "and",
    filters: [
      {
        field: "finM_DONEM_TANIM_ID",
        operator: "eq",
        value: props.term.finM_DONEM_TANIM_ID,
      },
      {
        field: "mahsuP_TIPI",
        operator: "eq",
        value: "SANAL_POS",
      },
      {
        field: "bayI_KODU",
        operator: "eq",
        value: props.auth.bayiKodu,
      },
      {
        field: "parA_BIRIMI",
        operator: "eq",
        value: props.dbsData[0].parA_BIRIMI,
      },
    ],
  };

  const [offsetsLoading, setOffsetsLoading] = useState(false);
  const [offsetsDataState, setOffsetsDataState] = useState({
    filter: initialFilter,
  });
  const [offsets, setOffsets] = useState([]);

  const [visibleOffset, setVisibleOffset] = useState(false);

  useEffect(() => {
    setOffsetsLoading(true);
    OffsetService.getOffsets(offsetsDataState, onCompletedGetOffsets);
  }, [offsetsDataState, props.isChangedData]);

  const onCompletedGetOffsets = (data) => {
    var totalDBS = props.dbsData
      .map((a) => a.tutar)
      .reduce((prev, curr) => prev + curr, 0);

    var sanalPosData = data.data;
    var totalSanalPos = 0;
    for (var i = 0; i < sanalPosData.length; i++) {
      sanalPosData[i].selected = true;
      totalSanalPos += sanalPosData[i].tutar;
      if (totalSanalPos > totalDBS) {
        break;
      }
    }

    setOffsets(data);
    if (data.dataState) {
      setOffsetsDataState(data.dataState);
    }
    setOffsetsLoading(false);
  };

  const dataStateChange = (event) => {
    setOffsetsDataState(event.dataState);
  };

  const toggleDialogOffsetSummary = (event) => {
    setVisibleOffset(!visibleOffset);
  };

  const rowRender = (trElement, dataItem) => {
    const green = {
      backgroundColor: "rgb(55, 180, 0,0.32)",
    };
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    if (dataItem.dataItem.selected) {
      trProps["style"] = green;
    }
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  return (
    <Window
      title="DBS-SanalPos Mahsuplaştırma"
      onClose={props.toggleDialogVirtualPos}
      initialHeight={"80vh"}
      initialWidth={"70%"}
      style={{
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      {visibleOffset ? (
        <OffsetSummary
          toggleDialogOffsetSummary={toggleDialogOffsetSummary}
          sanalPosData={offsets.data.filter((a) => a.selected === true)}
          dbsData={props.dbsData}
        ></OffsetSummary>
      ) : undefined}
      <Card style={{ marginBottom: "10px" }}>
        <CardHeader>
          <h6>
            <b>Seçilen DBS Listesi</b>
            <span style={{ float: "right" }}>
              Toplam Tutar :
              {FormatNumber(
                props.dbsData
                  .map((a) => a.tutar)
                  .reduce((prev, curr) => prev + curr, 0),
                2
              )}{" "}
              {props.dbsData.map((a) => a.parA_BIRIMI)[0]}
            </span>
          </h6>
        </CardHeader>
        <CardBody>
          {props.dbsData.map((item) => {
            return (
              <Row>
                <Col lg="4" md="4" xs="4">
                  <b>Tutar: </b>
                  {FormatNumber(item.tutar, 2)} {item.parA_BIRIMI}
                </Col>
                <Col lg="4" md="4" xs="4">
                  <b>Vade Tarihi: </b>
                  {formatDate(new Date(item.vadE_TARIHI), "dd.MM.yyyy")}
                </Col>
                <Col lg="4" md="4" xs="4">
                  <b>Ödeme Tipi: </b>
                  {item.odemE_TIPI}
                </Col>
              </Row>
            );
          })}
        </CardBody>
      </Card>
      {offsetsLoading && LoadingPanel}
      <div className="row">
        <div className="col-12">
          <Grid
            data={offsets}
            {...offsetsDataState}
            onDataStateChange={dataStateChange}
            sortable={false}
            style={{ height: "400px", fontSize: "11px" }}
            resizable={true}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={false}
            filterable={false}
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => toggleDialogOffsetSummary()}
              >
                {" "}
                <span className="k-icon k-i-check"></span> Onayla
              </Button>
            </GridToolbar>
            <GridColumn
              field={SELECTED_FIELD}
              width="50px"
              cell={(props) => (
                <td>
                  {props.dataItem.mahsuP_KODU ? undefined : (
                    <Checkbox
                      // defaultChecked={false}
                      disabled={true}
                      value={
                        offsets.data.find(
                          (s) =>
                            s.finM_MAHSUP_MST_ID ===
                            props.dataItem.finM_MAHSUP_MST_ID
                        ).selected
                      }
                      // onChange={() => handleChangeCheckBox(props.dataItem)}
                    />
                  )}
                </td>
              )}
            />
            <GridColumn
              field="doneM_KODU"
              title="Dönem Kodu"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="isleM_DURUMU"
              title="İşlem Durumu"
              width="120px"
            ></GridColumn>
            <GridColumn
              field="kredI_KONTROL_ALANI"
              title="Kredi Kontrol Alanı"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="belgE_TURU"
              title="Belge Türü"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="belgE_NO"
              title="Belge No"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="ihtaR_ALANI"
              title="İhtar Alanı"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="bankA_ADI"
              title="Banka Adı"
              width="200px"
            ></GridColumn>
            <GridColumn
              field="referanS_NO_2"
              title="Referans No"
              width="100px"
            ></GridColumn>
            <GridColumn
              field="belgE_TARIHI"
              title="Belge Tarihi"
              width="100px"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="vadE_TARIHI"
              title="Vade Tarihi"
              width="100px"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="tutar"
              title="Tutar"
              width="100px"
              cell={(props) => GridNumberFormatCell(props, 2)}
              filterable={false}
            ></GridColumn>
            <GridColumn
              field="parA_BIRIMI"
              title="Para Birimi"
              width="100px"
              filterCell={(props) => (
                <DropdownFilterCell
                  {...props}
                  data={DATAPARABIRIMI.map((a) => a.text)}
                  defaultValue={"Seçiniz"}
                ></DropdownFilterCell>
              )}
            ></GridColumn>
            <GridColumn
              field="tutaR_TRY"
              title="Tutar (TRY)"
              width="100px"
              cell={(props) => GridNumberFormatCell(props, 2)}
              filterable={false}
            ></GridColumn>
          </Grid>
        </div>
      </div>
    </Window>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(VirtualPostList);
