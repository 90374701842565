import React, { cloneElement, useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import BiggSerialsModifiedService from "../../services/srv-bigg-loyalty/BiggSerialsModifiedService";
import { biggSerialsModifiedReportGridColumns } from "./BiggSerialsModifiedReportGridColumns";
import ExportExcel from "../sys-toolbox/ExcelExport";
import filterLocalization from "../../helpers/filterLocalization";
import ColumnsMenu from "../grid/ColumnsMenu";

export function BiggSerialsModifiedReport() {
  const _grid = useRef(null);
  const columnState = React.useState(biggSerialsModifiedReportGridColumns);
  const sort = [
    {
      field: "dkN_BIGG_SERIALS_MODIFIED_ID",
      dir: "desc",
    },
  ];

  const [
    biggSerialsModifiedReportsLoading,
    setBiggSerialsModifiedReportsLoading,
  ] = useState(false);
  const [biggSerialsModifiedReports, setBiggSerialsModifiedReports] =
    useState(null);
  const [
    biggSerialsModifiedReportsDataState,
    setBiggSerialsModifiedReportsDataState,
  ] = useState({
    sort,
    take: 10,
    skip: 0,
  });

  useEffect(() => {
    setBiggSerialsModifiedReportsLoading(true);
    BiggSerialsModifiedService.getBiggSerialsModifiedReports(
      biggSerialsModifiedReportsDataState,
      onGetDataCompleted
    );
  }, [biggSerialsModifiedReportsDataState]);

  const onGetDataCompleted = (data) => {
    setBiggSerialsModifiedReports(data);
    setBiggSerialsModifiedReportsLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setBiggSerialsModifiedReportsLoading(true);
    setBiggSerialsModifiedReportsDataState(event.dataState);
    BiggSerialsModifiedService.getBiggSerialsModifiedReports(
      event.dataState,
      onGetDataCompleted
    );
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Bigg Serials Modified</CardTitle>
        </CardHeader>
        <CardBody>
          {biggSerialsModifiedReportsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={biggSerialsModifiedReports}
            dataItemKey={"dkN_BIGG_SERIALS_MODIFIED_ID"}
            {...biggSerialsModifiedReportsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ColumnsMenu
                columns={columnState[0]}
                onChange={columnState[1]}
                storageKey={"big_serials_modified_columns"}
              />
              <ExportExcel
                data={biggSerialsModifiedReports}
                columns={biggSerialsModifiedReportGridColumns}
                loadingFunction={setBiggSerialsModifiedReportsLoading}
                dataState={biggSerialsModifiedReportsDataState}
                serviceMethod={
                  BiggSerialsModifiedService.getBiggSerialsModifiedReportsForExcel
                }
                fileName="BiggSerialModifiedReport"
              ></ExportExcel>
            </GridToolbar>
            {columnState[0]
              .filter((column) => !column.hidden)
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((column) => (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  filter={column.filter}
                  filterable={column.filterable ? column.filterable : true}
                  cell={column.cell}
                  filterCell={column.filterCell}
                />
              ))}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
