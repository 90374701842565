import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import UserService from "../../services/srv-user/UserService";

const UserMultiSelect = ({ required, onChange, value }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    UserService.getKullaniciList(defaultDataHandler);
  }, []);

  const defaultDataHandler = ({ data }) => {
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.adsoyad.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <MultiSelect
      name="kullanicI_ID"
      id="kullanicI_ID"
      label="Kullanıcılar"
      data={filteredData}
      textField="adsoyad"
      dataItemKey="kullanicI_ID"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      placeholder="Kullanıcıları seçiniz."
      value={
        data && value.length > 0
          ? data.filter((a) => value.includes(a.kullanicI_ID))
          : []
      }
    ></MultiSelect>
  );
};

export default UserMultiSelect;
