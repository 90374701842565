import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import UserService from "../../services/srv-user/UserService";

const UserDropDownList = ({ id, label, value, required, onChange }) => {
  var pendingRequest = undefined;
  const [filteredData, setFilteredData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getDealersByFilter();
  }, []);

  useEffect(() => {
    if (value && filteredData === false) {
      var filter = [];
      filter.push({
        field: "kullanicI_ID",
        operator: "eq",
        value: value,
      });
      getDealersByFilter(filter);
    }
  }, [value, filteredData]);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setFilteredData(true);
    setDataLoading(false);
  };

  const getDealersByFilter = (filters) => {
    setDataLoading(true);
    UserService.getDaikinUsers(
      {
        filter: { logic: "or", filters: filters },
      },
      onCompletedGetData
    );
  };

  const dealerFilter = (event) => {
    if (dataLoading) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        dealerFilter(event);
      }, 50);
      return;
    }

    var dealerFilters = [];
    dealerFilters.push({
      field: "adsoyad",
      operator: "contains",
      value: event.filter.value,
    });
    dealerFilters.push(event.filter);
    getDealersByFilter(dealerFilters);
  };

  const onFilterDealer = (event) => {
    if (event.filter.value.length > 2) {
      dealerFilter(event);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.email})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="adsoyad"
      defaultItem={{ kullanicI_ID: 0, adsoyad: "Lütfen Seçiniz" }}
      dataItemKey="kullanicI_ID"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterDealer(event)}
      loading={dataLoading}
      value={value ? data.find((v) => v.kullanicI_ID === value) : ""}
    ></DropDownList>
  );
};

export default UserDropDownList;
