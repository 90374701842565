import * as React from "react";
import { NumericTextBox } from "@progress/kendo-react-inputs";
export class GridInlineEditNumericPercentCell extends React.Component {
  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange({
        dataIndex: 0,
        dataItem: this.props.dataItem,
        field: this.props.field,
        syntheticEvent: e.syntheticEvent,
        value: e.value,
      });
    }
  };

  render() {
    const { dataItem } = this.props;
    const field = this.props.field || "";
    const dataValue = dataItem[field] === null ? "" : dataItem[field];
    return dataItem.inEdit ? (
      <td>
        <NumericTextBox
          value={dataValue}
          onChange={this.handleChange}
        ></NumericTextBox>{" "}
      </td>
    ) : dataItem.puto === "DET" ? (
      <td style={{ fontStyle: "italic", color: "gray" }}>
        % {dataValue.toString()}{" "}
      </td>
    ) : (
      <td>% {dataValue.toString()} </td>
    );
  }
}
