import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";

class CECService {
  createTicket(data, callback) {
    const formData = new FormData();
    formData.append("KATEGORI", data.kategori);
    formData.append("ACIKLAMA", data.aciklama);
    formData.append("SERVICE_TEAM_ID", data.service_Team_Id);
    data.files.forEach((f) => formData.append("files", f, f.name));
    return axios
      .post(`${API_GATEWAY + PR_DEX}/selfservis/create-ticket-cec`, formData)
      .then((response) => {
        if (callback) callback(response);
        defaultAlertMessages.MessageDetailSuccess =
          "Ticket başarıyla oluşturulmuştur. Ticket Numarası: " + response.data;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        return response.data;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new CECService();
