import GridDateFormatCell from "../sys-common/GridDateFormatCell";

export const announcementGridColumns = [
  {
    field: "duyurU_ID",
    title: "Duyuru ID",
    width: "128px",
  },
  {
    field: "dagitim",
    title: "Dağıtım Tipi",
    width: "128px",
  },
  {
    field: "icerik",
    title: "İçerik",
    width: "450px",
  },
  {
    field: "duyurU_TIP",
    title: "Duyuru Tipi",
    width: "128px",
  },
  {
    field: "duyurU_LINK",
    title: "Duyuru Link",
    width: "128px",
  },
  {
    field: "sirA_NO",
    title: "Sıra No",
    width: "128px",
  },
  {
    field: "baslangiC_TARIHI",
    title: "Başlangıç Tarihi",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
  {
    field: "bitiS_TARIHI",
    title: "Bitiş Tarihi",
    width: "256px",
    filter: "date",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
  },
];
