import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OfferDenvProductService from "../../../services/mercury/srv-offer-denv/OfferDenvProductService";
import ExportExcel from "../../sys-toolbox/ExcelExport";
import { offerDenvProductGridColumns } from "../../sys-toolbox/grid-columns/OfferDenvProductGridColumns";

export function OfferDenvProductList(props) {
  const { t, i18n } = useTranslation();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [productsDataState, setProductsDataState] = useState({
    skip: 0,
    take: 9999,
  });

  useEffect(() => {
    setIsLoading(true);
    OfferDenvProductService.getByOfferDenvId(
      props.offer.mrC_TEKLIF_DENV_ONAY_ID,
      onCompletedOfferDenvList
    );
  }, []);

  function onCompletedOfferDenvList(result) {
    if (result) {
      setProducts(result);
      if (result.dataState) setProductsDataState(result.dataState);
    }
    setIsLoading(false);
  }

  function onDataStateChange(event) {
    setProductsDataState(event.dataState);
  }

  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    if (dataItem.dataItem.puto === "DET") {
      trProps["style"] = {
        color: "gray",
        fontStyle: "italic",
      };
    }

    return React.cloneElement(
      trElement,
      { ...trProps },
      ...trElement.props.children
    );
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t("offer.products.list")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Grid
            sortable={true}
            resizable={true}
            filterable={false}
            data={products}
            {...productsDataState}
            dataItemKey={"mrC_TEKLIF_DENV_ONAY_URUN_ID"}
            onDataStateChange={onDataStateChange}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
          >
            <GridToolbar>
              <ExportExcel
                data={products}
                columns={offerDenvProductGridColumns.filter(
                  (a) =>
                    (i18n.language === "tr" &&
                      a.field !== "uruN_ADI_EN" &&
                      a.field !== "mrC_URUN_GRUBU_EN") ||
                    (i18n.language === "en" &&
                      a.field !== "uruN_ADI" &&
                      a.field !== "mrC_URUN_GRUBU")
                )}
                loadingFunction={setIsLoading}
                dataState={productsDataState}
                serviceMethod={
                  OfferDenvProductService.getOfferDenvProductListForExcel
                }
                fileName={t("offer.products.list_file_name")}
              ></ExportExcel>
            </GridToolbar>
            {offerDenvProductGridColumns.map((column) => {
              if (
                (i18n.language === "tr" &&
                  column.field !== "uruN_ADI_EN" &&
                  column.field !== "mrC_URUN_GRUBU_EN") ||
                (i18n.language === "en" &&
                  column.field !== "uruN_ADI" &&
                  column.field !== "mrC_URUN_GRUBU")
              ) {
                return (
                  <GridColumn
                    field={column.field}
                    title={t(column.title)}
                    width={column.width}
                    cell={column.cell}
                  />
                );
              }
            })}
          </Grid>
        </CardBody>
      </Card>
    </>
  );
}
