import React, { useState, useEffect } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';

import ProductGroupService from '../../services/srv-product/ProductGroupService';

const ProductGroupDropDownList = ({ 
  id, 
  label, 
  value,
  required,
  onChange
}) => {

  var pendingRequest = undefined;
  const [filteredData, setFilteredData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (value && filteredData === false) {
      var filter = [];
      filter.push({
        field: 'uruN_GRUBU',
        operator: 'eq',
        value: value
      });
      getDataByFilter(filter);
    }
  }, [value, filteredData]);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setFilteredData(true);
    setDataLoading(false);
  };

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    ProductGroupService.getProductGroups(
      {
        filter: { logic: 'or', filters: filters }
      },
      onCompletedGetData
    );
  };

  const dataFilter = (event) => {
    if (dataLoading) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        dataFilter(event);
      }, 50);
      return;
    }

    var dataFilters = [];
    dataFilters.push({
      field: 'uruN_GRUBU',
      operator: 'contains',
      value: event.filter.value
    });
    dataFilters.push(event.filter);
    getDataByFilter(dataFilters);
  };

  const onFilterData = (event) => {
    if (event.filter.value.length > 2) {
      dataFilter(event);
    }
  };

  const itemRender = (li, itemProps) => {
    console.log(li.props);
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.uruN_GRUBU})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };
  
  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="aciklama"
      dataItemKey="uruN_GRUBU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterData(event)}
      loading={dataLoading}
      value={
        value
        ? data.find((v) => v.uruN_GRUBU === value)
        : ""
      }></DropDownList>
  )
}

export default ProductGroupDropDownList;