import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function campaignReducer(
  state = initialState.campaignProduct,
  action
) {
  switch (action.type) {
    case actionTypes.GET_BRY_CAMPAIGNS_PRODUCT:
      return {
        ...state,
        _campaignProduct: action.payload,
      };
    case actionTypes.GET_BRY_CAMPAIGNS_PRODUCT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_BRY_CAMPAIGNS_PRODUCT_FULFILLED:
      return {
        ...state,
        _campaignProduct: action.payload,
        fetching: false,
      };
    case actionTypes.GET_BRY_CAMPAIGNS_PRODUCT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.INSERT_BRY_CAMPAIGN_PRODUCTS_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.INSERT_BRY_CAMPAIGN_PRODUCTS_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        _campaignProduct: [],
        fetching: false,
      };
    case actionTypes.INSERT_BRY_CAMPAIGN_PRODUCTS_REJECTED:
      return {
        ...state,
        error: action.payload,
        rejectedData: true,
        fetching: false,
      };
    case actionTypes.SET_BRY_CAMPAIGNS_PRODUCT:
      var newCampaignProducts;
      if (action.payload.target.name) {
        if (action.payload.target.value) {
          if (action.payload.target.value.uruN_KODU === "Seciniz") {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              adet: null,
            };
          } else if (
            action.payload.target.value.brY_URUN_MODELI_DETAY === "Seciniz"
          ) {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              brY_URUN_MODELI_DETAY: "Seciniz",
              adet: null,
            };
          } else if (
            action.payload.target.value.brY_URUN_MODELI === "Seciniz"
          ) {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              brY_URUN_MODELI_DETAY: "Seciniz",
              brY_URUN_MODELI: "Seciniz",
              adet: null,
            };
          } else if (action.payload.target.value.brY_URUN_TIPI === "Seciniz") {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              brY_URUN_MODELI_DETAY: "Seciniz",
              brY_URUN_MODELI: "Seciniz",
              brY_URUN_TIPI: "Seciniz",
              adet: null,
            };
          } else if (action.payload.target.value.brY_URUN_GRUBU === "Seciniz") {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              brY_URUN_MODELI_DETAY: "Seciniz",
              brY_URUN_MODELI: "Seciniz",
              brY_URUN_TIPI: "Seciniz",
              brY_URUN_GRUBU: "Seciniz",
              adet: null,
            };
          } else if (
            action.payload.target.value.brY_URUN_KATEGORI === "Seciniz"
          ) {
            newCampaignProducts = {
              uruN_KODU: "Seciniz",
              brY_URUN_MODELI_DETAY: "Seciniz",
              brY_URUN_MODELI: "Seciniz",
              brY_URUN_TIPI: "Seciniz",
              brY_URUN_GRUBU: "Seciniz",
              brY_URUN_KATEGORI: "Seciniz",
              adet: null,
            };
          } else {
            newCampaignProducts = {
              [action.payload.target.name]: action.payload.target.value.id
                ? action.payload.target.value.id
                : action.payload.target.value.uruN_KODU
                ? action.payload.target.value.uruN_KODU
                : action.payload.target.value.brY_URUN_MODELI_DETAY
                ? action.payload.target.value.brY_URUN_MODELI_DETAY
                : action.payload.target.value.brY_URUN_MODELI
                ? action.payload.target.value.brY_URUN_MODELI
                : action.payload.target.value.brY_URUN_TIPI
                ? action.payload.target.value.brY_URUN_TIPI
                : action.payload.target.value.brY_URUN_GRUBU
                ? action.payload.target.value.brY_URUN_GRUBU
                : action.payload.target.value.brY_URUN_KATEGORI
                ? action.payload.target.value.brY_URUN_KATEGORI
                : action.payload.target.value.brY_SATIS_MARKA
                ? action.payload.target.value.brY_SATIS_MARKA
                : action.payload.target.value,
            };

            if (action.payload.target.props.name === "brY_URUN_KATEGORI") {
              newCampaignProducts = {
                ...newCampaignProducts,
                ...{ brY_URUN_GRUBU: null },
                ...{ brY_URUN_TIPI: null },
                ...{ brY_URUN_MODELI: null },
                ...{ brY_URUN_MODELI_DETAY: null },
                ...{ uruN_KODU: null },
              };
            }
            if (action.payload.target.props.name === "brY_URUN_GRUBU") {
              newCampaignProducts = {
                ...newCampaignProducts,
                ...{ brY_URUN_TIPI: null },
                ...{ brY_URUN_MODELI: null },
                ...{ brY_URUN_MODELI_DETAY: null },
                ...{ uruN_KODU: null },
              };
            }
            if (action.payload.target.props.name === "brY_URUN_TIPI") {
              newCampaignProducts = {
                ...newCampaignProducts,
                ...{ brY_URUN_MODELI: null },
                ...{ brY_URUN_MODELI_DETAY: null },
                ...{ uruN_KODU: null },
              };
            }
            if (action.payload.target.props.name === "brY_URUN_MODELI") {
              newCampaignProducts = {
                ...newCampaignProducts,
                ...{ brY_URUN_MODELI_DETAY: null },
                ...{ uruN_KODU: null },
              };
            }
            if (action.payload.target.props.name === "brY_URUN_MODELI_DETAY") {
              newCampaignProducts = {
                ...newCampaignProducts,
                ...{ uruN_KODU: null },
              };
            }
          }
        }
      }
      return {
        ...state,
        _campaignProduct: { ...state._campaignProduct, ...newCampaignProducts },
      };
    default:
      return state;
  }
}
