export const productGridColums = [
  {
    field: "uruN_ID",
    title: "Id",
    width: "256px"
  },
  {
    field: "uruN_KODU",
    title: "Ürün Kodu",
    width: "256px"
  },
  {
    field: "uruN_ADI",
    title: "Ürün Adı",
    width: "256px"
  },
  {
    field: "uruN_GRUBU",
    title: "Ürün Grubu",
    width: "256px"
  },
  {
    field: "marka",
    title: "Marka",
    width: "256px"
  },
  {
    field: "model",
    title: "Model",
    width: "256px"
  },
  {
    field: "kapasite",
    title: "Kapasite",
    width: "256px"
  },
  {
    field: "unite",
    title: "Ünite",
    width: "256px"
  },
  {
    field: "stoK_MIKTARI",
    title: "Stok Miktarı",
    width: "256px"
  },
  {
    field: "boM_URUN",
    title: "BOM Ürünü",
    width: "256px"
  },
  {
    field: "serI_PROFILI",
    title: "Seri Profili",
    width: "256px"
  },
  {
    field: "bayI_STOK_BILDIRIM",
    title: "Bayi Stok Bildirim",
    width: "256px"
  },
  {
    field: "gecerli",
    title: "Geçerli",
    width: "256px"
  },
  {
    field: "mrC_URUN_ADI_TR",
    title: "MRC Ürün Adı (TR)",
    width: "256px"
  },
  {
    field: "mrC_URUN_ADI_EN",
    title: "MRC Ürün Adı (EN)",
    width: "256px"
  },
  {
    field: "mrC_LISTE_FIYATI",
    title: "MRC Liste Fiyatı (Domestic)",
    width: "256px"
  },
  {
    field: "mrC_SECIM_FIYATI",
    title: "Seçim Fiyatı (Domestic)",
    width: "256px"
  },
  {
    field: "mrC_URUN_GRUBU",
    title: "MRC Ürün Grubu",
    width: "256px"
  },
  {
    field: "mrC_URUN_YAPISI",
    title: "MRC Ürün Yapısı",
    width: "256px"
  },
  {
    field: "mrC_URUN_TIPI",
    title: "MRC Ürün Tipi",
    width: "256px"
  },
  {
    field: "mrC_URUN_CINSI",
    title: "MRC Ürün Cinsi",
    width: "256px"
  },
  {
    field: "mrC_SECIM_FIYATI_EUR",
    title: "Seçim Fiyatı (Export)",
    width: "256px"
  },
  {
    field: "mrC_LISTE_FIYATI_EUR",
    title: "MRC Liste Fiyatı (Export)",
    width: "256px"
  },
  {
    field: "saP_URUNU",
    title: "SAP Ürünü",
    width: "256px"
  },
  {
    field: "mrC_URUNU",
    title: "MRC Ürünü",
    width: "256px"
  },
  {
    field: "sirA_NO",
    title: "Sıra No",
    width: "256px"
  },
  {
    field: "anliK_FIYAT_MI",
    title: "Anlık Fiyat",
    width: "256px"
  },
  {
    field: "uruN_CARPANI",
    title: "Ürün Çarpanı",
    width: "256px"
  },
  {
    field: "uruN_CARPANI_EXP",
    title: "Ürün Çarpanı Exp.",
    width: "256px"
  },
  {
    field: "uruN_CARPANI_2",
    title: "Ürün Çarpanı 2",
    width: "256px"
  },
  {
    field: "uruN_CARPANI_EXP_2",
    title: "Ürün Çarpanı Exp. 2",
    width: "256px"
  },
  {
    field: "stocK_QUAN",
    title: "Stok Adedi",
    width: "256px"
  },
  {
    field: "seciM_PRG_URUN_KODU",
    title: "Seçim PRG Ürün Kodu",
    width: "256px"
  },
  {
    field: "smartY_GOND_TARIH",
    title: "Smarty Gönderi Tarihi",
    width: "256px"
  },
  {
    field: "uruN_KODU_SAP",
    title: "Ürün Kodu SAP",
    width: "256px"
  },
  {
    field: "brY_URUN_MODELI_PCM",
    title: "BRY Ürün Modeli PCM",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_DOM_TRY",
    title: "SAP Liste Fiyatı TRY (Domestic)",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_DOM_USD",
    title: "SAP Liste Fiyatı USD (Domestic)",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_DOM_EUR",
    title: "SAP Liste Fiyatı EUR (Domestic)",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_EXP_TRY",
    title: "SAP Liste Fiyatı TRY (Export)",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_EXP_USD",
    title: "SAP Liste Fiyatı USD (Export)",
    width: "256px"
  },
  {
    field: "listE_FIYATI_SAP_EXP_EUR",
    title: "SAP Liste Fiyatı EUR (Export)",
    width: "256px"
  },
  {
    field: "brY_SATIS_MARKA",
    title: "BRY Satış Marka",
    width: "256px"
  },
  {
    field: "brY_MARKA_SEGMENT",
    title: "BRY Marka Segment",
    width: "256px"
  },
  {
    field: "brY_URUN_KATEGORI",
    title: "BRY Ürün Kategori",
    width: "256px"
  },
  {
    field: "brY_URUN_GRUBU",
    title: "BRY Ürün Grubu",
    width: "256px"
  },
  {
    field: "brY_URUN_TIPI",
    title: "BRY Ürün Tipi",
    width: "256px"
  },
  {
    field: "brY_URUN_MODELI",
    title: "BRY Ürün Modeli",
    width: "256px"
  },
  {
    field: "brY_URUN_MODELI_DETAY",
    title: "BRY Ürün Modeli Detay",
    width: "256px"
  },
  {
    field: "budgeT_REVISED_STATUS",
    title: "Bütçe Revize Durumu",
    width: "256px"
  },
  {
    field: "vendoR_ID",
    title: "Vendor",
    width: "256px"
  },
  {
    field: "vendoR_NAME",
    title: "Vendor Adı",
    width: "256px"
  },
  {
    field: "vendoR_GROUP",
    title: "Vendor Grubu",
    width: "256px"
  },
  {
    field: "plc",
    title: "PLC",
    width: "256px"
  },
  {
    field: "plC_TEXT",
    title: "PLC Yazısı",
    width: "256px"
  },
  {
    field: "producT_OWNER_KULLANICI_ID",
    title: "Ürün Sahibi",
    width: "256px"
  },
  {
    field: "scP_OWNER_KULLANICI_ID",
    title: "SCP Sahibi",
    width: "256px"
  },
  {
    field: "slP_OWNER_KULLANICI_ID",
    title: "SLP Sahibi",
    width: "256px"
  },
  {
    field: "mtO_MTS",
    title: "MTO MTS",
    width: "256px"
  },
  {
    field: "transitioN_CODE_AGGREGATE",
    title: "Transition Code Aggregate",
    width: "256px"
  },
  {
    field: "sB_MB",
    title: "SB MB",
    width: "256px"
  },
  {
    field: "sB_QTY",
    title: "SB QTY",
    width: "256px"
  },
  {
    field: "mB_DAY",
    title: "MB DAY",
    width: "256px"
  },
  {
    field: "budgeT_CODE",
    title: "Bütçe Kodu",
    width: "256px"
  },
  {
    field: "budgeT_DUMMY_CODE",
    title: "Bütçe Dummy Kodu",
    width: "256px"
  },
  {
    field: "annueL_POWER_ENERGY_CONSUMPTION",
    title: "Annuel Power Energy Consumption",
    width: "256px"
  },
  {
    field: "yukseklik",
    title: "Yükseklik",
    width: "256px"
  },
  {
    field: "uzunluk",
    title: "Uzunluk",
    width: "256px"
  },
  {
    field: "uruN_MODEL_YON",
    title: "Ürün Model Yön",
    width: "256px"
  },
  {
    field: "uruN_TIPI",
    title: "Ürün Tipi",
    width: "256px"
  },
  {
    field: "uruN_CINSI",
    title: "Ürün Cinsi",
    width: "256px"
  },
  {
    field: "gtip",
    title: "GTIP",
    width: "256px"
  },
  {
    field: "discounT_URUN_GRUBU",
    title: "Discount Ürün Grubu",
    width: "256px"
  },
  {
    field: "checksuM_VAR",
    title: "Checksum Var",
    width: "256px"
  },
];
