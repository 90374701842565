import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function userProjectResponsibilityReducer(
  state = initialState.userProjectResponsibilityList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROJECT_RESPONSIBILITY_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_USER_PROJECT_RESPONSIBILITY_FULFILLED:
      return {
        ...state,
        userProjectResponsibilities: action.payload.data,
        fetching: false,
        dataState: action.payload.dataState,
      };
    case actionTypes.FETCH_USER_PROJECT_RESPONSIBILITY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_USER_PROJECT_RESPONSIBILITY:
      return {
        ...state,
        dataState: action.payload,
        fetching: true,
      };
    case actionTypes.INSERT_USER_PROJECT_RESPONSIBILITY_PENDING:
      return {
        ...state,
        fetching: true,
        dataInserted: false,
      };
    case actionTypes.INSERT_USER_PROJECT_RESPONSIBILITY_FULFILLED:
      return {
        ...state,
        dataInserted: true,
        fetching: false,
      };
    case actionTypes.INSERT_USER_PROJECT_RESPONSIBILITY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.UPDATE_USER_PROJECT_RESPONSIBILITY_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_USER_PROJECT_RESPONSIBILITY_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.UPDATE_USER_PROJECT_RESPONSIBILITY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.DELETE_USER_PROJECT_RESPONSIBILITY:
      return {
        ...state,
        fetching: true,
        dataRemoved: false,
      };
    case actionTypes.DELETE_USER_PROJECT_RESPONSIBILITY_PENDING:
      return {
        ...state,
        fetching: true,
        dataRemoved: false,
      };
    case actionTypes.DELETE_USER_PROJECT_RESPONSIBILITY_FULFILLED:
      return {
        ...state,
        dataRemoved: true,
        fetching: false,
      };
    case actionTypes.DELETE_USER_PROJECT_RESPONSIBILITY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
