import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";

class VerticalSegmentationService {
  getVerticalSegmentations(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX}/PazarSegmenti/get-all`)
      .then((response) => {
        if (callback) callback(response);
        return response;
      });
  }
}

export default new VerticalSegmentationService();