import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import FreeStockService from "../../services/srv-freeStock/FreeStockService";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { groupBy } from "@progress/kendo-data-query";
import { useInternationalization } from "@progress/kendo-react-intl";

const FreeStockRDD = (props) => {
  const intl = useInternationalization();
  var { teklifSiparis, teklifSiparisId } = props;

  const [selected, setSelected] = useState(0);
  const handleSelect = (e) => {
    setSelected(e.selected);
  };

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setDataLoading(true);
    FreeStockService.getByTeklifSiparisId(
      teklifSiparis,
      teklifSiparisId,
      onCallBackGetData
    );
  };
  const onCallBackGetData = ({ data }) => {
    var updatedData = data.map((a) => ({
      ...a,
      rdD_DATE: intl.formatDate(new Date(a.rdD_DATE), "d.MM.y"),
    }));

    const groupedData = groupBy(updatedData, [{ field: "mrC_URUN_GRUBU" }]);

    var result = groupedData.flatMap((g) => {
      var subGroupedData = groupBy(g.items, [{ field: "rdD_DATE" }]);

      return subGroupedData
        .map((sg) => {
          return {
            mrC_URUN_GRUBU: g.value,
            rdD_DATE: sg.value,
            adet: sg.items.reduce((accum, item) => accum + item.adet, 0),
          };
        })
        .filter((a) => a.adet !== 0);
    });

    var result2 = groupedData.flatMap((g) => {
      return g.items
        .map((sg) => {
          return {
            mrC_URUN_GRUBU: g.value,
            rdD_DATE: sg.rdD_DATE,
            uruN_KODU: sg.uruN_KODU,
            adet: sg.adet,
          };
        })
        .filter((a) => a.adet !== 0);
    });

    setData(result);
    setData2(result2);
    setDataLoading(false);
  };
  return (
    <div>
      {dataLoading && LoadingPanel}
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{ marginTop: "15px" }}
      >
        <TabStripTab title="Ürün Grubuna Göre">
          <Grid data={data} sortable={false} style={{ marginTop: "15px" }}>
            <GridColumn field="mrC_URUN_GRUBU" title="Ürün Grubu"></GridColumn>
            <GridColumn field="adet" title="Adet"></GridColumn>
            <GridColumn field="rdD_DATE" title="RDD Date"></GridColumn>
          </Grid>
        </TabStripTab>
        <TabStripTab title="Ürüne Göre">
          <Grid data={data2} sortable={false} style={{ marginTop: "15px" }}>
            <GridColumn field="mrC_URUN_GRUBU" title="Ürün Grubu"></GridColumn>
            <GridColumn field="uruN_KODU" title="Ürün Kodu"></GridColumn>
            <GridColumn field="adet" title="Adet"></GridColumn>
            <GridColumn field="rdD_DATE" title="RDD Date"></GridColumn>
          </Grid>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(FreeStockRDD);
