import { API_GATEWAY, PR_DEX_BIREYSEL } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";
import axios from "axios";

export function fetchCustomerGroup() {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BRY_CUSTOMER_GROUP,
      payload: axios
        .get(`${API_GATEWAY + PR_DEX_BIREYSEL}/musterigrubu/get-all`)
        .then((result) => result.data),
    });
  };
}
