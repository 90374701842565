import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function salesRepresentativeManagerListReducer(
  state = initialState.salesRepresentativeManagerList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_REPRESENTATIVE_MANAGER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_SALES_REPRESENTATIVE_MANAGER_FULFILLED:
      return {
        ...state,
        salesRepresentativeManagers: action.payload.data,
        fetching: false,
        dataState: action.payload.dataState,
      };
    case actionTypes.FETCH_SALES_REPRESENTATIVE_MANAGER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_SALES_REPRESENTATIVE_MANAGER:
      return {
        ...state,
        dataState: action.payload,
        fetching: true,
      };
    case actionTypes.INSERT_SALES_REPRESENTATIVE_MANAGER_PENDING:
      return {
        ...state,
        fetching: true,
        dataInserted: false,
      };
    case actionTypes.INSERT_SALES_REPRESENTATIVE_MANAGER_FULFILLED:
      return {
        ...state,
        dataInserted: true,
        fetching: false,
      };
    case actionTypes.INSERT_SALES_REPRESENTATIVE_MANAGER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.UPDATE_SALES_REPRESENTATIVE_MANAGER_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.UPDATE_SALES_REPRESENTATIVE_MANAGER_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.UPDATE_SALES_REPRESENTATIVE_MANAGER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.DELETE_SALES_REPRESENTATIVE_MANAGER:
      return {
        ...state,
        fetching: true,
        dataRemoved: false,
      };
    case actionTypes.DELETE_SALES_REPRESENTATIVE_MANAGER_PENDING:
      return {
        ...state,
        fetching: true,
        dataRemoved: false,
      };
    case actionTypes.DELETE_SALES_REPRESENTATIVE_MANAGER_FULFILLED:
      return {
        ...state,
        dataRemoved: true,
        fetching: false,
      };
    case actionTypes.DELETE_SALES_REPRESENTATIVE_MANAGER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
