export const offerDenvProductGridColumns = [
  {
    field: "uruN_KODU",
    title: "product.code",
    width: "300px",
  },
  {
    field: "uruN_ADI",
    title: "product.name",
    width: "300px",
  },
  {
    field: "uruN_ADI_EN",
    title: "product.name",
    width: "300px",
  },
  {
    field: "mrC_URUN_GRUBU",
    title: "product.group",
    width: "150px",
  },
  {
    field: "mrC_URUN_GRUBU_EN",
    title: "product.group",
    width: "180px",
  },
  {
    field: "adet",
    title: "product.quantity",
    width: "120px",
  },
  {
    field: "uretildigI_ULKE_KODU",
    title: "product.origin_country",
    width: "200px",
  },
  {
    field: "marka",
    title: "product.brand",
    width: "150px",
  },
];
