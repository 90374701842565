import { orderBy } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "@progress/kendo-react-layout";
import alertify from "alertifyjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Col, List, Row } from "reactstrap";
import { TIMEZONE } from "../../config/globalVariables";
import { getLocalDateWithFormat } from "../../helpers/utils";
import FreeStockDetService from "../../services/srv-freeStock/FreeStockDetService";
import FreeStockMstService from "../../services/srv-freeStock/FreeStockMstService";
import FreeStockService from "../../services/srv-freeStock/FreeStockService";
import CommonDatePicker from "../sys-common/CommonDatePicker";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { formatDate } from "@telerik/kendo-intl";

const EDIT_FIELD = "inEdit";
const initialDataState = {
  skip: 0,
  take: 10,
};
const FreeStock = (props) => {
  var { teklifSiparis, teklifSiparisId, teklifSiparisUrunId } = props;
  const [dataLoading, setDataLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [mstData, setMstData] = useState(null);
  const [detData, setDetData] = useState(null);
  const [manuelConfDate, setManuelConfDate] = useState(null);
  const [mdStokValue, setMdStokValue] = useState(false);
  const [page, setPage] = React.useState(initialDataState);
  var minDate = new Date(
    orderBy(gridData, [{ field: "crE_DATE", dir: "desc" }])[0]?.conF_DATE
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setDataLoading(true);
    FreeStockService.getByTeklifSiparisUrunId(
      teklifSiparis,
      teklifSiparisUrunId,
      onCallBackGetData
    );
    FreeStockMstService.getByTeklifSiparisId(
      teklifSiparis,
      teklifSiparisId,
      onCallBackGetMstData
    );
    FreeStockDetService.getByTeklifSiparisUrunId(
      teklifSiparis,
      teklifSiparisUrunId,
      onCallBackGetDetData
    );
  };

  const getFirstData = (data) => {
    return orderBy(
      data.filter((a) => a.mmD_ADET > 0 || a.mmdH_ADET > 0 || a.adet > 0),
      [{ field: "crE_DATE", dir: "asc" }]
    )[0];
  };

  const getIndexOfFirstData = (data, firstData) => {
    var orderedData = orderBy(data, [{ field: "conF_DATE", dir: "asc" }]);
    var indexOfFirstData = orderedData.findIndex((a) => {
      return a.crE_DATE === firstData.crE_DATE;
    });
    return indexOfFirstData;
  };

  const onCallBackGetData = ({ data }) => {
    var firstData = getFirstData(data);
    setGridData(
      data.map((item) =>
        Object.assign(
          { inEdit: true },
          { isFirst: item.crE_DATE === firstData.crE_DATE },
          item
        )
      )
    );
    var indexOfFirstData = getIndexOfFirstData(data, firstData);
    var skip = Math.floor(indexOfFirstData / 10) * 10;
    setPage({ skip: skip, take: 10 });
    setDataLoading(false);
  };
  const onCallBackGetMstData = ({ data }) => {
    setMstData(data);
  };
  const onCallBackGetDetData = ({ data }) => {
    setDetData(data);
    setMdStokValue(data.mmD_STOK_DETAY === "EVET");
  };
  const saveChanges = () => {
    setDataLoading(true);
    FreeStockService.updateFreeStock(
      {
        tekliF_SIPARIS: teklifSiparis,
        mD_STOK_DETAY: mdStokValue ? "EVET" : "HAYIR",
        freE_STOCK: gridData,
      },
      callbackAfterUpdate,
      onErrorCallbackAfterUpdate
    );
  };
  const assignAutoDates = () => {
    setDataLoading(true);
    FreeStockService.assignFreeStockAutoDates(
      {
        tekliF_SIPARIS: teklifSiparis,
        tekliF_SIPARIS_ID: teklifSiparisId,
        tekliF_SIPARIS_URUN_ID: teklifSiparisUrunId,
        mD_STOK_DETAY: mdStokValue ? "EVET" : "HAYIR",
        islem: "ATAMA",
      },
      callbackAfterAutoDates,
      onErrorAssignAutoDates
    );
  };
  const callbackAfterAutoDates = (response) => {
    setDataLoading(false);
    getData();
  };
  const onErrorAssignAutoDates = () => {
    setDataLoading(false);
  };
  const callbackAfterUpdate = (response) => {
    setDataLoading(false);
    getData();
  };
  const onErrorCallbackAfterUpdate = (response) => {
    setDataLoading(false);
  };

  const itemChange = (e) => {
    let newData = gridData.map((item) => {
      if (
        new Date(item.conF_DATE).toLocaleDateString() ===
        new Date(e.dataItem.conF_DATE).toLocaleDateString()
      ) {
        item[e.field || ""] = e.value;
      }
      e.dataItem.mmD_ADET = !e.dataItem.mmD_ADET ? 0 : e.dataItem.mmD_ADET;
      e.dataItem.mmdH_ADET = !e.dataItem.mmdH_ADET ? 0 : e.dataItem.mmdH_ADET;
      return item;
    });
    setGridData(newData);
  };
  const addConfDate = () => {
    if (manuelConfDate) {
      var control = gridData.find(
        (a) =>
          new Date(a.conF_DATE).toLocaleDateString() ===
          new Date(manuelConfDate).toLocaleDateString()
      );
      if (control) {
        alertify.error(
          "Lütfen daha önce eklenmemiş bir confirmation date seçiniz."
        );
      } else {
        const newItem = {
          inEdit: true,
          conF_DATE: manuelConfDate,
          mmD_ADET: 0,
          mmdH_ADET: 0,
          durum: "DEVAM",
          manueL_TARIH_MI: "EVET",
          rezervE_GUN: 1,
          tekliF_SIPARIS: teklifSiparis,
          tekliF_SIPARIS_ID: teklifSiparisId,
          tekliF_SIPARIS_URUN_ID: teklifSiparisUrunId,
        };
        setGridData([...gridData, newItem]);
      }
    }
  };
  const changeMDStok = (e) => {
    setMdStokValue(e.value);
  };
  const remove = (dataItem) => {
    var index = gridData.findIndex((x) => x.conF_DATE === dataItem.conF_DATE);
    gridData.splice(index, 1);
    setGridData([...gridData]);
  };
  const pageChange = (event) => {
    const take = 10;
    setPage({
      ...event.page,
      take,
    });
  };
  function rowRender(trElement, dataItem) {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    if (dataItem.dataItem.isFirst) {
      trProps["style"] = {
        backgroundColor: "#edefdb",
      };
    }

    return React.cloneElement(
      trElement,
      { ...trProps },
      ...trElement.props.children
    );
  }
  return (
    <div>
      {dataLoading && LoadingPanel}
      <Row style={{ paddingTop: "20px" }}>
        <Col lg="6" md="6" xs="12">
          <Card>
            <CardHeader>
              <CardTitle>
                <i class="fa fa-info-circle"></i> Ürün Detay Bilgisi
              </CardTitle>
            </CardHeader>
            <CardBody>
              <List type="unstyled">
                <li>
                  <i class="fa fa-building"></i> Ürün :{" "}
                  <b>{detData?.uruN_KODU}</b> ({detData?.uruN_ADI})
                </li>
                <hr></hr>
                <li>
                  <i class="fa fa-building"></i> Talep Edilen Adet :{" "}
                  <b>{detData?.adet}</b>
                </li>
                <hr></hr>
                <li>
                  <i class="fa fa-building"></i> İstenen Teslim Tarihi :{" "}
                  <b>
                    {formatDate(
                      new Date(detData?.isteneN_TESLIM_TARIHI),
                      "dd.MM.yyyy"
                    )}
                  </b>
                </li>
                <hr></hr>
                <li>
                  <i class="fa fa-building"></i> Toplam Kullanılabilir Stok :{" "}
                  <b>
                    {gridData
                      .filter(
                        (a) => a.kalaN_FREE_STOCK && a.kalaN_FREE_STOCK != null
                      )
                      .reduce((x, y) => {
                        return x + y.kalaN_FREE_STOCK;
                      }, 0)}
                  </b>
                </li>
              </List>
            </CardBody>
          </Card>
        </Col>
        <Col lg="6" md="6" xs="12">
          <Card>
            <CardHeader>
              <CardTitle>
                <i class="fa fa-calendar"></i> Manuel Ürün Stok Tarihi Ekle
              </CardTitle>
            </CardHeader>
            <CardBody>
              <CommonDatePicker
                label="Lütfen Tarih Seçiniz"
                id="manuel_conf_date"
                min={new Date(minDate.setDate(minDate.getDate() + 1))}
                value={manuelConfDate}
                onChange={(e) => {
                  setManuelConfDate(
                    getLocalDateWithFormat(e.target.value, TIMEZONE)
                  );
                }}
              />
              <Button
                style={{ marginTop: "20px" }}
                color="primary"
                size="sm"
                onClick={addConfDate}
              >
                <span class="fas fa-plus-circle"> </span> EKLE
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col lg="12" md="12" xs="12">
          <Card>
            {" "}
            <CardHeader>
              <CardTitle>
                <i class="fa fa-calendar"></i> Ürün Stok Tarihleri Atama Listesi
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Grid
                data={orderBy(
                  gridData.slice(page.skip, page.take + page.skip),
                  [{ field: "conF_DATE", dir: "asc" }]
                )}
                skip={page.skip}
                take={page.take}
                pageable={{
                  buttonCount: 3,
                  pageSize: 10,
                }}
                total={gridData.length}
                onPageChange={pageChange}
                style={{ height: 680 }}
                rowHeight={50}
                onItemChange={itemChange}
                editField={EDIT_FIELD}
                rowRender={(trElement, dataItem) =>
                  rowRender(trElement, dataItem)
                }
              >
                <GridToolbar>
                  <Button color="success" size="sm" onClick={saveChanges}>
                    <span class="fas fa-check-circle"> </span> DEĞİŞİKLİKLERİ
                    KAYDET
                  </Button>
                  {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                  props.auth.roles?.includes("DAIKIN_FREE_STOCK_MMD_DETAY") ? (
                    <Checkbox
                      style={{ marginLeft: "20px" }}
                      onChange={changeMDStok}
                      label={"MD STOK DETAY"}
                      value={mdStokValue}
                    />
                  ) : undefined}
                  <Button
                    style={{ marginLeft: "20px" }}
                    color="info"
                    size="sm"
                    onClick={assignAutoDates}
                  >
                    <span class="fas fa-calendar"> </span> TARIHLERI YENIDEN ATA
                  </Button>
                </GridToolbar>
                <GridColumn
                  field="conF_DATE"
                  title="Ürün Stok Tarihi"
                  editor="date"
                  cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
                  editable={false}
                />
                <GridColumn
                  field="rdD_DATE"
                  title="SAP Teslim Tarihi(RDD)"
                  editor="date"
                  cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
                  editable={false}
                />
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? (
                  <GridColumn
                    field="kalaN_FREE_STOCK"
                    title="Kullanılabilir Stok"
                    editable={false}
                  />
                ) : undefined}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? (
                  <GridColumn
                    field="kalaN_CUM_FREE_STOCK"
                    title="Kümülatif Kullanılabilir Stok"
                    editable={false}
                  />
                ) : undefined}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? (
                  <GridColumn
                    field="adet"
                    title="Seçilen Stok"
                    editor={"numeric"}
                  />
                ) : undefined}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? (
                  <GridColumn
                    field="kalaN_ADET"
                    title="Kalan Adet"
                    editable={false}
                  />
                ) : undefined}

                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmD_KALAN_FREE_STOCK"
                    title="MMD Stok"
                    editable={false}
                  />
                )}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmdH_KALAN_FREE_STOCK"
                    title="MMDH Stok"
                    editable={false}
                  />
                )}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmD_ADET"
                    title="MMD Seçilen Stok"
                    editor={"numeric"}
                  />
                )}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmdH_ADET"
                    title="MMDH Seçilen Stok"
                    editor={"numeric"}
                  />
                )}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmD_KALAN_ADET"
                    title="MMD Kalan Adet"
                    editable={false}
                  />
                )}
                {!mstData?.satiS_DEPARTMANI_KODU.includes("EXPORT") &&
                !mdStokValue ? undefined : (
                  <GridColumn
                    field="mmdH_KALAN_ADET"
                    title="MMDH Kalan Adet"
                    editable={false}
                  />
                )}
                <GridColumn
                  field="manueL_TARIH_MI"
                  title="Manuel Tarih Mi?"
                  editable={false}
                />
                <GridColumn
                  field="tahminI_TARIH_MI"
                  title="Tahmini Tarih Mi?"
                  editable={false}
                />
                <GridColumn
                  cell={(props) => (
                    <td className="k-command-cell">
                      {props.dataItem.manueL_TARIH_MI === "EVET" ? (
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() =>
                            window.confirm(
                              "Silmek istediğinizden emin misiniz?"
                            ) && remove(props.dataItem)
                          }
                        >
                          <span class="fas fa-trash"> </span> SİL
                        </Button>
                      ) : undefined}
                    </td>
                  )}
                />
              </Grid>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(FreeStock);
