import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";

class FreeStockPutoLogService {
  getPutoLogByTeklifSiparisUrunId(
    teklifSiparis,
    teklifSiparisId,
    teklifSiparisUrunId,
    callback
  ) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestockputolog/get-by-teklif-siparis-urun-id/${teklifSiparis}/${teklifSiparisId}/${teklifSiparisUrunId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
  getPutoLogSonucByTeklifSiparisUrunId(
    teklifSiparis,
    teklifSiparisId,
    teklifSiparisUrunId,
    callback
  ) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestockputolog/get-sonuc-by-teklif-siparis-urun-id/${teklifSiparis}/${teklifSiparisId}/${teklifSiparisUrunId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
}

export default new FreeStockPutoLogService();
