import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const SectorDropDownList = ({
  id,
  label,
  data,
  required,
  onChange,
  defaultValue,
  value,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    setDataLoading(true);
    defaultDataHandler();
  }, [data]);

  const defaultDataHandler = () => {
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.sektoR_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  const onChangeTest = (e) => {
    value = e.target.value;
    onChange(e);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={filteredData}
      textField="sektoR_ADI"
      dataItemKey="sektoR_KODU"
      style={{ width: "100%" }}
      onChange={onChangeTest}
      defaultValue={defaultValue}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      value={value ? data.find((a) => a.sektoR_KODU === value) : ""}
    ></DropDownList>
  );
};

export default SectorDropDownList;
