import React from "react";
import { formatDate } from "@telerik/kendo-intl";

const GridDateFormatCell = (props, format) => {
  const value = props.dataItem[props.field];
  if (value === null || value === undefined || value === "") {
    return <td title={format}></td>;
  } else {
    return <td title={format}>{formatDate(new Date(value), format)}</td>;
  }
};

export default GridDateFormatCell;
