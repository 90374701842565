import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Routes from "./Routes";
import Header from "./Header";
import SideBar from "./SideBar";
import JwtDecode from "jwt-decode";
import { changeUser } from "../../redux/actions/act-root/authActions";
import { fetchAllMenus } from "../../redux/actions/act-root/menuActions";
import Footer from "./Footer";
import ExtRoutes from "./ExtRoutes";

class Layout extends Component {
  componentDidMount() {
    this.props.fetchAllMenus();
    console.log("Path:", window.location.pathname);
  }

  setUser = (token) => {
    if (this.props.auth.userName === "") {
      this.props.changeUser(JwtDecode(token));
    }
  };

  setAuth = (auth) => {
    if (auth.userName === "") {
      const token = localStorage.getItem("jwtToken");
      if (token) {
        this.setUser(token);
      }
    }
  };

  render() {
    this.setAuth(this.props.auth);
    return window.location.pathname.startsWith("/ex-ter-nal-") ? (
      <div>
        <ExtRoutes />
      </div>
    ) : (
      <div>
        <Header />
        <Routes />
        <SideBar
          menus={this.props}
          userName={this.props.auth.userName}
          bayiKodu={this.props.auth.bayiKodu}
        />
        <Footer></Footer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    menuList: state.menuListReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeUser: bindActionCreators(changeUser, dispatch),
    fetchAllMenus: bindActionCreators(fetchAllMenus, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
