import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { BASE_URL } from "../../config/globalVariables";
import ExtFreeStock from "../cmp-external/ExtFreeStock";
import ExtFreeStockRDD from "../cmp-external/ExtFreeStockRDD";

class Routes extends Component {
  render() {
    var BASE_URL_EXT = BASE_URL + "ex-ter-nal-";
    return (
      <Switch>
        <Route
          exact={true}
          path={BASE_URL_EXT + "free-stok/:teklifId/:teklifUrunId"}
          component={ExtFreeStock}
        ></Route>
        <Route
          exact={true}
          path={BASE_URL_EXT + "free-stok-rdd/:teklifId"}
          component={ExtFreeStockRDD}
        ></Route>
      </Switch>
    );
  }
}

export default withRouter(Routes);
