import React, { Component } from "react";
import SimpleTooltip from "../sys-toolbox/SimpleTooltip";

export default class GridToolTipCell extends Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    return (
      <td>
        <span id={this.props.id}>{value}</span>
        <SimpleTooltip placement="top" target={this.props.id}>
          {value}
        </SimpleTooltip>
      </td>
    );
  }
}
