import i18n from "../config/languageInit";

/**
 *
 * @param {string} error
 * @returns {string[]}
 */
export default function errorNormalize(message) {
  const reg = /'(.*?)'/g;
  let messages = message
    .replaceAll("Validation failed:", "")
    .replaceAll("Severity: Error", "")
    .split("-- ");
  messages = messages.map((a) => {
    const k = new RegExp(reg).exec(a);
    if (k && k.length > 0) return a.replace(k[0], i18n.t(k[1]));
    return a;
  });
  return messages.filter((d) => d !== " \r\n ");
}
export function reduxErrorThrower(err) {
  const message = err.response.data.message || err.response.data.Message;
  if (message)
    throw new Error(err.response.data.message || err.response.data.Message);
  throw new Error(JSON.stringify(err.response));
}
