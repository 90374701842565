import {
  DATAPARABIRIMI,
  DATASATISDEPARTMAN,
  YES_NO,
} from "../../config/globalVariables";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";

function KurFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={DATAPARABIRIMI.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );
}

function OlusturanFirmaFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={["DAIKIN", "BAYI"]}
      defaultValue={"Seçiniz"}
    />
  );
}

function YesNoFilterCell(props) {
  return (
    <DropdownFilterCell {...props} data={YES_NO} defaultValue={"Seçiniz"} />
  );
}

function ResmiOzelFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={["RESMI", "OZEL"]}
      defaultValue={"Seçiniz"}
    />
  );
}

function DurumFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={[
        "BASSIS",
        "DEVAM",
        "FRMBRK",
        "IPT-BS",
        "IPT-KYP",
        "IPT-SIP",
        "IPT-YI",
        "KAYIP",
        "MUKR",
        "SIPARIS",
        "YAT.IPT",
      ]}
      defaultValue={"Seçiniz"}
    />
  );
}

function SantiyeTipiFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={[
        "ESKI_BINA",
        "ESKİ_BİNA_SOLUTION_BUSINESS",
        "REPLACEMENT",
        "YENI_BINA",
        "YENİ_BİNA_SOLUTION_BUSINESS",
      ]}
      defaultValue={"Seçiniz"}
    />
  );
}

function SatisDepartmaniFilterCell(props) {
  return (
    <DropdownFilterCell
      {...props}
      data={DATASATISDEPARTMAN.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );
}

export const projectReportGridColumns = [
  {
    field: "satiS_TEMSILCISI_BOLGE",
    title: "BÖLGE",
    width: "100px",
  },
  {
    field: "satiS_TEMSILCISI_KULLANICI_ADI",
    title: "SATIŞ TEMSİLCİSİ",
    width: "85px",
  },
  {
    field: "mrC_PROJE_ID",
    title: "PROJE_ID",
    width: "65px",
    filter: "numeric",
  },
  {
    field: "mrC_TEKLIF_ID",
    title: "TEKLİF ID",
    width: "65px",
    filter: "numeric",
  },
  {
    field: "projE_ADI",
    title: "PROJE ADI",
    width: "105px",
  },
  {
    field: "muS_ADI",
    title: "MÜŞ. ADI",
    width: "105px",
  },
  {
    field: "tekliF_VERILEN_FIRMA",
    title: "TEK. VERİLEN FİRMA",
    width: "105px",
  },
  {
    field: "tekliF_TARIHI",
    title: "TEKLİF TARİHİ",
    width: "65px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "onaY_TARIHI",
    title: "TEKLİF ONAY TARİHİ",
    width: "65px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "onaylayaN_KISI",
    title: "ONAYLAYAN KİŞİ",
    width: "125px",
  },
  {
    field: "toplaM_TEKLIF_TUTARI",
    title: "TOPLAM TUTAR",
    width: "75px",
    filter: "numeric",
    cell: (props) => GridNumberFormatCell(props, 2),
  },
  {
    field: "toplaM_LISTE_FIYATI",
    title: "TOPLAM LİSTE FİYATI",
    width: "75px",
    filter: "numeric",
    cell: (props) => GridNumberFormatCell(props, 2),
  },
  {
    field: "kur",
    title: "KUR",
    width: "45px",
    filterCell: () => KurFilterCell,
  },
  {
    field: "baglanmA_ORANI",
    title: "BAĞL. ORANI",
    width: "45px",
    filter: "numeric",
  },
  {
    field: "baglanmA_ZAMANI",
    title: "BAĞL. ZAMANI",
    width: "45px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "baglanmA_ZAMANI_YILHAFTA",
    title: "BAĞL. ZAMANI(Haftalık)",
    width: "45px",
  },
  {
    field: "faturA_TARIHI",
    title: "FATURA TARİHİ",
    width: "125px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "faturA_TARIHI_YILHAFTA",
    title: "FATURA TARİHİ(Haftalık)",
    width: "125px",
  },
  {
    field: "vrV_DIS_UNITE_SAYISI",
    title: "VRV DIŞ ÜNİTE SAYISI",
    width: "40px",
    filter: "numeric",
  },
  {
    field: "oraclE_PROJE_ID",
    title: "O_TEKLIF ID",
    width: "60px",
    filter: "numeric",
  },
  {
    field: "teklifcI_KULLANICI_ADI",
    title: "OLUŞTURAN KİŞİ",
    width: "80px",
  },
  {
    field: "ilK_OLUSTURAN_TIPI",
    title: "İLK OLUŞTURAN FİRMA",
    width: "80px",
  },
  {
    field: "crE_TYPE",
    title: "OLUŞTURAN FİRMA",
    width: "80px",
    filterCell: OlusturanFirmaFilterCell,
  },
  {
    field: "hedeF_PROJE",
    title: "HEDEF PROJE",
    width: "80px",
  },
  {
    field: "durum",
    title: "DURUM",
    width: "80px",
    filterCell: DurumFilterCell,
  },
  {
    field: "sipariS_TARIHI",
    title: "SİPARİŞ TARİHİ",
    width: "80px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "satiS_DEPARTMANI_KODU",
    title: "PROJE DEPARTMANI KODU",
    width: "80px",
    filterCell: SatisDepartmaniFilterCell,
  },
  {
    field: "satiS_BOLGE_KODU",
    title: "PROJE BÖLGE",
    width: "80px",
  },
  {
    field: "kaybedileN_MARKA",
    title: "KAYBEDILEN MARKA",
    width: "80px",
  },
  {
    field: "kaybedilmE_NEDENI",
    title: "KAYBEDILME NEDENİ",
    width: "80px",
  },
  {
    field: "kaybedilmE_TARIHI",
    title: "KAYBEDILME TARİHİ",
    width: "80px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "forecast",
    title: "FORECAST",
    width: "80px",
    filterCell: YesNoFilterCell,
  },
  {
    field: "projecI_FIRMA_MUS_KODU",
    title: "PROJECI FİRMA",
    width: "80px",
  },
  {
    field: "santiyE_KULLANIM_AMACI",
    title: "SANTIYE KULLANIM AMACI",
    width: "80px",
  },
  {
    field: "ortaK_PROJE_DEPARTMANI",
    title: "ORTAK PROJE DEPARTMANI",
    width: "80px",
  },
  {
    field: "ortaK_PROJE_TEMSILCISI",
    title: "ORTAK PROJE TEMSILCISI",
    width: "80px",
  },
  {
    field: "santiyE_TIPI",
    title: "ŞANTİYE TİPİ",
    width: "80px",
    filterCell: SantiyeTipiFilterCell,
  },
  {
    field: "projE_SORUMLUSU",
    title: "PROJE SORUMLUSU",
    width: "80px",
  },
  {
    field: "projecI_ZIYARET_EDILDI",
    title: "PROJECİ ZİYARET EDİLDİ",
    width: "80px",
    filterCell: YesNoFilterCell,
  },
  {
    field: "yatirimcI_ZIYARET_EDILDI",
    title: "YATIRIMCI ZİYARET EDİLDİ",
    width: "80px",
    filterCell: YesNoFilterCell,
  },
  {
    field: "ulkE_KODU",
    title: "ÜLKE KODU",
    width: "80px",
  },
  {
    field: "daikiN_IL_KODU",
    title: "İL",
    width: "80px",
  },
  {
    field: "daikiN_ILCE_ADI",
    title: "İLÇE",
    width: "80px",
  },
  {
    field: "santiyE_ZIYARET_EDILDI",
    title: "ŞANTİYE ZİYARET EDİLDİ",
    width: "80px",
  },
  {
    field: "ilK_TEKLIF_TARIHI",
    title: "İLK TEKLİF TARİHİ",
    width: "80px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "soN_TEKLIF_TARIHI",
    title: "TEKLİF SON GÜNCELLEME TARİHİ",
    width: "80px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "projE_ILK_OLUSTURMA_TARIHI",
    title: "PROJE ILK OLUSTURMA TARİHİ",
    width: "80px",
    filterable: false,
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date"
  },
  {
    field: "fcU_SIP",
    title: "FCU SIP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "fcU_KYP",
    title: "FCU KYP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "kS_SIP",
    title: "KS SIP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "kS_KYP",
    title: "KS KYP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "soG_GR_SIP",
    title: "SOG GR SIP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "soG_GR_KYP",
    title: "SOG GR KYP",
    width: "80px",
    filter: "numeric",
  },
  {
    field: "fcU_KYP_MARKA",
    title: "FCU KYP MARKA",
    width: "80px",
  },
  {
    field: "kS_KYP_MARKA",
    title: "KS KYP MARKA",
    width: "80px",
  },
  {
    field: "soG_GR_KYP_MARKA",
    title: "SOG GR KYP MARKA",
    width: "80px",
  },
  {
    field: "resmI_OZEL",
    title: "RESMI - OZEL",
    width: "80px",
    filterCell: ResmiOzelFilterCell,
  },
  {
    field: "projE_YAPILDI",
    title: "PROJE YAPILDI",
    width: "80px",
  },
  {
    field: "butcE_FIYATI_VERILDI",
    title: "BÜTÇE FİYATI VERİLDİ",
    width: "80px",
  },
  {
    field: "prJ_ASAMASI_VRV_SARTNAME",
    title: "VRV ŞARTNAME",
    width: "80px",
  },
  {
    field: "projE_CALISMASI_YAPAN_KISI",
    title: "PROJE ÇALIŞMASI YAPAN KİŞİ",
    width: "80px",
  },
  {
    field: "prJ_AKT_YAPAN",
    title: "PROJE AKTARIMI YAPAN KİŞİ",
    width: "80px",
  },
  {
    field: "bilgI_KAYNAGI",
    title: "BILGI KAYNAGI",
    width: "80px",
  },
  {
    field: "destekleme",
    title: "DESTEKLEME",
    width: "80px",
  },
];
