import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { BASE_URL } from "../../config/globalVariables";
import _ from "lodash";

class SideBar extends Component {
  fnGroupMenuItems = (props) => {
    const filteredWebMenus = _.filter(props.menuList._menuList, function (val) {
      return (
        val.menU_TYPE !== "DAI" &&
        val.menU_TYPE !== "INVISIBLE" &&
        val.menU_TYPE !== "FINANS_MENU" &&
        val.uppeR_MENU_CODE !== "0000"
      );
    });

    const menuGrouped = _.chain(filteredWebMenus)
      .groupBy("uppeR_MENU_CODE")
      .map(function (v, i) {
        return {
          menU_CODE: i,
          ordeR_NO: _.find(props.menuList._menuList, ["menU_CODE", i])
            ?.ordeR_NO,
          menU_ADI: _.find(props.menuList._menuList, ["menU_CODE", i])
            ?.menU_ADI,
          menU_URL: _.find(props.menuList._menuList, ["menU_CODE", i])
            ?.menU_URL,
          menU_ICON_CLASS_NAME: _.find(props.menuList._menuList, [
            "menU_CODE",
            i,
          ])?.menU_ICON_CLASS_NAME,
          menU_TANIM_ID: _.find(props.menuList._menuList, ["menU_CODE", i])
            ?.menU_TANIM_ID,
          subMenus: v,
        };
      })
      .value();

    const constMenus = _.filter(props.menuList._menuList, function (val) {
      return (
        val.uppeR_MENU_CODE === "0000" &&
        val.menU_URL &&
        val.menU_TYPE !== "DAI" &&
        val.menU_TYPE !== "INVISIBLE" &&
        val.menU_TYPE !== "FINANS_MENU"
      );
    });

    menuGrouped.push(...constMenus);

    return menuGrouped.sort((a, b) => (a.ordeR_NO > b.ordeR_NO ? 1 : -1));
  };

  // fnMenuItem2 = (menu) => {
  //   return (
  //     <Link
  //       to={ (menu.projecT_TYPE === "HAYIR" ? "/Dex" : "") + menu.menU_URL}
  //       className="nav-link"
  //     >
  //       <p>
  //         <i className={menu.menU_ICON_CLASS_NAME}></i> {menu.menU_ADI}
  //       </p>
  //     </Link>
  //   );
  // };

  fnMenuItem = (menu) => {
    if (menu.projecT_TYPE === "HAYIR") {
      return (
        <a
          href={window.location.origin + "/Dex/" + menu.menU_URL}
          className="nav-link"
        >
          <p>
            <i className={menu.menU_ICON_CLASS_NAME}></i> {menu.menU_ADI}
          </p>
        </a>
        // <Link
        //   onClick={() =>
        //     (window.location = window.location.origin + "/Dex/" + menu.menU_URL)
        //   }
        //   className="nav-link"
        // >
        //   <p>
        //     <i className={menu.menU_ICON_CLASS_NAME}></i> {menu.menU_ADI}
        //   </p>
        // </Link>
      );
    } else {
      return (
        <Link to={menu.menU_URL} className="nav-link">
          <p>
            <i className={menu.menU_ICON_CLASS_NAME}></i> {menu.menU_ADI}
          </p>
        </Link>
      );
    }
  };

  fnSubMenuItems = (argSubMenus) => {
    let lcHtml = "";
    if (argSubMenus.length > 0) {
      lcHtml = argSubMenus.map((menu) => (
        <li className="nav-item" key={menu.menU_TANIM_ID}>
          {this.fnMenuItem(menu)}
        </li>
      ));
      lcHtml = <ul className="nav nav-treeview">{lcHtml}</ul>;
    }
    return lcHtml;
  };

  goToHomepage() {
    window.location.href = BASE_URL + "dex/member";
  }

  render() {
    const { t } = this.props;
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a
          href={BASE_URL + "homepage"}
          className="brand-link"
          style={{
            padding: "0px",
            textAlign: "center",
            backgroundColor: "#0095de",
            height: "56px",
          }}
        >
          <img
            id="idLogo"
            alt=""
            style={{
              backgroundColor: "white",
              marginTop: "10px",
              borderRadius: "5px",
              height: "36px",
              padding: "3px",
            }}
            src={window.location.origin + process.env.PUBLIC_URL + "/logo2.png"}
          />
        </a>

        <div className="sidebar">
          <div className="user-panel mt-2 pb-2 mb-2 d-flex">
            <div className="info">
              <span style={{ fontStyle: "italic", color: "#c2c7d0" }}>
                {t("welcome")}, <b>{this.props.userName}</b>{" "}
                {this.props.bayiKodu ? "Bayi Kodu: " + this.props.bayiKodu : ""}
              </span>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column sidebar-menu tree"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <Link onClick={() => this.goToHomepage()} className="nav-link">
                <p>
                  <i className="fa fa-home"></i> Ana Sayfa
                </p>
              </Link>

              {this.fnGroupMenuItems(this.props.menus).map((menu) => {
                if (menu.uppeR_MENU_CODE === "0000") {
                  return this.fnMenuItem(menu);
                } else {
                  return (
                    <li
                      key={menu.menU_TANIM_ID}
                      className="nav-item has-treeview"
                    >
                      <a href="/#" className="nav-link">
                        <i className={menu.menU_ICON_CLASS_NAME}></i>{" "}
                        <p>
                          {menu.menU_ADI}
                          <i className="right fas fa-angle-left"></i>
                        </p>
                      </a>
                      {this.fnSubMenuItems(menu.subMenus)}
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}
export default withTranslation()(SideBar);
