import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  BASE_URL,
  DATABRYSATISDEPARTMAN,
  DATALISTEVADESI,
  DATAPARABIRIMI,
  DATASAPDURUM,
  DATASIPARISTURU,
} from "../../config/globalVariables";
import filterLocalization from "../../helpers/filterLocalization";
import { getChannels } from "../../redux/actions/act-bry-channel/channelActions";
import {
  cancelOrderDealer,
  changeOrderDealer,
  fetchOrderDealer,
  fetchOrdersForExcel,
} from "../../redux/actions/act-order/orderActions";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import DropdownFilterCell from "../sys-toolbox/GridDropdownFilterCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";

class OrderListDealer extends Component {
  componentDidMount() {
    this.getOrderList();
    this.props.getChannels();
  }

  getOrderList = () => {
    if (this.props.auth.bayiKodu) {
      var take = this.props.orderList.dataState
        ? this.props.orderList.dataState.take
        : 6;
      var skip = this.props.orderList.dataState
        ? this.props.orderList.dataState.skip
        : 0;

      var sort = [
        {
          field: "brY_SIPARIS_ID",
          dir: "desc",
        },
      ];

      var bayiFilter = [];
      bayiFilter.push({
        field: "bayI_KODU",
        operator: "eq",
        value: this.props.auth.bayiKodu,
      });
      this.props.fetchOrderDealer({
        filter: { logic: "or", filters: bayiFilter },
        take: take,
        skip: skip,
        sort: sort,
      });
    }
  };

  goDetail = (dataItem) => {
    window.open(BASE_URL + "order-update-dealer/" + dataItem.brY_SIPARIS_ID);
  };

  cancelOrder = (dataItem) => {
    this.props.cancelOrderDealer(dataItem, this.props.orderList.dataState);
  };

  goNewOrder = () => {
    this.props.history.push(BASE_URL + "order-update-dealer/0");
  };

  export = () => {
    this.props.fetchOrdersForExcel(
      this._export,
      this._grid.columns,
      this.props.orderList.dataState
    );
  };

  dataStateChange = (event) => {
    filterLocalization(event);
    this.props.fetchOrderDealer(event.dataState);
    this.props.changeOrderDealer(event.dataState);
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  SapStatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATASAPDURUM.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  SalesDepartmantFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATABRYSATISDEPARTMAN.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  OrderTypeFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATASIPARISTURU.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  CurrencyFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={DATAPARABIRIMI.map((a) => a.text)}
      defaultValue={"Seçiniz"}
    />
  );

  SalesStatusFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={["YENI", "ONAYDA", "ONAYLI", "TEKLIF", "IPTAL"]}
      defaultValue={"Seçiniz"}
    />
  );

  ChannelFilterCell = (props) => (
    <DropdownFilterCell
      {...props}
      data={this.props.bryChannels.channels.map((a) => a.brY_KANAL)}
      defaultValue={"Seçiniz"}
    />
  );

  VadeGunCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];
    var data = DATALISTEVADESI.find((a) => a.id === dataValue.toString());
    return <td> {data ? data.text : dataValue}</td>;
  };

  render() {
    return (
      <main style={{ flexGrow: "1" }}>
        <h2>Bayi Sipariş Listesi</h2>
        {this.props.orderList.fetching && LoadingPanel}
        <ExcelExport
          data={this.props.orderList.orders}
          ref={(exporter) => (this._export = exporter)}
        >
          <Grid
            ref={(grid) => {
              this._grid = grid;
            }}
            data={this.props.orderList.result}
            {...this.props.orderList.dataState}
            onDataStateChange={this.dataStateChange}
            sortable={true}
            style={{ minHeight: "400px" }}
            pageable={{ buttonCount: 3, pageSizes: [6, 10, 20, 50, 100] }}
            resizable={true}
            filterable
            rowRender={(trElement, dataItem) =>
              this.rowRender(trElement, dataItem)
            }
          >
            <GridToolbar>
              <Button
                color="primary"
                type="button"
                size="sm"
                onClick={() => this.goNewOrder()}
              >
                {" "}
                <span className="k-icon k-i-plus-circle"></span> Yeni Sipariş
              </Button>
              <button
                title="Export Excel"
                className="btn btn-info btn-sm"
                onClick={this.export}
              >
                Excel
              </button>
            </GridToolbar>
            <GridColumn
              filterable={false}
              width="180px"
              cell={(props) => (
                <td>
                  <button
                    title="Detay"
                    className="btn btn-info btn-sm"
                    onClick={() => this.goDetail(props.dataItem)}
                  >
                    <i className="fas fa-info-circle"></i> Detay
                  </button>
                  {props.dataItem.satiS_ONAY_DURUM !== "IPTAL" ? (
                    <button
                      title="Sil"
                      style={{ marginLeft: "5px" }}
                      className="btn btn-danger btn-sm"
                      onClick={() => this.cancelOrder(props.dataItem)}
                    >
                      <i className="fas fa-times-circle"></i> İptal
                    </button>
                  ) : undefined}
                </td>
              )}
            />
            <GridColumn
              field="brY_SIPARIS_ID"
              title="Sipariş No"
              width="90px"
              filter={"numeric"}
            ></GridColumn>
            <GridColumn
              field="crE_DATE"
              title="Sipariş Giriş Tarihi"
              width="100px"
              filterable={false}
              filter="date"
              cell={(props) => GridDateFormatCell(props, "dd.MM.yyyy")}
            ></GridColumn>
            <GridColumn
              field="satiS_DEPARTMANI_ADI"
              title="Satış Departmanı"
              width="120px"
              filterCell={this.SalesDepartmantFilterCell}
            ></GridColumn>
            <GridColumn
              field="brY_KANAL"
              title="Kanal"
              width="120px"
              filterCell={this.ChannelFilterCell}
            ></GridColumn>
            <GridColumn
              field="sipariS_TURU"
              title="Sipariş Türü"
              width="120px"
              filterCell={this.OrderTypeFilterCell}
            ></GridColumn>
            <GridColumn
              field="kampanyA_KODU"
              title="Kampanya Kodu"
              width="120px"
            ></GridColumn>
            <GridColumn
              field="parA_BIRIMI"
              title="Para Birimi"
              width="100px"
              filterCell={this.CurrencyFilterCell}
            ></GridColumn>
            <GridColumn
              field="satiS_ONAY_DURUM"
              title="Satış Onay Durum"
              width="120px"
              filterCell={this.SalesStatusFilterCell}
            ></GridColumn>
            <GridColumn
              field="satiS_TEMSILCISI"
              width="150px"
              title="Satış Temsilcisi"
            ></GridColumn>
            <GridColumn
              field="saP_DURUM"
              title="SAP Sipariş Durumu"
              width="120px"
              filterCell={this.SapStatusFilterCell}
            ></GridColumn>
            <GridColumn
              field="saP_SIPARIS_NO"
              title="SAP Sipariş No"
              width="150px"
            ></GridColumn>
          </Grid>
        </ExcelExport>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bryChannels: state.channelsReducer,
    orderList: state.orderListDealerReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeOrderDealer: bindActionCreators(changeOrderDealer, dispatch),
    cancelOrderDealer: bindActionCreators(cancelOrderDealer, dispatch),
    getChannels: bindActionCreators(getChannels, dispatch),
    fetchOrderDealer: bindActionCreators(fetchOrderDealer, dispatch),
    fetchOrdersForExcel: bindActionCreators(fetchOrdersForExcel, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderListDealer);
