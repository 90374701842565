import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function salesDepartmantListReducer(
  state = initialState.salesDepartmantList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_SALES_DEPARTMANT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_SALES_DEPARTMANT_FULFILLED:
      return {
        ...state,
        salesDepartmants: action.payload,
        result: action.payload,
        fetching: false,
        dataState: action.payload.dataState,
      };
    case actionTypes.FETCH_SALES_DEPARTMANT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };

    case actionTypes.FETCH_SALES_DEPARTMENT_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_SALES_DEPARTMENT_FULFILLED:
      return {
        ...state,
        salesDepartments: action.payload.data,
        fetching: false,
        dataState: action.payload.dataState,
      };
    case actionTypes.FETCH_SALES_DEPARTMENT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_SALES_DEPARTMENT:
      return {
        ...state,
        dataState: action.payload,
        fetching: true,
      };
    case actionTypes.CALLSP_UPDATE_SALES_DEPARTMENT_PENDING:
      return {
        ...state,
        fetching: true,
        dataUpdated: false,
      };
    case actionTypes.CALLSP_UPDATE_SALES_DEPARTMENT_FULFILLED:
      return {
        ...state,
        dataUpdated: true,
        fetching: false,
      };
    case actionTypes.CALLSP_UPDATE_SALES_DEPARTMENT_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
