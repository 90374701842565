import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function productPriceListReducer(
  state = initialState.productPriceList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_PRICE_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_PRODUCT_PRICE_FULFILLED:
      return {
        ...state,
        productPrices: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_PRODUCT_PRICE_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_PRODUCT_PRICE:
      return {
        ...state,
        dataState: action.payload,
        // changeDataState: true,
      };
    default:
      return state;
  }
}
