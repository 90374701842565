import React from "react";
import NumberFormat from "react-number-format";

const FormatNumber = (value, decimalScale) => {
  return (
    <NumberFormat
      thousandSeparator={"."}
      value={value}
      displayType={"text"}
      decimalSeparator={","}
      decimalScale={decimalScale}
    ></NumberFormat>
  );
};

export default FormatNumber;
