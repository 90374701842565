import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX, MS_SENDER } from "../../config/globalVariables";

class ExcelService {
  getExcelTypes(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/exceltanim/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getExcelErrorsByProcId(dataState, callback) {
    const headers = new Headers({
      Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
    });

    return fetch(
      `${
        API_GATEWAY + MS_SENDER
      }/excel/get-list-by-filter?${toDataSourceRequestString(dataState)}`,
      {
        method: "GET",
        headers: headers,
      }
    )
      .then((response) => response.json())
      .then((response) => {
        if (callback) callback(response);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }
}

export default new ExcelService();
