import { PR_DEX } from "../../config/globalVariables";
import { ServiceBase, rawRequest } from '../srv-base/ServiceBase';

class ExcelTypeService extends ServiceBase {
  constructor() {
    super(PR_DEX, '/exceltanim');
  }

  getExcelTypes(onSuccessCallback, onErrorCallback) {
    return rawRequest(
      'get',
      PR_DEX,
      '/exceltanim',
      `/get-all`,
      undefined,
      (response, onSuccessCallback) => {
        if (onSuccessCallback) {
          onSuccessCallback(response.data);
        }

        return response.data;
      },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default new ExcelTypeService();