import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderAddressInfoReducer(
  state = initialState.orderAddressInfo,
  action
) {
  switch (action.type) {
    case actionTypes.GET_ORDER_ADDRESS_INFO:
      return {
        ...state,
        _address: action.payload,
      };
    case actionTypes.GET_ORDER_ADDRESS_INFO_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.GET_ORDER_ADDRESS_INFO_FULFILLED:
      return {
        ...state,
        _address: action.payload,
        fetching: false,
      };
    case actionTypes.GET_ORDER_ADDRESS_INFO_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.UPDATE_ORDER_ADDRESS_INFO_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.UPDATE_ORDER_ADDRESS_INFO_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.UPDATE_ORDER_ADDRESS_INFO_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.SET_ORDER_ADDRESS_INFO:
      var newAddressInfo;
      if (action.payload.target.element.current) {
        newAddressInfo = {
          [action.payload.target.element.current.name]: action.payload.value,
        };
      } else {
        newAddressInfo = {
          [action.payload.target.name]: action.payload.target.value.ulkE_KODU
            ? action.payload.target.value.ulkE_KODU
            : action.payload.target.value.daikiN_IL_KODU
            ? action.payload.target.value.daikiN_IL_KODU
            : action.payload.target.value.bolgE_KODU
            ? action.payload.target.value.bolgE_KODU
            : action.payload.target.value.tasimA_BOLGESI_KODU
            ? action.payload.target.value.tasimA_BOLGESI_KODU
            : action.payload.target.value.id ||
              action.payload.target.value.id === "0"
            ? action.payload.target.value.id
            : action.payload.target.value,
        };
      }

      if (action.payload.target.name === "ulkE_KODU") {
        newAddressInfo["iL_KODU"] = "";
        newAddressInfo["ilcE_KODU"] = "";
        newAddressInfo["bolgE_KODU"] = "";
        newAddressInfo["timezone"] = action.payload.value.timezone;
        newAddressInfo["tasimA_BOLGESI"] =
          action.payload.value.tasimA_BOLGESI_KODU;
      }

      if (action.payload.target.name === "iL_KODU") {
        newAddressInfo["iL_KODU"] = action.payload.target.value.daikiN_IL_KODU;
        newAddressInfo["ilcE_KODU"] = "";
        if (action.payload.target.value.ulkE_KODU === "TR") {
          newAddressInfo["tasimA_BOLGESI"] =
            action.payload.value.tasimA_BOLGESI_KODU;
          newAddressInfo["bolgE_KODU"] = action.payload.value.plakA_KODU;
        }
      }

      if (action.payload.target.name === "ulkE_KODU") {
        if (action.payload.target.value.ulkE_KODU !== "TR") {
          newAddressInfo["dil"] = "EN English";
        }
        else {
          newAddressInfo["dil"] = "TR Turkish";
        }
      }

      return {
        ...state,
        _address: { ...state._address, ...newAddressInfo },
      };
    default:
      return state;
  }
}
