import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";

class CityService {
  getCities(dataState, callback) {
    return axios
      .get(
        `${API_GATEWAY + PR_DEX}/sehir/get-all?${toDataSourceRequestString(
          dataState
        )}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getCitiesByRegionCode(regionCode, callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/sehir/get-list-by-bolgekodu/${regionCode}`)
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
        return result.data;
      });
  }
  getCitiesGenel(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/sehir/get-all-genel?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getCitiesGenelByRegionCode(regionCode, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/sehir/get-list-genel-by-bolgekodu/${regionCode}`
      )
      .then((result) => {
        if (callback) {
          callback(result.data);
        }
        return result.data;
      });
  }
}

export default new CityService();
