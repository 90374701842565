import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
  getConfirmSuccessMessage,
} from "../../helpers/utils";
import { confirmAlert } from "react-confirm-alert";

class FreeStockService {
  getByTeklifSiparisUrunId(teklifSiparis, teklifSiparisUrunId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestock/get-by-teklif-siparis-urun-id/${teklifSiparis}/${teklifSiparisUrunId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }

  getByTeklifSiparisId(teklifSiparis, teklifSiparisId, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/freestock/get-by-teklif-siparis-id/${teklifSiparis}/${teklifSiparisId}`
      )
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }

  getByTeklifSiparisRezerve(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/freestock/get-by-teklif-siparis-rezerve`)
      .then((result) => {
        if (callback) callback(result);
        return result;
      })
      .catch((error) => {});
  }
  updateFreeStock(data, callback, onErrorCallBack) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/freestock/update`, {
        ...data,
      })
      .then((response) => {
        if (callback) callback(response);
        defaultAlertMessages.MessageDetailSuccess = response.data;
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmSuccessMessage(onClose, defaultAlertMessages);
          },
        });
        return response;
      })
      .catch((error) => {
        if (onErrorCallBack) onErrorCallBack();
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          error.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
  assignFreeStockAutoDates(data, callback, onError) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/freestock/assign-auto-dates`, {
        ...data,
      })
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      })
      .catch((error) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          error.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });

        if (onError) {
          onError();
        }
      });
  }
  assignFreeStockAutoDatesAll(data, callback, onError) {
    return axios
      .post(`${API_GATEWAY + PR_DEX}/freestock/assign-auto-dates-all`, {
        ...data,
      })
      .then((result) => {
        if (callback) callback(result.data);
        return result.data;
      })
      .catch((error) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          error.response.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });

        if (onError) {
          onError();
        }
      });
  }
}

export default new FreeStockService();
