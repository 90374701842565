import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import {
  API_GATEWAY,
  CDN_PROJECT_NAME,
  MS_SENDER,
} from "../../config/globalVariables";
import {
  defaultAlertMessages,
  getConfirmErrorMessage,
} from "../../helpers/utils";

class FileService {
  getFiles(tableName, tableId, fileType, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + MS_SENDER
        }/file/get-files/${CDN_PROJECT_NAME}/${tableName}/${tableId}/${fileType}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  deleteFile(fileid, tableName, tableId, fileType, callback) {
    return axios
      .post(
        `${API_GATEWAY + MS_SENDER}/file/delete/${CDN_PROJECT_NAME}/${fileid}`
      )
      .then(() => {
        var data = this.getFiles(tableName, tableId, fileType, callback);
        return data;
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response?.data
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }

  downloadFile(cdn_file, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + MS_SENDER
        }/file/download/${cdn_file}/${CDN_PROJECT_NAME}`
      )
      .then((response) => {
        window.open(response.data);
        if (callback) callback();
      })
      .catch((err) => {
        defaultAlertMessages.MessageDetailError = JSON.stringify(
          err.response.data.Message
        );
        confirmAlert({
          customUI: ({ onClose }) => {
            return getConfirmErrorMessage(onClose, defaultAlertMessages);
          },
        });
      });
  }
}

export default new FileService();
