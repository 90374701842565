import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getOrder,
  insertOrder,
  updateOrder,
  calculateCampaignDiscount,
  executeOnayHierarchy,
  executeOnayIptal,
} from "../../redux/actions/act-order/orderActions";
import OrderProps from "./OrderProps";
import OrderProductList from "./OrderProductList";
import { fetchDealerProductGroup } from "../../redux/actions/act-bry-dealerProductGroup/dealerProductGroupActions";
import { OrderFiles } from "./OrderFiles";
import OrderReport from "./OrderReport";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import { BASE_URL } from "../../config/globalVariables";
import OrderAddressInfo from "./OrderAddressInfo";

class OrderUpdate extends Component {
  isSendForApproval = false;
  isAddedOrder = false;
  isCancelApproval = false;

  state = {
    visibleProductList: false,
    visibleOrderReport: false,
    visibleOrderFiles: false,
    visibleAddressInfo: false,
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.props.match.params.orderId === "0") {
      this.isAddedOrder = true;
      this.props.insertOrder(this.props.order);
    } else {
      this.props.updateOrder(this.props.order);
    }
  };

  componentDidUpdate() {
    if (
      this.props.order.dataInserted === true &&
      this.props.match.params.orderId === "0" &&
      this.isAddedOrder === true
    ) {
      this.props.history.push(
        BASE_URL +
          "order-update-dealer/" +
          this.props.order._order.brY_SIPARIS_ID
      );
    }

    if (this.isSendForApproval && this.props.orderApproval.dataUpdated) {
      this.props.history.push(BASE_URL + "order-dealer");
    }
  }

  componentDidMount() {
    this.props.getOrder(this.props.match.params.orderId);
    this.props.fetchDealerProductGroup();
    this.refreshProductList();
  }

  toggleProductList = () => {
    this.setState({ visibleProductList: !this.state.visibleProductList });
  };

  toggleDialogOrderReport = () => {
    this.setState({
      visibleOrderReport: !this.state.visibleOrderReport,
    });
  };

  toggleDialogOrderFiles = () => {
    this.setState({
      visibleOrderFiles: !this.state.visibleOrderFiles,
    });
  };

  toggleDialogAdressInfo = () => {
    this.setState({
      visibleAddressInfo: !this.state.visibleAddressInfo,
    });
  };

  calculateCampaignDiscount = () => {
    this.props.calculateCampaignDiscount(
      this.props.order._order.brY_SIPARIS_ID
    );
  };

  sendForApproval = () => {
    this.isSendForApproval = true;
    var satisOnay = {
      ISLEM: "ONAYA_GONDER",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };

    this.props.executeOnayHierarchy(satisOnay);
  };

  sendForRevise = () => {
    this.isSendForApproval = true;
    var satisOnay = {
      ISLEM: "REVIZE",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };

    this.props.executeOnayHierarchy(satisOnay);
  };

  cancelAproval = () => {
    var satisOnay = {
      ISLEM: "YENI_DURUMUNA_GETIR",
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
    };
    this.props.executeOnayIptal(satisOnay);
  };

  refreshProductList = () => {
    this.props.getOrderProducts(this.props.match.params.orderId);
  };

  render() {
    if (!this.props.auth.bayiKodu) {
      return (
        <div>
          Bayi ile kullanıcınız eşleşmemektedir. Lütfen DEX Admin ile iletişime
          geçiniz.
        </div>
      );
    }

    if (
      this.props.match.params.orderId !== "0" &&
      this.props.auth.bayiKodu !== this.props.order._order.bayI_KODU
    ) {
      return this.props.order.fetching ? (
        LoadingPanel
      ) : (
        <div>Sipariş bulunamadı.</div>
      );
    }

    return (
      <main style={{ flexGrow: "1", padding: "40px" }}>
        {(this.props.orderApproval.fetching ||
          this.props.campaignDiscountCalculate.fetching ||
          this.props.order.fetching) &&
          LoadingPanel}
        {this.state.visibleOrderReport ? (
          <OrderReport
            toggleDialog={this.toggleDialogOrderReport}
            reportName="OrderReport"
            title="Sipariş Formu"
            reportRelative={true}
            orderId={this.props.order._order.brY_SIPARIS_ID}
          ></OrderReport>
        ) : (
          ""
        )}
        {this.state.visibleOrderFiles ? (
          <OrderFiles
            toggleDialog={this.toggleDialogOrderFiles}
            title="Sipariş Dosyaları"
            detId={this.props.order._order.brY_SIPARIS_ID}
            fileType="SIPARIS"
          ></OrderFiles>
        ) : (
          ""
        )}

        {this.state.visibleAddressInfo ? (
          <OrderAddressInfo
            toggleDialog={this.toggleDialogAdressInfo}
            title="Sipariş Adres Bilgileri"
            orderId={this.props.match.params.orderId}
            order={this.props.order._order}
          ></OrderAddressInfo>
        ) : (
          ""
        )}
        {this.props.order.fetching ? undefined : (
          <div>
            {" "}
            <h3>
              {this.props.match.params.orderId === "0"
                ? "Yeni Sipariş"
                : "Sipariş Detayı"}
            </h3>
            <hr></hr>
            <OrderProps
              orderId={this.props.match.params.orderId}
              orderData={this.props.order._order}
              submitFunc={this.onSubmit}
            ></OrderProps>
            {this.props.match.params.orderId === "0" ? undefined : (
              <div>
                <hr></hr>
                {this.props.order._order.satiS_ONAY_DURUM === "ONAYLI" ? (
                  <Button
                    type="button"
                    color="info"
                    size="sm"
                    onClick={this.toggleDialogOrderReport}
                  >
                    <i className="k-icon k-i-graph"></i> SIPARIS FORMU
                  </Button>
                ) : undefined}

                {this.props.order._order.sipariS_TURU === "KAMPANYA" &&
                this.props.order._order.kampanyA_HESAPLANDI !== "EVET" ? (
                  <Button
                    type="button"
                    color="info"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={() => this.calculateCampaignDiscount()}
                  >
                    <i className="k-icon k-i-pencil"></i> KAMPANYA HESAPLA
                  </Button>
                ) : undefined}

                {this.props.order._order.satiS_ONAY_DURUM === "YENI" ||
                this.props.order._order.satiS_ONAY_DURUM === "RED" ? (
                  <Button
                    type="button"
                    color="primary"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={() => this.sendForApproval()}
                  >
                    <i className="k-icon k-i-redo"></i> ONAYA GÖNDER
                  </Button>
                ) : undefined}
                <Button
                  type="button"
                  color="secondary"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                  onClick={this.toggleDialogOrderFiles}
                >
                  <i className="k-icon k-i-folder-open"></i> SIPARIS DOSYALARI
                </Button>
                {this.props.order._order.satiS_ONAY_DURUM === "ONAYDA" ? (
                  <Button
                    color="warning"
                    type="button"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={this.cancelAproval}
                  >
                    <span class="k-icon k-i-reset"></span> ONAY SÜRECİNİ GERİ AL
                  </Button>
                ) : undefined}
                {this.props.order._order.sevK_ADRESI_TIPI !== "SAP_ADRES" ? (
                  <Button
                    type="button"
                    color="warning"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={this.toggleDialogAdressInfo}
                  >
                    <i className="k-icon k-i-marker-pin"></i> ADRES BİLGİLERİ
                  </Button>
                ) : undefined}
                {this.props.order._order.satiS_ONAY_DURUM === "ONAYLI" ? (
                  <Button
                    type="button"
                    color="warning"
                    size="sm"
                    style={{ marginLeft: "5px" }}
                    onClick={this.sendForRevise}
                  >
                    <i className="k-icon k-i-redo"></i> SİPARİŞ REVİZESİ
                  </Button>
                ) : undefined}
              </div>
            )}
            <hr></hr>
            {this.props.order &&
            this.props.order._order &&
            this.props.order._order.brY_SIPARIS_ID &&
            this.props.order._order.brY_SIPARIS_ID !== 0 &&
            this.props.orderProductList.fetching === false ? (
              <div>
                {(this.props.order.fetching ||
                  this.props.orderProductList.fetching ||
                  this.props.dealerProductGroupList.fetching) &&
                  LoadingPanel}
                <OrderProductList
                  orderId={this.props.match.params.orderId}
                  orderProductData={this.props.orderProductList.orderProducts}
                  refreshFunc={this.refreshProductList}
                ></OrderProductList>
                <hr></hr>
              </div>
            ) : undefined}
          </div>
        )}

        <br></br>
      </main>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
    order: state.orderReducer,
    dealerProductGroupList: state.dealerProductGroupListReducer,
    campaignDiscountCalculate: state.campaignDiscountCalculateReducer,
    orderProductList: state.orderProductListReducer,
    orderApproval: state.orderApprovalReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrder: bindActionCreators(getOrder, dispatch),
    insertOrder: bindActionCreators(insertOrder, dispatch),
    updateOrder: bindActionCreators(updateOrder, dispatch),
    calculateCampaignDiscount: bindActionCreators(
      calculateCampaignDiscount,
      dispatch
    ),
    executeOnayHierarchy: bindActionCreators(executeOnayHierarchy, dispatch),
    executeOnayIptal: bindActionCreators(executeOnayIptal, dispatch),
    fetchDealerProductGroup: bindActionCreators(
      fetchDealerProductGroup,
      dispatch
    ),
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderUpdate);
