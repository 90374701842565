import axios from "axios";
import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import { API_GATEWAY, PR_DEX } from "../../config/globalVariables";
import { ServiceBase } from '../srv-base/ServiceBase';

class BrandService extends ServiceBase {
  constructor() {
    super(PR_DEX, '/marka');
  }
    getByFilter(dataState, callback) {
    return axios
      .get(
        `${
          API_GATEWAY + PR_DEX
        }/marka/get-list-by-filter?${toDataSourceRequestString(dataState)}`
      )
      .then((response) => {
        if (callback) callback(response.data);
        return dataState.group && dataState.group.length
          ? translateDataSourceResultGroups(response.data)
          : { data: response.data, dataState };
      });
  }

  getBrands(callback) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/marka/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }

  getBrandsForExcel(dataState) {
    return axios
      .get(`${API_GATEWAY + PR_DEX}/marka/get-list-for-excel?${toDataSourceRequestString(dataState)}`)
      .then((response) => response.data);
  }

}

export default new BrandService();
