import React, { cloneElement, useState, useEffect, useRef } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "@progress/kendo-react-layout";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import BiggSerialsCsvService from "../../services/srv-bigg-loyalty/BiggSerialsCsvService";
import { BiggSerialsCsvReportGridColumns } from "./BiggSerialsCsvReportGridColumns";
import ExportExcel from "../sys-toolbox/ExcelExport";
import filterLocalization from "../../helpers/filterLocalization";
import { Button } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";
import {
  defaultAlertMessages,
  getConfirmWarningMessage,
} from "../../helpers/utils";
import ColumnsMenu, { ColumnMenuHelper } from "../grid/ColumnsMenu";

export function BiggSerialsCsvReport() {
  const _grid = useRef(null);
  const sort = [
    {
      field: "dkN_BIGG_SERIALS_CSV_ID",
      dir: "desc",
    },
  ];
  const columnState = React.useState(BiggSerialsCsvReportGridColumns);
  React.useEffect(() => {
    const ar = ColumnMenuHelper.getColumn("big_serials_csv_columns");
    console.log(ar);
    if (Array.isArray(ar)) {
      columnState[1](ar);
    }
  }, []);
  const [biggSerialsCsvReportsLoading, setBiggSerialsCsvReportsLoading] =
    useState(false);
  const [biggSerialsCsvReports, setBiggSerialsCsvReports] = useState(null);
  const [biggSerialsCsvReportsDataState, setBiggSerialsCsvReportsDataState] =
    useState({
      sort,
      take: 10,
      skip: 0,
    });
  console.log(columnState[0]);
  useEffect(() => {
    setBiggSerialsCsvReportsLoading(true);
    BiggSerialsCsvService.getBiggSerialsCsvReports(
      biggSerialsCsvReportsDataState,
      onGetDataCompleted
    );
  }, [biggSerialsCsvReportsDataState]);

  const onGetDataCompleted = (data) => {
    setBiggSerialsCsvReports(data);
    setBiggSerialsCsvReportsLoading(false);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setBiggSerialsCsvReportsLoading(true);
    setBiggSerialsCsvReportsDataState(event.dataState);
    BiggSerialsCsvService.getBiggSerialsCsvReports(
      event.dataState,
      onGetDataCompleted
    );
  };

  const rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  function DeleteCell(props) {
    return (
      <td style={{ textAlign: "center", padding: "4px" }}>
        <Button
          style={{ width: "100%", backgroundColor: "red", color: "white" }}
          variant="outlined"
          onClick={() => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return getConfirmWarningMessage(
                  onClose,
                  () =>
                    BiggSerialsCsvService.delete(
                      props.dataItem.dkN_BIGG_SERIALS_CSV_ID
                    ).finally(() => {
                      setBiggSerialsCsvReportsDataState({
                        sort,
                        take: 10,
                        skip: 0,
                      });
                    }),
                  defaultAlertMessages
                );
              },
            });
          }}
        >
          Sil
        </Button>
      </td>
    );
  }

  return (
    <main style={{ flexGrow: "1" }}>
      <Card>
        <CardHeader>
          <CardTitle>Bigg Serials CSV</CardTitle>
        </CardHeader>
        <CardBody>
          {biggSerialsCsvReportsLoading && LoadingPanel}
          <Grid
            ref={_grid}
            data={biggSerialsCsvReports}
            dataItemKey={"dkN_BIGG_SERIALS_CSV_ID"}
            {...biggSerialsCsvReportsDataState}
            onDataStateChange={onDataStateChange}
            sortable={true}
            resizable={true}
            filterable={true}
            style={{ minHeight: "400px", fontSize: "11px" }}
            rowRender={(trElement, dataItem) => rowRender(trElement, dataItem)}
            pageable={{
              buttonCount: 3,
              pageSizes: [10, 20, 50, 100],
            }}
          >
            <GridToolbar>
              <ColumnsMenu
                columns={columnState[0]}
                onChange={columnState[1]}
                storageKey={"big_serials_csv_columns"}
              />
              <ExportExcel
                data={biggSerialsCsvReports}
                columns={BiggSerialsCsvReportGridColumns}
                loadingFunction={setBiggSerialsCsvReportsLoading}
                dataState={biggSerialsCsvReportsDataState}
                serviceMethod={
                  BiggSerialsCsvService.getBiggSerialsCsvReportsForExcel
                }
                fileName="BiggSerialCsvReport"
              ></ExportExcel>
            </GridToolbar>
            {true && (
              <GridColumn
                title=" "
                filterable={false}
                width={100}
                cell={DeleteCell}
                sortable={false}
              />
            )}
            {columnState[0]
              .filter((column) => !column.hidden)
              .slice()
              .sort((a, b) => a.order - b.order)
              .map((column) => (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.title + column.order}
                  width={column.width}
                  filter={column.filter}
                  filterable={column.filterable ? column.filterable : true}
                  cell={column.cell}
                  filterCell={column.filterCell}
                />
              ))}
          </Grid>
        </CardBody>
      </Card>
    </main>
  );
}
