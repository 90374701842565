import axios from 'axios';
import { API_GATEWAY, PR_DEX_MERCURY } from "../../config/globalVariables";

class MrcProductGroupService {
  getProductGroups(callback) {
    return axios.get(`${API_GATEWAY + PR_DEX_MERCURY}/urungrubu/get-all`)
      .then((response) => {
        if (callback) callback(response.data);
        return response.data;
      });
  }
}

export default new MrcProductGroupService();