import {
  toDataSourceRequestString,
  translateDataSourceResultGroups,
} from "@progress/kendo-data-query";
import axios from "axios";
import { API_GATEWAY, PR_DEX } from "../../../config/globalVariables";
import * as actionTypes from "../sys-configure/actionTypes";

export function fetchProductPrice(dataState) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PRODUCT_PRICE,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX
          }/urunfiyat/get-list-by-filter?${toDataSourceRequestString(
            dataState
          )}`
        )
        .then((result) =>
          dataState.group && dataState.group.length
            ? translateDataSourceResultGroups(result.data)
            : result.data
        ),
    });
  };
}

export function fetchProductPriceForExcel(exports, columns, dataState) {
  var dataFilterState = {};
  dataFilterState.filter = dataState.filter;
  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_PRODUCT_PRICE,
      payload: axios
        .get(
          `${
            API_GATEWAY + PR_DEX
          }/urunfiyat/get-list-by-filter?${toDataSourceRequestString(
            dataFilterState
          )}`
        )
        .then((result) => {
          exports.save(result.data, columns);
          dispatch(fetchProductPrice(dataState));
        }),
    });
  };
}

export function changeProductPrice(event) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.CHANGE_PRODUCT_PRICE,
      payload: event,
    });
  };
}

export function updateProductPrice(productPrice) {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_PRODUCT_PRICE,
      payload: axios
        .post(`${API_GATEWAY + PR_DEX}/urunfiyat/update`, {
          ...productPrice,
        })
        .then((result) => {
          return result.data;
        }),
    });
  };
}
