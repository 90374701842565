import { DropDownList } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import BrandService from "../../services/srv-brand/BrandService";

const RegisteredTrademarkDropDownList = ({
  id,
  label,
  value,
  required,
  disabled,
  onChange,
}) => {
  var pendingRequest = undefined;
  const [filteredData, setFilteredData] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (value && filteredData === false) {
      var filter = [];
      filter.push({
        field: "markA_KODU",
        operator: "eq",
        value: value,
      });
      getBrandsByFilter(filter);
    }
  }, [value, filteredData]);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setFilteredData(true);
    setDataLoading(false);
  };

  const getBrandsByFilter = (filters) => {
    setDataLoading(true);
    BrandService.getByFilter(
      {
        filter: { logic: "or", filters: filters },
      },
      onCompletedGetData
    );
  };

  const brandFilter = (event) => {
    if (dataLoading) {
      clearTimeout(pendingRequest);
      pendingRequest = setTimeout(() => {
        brandFilter(event);
      }, 50);
      return;
    }

    var filters = [];
    filters.push({
      field: "markA_KODU",
      operator: "contains",
      value: event.filter.value,
    });
    filters.push(event.filter);
    getBrandsByFilter(filters);
  };

  const onFilterDealer = (event) => {
    if (event.filter.value.length > 2) {
      brandFilter(event);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="markA_ADI"
      dataItemKey="markA_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      disabled={disabled}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterDealer(event)}
      loading={dataLoading}
      value={value ? data.find((v) => v.markA_KODU === value) : ""}
    ></DropDownList>
  );
};

export default RegisteredTrademarkDropDownList;
