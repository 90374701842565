import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import React, { useEffect, useState } from "react";
import filterLocalization from "../../helpers/filterLocalization";
import AnnouncementService from "../../services/srv-announcement/AnnouncementService";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import AnnouncementDetail from "./Announcement";
import { announcementGridColumns } from "./AnnouncementGridColumns";

const AnnouncementList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [dataItem, setDataItem] = useState(null);
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  });
  const [visibleDuyuruDetay, setVisibleDuyuruDetay] = useState(false);
  const duyuruDetayToggle = (data) => {
    setDataItem(data);
    setVisibleDuyuruDetay(!visibleDuyuruDetay);
    visibleDuyuruDetay && getData();
  };

  useEffect(() => {
    getData();
  }, [dataState]);

  const getData = () => {
    setIsLoading(true);
    AnnouncementService.getAnnouncements(dataState, onGetDataCompleted);
  };

  const onDataStateChange = (event) => {
    filterLocalization(event);
    setDataState(event.dataState);
  };

  const onGetDataCompleted = (data) => {
    setData(data);
    setIsLoading(false);
  };

  return (
    <main style={{ flexGrow: "1" }}>
      {visibleDuyuruDetay ? (
        <AnnouncementDetail
          data={dataItem}
          modalToggle={duyuruDetayToggle}
        ></AnnouncementDetail>
      ) : (
        ""
      )}
      <div className="k-card-list">
        <CardTitle>Duyuru Liste</CardTitle>
        <Card>
          <CardBody>
            {isLoading && LoadingPanel}
            <Grid
              data={data}
              dataItemKey={"duyurU_ID"}
              {...dataState}
              onDataStateChange={onDataStateChange}
              sortable={true}
              resizable={true}
              filterable={true}
              style={{ minHeight: "400px", fontSize: "11px" }}
              pageable={{
                buttonCount: 3,
                pageSizes: [10, 20, 50, 100],
              }}
            >
              <GridToolbar>
                <button
                  title="Yeni Kayıt"
                  className="btn btn-primary btn-sm"
                  onClick={() => duyuruDetayToggle()}
                >
                  Yeni Kayıt
                </button>
              </GridToolbar>
              <GridColumn
                filterable={false}
                width="100px"
                cell={(props) => (
                  <td>
                    <button
                      title="Detay"
                      className="btn btn-success btn-sm"
                      onClick={() => duyuruDetayToggle(props.dataItem)}
                    >
                      <i className="fas fa-info-circle"></i> Detay
                    </button>
                  </td>
                )}
              />
              {announcementGridColumns.map((column) => (
                <GridColumn
                  key={column.field}
                  field={column.field}
                  title={column.title}
                  width={column.width}
                  filter={column.filter}
                  filterable={column.filterable ? column.filterable : true}
                  cell={column.cell}
                  filterCell={column.filterCell}
                />
              ))}
            </Grid>
          </CardBody>
        </Card>
      </div>
    </main>
  );
};

export default AnnouncementList;
