import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function orderListDealerReducer(
  state = initialState.orderListDealer,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ORDER_DEALER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_ORDER_DEALER_FULFILLED:
      return {
        ...state,
        orders: action.payload,
        result: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_ORDER_DEALER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    case actionTypes.CHANGE_ORDER_DEALER:
      return {
        ...state,
        dataState: action.payload,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_DEALER_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_DEALER_FULFILLED:
      return {
        ...state,
        fetching: false,
      };
    case actionTypes.CALLSP_CANCEL_ORDER_DEALER_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
