import { Window } from "@progress/kendo-react-dialogs";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReportViewer from "../sys-toolbox/ReportViewer";

class OrderReport extends Component {
  state = {
    reportPath: "DEX/" + this.props.reportName + ".trdp",
  };

  render() {
    return (
      <Window
        title={this.props.title}
        onClose={this.props.toggleDialog}
        initialHeight={"85vh"}
        initialWidth={"95%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        <ReportViewer
          reportSource={{
            report: this.state.reportPath,
            parameters: {
              orderId: this.props.orderId,
              userId: this.props.auth.id,
            },
          }}
          openPopup={true}
        ></ReportViewer>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderReport);
