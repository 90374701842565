import React from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const CommonDatePicker = (props) => {
  return (
    <div>
      <DatePicker
        label={props.label}
        name={props.id}
        format="dd.MM.yyyy"
        formatPlaceholder="formatPattern"
        min={props.min}
        value={props.value ? new Date(props.value) : null}
        onChange={props.onChange}
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default CommonDatePicker;
