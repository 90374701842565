import * as actionTypes from "../../actions/sys-configure/actionTypes";
import initialState from "../sys-configure/initialState";

export default function productCategoryListReducer(
  state = initialState.productCategoryList,
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_BRY_PRODUCT_CATEGORY_PENDING:
      return {
        ...state,
        fetching: true,
      };
    case actionTypes.FETCH_BRY_PRODUCT_CATEGORY_FULFILLED:
      return {
        ...state,
        productCategories: action.payload,
        fetching: false,
      };
    case actionTypes.FETCH_BRY_PRODUCT_CATEGORY_REJECTED:
      return {
        ...state,
        error: action.payload,
        fetching: false,
      };
    default:
      return state;
  }
}
