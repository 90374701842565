import { Grid, GridColumn } from "@progress/kendo-react-grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrderDiscountProductGroupSalesApprovalService from "../../services/srv-bry-orderDiscountProductGroup/OrderDiscountProductGroupSalesApprovalService";
import GridRowRenderDefault from "../sys-toolbox/GridRowRenderDefault";

const OrderDiscountProductGroupSalesApprovalList = (props) => {
  const [data, setData] = useState([]);
  const [dataState, setDataState] = useState({
    take: 10000,
    skip: 0,
  });

  useEffect(() => {
    OrderDiscountProductGroupSalesApprovalService.getOrderDiscountProductGroupsSalesApproval(
      props.orderId,
      callback
    );
  }, []);

  const callback = (discountData) => {
    setData(discountData);
  };

  return (
    <div>
      <Grid
        data={data}
        {...dataState}
        sortable={false}
        resizable
        rowRender={(trElement, dataItem) =>
          GridRowRenderDefault(trElement, dataItem)
        }
      >
        <GridColumn
          field="discounT_URUN_GRUBU"
          title="Discount Ürün Grubu"
          width="160px"
        ></GridColumn>
        <GridColumn
          field="indiriM_ORANI"
          title="İndirim Oranı"
          width="120px"
          filterable={false}
          editor="numeric"
        ></GridColumn>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.authReducer,
  };
};

export default connect(mapStateToProps)(
  OrderDiscountProductGroupSalesApprovalList
);
