import React, { useState } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import { DATAREVIZENEDENLERI } from "../../config/globalVariables";
import { Row, Col, Button } from "reactstrap";
import CommonTextArea from "../sys-common/CommonTextArea";
import CommonDropDownList from "../sys-common/CommonDropDownList";
import OrderService from "../../services/srv-bry-order/OrderService";

const OrderCopReviseStatus = (props) => {
  const [order, setOrder] = useState(props.data);

  function updateCopReviseStatus() {
    var sapIslemleriDto = {
      SAP_DURUM: "REVIZE",
      BRY_SIPARIS_ID: order.brY_SIPARIS_ID,
      REVIZE_SEBEBI: order.revizE_SEBEBI,
      REVIZE_ACIKLAMASI: order.revizE_NEDENI,
    };

    OrderService.executeSAPStatus(sapIslemleriDto, props.onCallback);
  }
  return (
    <Window
      title={props.title}
      onClose={props.toggleDialog}
      initialHeight={"60vh"}
      initialWidth={"30%"}
      style={{
        left: "50%",
        top: "40%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <CommonDropDownList
        id="revizE_SEBEBI"
        label="Geri Gönderme Sebebi"
        data={DATAREVIZENEDENLERI}
        onChange={(e) => setOrder({ ...order, revizE_SEBEBI: e.target.value.id })}
        required={true}
        value={order?.revizE_SEBEBI}
      />
      <CommonTextArea
        id="revizE_ACIKLAMASI"
        label="Açıklama"
        onChange={(e) => setOrder({ ...order, revizE_ACIKLAMASI: e.target.value })}
        value={order?.revizE_ACIKLAMASI}
      ></CommonTextArea>
      <Row>
        <Col lg="12" md="12" xs="12" style={{ marginTop: "40px" }}>
          <Button
            color="secondary"
            type="button"
            size="sm"
            onClick={props.toggleDialog}
          >
            Vazgeç
          </Button>
          <Button
            type="button"
            color="primary"
            size="sm"
            style={{ width: "150px", float: "right" }}
            onClick={updateCopReviseStatus}
          >
            {" "}
            Kaydet
          </Button>
        </Col>
      </Row>
    </Window>
  );
};

export default OrderCopReviseStatus;
