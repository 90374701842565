import React, { useState, useEffect } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ExcelTypeService from '../../services/srv-excel/ExcelTypeService';

const ExcelTypeDropDownList = ({
  id,
  label,
  value,
  required,
  onChange
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    ExcelTypeService.getExcelTypes(onCompletedGetData);
  }, []);

  const onCompletedGetData = (data) => {
    setData(data);
    setDataLoading(false);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.dkN_GENEL_EXCEL_TANIM_KODU})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="dkN_GENEL_EXCEL_TANIM_ACIKLAMA"
      dataItemKey="dkN_GENEL_EXCEL_TANIM_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      loading={dataLoading}
      value={
        value
          ? data.find((v) => v.dkN_GENEL_EXCEL_TANIM_KODU === value)
          : ""
      }></DropDownList>
  )
}

export default ExcelTypeDropDownList;