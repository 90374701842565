import React from "react";
import { Route } from "react-router-dom";
import Unauthorized from "../sys-common/Unauthorized";
import { useSelector } from "react-redux";
import LoadingPanel from "./LoadingPanel";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const menuList = useSelector((state) => state.menuListReducer);
  const isAuthenticated = menuList._menuList.find(
    (x) => x.menU_URL === restOfProps.protectedPath
  );
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        menuList.fetching === true ? (
          LoadingPanel
        ) : isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Unauthorized />
        )
      }
    />
  );
}

export default ProtectedRoute;
