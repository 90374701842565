import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import GridNumberFormatCell from "../sys-common/GridNumberFormatCell";

export const TermOffsetSummaryColumns = [
  {
    field: "finM_MAHSUP_MST_ID",
    title: "MAHSUP MST ID",
    width: "150px",
    type: "numeric",
    filter: "numeric",
  },
  {
    field: "bayI_KODU",
    title: "BAYİ KODU",
    width: "256px",
    type: "string",
  },
  {
    field: "finM_DONEM_TANIM_ID",
    title: "DÖNEM TANIM ID",
    width: "150px",
    type: "numeric",
  },
  {
    field: "mahsuP_TIPI",
    title: "MAHSUP TİPİ",
    width: "150px",
  },
  {
    field: "alacaK_BORC",
    title: "ALACAK BORÇ",
    width: "256px",
  },
  {
    field: "belgE_TURU",
    title: "BELGE TÜRÜ",
    width: "256px",
  },
  {
    field: "referanS_NO_2",
    title: "REFERANS NO",
    width: "256px",
  },
  {
    field: "vadE_TARIHI",
    title: "VADE TARİHİ",
    width: "256px",
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    filterable: false,
  },
  {
    field: "tutar",
    title: "TUTAR",
    width: "256px",
    cell: (props) => GridNumberFormatCell(props, 2),
    filter: "numeric",
    filterable: false,
  },
  {
    field: "parA_BIRIMI",
    title: "PARA BİRİMİ",
    width: "256px",
  },
  {
    field: "kredI_KONTROL_ALANI",
    title: "KREDİ KONTROL ALANI",
    width: "256px",
  },
  {
    field: "belgE_NO",
    title: "BELGE NO",
    width: "256px",
  },
  {
    field: "ihtaR_ALANI",
    title: "İHTAR ALANI",
    width: "256px",
  },
  {
    field: "belgE_TARIHI",
    title: "BELGE TARİHİ",
    width: "256px",
    cell: (props) => GridDateFormatCell(props, "dd.MM.yyyy"),
    type: "date",
    filterable: false,
  },
  {
    field: "tutaR_TRY",
    title: "TUTAR (TRY)",
    width: "256px",
  },
  {
    field: "aciklama",
    title: "AÇIKLAMA",
    width: "256px",
  },
  {
    field: "vadE_GUN",
    title: "VADE GÜN",
    width: "256px",
  },
  {
    field: "mahsuP_KODU",
    title: "MAHSUP KODU",
    width: "256px",
  },
  {
    field: "mahsuP_EDILEN_TUTAR",
    title: "MAHSUP EDİLEN TUTAR",
    width: "256px",
  },
  {
    field: "mahsuP_KODU_DA",
    title: "MAHSUP KODU DA",
    width: "256px",
  }
];
