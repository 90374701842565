import React, { Component } from "react";

import { Window } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { IntlProvider, LocalizationProvider } from "@progress/kendo-react-intl";
import { Upload, UploadFileStatus } from "@progress/kendo-react-upload";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import { API_GATEWAY, MS_SENDER } from "../../config/globalVariables";
import {
  changeExcelVisibility,
  changeExcelVisibilityData,
} from "../../redux/actions/act-root/excelActions";
import ExcelService from "../../services/srv-excel/ExcelService";
import { LinearProgress } from "@material-ui/core";

class OrderProductImport extends Component {
  state = {
    hasError: false,
  };

  /**
   *
   * @param {import("@progress/kendo-react-upload").UploadOnBeforeUploadEvent} event
   */
  onBeforeUpload = (event) => {
    event.additionalData = {
      p_pk1: this.props.orderId.toString(),
      localpath: "DEX",
      excelTanimKodu: "SIPARIS_EXCEL_COKLU_URUN",
    };
  };

  /**
   *
   * @param {import("@progress/kendo-react-upload").UploadOnStatusChangeEvent} event
   */
  onStatusChange = (event) => {
    console.log("onStatusChange=>", event);
    const [affectedFile] = event.affectedFiles;
    if (affectedFile.status === UploadFileStatus.UploadFailed) {
      if (event.response?.response.status === 400) {
        this.props.changeExcelVisibilityData(
          !this.props.excel.errorVisibilty,
          event.response.response.data
        );
        //alert(event.response.response.data.p_sonuc);
        this.setState({ hasError: true });
      } else {
        alert("Bir Hata Oluştu");
      }
    }
    if (affectedFile.status === UploadFileStatus.Uploaded) {
      alert("İşlem başarılı");
      this.setState({ hasError: false });
      if (this.props.onUploadSuccess) {
        this.props.onUploadSuccess();
      }
    }
  };
  showErrors = () => {
    const newDataState = {
      filter: {
        logic: "or",
        filters: [
          {
            field: "DKN_GENEL_EXCEL_UPLOAD_PROC_ID",
            operator: "eq",
            value: this.props.excel.errorProcId,
          },
        ],
      },
    };
    this.setState({ errorFetching: true });
    ExcelService.getExcelErrorsByProcId(newDataState)
      .then((result) => {
        this.setState({ errors: result.data });
        console.log(result);
      })
      .finally(() => this.setState({ errorFetching: false }));
  };
  render() {
    return (
      <Window
        title={this.props.title}
        onClose={this.props.toggleDialog}
        initialHeight={"50vh"}
        initialWidth={"40%"}
        style={{
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          position: "absolute",
        }}
      >
        {" "}
        <Row>
          <Col lg="6" md="6" xs="12">
            <h2>Excel Şablonu İndir</h2>
            <Button
              className="btn btn-success btn-sm"
              href={process.env.PUBLIC_URL + "/SiparisTopluUrun.xlsx"}
            >
              <i className="k-icon k-i-excel"></i> SiparisTopluUrun.xlsx
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" xs="12">
            <LocalizationProvider language="tr-TR">
              <IntlProvider locale="tr">
                <Upload
                  batch={true}
                  defaultFiles={[]}
                  multiple={false}
                  withCredentials={false}
                  onBeforeUpload={this.onBeforeUpload}
                  onProgress={(e) => console.log(e)}
                  saveHeaders={{
                    Authorization: "Bearer " + localStorage.getItem("jwtToken"),
                  }}
                  onStatusChange={this.onStatusChange}
                  saveUrl={`${API_GATEWAY + MS_SENDER}/excel/add`}
                />
              </IntlProvider>
            </LocalizationProvider>
          </Col>
        </Row>
        <br></br>
        {this.state.hasError ? (
          <Row>
            <Col lg="12" md="12" xs="12" style={{ textAlign: "center" }}>
              <Button
                id="updateStatus"
                color="danger"
                type="button"
                size="sm"
                onClick={this.showErrors}
              >
                Hataları Göster
              </Button>
            </Col>
            <Col lg="12" md="12" xs="12" style={{ textAlign: "center" }}>
              {this.state.errorFetching && <LinearProgress />}
              {this.state.errors && (
                <Grid data={this.state.errors}>
                  <GridColumn field={"alaN1"} title={"Ürün Kodu"} />
                  <GridColumn field={"alaN2"} width={150} title={"Adet"} />
                  <GridColumn
                    field={"alaN3"}
                    width={150}
                    title={"İlave İskonto"}
                  />
                  <GridColumn
                    field={"alaN4"}
                    width={150}
                    title={"Hedef Tutar"}
                  />
                  <GridColumn width={200} field={"sonuc"} title={"Sonuç"} />
                </Grid>
              )}
            </Col>
          </Row>
        ) : (
          ""
        )}
        <br></br>
        <Row>
          <Col lg="12" md="12" xs="12" style={{ textAlign: "right" }}>
            <Button
              color="primary"
              type="button"
              size="sm"
              onClick={this.props.toggleDialog}
            >
              Tamam
            </Button>
          </Col>
        </Row>
      </Window>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    excel: state.excelReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    changeExcelVisibility: bindActionCreators(changeExcelVisibility, dispatch),
    changeExcelVisibilityData: bindActionCreators(
      changeExcelVisibilityData,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderProductImport);
