import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useState } from "react";
import RoleService from "../../services/srv-role/RoleService";

const RoleMultiSelect = ({ required, onChange, value }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setDataLoading(true);
    RoleService.getRoleCodeList(defaultDataHandler);
  }, []);

  const defaultDataHandler = ({ data }) => {
    setData(data);
    setFilteredData(data);
    setDataLoading(false);
  };

  const filterData = (e) => {
    setFilteredData([
      ...data.filter((x) =>
        x.rolE_KODU.toLowerCase().includes(e.filter.value.toLowerCase())
      ),
    ]);
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <MultiSelect
      name="rolE_KODU"
      id="rolE_KODU"
      label="Roller"
      data={filteredData}
      textField="rolE_KODU"
      dataItemKey="rolE_KODU"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={filterData}
      loading={dataLoading}
      placeholder="Rolleri seçiniz."
      value={
        data && value.length > 0
          ? data.filter((a) => value.includes(a.rolE_KODU))
          : []
      }
    ></MultiSelect>
  );
};

export default RoleMultiSelect;
