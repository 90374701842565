import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import BryProductTypeService from "../../services/srv-product/BryProductTypeService";

const BryProductTypeDropDownList = ({
  id,
  label,
  value,
  required,
  onChange,
  filterField,
  filterValue,
  filterField2,
  filterValue2,
  readOnly
}) => {
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState([]);
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    var dataFilters = [];    
    if (filterField && filterValue) {
      dataFilters.push({
        field: filterField,
        operator: "eq",
        value: filterValue.toLowerCase(),
      });
    }

    if (filterField2 && filterValue2) {
      dataFilters.push({
        field: filterField2,
        operator: "eq",
        value: filterValue2.toLowerCase(),
      });
    }   
    getDataByFilter(dataFilters);
  }, []);

  const onCompletedGetData = (data) => {
    setData(data.data);
    setInitialData(data.data);
    setDataLoading(false);
  };

  const getDataByFilter = (filters) => {
    setDataLoading(true);
    BryProductTypeService.getProductTypes(
      {
        filter: { logic: "and", filters: filters },
      },
      onCompletedGetData
    );
  };    
  

  const onFilterData = (event) => {
    if (event.filter.value.length > 1) {
      setTimeout(() => {
        setData({
          ...initialData,
          data: [
            ...initialData.data.filter((x) =>
              x.brY_URUN_TIPI
                .toLocaleLowerCase("tr")
                .includes(event.filter.value.toLocaleLowerCase("tr"))
            ),
          ],
        });
      }, 500);
    }
  };

  const itemRender = (li, itemProps) => {
    var itemChildren = (
      <div>
        <div>
          <span style={{ fontSize: "15px" }}>{li.props.children}</span>
        </div>
        <div>
          <span style={{ fontSize: "12px", color: "blue" }}>
            ({itemProps.dataItem.brY_URUN_TIPI_ACIKLAMA})
          </span>
        </div>
      </div>
    );

    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <DropDownList
      name={id}
      id={id}
      label={label ? label : undefined}
      data={data}
      textField="brY_URUN_TIPI_ACIKLAMA"
      dataItemKey="brY_URUN_TIPI"
      style={{ width: "100%" }}
      onChange={onChange}
      required={required}
      disabled={readOnly}
      itemRender={itemRender}
      filterable={true}
      onFilterChange={(event) => onFilterData(event)}
      loading={dataLoading}
      value={value ? data.find((v) => v.brY_URUN_TIPI === value) : ""}
    ></DropDownList>
  );
};

export default BryProductTypeDropDownList;
