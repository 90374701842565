import React, { Component } from 'react';

import { connect } from 'react-redux';

import {
  TabStrip,
  TabStripTab,
} from '@progress/kendo-react-layout';

import CustomerGroupProductGroupList from './CustomerGroupProductGroupList';
import CustomerGroupProductList from './CustomerGroupProductList';
import CustomerProductGroupList from './CustomerProductGroupList';
import CustomerProductList from './CustomerProductList';
import SalesChannelProductGroupList from './SalesChannelProductGroupList';
import SalesChannelProductList from './SalesChannelProductList';

class CustomerProductDetail extends Component {
  state = {
    selected: 0,
  };

  onSelect = (e) => {
    this.setState({ selected: e.selected });
  };
  render() {
    return (
      <main style={{ flexGrow: "1", padding: "40px" }}>
        <TabStrip
          selected={this.state.selected}
          onSelect={this.onSelect}
          // scrollable={true}
        >
          <TabStripTab title="Müşteri Ürün">
            <CustomerProductList />
          </TabStripTab>
          <TabStripTab title="Müşteri Ürün Grubu">
            <CustomerProductGroupList />
          </TabStripTab>
          <TabStripTab title="Müşteri Grubu Ürün">
            <CustomerGroupProductList />
          </TabStripTab>
          <TabStripTab title="Müşteri Grubu Ürün Grubu">
            <CustomerGroupProductGroupList />
          </TabStripTab>
          <TabStripTab title="Satış Kanalı Ürünü">
            <SalesChannelProductList />
          </TabStripTab>
          <TabStripTab title="Satış Kanalı Ürün Grubu">
            <SalesChannelProductGroupList />
          </TabStripTab>
        </TabStrip>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerProductDetail);
