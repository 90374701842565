import { DateInput } from "@progress/kendo-react-dateinputs";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Card, CardBody, CardTitle } from "@progress/kendo-react-layout";
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import {
  executeOnayHierarchy,
  getOrder,
} from "../../redux/actions/act-order/orderActions";
import { getOrderApproveLog } from "../../redux/actions/act-order/orderApprovalActions";
import { getOrderProducts } from "../../redux/actions/act-order/orderProductActions";
import GridDateFormatCell from "../sys-common/GridDateFormatCell";
import LoadingPanel from "../sys-toolbox/LoadingPanel";
import OrderDiscountProductGroupSalesApprovalList from "./OrderDiscountProductGroupSalesApprovalList";

class OrderApproval extends Component {
  isExecutedHieararchy = false;

  state = {
    aciklama: null,
    satisonayid: this.props.match.params.satisonayid,
    time: 120,
    showContent: true,
  };

  componentDidMount() {
    this.props.getOrder(this.props.match.params.id);
    this.props.getOrderProducts(this.props.match.params.id);
    this.props.getOrderApproveLog(this.props.match.params.id);
    setInterval(this.timer, 1000);
  }

  componentDidUpdate() {
    if (
      this.isExecutedHieararchy === true &&
      this.props.orderApproval.dataUpdated === true
    ) {
      this.isExecutedHieararchy = false;
      //// ONAYDAN SONRA NE YAPILACAKSA.
    }
  }

  timer = () => {
    if (this.state.time === 0) {
      this.setState({ showContent: false });
    } else {
      this.setState({ time: this.state.time - 1 });
    }
  };

  addTime = () => {
    this.setState({ time: this.state.time + 60 });
  };

  rowRender = (trElement, dataItem) => {
    const trProps = {
      ...trElement.props,
      className: "ellipsis",
    };

    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  onChange = (event) => {
    this.setState({ aciklama: event.value });
  };

  executeOnayHierarchy = (islem) => {
    this.isExecutedHieararchy = true;
    var satisOnay = {
      ISLEM: islem,
      BRY_SIPARIS_ID: this.props.order._order.brY_SIPARIS_ID,
      SATIS_ONAY_ID: parseInt(this.state.satisonayid),
      ACIKLAMA: this.state.aciklama,
    };

    this.props.executeOnayHierarchy(satisOnay);
  };

  PercentCell = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return <td>% {dataValue}</td>;
  };

  FooterSumCell = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam :<br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        Adet
      </td>
    );
  };

  FooterSumCellCurrency = (props) => {
    var total = this.getTotalByField(props.field);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        Toplam : <br></br>
        <NumberFormat
          thousandSeparator={"."}
          value={total ? total : ""}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>{" "}
        {this.props.order._order.parA_BIRIMI}
      </td>
    );
  };

  getTotalByField = (field) => {
    return this.props.orderProductList.orderProducts
      .filter((a) => a.puto !== "DET")
      .reduce((acc, current) => acc + current[field], 0);
  };

  PutoCellNumber = (props) => {
    var { dataItem, field } = props;
    var dataValue = dataItem[field] === null ? "" : dataItem[field];

    return this.NumberFormatCell(dataValue, props.dataItem, false, false);
  };

  NumberFormatCell = (value, dataItem, isPercent, isKDV) => {
    return (
      <td
        style={{
          fontStyle: dataItem.puto === "DET" ? "italic" : "normal",
          color: dataItem.puto === "DET" ? "gray" : "rgba(0,0,0,0.78)",
        }}
      >
        {isPercent ? "% " : ""}
        <NumberFormat
          thousandSeparator={"."}
          value={value ? (isKDV ? value * 100 : value) : 0}
          displayType={"text"}
          decimalSeparator={","}
          decimalScale="2"
        ></NumberFormat>
      </td>
    );
  };

  render() {
    const data = this.props.order._order;
    return this.state.showContent ? (
      this.props.orderApproval.fetching === true ? (
        LoadingPanel
      ) : (
        <main style={{ flexGrow: "1", padding: "40px" }}>
          <h3>Sipariş Onay-Red İşlemleri</h3>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardBody>
              <CardTitle>
                <img
                  width="50px"
                  height="50px"
                  alt=""
                  src={
                    window.location.origin +
                    process.env.PUBLIC_URL +
                    "/dex_logo.png"
                  }
                />
                Bu ekranda işlem yapabilmek için 2 dakikanız bulunmaktadır.
              </CardTitle>
              <hr></hr>
              <div style={{ fontSize: "15px", color: "red" }}>
                Kalan Süre : {this.state.time}
                <Button
                  type="button"
                  color="primary"
                  size="md"
                  style={{ marginLeft: "20px" }}
                  onClick={() => this.addTime()}
                >
                  <i className="k-icon k-i-plus-circle"></i> Süre Ekle
                </Button>
              </div>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
            }}
          >
            <CardBody>
              <CardTitle>Sipariş Bilgileri</CardTitle>
              <hr></hr>
              <Row>
                <Col lg="4" md="4" xs="12">
                  <Input
                    label="Sipariş No"
                    name="brY_SIPARIS_ID"
                    id="brY_SIPARIS_ID"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.brY_SIPARIS_ID}
                  ></Input>
                  <Input
                    label="Bayi Kodu"
                    name="bayI_KODU"
                    id="bayI_KODU"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.bayI_KODU}
                  ></Input>
                  <Input
                    label="Bayi Adı"
                    name="bayI_ADI"
                    id="bayI_ADI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.bayI_ADI}
                  ></Input>
                  <Input
                    name="satiS_DEPARTMANI_KODU"
                    label="Satış Departmanı"
                    style={{ width: "100%" }}
                    value={"UPG"}
                    disabled={true}
                  />
                  <Input
                    label="Ana Ürün Grubu"
                    name="satiS_DEPARTMANI_KODU"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={"UPG"}
                  />
                  <Input
                    label="Ana Ürün Grubu"
                    name="satiS_DEPARTMANI_KODU"
                    id="satiS_DEPARTMANI_KODU"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.satiS_DEPARTMANI_KODU}
                  ></Input>
                  <Input
                    label="Marka"
                    name="brY_KANAL"
                    id="brY_KANAL"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.brY_KANAL}
                  ></Input>
                  <Input
                    label="Onay Durum"
                    name="onaY_DURUM"
                    id="onaY_DURUM"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.onaY_DURUM}
                  ></Input>
                  <Input
                    label="Satış Onay Durum"
                    name="satiS_ONAY_DURUM"
                    id="satiS_ONAY_DURUM"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.satiS_ONAY_DURUM}
                  ></Input>
                </Col>
                <Col lg="4" md="4" xs="12">
                  <DateInput
                    label="Sipariş Giriş Tarihi"
                    format="dd.MM.yyyy"
                    formatPlaceholder="formatPattern"
                    value={new Date(data.crE_DATE)}
                    style={{ width: "100%" }}
                    disabled={true}
                  />
                  <Input
                    label="Sipariş Türü"
                    name="sipariS_TURU"
                    id="sipariS_TURU"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.sipariS_TURU}
                  ></Input>
                  <Input
                    label="Kampanya"
                    name="kampanyA_KODU"
                    id="kampanyA_KODU"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.kampanyA_KODU}
                  ></Input>
                  <Input
                    label="Liste Fiyatı Vadesi"
                    name="listE_FIYATI_VADESI"
                    id="listE_FIYATI_VADESI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.listE_FIYATI_VADESI}
                  ></Input>
                  <Input
                    label="Fatura Vadesi"
                    name="faturA_VADESI"
                    id="faturA_VADESI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.faturA_VADESI}
                  ></Input>
                  <Input
                    label="Para Birimi"
                    name="parA_BIRIMI"
                    id="parA_BIRIMI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.parA_BIRIMI}
                  ></Input>
                  <Input
                    label="Ödeme Tipi"
                    name="odemE_TIPI"
                    id="odemE_TIPI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.odemE_TIPI}
                  ></Input>
                </Col>
                <Col lg="4" md="4" xs="12">
                  <Input
                    label="Firma Yetkili Kişi"
                    name="firmA_YETKILI_KISI"
                    id="firmA_YETKILI_KISI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.firmA_YETKILI_KISI}
                  ></Input>
                  <Input
                    label="Sevk Edilecek Kişi"
                    name="sevK_EDILECEK_KISI"
                    id="sevK_EDILECEK_KISI"
                    style={{ width: "100%" }}
                    disabled={true}
                    value={data.sevK_EDILECEK_KISI}
                  ></Input>
                  <DateInput
                    label="Sevk Tarihi"
                    format="dd.MM.yyyy"
                    formatPlaceholder="formatPattern"
                    value={
                      data.sevK_TARIHI !== null
                        ? new Date(data.sevK_TARIHI)
                        : null
                    }
                    style={{ width: "100%" }}
                    disabled={true}
                  />
                  <div style={{ color: "#c1c1c1" }}>Sevk Adresi</div>
                  <TextArea
                    placeholder="Sevk Adresi"
                    id="sevK_ADRESI"
                    name="sevK_ADRESI"
                    autoSize={false}
                    style={{ width: "100%" }}
                    value={data.sevK_ADRESI}
                    disabled={true}
                  ></TextArea>
                  <div style={{ color: "#c1c1c1" }}>Sipariş Açıklaması</div>
                  <TextArea
                    placeholder="Açıklama"
                    id="aciklama"
                    name="aciklama"
                    autoSize={false}
                    style={{ width: "100%" }}
                    value={data.aciklama}
                    disabled={true}
                  ></TextArea>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <CardBody>
              <CardTitle>Ürün Listesi</CardTitle>
              <hr></hr>
              {this.props.orderProductList.fetching && LoadingPanel}
              <ExcelExport
                data={this.props.orderProductList.orderProducts}
                ref={(exporter) => (this._export = exporter)}
              >
                <Grid
                  data={this.props.orderProductList.orderProducts.sort(
                    (a, b) => b.ilavE_ISKONTO - a.ilavE_ISKONTO
                  )}
                  {...this.props.orderProductList.dataState}
                  onDataStateChange={this.dataStateChange}
                  sortable={true}
                  resizable
                  rowRender={(trElement, dataItem) =>
                    this.rowRender(trElement, dataItem)
                  }
                >
                  <GridColumn
                    field="uruN_KODU"
                    title="Ürün Kodu"
                    width="90px"
                  ></GridColumn>
                  <GridColumn
                    field="uruN_ADI"
                    title="Ürün Adı"
                    width="250px"
                  ></GridColumn>
                  <GridColumn
                    field="adet"
                    title="Adet"
                    width="90px"
                    filterable={false}
                    editor="numeric"
                    footerCell={this.FooterSumCell}
                  ></GridColumn>
                  <GridColumn
                    field="listE_BIRIM_FIYATI_KDV_SIZ"
                    title={
                      "Liste Birim Fiyatı (" +
                      this.props.order._order.parA_BIRIMI +
                      ")"
                    }
                    width="120px"
                    filterable={false}
                    editable={false}
                    footerCell={this.FooterSumCellCurrency}
                    cell={this.PutoCellNumber}
                  ></GridColumn>
                  <GridColumn
                    field="kdv"
                    title="KDV"
                    width="60px"
                    filterable={false}
                    format="{0:p}"
                  ></GridColumn>
                  <GridColumn
                    field="listE_BIRIM_FIYATI_KDV_LI"
                    title={
                      "KDV'li Liste Birim Fiyatı (" +
                      this.props.order._order.parA_BIRIMI +
                      ")"
                    }
                    width="120px"
                    filterable={false}
                    footerCell={this.FooterSumCellCurrency}
                    cell={this.PutoCellNumber}
                  ></GridColumn>
                  <GridColumn
                    field="standarT_ISKONTO"
                    title="Standart İskonto"
                    width="100px"
                    filterable={false}
                    editable={false}
                    cell={this.PercentCell}
                  ></GridColumn>
                  <GridColumn
                    field="kampanyA_ISKONTO"
                    title="Kampanya İskonto"
                    width="100px"
                    filterable={false}
                    cell={this.PercentCell}
                  ></GridColumn>
                  <GridColumn
                    field="ilavE_ISKONTO"
                    title="İlave İskonto"
                    width="100px"
                    filter="numeric"
                    filterable={false}
                    cell={this.PercentCell}
                  ></GridColumn>
                  <GridColumn
                    field="satiS_BIRIM_FIYAT_KDV_SIZ"
                    title={
                      "KDV'siz Birim Fiyat (" +
                      this.props.order._order.parA_BIRIMI +
                      ")"
                    }
                    width="120px"
                    filterable={false}
                    cell={this.PutoCellNumber}
                    footerCell={this.FooterSumCellCurrency}
                  ></GridColumn>
                  <GridColumn
                    field="toplaM_FIYAT_KDV_SIZ"
                    title={
                      "KDV'siz Toplam Fiyat (" +
                      this.props.order._order.parA_BIRIMI +
                      ")"
                    }
                    width="120px"
                    filterable={false}
                    cell={this.PutoCellNumber}
                    footerCell={this.FooterSumCellCurrency}
                  ></GridColumn>
                </Grid>
              </ExcelExport>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <CardBody>
              <CardTitle>Satış Onay Discount Ürün Grubu Listesi</CardTitle>
              <hr></hr>
              <OrderDiscountProductGroupSalesApprovalList
                orderId={data.brY_SIPARIS_ID}
              ></OrderDiscountProductGroupSalesApprovalList>
            </CardBody>
          </Card>
          <Card
            style={{
              width: "100%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <CardBody>
              <Row>
                <Col
                  lg={{ size: 6, offset: 3 }}
                  md={{ size: 6, offset: 3 }}
                  xs={{ size: 12 }}
                >
                  <div>Onay/Red Açıklaması</div>
                  <TextArea
                    placeholder="Açıklama"
                    id="onayRedAciklama"
                    name="onayRedAciklama"
                    autoSize={false}
                    style={{ width: "100%" }}
                    value={this.state.aciklama}
                    onChange={this.onChange}
                  ></TextArea>
                  <div style={{ marginTop: "10px", textAlign: "center" }}>
                    {" "}
                    <Button
                      type="button"
                      color="success"
                      size="md"
                      onClick={() => this.executeOnayHierarchy("ONAY")}
                    >
                      <i className="k-icon k-i-check"></i> ONAYLA
                    </Button>
                    <Button
                      type="button"
                      color="danger"
                      size="md"
                      style={{ marginLeft: "5px" }}
                      onClick={() => this.executeOnayHierarchy("RED")}
                    >
                      <i className="k-icon k-i-times"></i> REDDET
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {this.props.orderApproval.approveLogs.length > 0 ? (
            <Card
              style={{
                width: "100%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <CardBody>
                <CardTitle>Onay Red Geçmişi</CardTitle>
                <hr></hr>
                {this.props.orderApproval.fetching && LoadingPanel}
                <Grid
                  data={this.props.orderApproval.approveLogs.filter(
                    (a) => a.onaY_RED_TARIHI !== null
                  )}
                  sortable={false}
                  resizable={false}
                >
                  <GridColumn
                    field="satiS_ONAYCI_UNVAN"
                    title="Onay Hiyerarşisi"
                  ></GridColumn>
                  <GridColumn
                    field="satiS_ONAYCI_ADSOYAD"
                    title="Ad Soyad"
                  ></GridColumn>
                  <GridColumn field="onaY_RED" title="Onay/Red"></GridColumn>
                  <GridColumn
                    field="onaY_RED_TARIHI"
                    title="Onay Red Tarihi"
                    cell={(props) =>
                      GridDateFormatCell(props, "dd.MM.yyyy HH:mm")
                    }
                  ></GridColumn>
                  <GridColumn field="aciklama" title="Açıklama"></GridColumn>
                </Grid>
              </CardBody>
            </Card>
          ) : undefined}
        </main>
      )
    ) : (
      "Süreniz dolmuştur, lütfen mailinizden tekrar giriş yapınız."
    );
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orderReducer,
    orderProductList: state.orderProductListReducer,
    orderApproval: state.orderApprovalReducer,
    auth: state.authReducer,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    getOrder: bindActionCreators(getOrder, dispatch),
    getOrderProducts: bindActionCreators(getOrderProducts, dispatch),
    executeOnayHierarchy: bindActionCreators(executeOnayHierarchy, dispatch),
    getOrderApproveLog: bindActionCreators(getOrderApproveLog, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderApproval);
